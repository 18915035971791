import React, { useState, useMemo, useEffect } from "react";
import { useHistory } from "react-router";

import "../SneakerTinder.css";
import TinderCard from "react-tinder-card";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import GradeIcon from "@material-ui/icons/Grade";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import { IconButton } from "@material-ui/core";
// import shoes from "assets/custom/shoe_data.js";
import SneakerTinderSubmit from "../SneakerTinderSubmit";
import useWindowDimensions from "hooks/useWindowDimensions";
import { database, dbUserReview } from "../../../../../firebase.js";
import { Row, Col } from "reactstrap";
import FavoriteIcon from "@material-ui/icons/Favorite";

const alreadyRemoved = [];
// let charactersState = shoes_fb;

export default function Recom2SneakerTinder({
  setShowButton,
  isSubmit,
  isChange,
  reviews,
  currentUserID,
  fbShoes,
  allfbShoes,
  stripeURL,
}) {
  // answers will contain the responses of the user. An element of the array will be an object containing id and response as keys
  // const [answers, setAnswers] = useState([]);
  const [count, setCount] = useState(1);
  const history = useHistory();

  const [characters, setCharacters] = useState(fbShoes);
  const [charactersState, setCharactersState] = useState(fbShoes);
  const [lastDirection, setLastDirection] = useState();
  const [displaySubmit, setDisplaySubmit] = useState(false);
  const [isMounted, setIsMounted] = useState(!isSubmit);
  const [isLoading, setIsLoading] = useState(false);
  // let charactersState;
  console.log("characters2");

  console.log(characters);
  console.log(fbShoes);

  const childRefs = useMemo(
    () =>
      Array(10)
        .fill(0)
        .map((i) => React.createRef()),
    []
  );
  console.log(childRefs);
  const Stopwatch = require("statman-stopwatch");
  const sw = new Stopwatch(true);

  function pushDataToFirebase(character, val) {
    const ref = dbUserReview(currentUserID);
    sw.stop();
    const obj = {
      shoeID: character.shoeID,
      val: val,
      timer: sw.read(0),
      createdAt: Date.now(),
    };
    ref.push(obj);
    sw.reset();
    sw.start();
  }

  const swiped = (direction, character) => {
    // console.log('removing: ' + idToDelete)
    setLastDirection(direction);
    setCount((prevState) => {
      return prevState + 1;
    });
    var val = -2;
    switch (direction) {
      case "left":
        val = 0;
        break;
      case "right":
        val = 1;
        break;
      default:
        val = 2;
    }
    pushDataToFirebase(character, val);
    // setAnswers((arr) => [...arr, { id: character.id, response: val }]);
    alreadyRemoved.indexOf(character.shoeID) === -1
      ? alreadyRemoved.push(character.shoeID)
      : console.log("seh");
    if (alreadyRemoved.length === 10) {
      console.log(alreadyRemoved.size);
      setIsMounted(false);
    }
    const setAlready = new Set(alreadyRemoved);
  };

  const outOfFrame = (id) => {
    // console.log(id + ' left the screen!');
    setCharactersState(
      charactersState.filter((character) => character.shoeID !== id)
    );
    setCharacters(charactersState);
  };

  const swipe = (dir) => {
    const cardsLeft = characters.filter(
      (person) => !alreadyRemoved.includes(person.shoeID)
    );
    if (cardsLeft.length) {
      const toBeRemoved = cardsLeft[cardsLeft.length - 1].shoeID; // Find the card object to be removed
      const index = fbShoes.map((person) => person.shoeID).indexOf(toBeRemoved); // Find the index of which to make the reference to
      alreadyRemoved.indexOf(toBeRemoved) === -1
        ? alreadyRemoved.push(toBeRemoved)
        : console.log("");
      // alreadyRemoved.push(toBeRemoved); // Make sure the next card gets removed next time if this card do not have time to exit the screen
      console.log(index);
      childRefs[index].current.swipe(dir); // Swipe the card!
    }
  };

  // reference: https://dev.to/oussel/how-to-use-conditional-rendering-with-animation-in-react-1k20
  const mountedStyle = {
    animation: "inAnimation 400ms ease-in",
  };

  const unmountedStyle = {
    animation: "outAnimation 400ms ease-out",
    animationFillMode: "forwards",
  };

  useEffect(() => {
    if (isSubmit) setDisplaySubmit(true);
  }, [isSubmit]);

  const { width, height } = useWindowDimensions();
  var mobile, landscape;
  if (width < 768) {
    mobile = true;
    landscape = false;
  } else if (height < 576 || width < 992) {
    mobile = false;
    landscape = true;
  } else {
    mobile = false;
    landscape = false;
  }

  var mystryTitle;
  if (height <= 414) {
    mystryTitle = (
      <span>
        <span style={{ color: "#FF6015", fontFamily: "edosz" }}>MYSTRY</span>
        &nbsp; SneakerSwipe
      </span>
    );
  } else {
    mystryTitle = (
      <span>
        <span style={{ color: "#FF6015", fontFamily: "edosz" }}>MYSTRY</span>
        <br />
        SneakerSwipe
      </span>
    );
  }

  const infoText = (direction) => {
    if (direction === "right") {
      return "copped";
    } else if (direction === "left") {
      return "dropped";
    } else {
      return "superliked";
    }
  };

  const getWidth = () => {
    return mobile ? "85vw" : "55vh";
  };

  const getHeight = () => {
    return mobile ? "60vh" : "60vh";
  };

  return (
    <>
      <div className="mainContainer">
        {!isSubmit && !displaySubmit && (
          <div
            style={isMounted ? mountedStyle : unmountedStyle}
            onAnimationEnd={() => {
              if (!isMounted) setDisplaySubmit(true);
            }}
          >
            <img
              className="mb-2"
              style={{
                paddingLeft: mobile ? "30px" : "",
                paddingRight: mobile ? "30px" : "",
              }}
              src={
                require("assets/custom/SneakerTinder/SneakerTinderLogo.png")
                  .default
              }
            />
            <div
              className="cardContainer"
              style={{
                paddingTop: "5px",
                marginTop: "5px",
              }}
            >
              {!mobile && (
                <img
                  src={require("assets/custom/Mobile.png").default}
                  style={{
                    position: "absolute",
                    height: "80vh",
                  }}
                />
              )}
              {characters.map((character, index) => (
                <TinderCard
                  ref={childRefs[index]}
                  className="swipe"
                  key={character.shoeID}
                  onSwipe={(dir) => swiped(dir, character)}
                  onCardLeftScreen={() => outOfFrame(character.shoeID)}
                  style={{
                    paddingTop: "5px",
                    marginTop: "5px",
                    borderradius: "40px",
                  }}
                >
                  <Col
                    className="d-flex justify-content-center align-items-center"
                    style={{
                      height: mobile ? "65vh" : "52vh",
                      width: mobile ? "85vw" : "32vh",
                      top: mobile ? "2vh" : "9vh",
                      backgroundColor: "#131313",
                      border: "1px solid white",
                      padding: "15px 10px px 10px",
                      borderRadius: "25px",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "2vh",
                      }}
                    >
                      <Col>
                        <div
                          style={{
                            height: mobile ? "32vh" : "24vh",
                            width: "100%",
                            minWidth: mobile ? "75vw" : "25vh",
                            backgroundImage:
                              "url(" + character.shoeImg.highRes.front + ")",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            backgroundColor: "#ffffff",
                            borderRadius: "15px",
                            backgroundSize: "95%",
                            marginBottom: "20px",
                          }}
                          className="card"
                        />
                        <Row
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            fontWeight: "600",
                            fontSize: mobile ? "3vh" : "18.5px",
                            textShadow: "2px 2px 5px #000000",
                            color: "#fff",
                            marginBottom: "15px",
                            marginRight: "5px",
                            marginLeft: "5px",
                          }}
                        >
                          {character.shoeName}
                        </Row>
                        <Row
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#fff",
                            fontSize: mobile ? "2vh" : "12px",
                            marginBottom: "10px",
                          }}
                        >
                          {character.shoeSKU}
                        </Row>
                        <Row
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#fff",
                            fontSize: mobile ? "2vh" : "14px",
                            marginBottom: "10px",
                          }}
                        >
                          <div>
                            <i>Release</i>
                            <br />"{character.releaseYear}"
                          </div>
                        </Row>
                      </Col>
                    </div>
                  </Col>
                </TinderCard>
              ))}
              {/* {lastDirection ? (
								<h2 className="infoText">You {infoText(lastDirection)}</h2>
							) : (
								<h2 className="infoText">Begin swiping</h2>
							)} */}
            </div>
            <div className="buttons">
              <IconButton
                onClick={() => swipe("left")}
                style={{ color: "white", background: "#f74040", margin: "4px" }}
              >
                <ThumbDownIcon fontSize="small" />
              </IconButton>
              <IconButton
                onClick={() => swipe("up")}
                style={{ color: "white", background: "#cd5c7e", margin: "4px" }}
              >
                <FavoriteIcon fontSize="large" />
              </IconButton>
              <IconButton
                onClick={() => swipe("right")}
                style={{ color: "white", background: "#50fc59", margin: "4px" }}
              >
                <ThumbUpAltIcon fontSize="small" />
              </IconButton>
            </div>
          </div>
        )}
        {displaySubmit &&
          history.push({
            pathname: "/waitlist",
            state: { stripeCheckout: stripeURL },
          }) && (
            <div></div>
            // <div style={!isMounted ? mountedStyle : unmountedStyle}>
            //   <SneakerTinderSubmit
            //     setShowButton={setShowButton}
            //     isComplete={isSubmit}
            //     currentUserID={currentUserID}
            //     mobile={mobile}
            //     landscape={landscape}
            //   />
            // </div>
          )}
      </div>
    </>
  );
}
