import {React, useState} from 'react';
import useWindowDimensions from "hooks/useWindowDimensions";
import "./Metaverse.css";

const Metaverse = () => {
  const { width, height } = useWindowDimensions();
  var mobile, landscape;
  if (width < 768) {
    mobile = true;
    landscape = false;
  } else if (height < 576 || width < 992) {
    mobile = false;
    landscape = true;
  } else {
    mobile = false;
    landscape = false;
  }

  const [muted, setMuted] = useState(true);
  
  function muteVideo(){
    document.getElementById("metavid").muted = !muted;
    setMuted(!muted);
  }

  return (
    <>
      <div
        style={{
          position: "relative",
          overflow: "hidden",
          background: "black",
        }}
      >
        <div 
          class="stage"
          style={{
            margin: mobile? "10px": "30px",
          }}
        >
          {!muted && (
            <div class="icon pulsing">
              <i 
                class="fas fa-volume-up align-middle" 
                style={{
                  fontSize: mobile? "20px" : "30px"
                }}>
              </i>
            </div>
          )}
          {muted && (
            <div class="icon">
              <i 
                class="fa fa-volume-off align-middle"
                style={{
                  fontSize: mobile? "20px" : "30px"
                }}>
              </i>
            </div>
          )}
        </div>
          <video
            onClick={() => muteVideo()}
            id="metavid"
            width="100%"
            autoPlay
            loop
            muted
            playsInline
            poster={require('assets/custom/Metaverse/MetaverseThumbnail.png').default}
          >
            <source
              src={
                require("assets/custom/Metaverse/metaverse.hevc.mp4")
                  .default
              }
              type="video/mp4; codecs=hvc1"
            ></source>
            <source
              src={
                require("assets/custom/Metaverse/metaverse.webm")
                  .default
              }
              type="video/webm"
            ></source>
            <source
              src={
                require("assets/custom/Metaverse/metaverse.mp4")
                  .default
              }
              type="video/mp4"
            ></source>
          </video>
      </div>
    </>
  );
};

export default Metaverse;
