import React from 'react';
import { btsData  as data} from './data.js';
import { Carousel as CarouselBTS} from "react-responsive-carousel";

function BTSCarouselMobile() {
  return (
   <>
      <CarouselBTS
        autoPlay={true}
        interval={8000}
        showArrows={true}
        showThumbs={false}
        showIndicators={false}
        showStatus={false}
        infiniteLoop={true}
      >
        {data.map((shoe) => {
          return(
            <div className='d-flex justify-content-center align-items-center'>
              <div style={{width:"75vw"}} >
                <p className='pb-4' style={{fontSize: "20px"}}>{shoe.title}</p>
                <img className='mb-4' src={shoe.image}/>
                <p>{shoe.description}</p>
              </div>
            </div>
          )
        })}
      </CarouselBTS>
    </>
  );
}

export default BTSCarouselMobile;