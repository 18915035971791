const US_STATES_TAX_MAP = new Map();
US_STATES_TAX_MAP.set("AL", 4);
US_STATES_TAX_MAP.set("AK", 0);
US_STATES_TAX_MAP.set("AZ", 5.6);
US_STATES_TAX_MAP.set("AR", 6.5);
US_STATES_TAX_MAP.set("CA", 7.25);
US_STATES_TAX_MAP.set("CO", 2.9);
US_STATES_TAX_MAP.set("CT", 6.35);
US_STATES_TAX_MAP.set("DE", 0);
// ! check dc
US_STATES_TAX_MAP.set("DC", 6);
US_STATES_TAX_MAP.set("FL", 6);
US_STATES_TAX_MAP.set("GA", 4);
US_STATES_TAX_MAP.set("HI", 4);
US_STATES_TAX_MAP.set("ID", 6);
US_STATES_TAX_MAP.set("IL", 6.25);
US_STATES_TAX_MAP.set("IN", 7);
US_STATES_TAX_MAP.set("IA", 6);
US_STATES_TAX_MAP.set("KS", 6.5);
US_STATES_TAX_MAP.set("KY", 6);
US_STATES_TAX_MAP.set("LA", 4.45);
US_STATES_TAX_MAP.set("ME", 5.5);
US_STATES_TAX_MAP.set("MD", 6);
US_STATES_TAX_MAP.set("MA", 6.25);
US_STATES_TAX_MAP.set("MI", 6);
US_STATES_TAX_MAP.set("MN", 6.88);
US_STATES_TAX_MAP.set("MS", 7);
US_STATES_TAX_MAP.set("MO", 4.23);
US_STATES_TAX_MAP.set("MT", 0);
US_STATES_TAX_MAP.set("NE", 5.5);
US_STATES_TAX_MAP.set("NV", 6.85);
US_STATES_TAX_MAP.set("NH", 0);
US_STATES_TAX_MAP.set("NJ", 6.63);
US_STATES_TAX_MAP.set("NM", 5.13);
US_STATES_TAX_MAP.set("NY", 4);
US_STATES_TAX_MAP.set("NC", 4.75);
US_STATES_TAX_MAP.set("ND", 5);
US_STATES_TAX_MAP.set("OH", 5.75);
US_STATES_TAX_MAP.set("OK", 4.5);
US_STATES_TAX_MAP.set("OR", 0);
US_STATES_TAX_MAP.set("PA", 6);
US_STATES_TAX_MAP.set("RI", 7);
US_STATES_TAX_MAP.set("SC", 6);
US_STATES_TAX_MAP.set("SD", 4.5);
US_STATES_TAX_MAP.set("TN", 7);
US_STATES_TAX_MAP.set("TX", 6.25);
US_STATES_TAX_MAP.set("UT", 5.95);
US_STATES_TAX_MAP.set("VT", 6);
US_STATES_TAX_MAP.set("VA", 5.3);
US_STATES_TAX_MAP.set("WA", 6.5);
US_STATES_TAX_MAP.set("WV", 6);
US_STATES_TAX_MAP.set("WI", 5);
US_STATES_TAX_MAP.set("WY", 4);
//! check pr
US_STATES_TAX_MAP.set("PR", 11.5);

export default US_STATES_TAX_MAP;
