import React from "react";
import { Link } from "react-router-dom";
import "../Blogs/Blog.css";
import { Container } from "@material-ui/core";
import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import Navbar from "components/Navbars/Navbar.js";
import Footer from "../pagesV2/Footer/Footer.js";
import useWindowDimensions from "hooks/useWindowDimensions";
import CarouselPage from "./BlogCarousel";

const Blog = () => {
  const { width, height } = useWindowDimensions();
  var mobile, landscape;
  if (width < 768) {
    mobile = true;
    landscape = false;
  } else if (height < 576 || width < 992) {
    mobile = false;
    landscape = true;
  } else {
    mobile = false;
    landscape = false;
  }
  return (
    <>
      <Navbar />
      <Container
        fluid={true}
        style={{
          paddingTop: "100px",
          paddingLeft: "5vw",
          paddingBottom: "2vh",
        }}
      >
        <h1 style={{ textAlign: "center" }}>Mystry Blog</h1>
        <h3 style={{ textAlign: "center" }}>
          Read all about new releases, brands, and how Mystry is built
        </h3>
      </Container>
      <CarouselPage />
      <Container
        style={{
          paddingBottom: "50px",
          paddingRight: "50px",
          paddingLeft: "50px",
        }}
      >
        <div class="row justify-content-between">
          <div class="col-md-12">
            <h2 class="font-weight-bold spanborder">
              <span>Recent Blogs</span>
            </h2>
            <br />
            <MDBCard
              className="text-white"
              style={{ backgroundColor: "black" }}
            >
              <MDBRow className="g-0">
                <MDBCol md="3">
                  <MDBCardImage
                    src={require("assets/custom/BlogImages/JB_1.jpg").default}
                    alt="..."
                    fluid
                  />
                </MDBCol>
                <MDBCol md="9" className="p-4">
                  <MDBCardBody>
                    <MDBCardTitle class="font-weight-bold spanborder">
                      <h3>Justin Bieber and Balenciaga </h3>
                    </MDBCardTitle>
                    <MDBCardText
                      style={{ textAlign: !mobile ? "justify" : "" }}
                    >
                      This year has been all about firsts for Demna Gvasalia,
                      the creative director of Balenciaga. It’s evident that
                      he’s clearing the ground for the historic maison’s next
                      phase, from designing a virtual apocalyptic gamer-verse
                      for the Fall/Winter 2021 collection to wiping the
                      Balenciaga Instagram clean ahead of his couture debut.
                      Now, with the release of a new Runner sneaker, the brand
                      has enlisted the help of Justin Bieber for a campaign —
                      and the results are exactly what we expected.
                    </MDBCardText>
                    <MDBCardText>
                      <br />
                      <Link
                        to={{
                          pathname: "/blogpage",
                          state: {
                            title1: "Justin Bieber and Balenciaga",
                            title2: "",
                            image: require("assets/custom/BlogImages/JB_1.jpg")
                              .default,
                            para1:
                              "This year has been all about firsts for Demna Gvasalia, the creative director of Balenciaga. It’s evident that he’s clearing the ground for the historic maison’s next phase, from designing a virtual apocalyptic gamer-verse for the Fall/Winter 2021 collection to wiping the Balenciaga Instagram clean ahead of his couture debut.",
                            para2:
                              "Now, with the release of a new Runner sneaker, the brand has enlisted the help of Justin Bieber for a campaign — and the results are exactly what we expected.",
                            para3:
                              "Bieber appears alongside a slew of celebrities including French actress Isabelle Huppert, Awar Adhiero Odhiang, Anania Orgeas, Isabelle Weldon Herouard, Minttu Vesala, Litay Marcus, Angel aka Emmanuel Culkin Mugisha, Abdou Diop, Taishi Suzuki, Hans Schmidt, and Eliza Douglas, all dressed in oversized leather bombers with hoodies and tapered sweatpants.",
                            shoeLink: "Get this pair at the Mystry Kitchen",
                          },
                        }}
                      >
                        <h4 style={{ color: "lightgreen" }}>Read More</h4>
                      </Link>
                    </MDBCardText>
                  </MDBCardBody>
                </MDBCol>
              </MDBRow>
            </MDBCard>
            <MDBCard
              className="text-white"
              style={{ backgroundColor: "black" }}
            >
              <MDBRow className="g-0">
                <MDBCol md="3">
                  <MDBCardImage
                    src={require("assets/custom/BlogImages/Blog2.jpg").default}
                    alt="..."
                    fluid
                  />
                </MDBCol>
                <MDBCol md="9" className="p-4">
                  <MDBCardBody>
                    <MDBCardTitle class="font-weight-bold spanborder">
                      <h3>Nike acquires RTFKT Studios </h3>
                    </MDBCardTitle>
                    <MDBCardText
                      style={{ textAlign: !mobile ? "justify" : "" }}
                    >
                      The sportswear giant today announced the acquisition of
                      RTFKT Studios, which it claims “a leading brand that
                      harnesses cutting edge innovation to offer next generation
                      collectibles that mix culture and gaming.” RTFKT claims
                      that in February, a collaboration with young artist
                      FEWOCiOUS to sell actual sneakers combined with virtual
                      ones managed to sell approximately 600 pairs/NFTs in just
                      six minutes, making over $3.1 million at the time.
                    </MDBCardText>
                    <MDBCardText>
                      <br />
                      <Link
                        to={{
                          pathname: "/blogpage",
                          state: {
                            title1: "Nike acquires RTFKT Studios ",
                            title2: "",
                            image: require("assets/custom/BlogImages/Blog2.jpg")
                              .default,
                            para1:
                              "The sportswear giant today announced the acquisition of RTFKT Studios, which it claims “a leading brand that harnesses cutting edge innovation to offer next generation collectibles that mix culture and gaming.”",
                            para2:
                              "RTFKT claims that in February, a collaboration with young artist FEWOCiOUS to sell actual sneakers combined with virtual ones managed to sell approximately 600 pairs/NFTs in just six minutes, making over $3.1 million at the time. This was around the same early spring period when most of us were hearing about NFTs for the first time, as Grimes sold roughly $6 million worth of digital artwork on March 1st. ",
                            para3:
                              "The company’s website immediately asks visitors to link their Metamask wallets, which is one of the ways NFT owners can verify their purchases, with the idea that at some point in the future you’ll play games or enter other sorts of VR spaces where your items can materialise, once those spaces have read the blockchain to assess which items you own the rights to. <br/><br/> This is the kind of vision that Nike is buying into, citing the company’s creator Benoit Pagotto, Chris Le, and Steven Vasilev with using “the latest in gaming engines, NFTs, blockchain authentication and augmented reality to create one of a kind virtual products and experiences.” ",
                            shoeLink: "Get this pair at the Mystry Kitchen",
                          },
                        }}
                      >
                        <h4 style={{ color: "lightgreen" }}>Read More</h4>
                      </Link>
                    </MDBCardText>
                  </MDBCardBody>
                </MDBCol>
              </MDBRow>
            </MDBCard>
            <MDBCard
              className="text-white"
              style={{ backgroundColor: "black" }}
            >
              <MDBRow className="g-0">
                <MDBCol md="3">
                  <MDBCardImage
                    src={
                      require("assets/custom/bts/TheNikeSBDunks.jpeg").default
                    }
                    alt="..."
                    fluid
                  />
                </MDBCol>
                <MDBCol md="9" className="p-4">
                  <MDBCardBody>
                    <MDBCardTitle class="font-weight-bold spanborder">
                      <h3>THE NIKE SB DUNKS | TRAVIS SCOTT</h3>
                    </MDBCardTitle>
                    <h4>BEHIND THE SHOE I</h4>
                    <MDBCardText
                      style={{ textAlign: !mobile ? "justify" : "" }}
                    >
                      This iconic collaboration between Nike and Travis Scott
                      led to one of the most highly anticipated sneakers of all
                      time. This pair uses details and patterns that are
                      inspired by his hometown of Houston, Texas. The release of
                      this influential shoe had a strong impact on the
                      resurgence of the historical SB Dunks.
                    </MDBCardText>
                    <MDBCardText>
                      <br />
                      <Link
                        to={{
                          pathname: "/blogpage",
                          state: {
                            title1: "THE NIKE SB DUNKS | TRAVIS SCOTT",
                            title2: "BEHIND THE SHOE I",
                            image:
                              require("assets/custom/bts/TheNikeSBDunks.jpeg")
                                .default,
                            para1:
                              "This iconic collaboration between Nike and Travis Scott led to one of the most highly anticipated sneakers of all time. This pair uses details and patterns that are inspired by his hometown of Houston, Texas. The release of this influential shoe had a strong impact on the resurgence of the historical SB Dunks. It is a staple of the sneaker industry and culture, which even grabs the attention of a non-sneaker head.",
                            para2:
                              "This design features a variety of materials and prints, similar to those seen on the Air Force 1 Low Travis Scott Cactus Jack. Unlike previous Travis Scott releases, they were only available at certain Nike SB retailers, not on SNKRS. Suede uppers with paisley canvas and plaid flannel overlays make up this Nike SB Dunk Low. The elephant print emerges as the paisley overlays fade. The style is completed with embroidered writing across the collar, rope laces, and La Flame's Cactus Jack insignia on the puffy tongues.",
                            para3:
                              "The Travis Scott x Dunk Low SB features a unique patchwork motif on the upper featuring a tan leather base with a plaid quarter panel and paisley print overlays. Complementing the dueling pattern are drawstring laces, Cactus Jack Tongue labels, and mismatched black and pink Swoosh on each shoe. A white midsole and tan rubber outsole highlight the intricate design. These sneakers were released in February 2020 and cost a retail price of $150. At the time of writing, this pair goes for a market price of $2,200.",
                            shoeLink:
                              "You have the opportunity to get your hands on the most anticipated Nike sneakers at Mystry box.",
                          },
                        }}
                      >
                        <h4 style={{ color: "lightgreen" }}>Read More</h4>
                      </Link>
                    </MDBCardText>
                  </MDBCardBody>
                </MDBCol>
              </MDBRow>
            </MDBCard>
            <MDBCard
              className="text-white"
              style={{ backgroundColor: "black" }}
            >
              <MDBRow className="g-0">
                <MDBCol md="3">
                  <MDBCardImage
                    src={
                      require("assets/custom/bts/University_Blue.jpeg").default
                    }
                    alt="..."
                    fluid
                  />
                </MDBCol>
                <MDBCol md="9" className="p-4">
                  <MDBCardBody>
                    <MDBCardTitle class="font-weight-bold spanborder">
                      <h3>THE AIR JORDAN 1 RETRO HIGH UNIVERSITY BLUE</h3>
                    </MDBCardTitle>
                    <h4>BEHIND THE SHOE II</h4>
                    <MDBCardText
                      style={{ textAlign: !mobile ? "justify" : "" }}
                    >
                      This iconic pair of sneakers is a tribute to the
                      University of North Carolina where the creator of the
                      shoe-line himself, Michael Jordan, attended from
                      1981-1984. Carolina blue (occasionally referred to as Tar
                      Heel blue) is the shade of blue used as one of the
                      official school colors of the University of North
                      Carolina.
                    </MDBCardText>
                    <MDBCardText>
                      <br />
                      <Link
                        to={{
                          pathname: "/blogpage",
                          state: {
                            title1:
                              "THE AIR JORDAN 1 RETRO HIGH UNIVERSITY BLUE",
                            title2: "BEHIND THE SHOE II",
                            image:
                              require("assets/custom/bts/University_Blue.jpeg")
                                .default,
                            para1: `For reasons unknown, the Jordan 1 Retro "University Blue" has captivated many sneakerheads around the world. These pairs feature an iconic light blue silhouette named after the original Jordan Brand color. This pair is an evolution of his patented UNC colorway with full leather trim and classic black signature Swoosh. Please note, this is a modern classic pair.`,
                            para2: `This iconic sneaker pays tribute to the line's creator, the University of North Carolina, where Michael Jordan attended from 1981-1984. Carolina Blue (also known as Tar Heel Blue) is a shade of blue used as one of the University of North Carolina's Official School of His Colors. This powder blue colorway is limited to just 12,000 pairs worldwide and sells for three times its market price, around 300-450 USD.`,
                            para3: `The Air Jordan 1 Retro High OG "University Blue" uses a familiar color palette reminiscent of Michael Jordan's UNC alma mater. The all-leather upper features a White base with Powder Blue overlays and a signature Black Swoosh. Matching black accents can be seen on the collar, tongue label, and the Wings logo printed on the side collar tab. A lighter shade of blue is applied to the standard AJ1 outsole, which features multi-directional traction and a pivot point under the forefoot.`,
                            shoeLink: `At Mystry Box, you might add this shoe to your sneaker collection.`,
                          },
                        }}
                      >
                        <h4 style={{ color: "lightgreen" }}>Read More</h4>
                      </Link>
                    </MDBCardText>
                  </MDBCardBody>
                </MDBCol>
              </MDBRow>
            </MDBCard>
            <MDBCard
              className="text-white"
              style={{ backgroundColor: "black" }}
            >
              <MDBRow className="g-0">
                <MDBCol md="3">
                  <MDBCardImage
                    src={
                      require("assets/custom/bts/PharrellAdidas.jpeg").default
                    }
                    alt="..."
                    fluid
                  />
                </MDBCol>
                <MDBCol md="9" className="p-4">
                  <MDBCardBody>
                    <MDBCardTitle class="font-weight-bold spanborder">
                      <h3>PHARRELL X CHANEL X ADIDAS HU NMD</h3>
                    </MDBCardTitle>
                    <h4>BEHIND THE SHOE III</h4>
                    <MDBCardText
                      style={{ textAlign: !mobile ? "justify" : "" }}
                    >
                      Pharrell x Chanel x Adidas was launched back in November
                      of 2017. It was a limited time collaboration with only 500
                      pairs sold through the Chanel’s temporary pop-up shop at
                      Colette in Paris. Pharrell Williams’s Chanel Sneakers are
                      the logical culmination of his collaboration with Karl
                      Lagerfeld
                    </MDBCardText>
                    <MDBCardText>
                      <br />
                      <Link
                        to={{
                          pathname: "/blogpage",
                          state: {
                            title1: "PHARRELL X CHANEL X ADIDAS HU NMD",
                            title2: "BEHIND THE SHOE III",
                            image:
                              require("assets/custom/bts/PharrellAdidas.jpeg")
                                .default,
                            para1: `Pharrell x Chanel x Adidas was launched back in November of 2017. It was a limited time collaboration with only 500 pairs sold through the Chanel’s temporary pop-up shop at Colette in Paris. Pharrell Williams’s Chanel Sneakers are the logical culmination of his collaboration with Karl Lagerfeld`,
                            para2: `None of the many Pharrell collaborations were more legendary than this ultra-rare adidas NMD Hu Trail in collaboration with famed fashion house Chanel. The new NMD Hu features “Pharrell” and “Chanel” typography, while we also see the addition of a treaded outsole and Chanel branding on the heel.`,
                            para3: `They were auctioned for 1,000 EUR (approx. 1,160 USD). The resale value of the pair shot up to 32,000 USD after 120,000 people pre-registered on the website for the opportunity to buy one of 500 pairs. Currently, the shoe is re-selling for more than 11,000 USD from resellers. They are currently the most expensive NMD Hu available from the resellers.`,
                            shoeLink: `Get your hands on these super rare shoes from Mystry box.`,
                          },
                        }}
                      >
                        <h4 style={{ color: "lightgreen" }}>Read More</h4>
                      </Link>
                    </MDBCardText>
                  </MDBCardBody>
                </MDBCol>
              </MDBRow>
            </MDBCard>
            <MDBCard
              className="text-white"
              style={{ backgroundColor: "black" }}
            >
              <MDBRow className="g-0">
                <MDBCol md="3">
                  <MDBCardImage
                    src={
                      require("assets/custom/bts/Hyper Crimson.jpeg").default
                    }
                    alt="..."
                    fluid
                  />
                </MDBCol>
                <MDBCol md="9" className="p-4">
                  <MDBCardBody>
                    <MDBCardTitle class="font-weight-bold spanborder">
                      <h3>JORDAN 1 RETRO HIGH HYPER CRIMSON</h3>
                    </MDBCardTitle>
                    <h4>BEHIND THE SHOE IV</h4>
                    <MDBCardText
                      style={{ textAlign: !mobile ? "justify" : "" }}
                    >
                      The trailer for “Spider-Man: No Way Home,” is filled with
                      nostalgic references for longtime fans of the franchise,
                      from the resurgence of Doctor Octopus to amount of Mystry
                      left wavering about the contents of the movie. But at the
                      end of the trailer, sneakerheads got a shoutout as well,
                      as Peter Parker was seen wearing a pair of Air Jordan 1s
                      ‘Hyper Crimson’ which takes its name from the shoe’s
                      crimson-colored collar.
                    </MDBCardText>
                    <MDBCardText>
                      <br />
                      <Link
                        to={{
                          pathname: "/blogpage",
                          state: {
                            title1: "JORDAN 1 RETRO HIGH HYPER CRIMSON",
                            title2: "BEHIND THE SHOE IV",
                            image:
                              require("assets/custom/bts/Hyper Crimson.jpeg")
                                .default,
                            para1: `The trailer for “Spider-Man: No Way Home,” is filled with nostalgic references for longtime fans of the franchise, from Tobey Maguire to the resurgence of Doctor Octopus. But at the end of the trailer, sneakerheads got a shoutout as well, as Peter Parker was seen wearing a pair of Air Jordan 1s ‘Hyper Crimson’ which takes its name from the shoe’s crimson-colored collar.`,
                            para2: `This January 2019 release features grey suede overlays and a grey leather base. On the upper, a black and fuzzy grey suede mix keeps things simple, while the bright crimson ankle collar adds a welcome splash of colour.`,
                            para3: `Text branding is also used on the model, which is inspired by OFF-"The WHITE's Ten" partnership with the Swoosh. "SP 19 AIR JORDAN 1 HIGH OG," the text reads. Currently, the shoes cost around 300-750 USD from resellers depending upon the size.`,
                            shoeLink: `You have the opportunity to add this shoe to your collection of sneakers at Mystry Box.`,
                          },
                        }}
                      >
                        <h4 style={{ color: "lightgreen" }}>Read More</h4>
                      </Link>
                    </MDBCardText>
                  </MDBCardBody>
                </MDBCol>
              </MDBRow>
            </MDBCard>
            <MDBCard
              className="text-white"
              style={{ backgroundColor: "black" }}
            >
              <MDBRow className="g-0">
                <MDBCol md="3">
                  <MDBCardImage
                    src={
                      require("assets/custom/bts/Yeezy Foam Runner.jpg").default
                    }
                    alt="..."
                    fluid
                  />
                </MDBCol>
                <MDBCol md="9" className="p-4">
                  <MDBCardBody>
                    <MDBCardTitle class="font-weight-bold spanborder">
                      <h3>YEEZY FOAM RUNNER</h3>
                    </MDBCardTitle>
                    <h4>BEHIND THE SHOE V</h4>
                    <MDBCardText
                      style={{ textAlign: !mobile ? "justify" : "" }}
                    >
                      The Yeezy Foam Runner was released as a surprise in June
                      2020 in the Ararat colorway. Yeezy products, as usual,
                      sold out quickly. The Yeezy Foam Runner has been a super
                      popular shoe model for over a year since its initial
                      release, generating constant opinions and discussions. The
                      sneaker prototype was manufactured in Atlanta from a
                      combination of petroleum-based ethylene-vinyl acetate and
                      foam produced from algae.
                    </MDBCardText>
                    <MDBCardText>
                      <br />
                      <Link
                        to={{
                          pathname: "/blogpage",
                          state: {
                            title1: "YEEZY FOAM RUNNER",
                            title2: "BEHIND THE SHOE V",
                            image:
                              require("assets/custom/bts/Yeezy Foam Runner.jpg")
                                .default,
                            para1: `The Yeezy Foam Runner was released as a surprise in June 2020 in the Ararat colorway. Yeezy products, as usual, sold out quickly. The Yeezy Foam Runner has been a super popular shoe model for over a year since its initial release, generating constant opinions and discussions. The sneaker prototype was manufactured in Atlanta from a combination of petroleum-based ethylene-vinyl acetate and foam produced from algae.`,
                            para2: `Switching to more eco-friendly materials and processes, players in the fashion industry can do their part to stop this harm from befalling the planet-one ugly sneaker at a time. We may soon see the silhouette exceed even Crocs, thanks to the impending variety and its affordable retail price point. Kanye’s latest venture into the sneaker verse proves to be yet another surprise, but the hype for the foam runners is only on the rise.`,
                            para3: `Aftermarket prices on foam runners aside, at $80 retail, these are killer. Amazingly comfortable with super soft cushioning that conforms to your foot. It's visually and functionally durable and outlasts other 100% foam shoes. With all their holes they are very well ventilated. Depending on your climate, this can be a big advantage or disadvantage. And most obviously, its aesthetic is unique. This is a real neck breaker. Nothing you see ordinarily in sneaker space.`,
                            shoeLink: `Get your hands on these fashion savvy and eco-friendly shoes at Mystry box.`,
                          },
                        }}
                      >
                        <h4 style={{ color: "lightgreen" }}>Read More</h4>
                      </Link>
                    </MDBCardText>
                  </MDBCardBody>
                </MDBCol>
              </MDBRow>
            </MDBCard>
            <MDBCard
              className="text-white"
              style={{ backgroundColor: "black" }}
            >
              <MDBRow className="g-0">
                <MDBCol md="3">
                  <MDBCardImage
                    src={
                      require("assets/custom/bts/Nike Blazer Mid ‘77 vintage.jpg")
                        .default
                    }
                    alt="..."
                    fluid
                  />
                </MDBCol>
                <MDBCol md="9" className="p-4">
                  <MDBCardBody>
                    <MDBCardTitle class="font-weight-bold spanborder">
                      <h3>NIKE BLAZER MID ‘77 VINTAGE</h3>
                    </MDBCardTitle>
                    <h4>BEHIND THE SHOE VI</h4>
                    <MDBCardText
                      style={{ textAlign: !mobile ? "justify" : "" }}
                    >
                      The Nike Blazer Mid '77 Vintage is simple. But it might be
                      too easy for die-hard sneakerheads. A foam-impregnated
                      nylon tongue combines with a leather upper, flat cotton
                      laces, suede accents, and a vulcanized rubber sole to
                      create a look that resembles '70s ads (and athletes).
                      Unconventional writing on the heel and a vintage logo on
                      the tongue enhance this vibe. The Nike Blazer has been
                      around in one form or another for almost 50 years.
                    </MDBCardText>
                    <MDBCardText>
                      <br />
                      <Link
                        to={{
                          pathname: "/blogpage",
                          state: {
                            title1: "NIKE BLAZER MID ‘77 VINTAGE",
                            title2: "BEHIND THE SHOE VI",
                            image:
                              require("assets/custom/bts/Nike Blazer Mid ‘77 vintage.jpg")
                                .default,
                            para1: `The Nike Blazer Mid '77 Vintage is simple. But it might be too easy for die-hard sneakerheads. A foam-impregnated nylon tongue combines with a leather upper, flat cotton laces, suede accents, and a vulcanized rubber sole to create a look that resembles '70s ads (and athletes). Unconventional writing on the heel and a vintage logo on the tongue enhance this vibe. The Nike Blazer has been around in one form or another for almost 50 years.`,
                            para2: `First designed in 1972 for NBA legend George "The Iceman" Garvin. This sneaker has been around since Nike ditched the old Blue-Ribbon Sports name in favor of its iconic logo, the Swoosh. It was his third Nike sneaker released just one year after him.`,
                            para3: `Originally a basketball shoe, it's now firmly established as a lifestyle choice. They're super comfortable, look classy in a way that's not cheesy, and go with all kinds of outfits. Like the Chuck Taylor All Star, this blazer is tall enough to be tucked under your pants for fall and winter, and it's easy to wear. A great option for shorts wearers in spring and summer. Moreover, they are priced at an affordable flat price of $100. Considering the suede and leather sneakers, it's a pretty good bargain compared to the often-overpriced Nike mesh sneakers.`,
                            shoeLink: `The opportunity to add this shoe to your collection of sneakers is now available at Mystry Box.`,
                          },
                        }}
                      >
                        <h4 style={{ color: "lightgreen" }}>Read More</h4>
                      </Link>
                    </MDBCardText>
                  </MDBCardBody>
                </MDBCol>
              </MDBRow>
            </MDBCard>
            <MDBCard
              className="text-white"
              style={{ backgroundColor: "black" }}
            >
              <MDBRow className="g-0">
                <MDBCol md="3">
                  <MDBCardImage
                    src={
                      require("assets/custom/BlogImages/BTS_VII.jpg").default
                    }
                    alt="..."
                    fluid
                  />
                </MDBCol>
                <MDBCol md="9" className="p-4">
                  <MDBCardBody>
                    <MDBCardTitle class="font-weight-bold spanborder">
                      <h3>Off-White x Air Jordan 1 Retro High OG</h3>
                    </MDBCardTitle>
                    <h4>BEHIND THE SHOE VII</h4>
                    <MDBCardText
                      style={{ textAlign: !mobile ? "justify" : "" }}
                    >
                      This sneaker was one of the most highly anticipated
                      footwear collaborations of 2017. It marked the first time
                      Virgil Abloh, founder of the Milan-based fashion label and
                      Jordan Brand had teamed up. Nicknamed “The 10” edition,
                      this pair comes in the original Chicago-themed white,
                      black and varsity red colorway.”
                    </MDBCardText>
                    <MDBCardText>
                      <br />
                      <Link
                        to={{
                          pathname: "/blogpage",
                          state: {
                            title1: "Off-White x Air Jordan 1 Retro High OG",
                            title2: "BEHIND THE SHOE VI",
                            image:
                              require("assets/custom/BlogImages/BTS_VII.jpg")
                                .default,
                            para1:
                              "This sneaker was one of the most highly anticipated footwear collaborations of 2017. It marked the first time Virgil Abloh, founder of the Milan-based fashion label and Jordan Brand had teamed up. Nicknamed “The 10” edition, this pair comes in the original Chicago-themed white, black and varsity red colorway. Featuring a white, red and black-based deconstructed leather upper with a Swooshless medial side branded with “Off-White for Nike Air Jordan 1, Beaverton, Oregon, USA © 1985.” ",
                            para2:
                              "Other details include: floppy ankle collars with hidden “85” written on the inside, an oversized off-centered Swoosh on the lateral sides, “Air” written on the midsole and the word “Shoelaces” on the laces with no branding on the tongue. Their release date is set for 2017 where they retailed for $190 in men’s sizes only. It currently values at $12,000 USD.",
                            para3: "",
                            shoeLink: "Get this pair at the Mystry Kitchen",
                          },
                        }}
                      >
                        <h4 style={{ color: "lightgreen" }}>Read More</h4>
                      </Link>
                    </MDBCardText>
                  </MDBCardBody>
                </MDBCol>
              </MDBRow>
            </MDBCard>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default Blog;
