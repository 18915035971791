import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ButtonGroup,
  ButtonToolbar,
  UncontrolledTooltip,
} from "reactstrap";
import { useLocation } from "react-router";
import "./Shoes.css";
import LoginWith from "components/User/LoginWith";
import Login from "components/User/Login";
import { useAuth } from "contexts/AuthContext";
import { Row, Col } from "reactstrap";
import { useRef } from "react";
import { Link } from "react-router-dom";
import "firebase/auth";
import { createWant, userDb } from "../../../firebase";
import FavoriteIcon from "@material-ui/icons/Favorite";
var _ = require("lodash");

//incorporate this button in the specific product page
const Notify = ({ sId }) => {
  const { loggedIn, currentUser } = useAuth();

  const location = useLocation();
  const checkout = location.state ? location.state.checkout : false;
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const handleSizeClick = (currSize, idx) => {
    if (currSize === size) {
      setSize("");
      setButtonColor(_.fill(Array(21), "simple"));
    } else {
      setSize(currSize);
      let colorArr = _.fill(Array(21), "simple");
      colorArr[idx] = "neutral";
      setButtonColor(colorArr);
    }
  };

  const [shoe, setShoe] = useState();
  const shoeRef = useRef(shoe);
  shoeRef.current = shoe;
  const [size, setSize] = useState("");
  const [buttonColor, setButtonColor] = useState(_.fill(Array(21), "simple"));
  const sizes = _.range(5, 16, 1);

  const handlePush = (e) => {
    e.preventDefault();
    const shoeID = sId;
    createWant(currentUser, shoeID, size);
    setModal(false);
  };

  return (
    <>
      <div class="col-md-12 text-center p-3">
        {/* putting the same css as shoeSizeChart for equal sizing of buttons */}
        <div className="shoeSizeChart">
          <ButtonToolbar>
            <ButtonGroup vertical>
              <button
                id="right"
                onClick={toggle}
                className="menuButton"
                style={{ color: "#32CD32" }}
              >
                Add to Wishlist
              </button>
            </ButtonGroup>
          </ButtonToolbar>
        </div>
      </div>
      <UncontrolledTooltip placement="right" target="right" delay={0}>
        Items sell out real quickly in the Mystry Kitchen. If this item is
        currently sold out, you can add it to your wishlist. Nonetheless, all
        items are regularly restocked in the Mystry Kitchen.
      </UncontrolledTooltip>
      {!loggedIn && (
        <Modal isOpen={modal} toggle={toggle} size="lg" className="notifyModal">
          <div className="modal-header">
            <h3
              className="modal-title"
              style={{ fontWeight: "bolder", color: "#ffffff" }}
            >
              LogIn{" "}
            </h3>
          </div>

          <ModalBody className="modal-body">
            <h5>
              Login to your account to start adding shoes to your Wishlist.
              You’ll be the first to be notified when we restock.
            </h5>
            <Row className="loginPageHeading">
              <Col>
                <p style={{ fontSize: "14px", color: "#ffffff" }}>
                  If you don’t have an account, you can
                  <Link to={{ pathname: "/signup", state: checkout }}>
                    {" "}
                    Sign Up{" "}
                  </Link>
                  here
                </p>

                <Row>
                  <br />{" "}
                </Row>

                <Login checkout={checkout} />
                <LoginWith checkout={checkout} />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </Modal>
      )}
      {loggedIn && (
        <Modal isOpen={modal} toggle={toggle} size="sm" className="notifyModal" style={{ top: "-200px" }}>
          <div className="modal-header">
            <h5
              className="modal-title"
              style={{ fontWeight: "bolder", color: "#ffffff" }}
            >
              Notify me
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
              onClick={toggle}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
          </div>
          <ModalBody>
            <p style={{ color: "#00b200" }}>
              Choose your size
              <br />{" "}
            </p>
            <div className="shoeSizeChart">
              <ButtonToolbar>
                <ButtonGroup vertical>
                  {sizes.map((currSize, idx) => (
                    <ButtonGroup className="shoeSizeButtonGroup" key={idx}>
                      <Button
                        className="shoeSizeButton"
                        onClick={() => handleSizeClick(currSize, 2 * idx)}
                        key={currSize}
                        color={buttonColor[2 * idx]}
                        // disabled={
                        //   shoe.shoeInventory
                        //     ? !shoe.shoeInventory[shoeSizeIdx[2 * idx]]
                        //         .quantity
                        //     : true
                        // }
                      >
                      {currSize  === size &&
                          <span style={{fontWeight: "bold", color: "black"}}>{currSize} M / {currSize  + 1.5} W</span>}
                      {currSize !== size &&
                          `${currSize} M / ${currSize + 1.5} W`}
                      </Button>
                      {idx < 10 && (
                        <Button
                          className="shoeSizeButton"
                          onClick={() =>
                            handleSizeClick(currSize + 0.5, 2 * idx + 1)
                          }
                          key={currSize + 0.5}
                          color={buttonColor[2 * idx + 1]}
                          // disabled={
                          //   shoe.shoeInventory
                          //     ? !shoe.shoeInventory[
                          //         shoeSizeIdx[2 * idx + 1]
                          //       ].quantity
                          //     : true
                          // }
                        >
                        {currSize + 0.5 === size && 
                          <span style={{fontWeight: "bold", color: "black"}}>{currSize+ 0.5} M / {currSize + 0.5 + 1.5} W</span>}
                        {currSize + 0.5 !== size &&
                            `${currSize + 0.5}M / ${currSize + 0.5 + 1.5} W`}
                        </Button>
                      )}
                    </ButtonGroup>
                  ))}
                </ButtonGroup>
              </ButtonToolbar>
            </div>
            <div>
            {size && (
              <div className="shoeSizeChart pt-2">
                <ButtonToolbar>
                  <ButtonGroup className="shoeSizeButtonGroup">   
                  <Button
                      type="submit"
                      color="green"
                      onClick={handlePush}
                      className="buyButton p-0 m-1"
                    >
                      <FavoriteIcon/> Add to Wishlist
                    </Button>
                    <Button
                      className="cartButtonNew m-1"
                      color="simple"
                      to="/profile-page"
                      tag={Link}
                      // disabled={size ? false : true}
                    >
                      My Wishlist
                    </Button>
                  </ButtonGroup>
                </ButtonToolbar>
              </div>
            )}
            </div>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default Notify;
