import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import "assets/css/nucleo-icons.css";
import "assets/scss/blk-design-system-react.scss?v=1.2.0";
import "assets/demo/demo.css";

import { ComingSoonMain } from "./views/pages/index.jsx";
import SneakerTinderMain from "./views/pages/pagesV2/Sneakerswipe/SneakerTinderMain";
import LandingPage from "views/pages/Landing/LandingPage.js";
import MarketplacePage from "views/pages/Marketplace/Marketplace.js";
// import KitchenPage from "views/pages/Kitchen/Kitchen.js";
import Shoes from "./views/pages/Shoes/Shoes.js";
import Cookbook from "views/pages/Kitchen/Cookbook.js";
import Chefspicks from "views/pages/Kitchen/Chefspicks.js";
import BrandCollection from "views/pages/Kitchen/BrandCollection.js";
// import LoginSignUp from 'views/pages/LoginSignUp/LoginSignUp';
import Error from "views/pages/Error/Error.js";
import Membership from "views/pages/Membership/Membership.js";
import Contact from "views/pages/Contact/Contact.js";
// import Returns from 'views/pages/Returns/Returns.jsx';
import Terms from "views/pages/Terms/Terms.jsx";
import Careers from "views/pages/Careers/Careers.js";
import Partnership from "views/pages/Partnership/Partnership.js";
import Blog from "views/pages/Blogs/Blog.js";
import BlogPage from "views/pages/Blogs/BlogPage.js";
import PaymentOptions from "views/pages/Payment/PaymentOptions.jsx";
import Privacy from "views/pages/Privacy/Privacy.jsx";
import Faqpage from "views/pages/FAQ/Faqpage.jsx";
import ForgotPassword from "components/User/ForgotPassword.js";
import Verify from "views/pages/LoginSignUp/Verify";
import { database } from "firebase.js";
import { useDispatch } from "react-redux";
import { setShoes } from "./reducers/shoesReducer";
import Checkout from "views/pages/Checkout/Checkout.js";
import Payment from "views/pages/Payment/Payment";
import About from "views/pages/About/About.jsx";
import { SuccessPayment } from "views/pages/Payment/SuccessPayment.js";
import { FailurePayment } from "views/pages/Payment/FailurePayment.js";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "views/pages/LoginSignUp/LoginPage";
import SignUpPage from "views/pages/LoginSignUp/SignUpPage";
import DriveThru from "views/pages/Marketplace/DriveThru.js";
import UserInfoForm from "views/PreLaunch/WaitlistForm/UserInfoForm";
import ProfilePage from "views/pages/ProfilePage/ProfilePage.js";
import TrackShoePage from "views/pages/ProfilePage/components/TrackShoe";
import TrackShoeStatusPage from "views/pages/ProfilePage/components/TrackShoeStatus.js";
import Metaverse from "views/pages/Metaverse/Metaverse.js";
import ALaCarte from "views/pages/ALaCarte/aLaCarte.js";
import ALaCartePage from "views/pages/ALaCarte/ALaCartePage.js";
import LandingV2 from "views/pages/pagesV2/Landing/Landing.js";
import UserInfoV2 from "views/pages/pagesV2/UserInfo/UserInfoV2.js";
import WaitlistedMystrbox from "views/pages/pagesV2/UserInfo/WaitlistedMystrbox.js";
import MembershipV2 from "views/pages/pagesV2/Membership/MembershipV2.js";
import ProductPage from "views/pages/pagesV2/ProductPage/ProductPage.js";
import MystryBoxProduct from "views/pages/pagesV2/MystryBoxProduct/MystryBoxProduct.js";
import HouseOfTech from "views/pages/pagesV2/HouseOfTech/HouseOfTech.js";

const App = () => {
  // const [shoes, setShoes] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    const ref = database.shoes;
    // Changed on to once since we do not need to fetch the shoe list continously.
    // ref.on("value", (snapshot) => {
    ref.once("value", (snapshot) => {
      var shoe_data = snapshot.val();
      dispatch(setShoes(shoe_data));
    });
    // eslint-disable-next-line
  }, []);

  toast.configure();

  return (
    <div>
      {/* <Navbar /> */}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Zoom}
      />
      <Switch>
        {/* <Route
          path="/sneakerswipe/:userID"
          // render={(props) => <SneakerTinderMain {...props} />}
          render={() =>
            (window.location = `https://mystryworld.com/sneakerswipe/`)
          }
        /> */}

        <Route
          path="/comingsoon"
          render={(props) => <ComingSoonMain {...props} />}
        />
        <Route exact path="/" render={(props) => <LandingV2 {...props} />} />
        <Route
          exact
          path="/landing"
          render={(props) => <LandingPage {...props} />}
        />
        <Route
          exact
          path="/aLaCarte"
          render={(props) => <ALaCarte {...props} />}
        />
        <Route
          exact
          path="/aLaCartePage"
          render={(props) => <ALaCartePage {...props} />}
        />
        <Route
          path="/aLaCarte/:chef"
          render={(props) => <ALaCarte {...props} />}
        />
        <Route
          path="/kitchen"
          render={(props) => <MarketplacePage {...props} />}
        />
        <Route path="/metaverse" render={(props) => <Metaverse {...props} />} />
        <Route path="/drivethru" render={(props) => <DriveThru {...props} />} />
        {/*<Route
            exact path="/kitchen"
            render={(props) => <KitchenPage {...props} />}
          />*/}
        <Route
          exact
          path="/cookbook"
          render={(props) => <Cookbook {...props} />}
        />
        <Route
          exact
          path="/picks"
          render={(props) => <Chefspicks {...props} />}
        />
        <Route
          exact
          path="/trackOrder"
          render={(props) => <TrackShoePage {...props} />}
        />
        <Route
          exact
          path="/trackOrderStatus"
          render={(props) => <TrackShoeStatusPage {...props} />}
        />
        <Route
          exact
          path="/sneakers/:id"
          render={(props) => <Shoes {...props} />}
        />

        {/* <Route
          path="/sneakers/:id"
          render={(props) => <ShoesMain {...props} />}
        /> */}
        <Route
          path="/cookbook/:brand"
          render={(props) => <BrandCollection {...props} />}
        />
        {/* <Route path="/login" render={(props) => <LoginSignUp {...props} />} />*/}
        <Route path="/login" render={(props) => <Login {...props} />} />
        <Route path="/signup" render={(props) => <SignUpPage {...props} />} />

        <Route
          path="/membership"
          render={(props) => <MembershipV2 {...props} />}
        />
        <Route path="/faq" render={(props) => <Faqpage {...props} />} />

        <Route path="/contact" render={(props) => <Contact {...props} />} />
        {/*
        <Route path="/returns" render={(props) => <Returns {...props} />} />
        */}
        <Route path="/tech" render={(props) => <HouseOfTech {...props} />} />
        <Route path="/terms" render={(props) => <Terms {...props} />} />
        <Route path="/privacy" render={(props) => <Privacy {...props} />} />
        <Route path="/blog" render={(props) => <Blog {...props} />} />
        <Route path="/blogpage" render={(props) => <BlogPage {...props} />} />
        <Route
          path="/partnership"
          render={(props) => <Partnership {...props} />}
        />
        <Route
          path="/payment-options"
          render={(props) => <PaymentOptions {...props} />}
        />
        <Route path="/careers" render={(props) => <Careers {...props} />} />
        <Route path="/verify" render={(props) => <Verify {...props} />} />
        <Route
          path="/forgot-password"
          render={(props) => <ForgotPassword {...props} />}
        />
        <Route
          path="/infoForm"
          render={(props) => <UserInfoForm {...props} />}
        />
        <Route
          path="/infoFormV2"
          render={(props) => <UserInfoV2 {...props} />}
        />
        <Route
          path="/waitlist"
          render={(props) => <WaitlistedMystrbox {...props} />}
        />
        <Route
          path="/sneakerswipe"
          render={(props) => <SneakerTinderMain {...props} />}
        />
        <Redirect from="/sneakers" to="/kitchen" />
        <Route path="/checkout" render={(props) => <Checkout {...props} />} />
        <Route path="/payment" render={(props) => <Payment {...props} />} />
        <Route path="/about" render={(props) => <About {...props} />} />
        {/* <Route path="/drivethru" render={(props) => <DriveThru {...props} />} /> */}
        <Route
          path="/successPayment"
          render={(props) => <SuccessPayment {...props} />}
        />
        <Route
          path="/failurePayment"
          render={(props) => <FailurePayment {...props} />}
        />

        <Route
          path="/profile-page"
          render={(props) => <ProfilePage {...props} />}
        />

        <Route
          path="/productPage/YEEZY"
          render={(props) => <ProductPage {...props} name="YEEZY" />}
        />

        <Route
          path="/productPage/NikeDunks"
          render={(props) => <ProductPage {...props} name="Nike Dunk" />}
        />

        <Route
          path="/productPage/AirJordan"
          render={(props) => <ProductPage {...props} name="Air Jordan" />}
        />

        <Route
          path="/productPage/NikeAirforceOne"
          render={(props) => <ProductPage {...props} name="Nike Airforce" />}
        />

        <Route
          path="/mystryBoxProduct"
          render={(props) => <MystryBoxProduct {...props} />}
        />

        {/*<Redirect from="/" to="/landing" />*/}
        <Route component={Error} />
      </Switch>
      {/* <Footer /> */}
    </div>
  );
};

export default App;
