import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';

export const ChefspicksZeroState = () => {
  return (
    <div  style={{
      position: "relative",
      overflow: "hidden",
      background:'black'
    }}>
      {/* <h4>Find shoes carefully selected by our chefs just for you!</h4> */}
      <Button color="green" tag={Link} to="/login" size="sm">
        <span color="white">Join Now</span>
      </Button>
    </div>
  );
};
