import SneakerswipeLottie from "../Sneakerswipe/SneakerswipeLottie";

export default function LoadingPageMystryBox() {
  return (
    <div style={{ padding: "70px" }}>
      <center>
      <h1 className="mt-4">You are in Queue...</h1>
        <SneakerswipeLottie/>
      </center>
    </div>
  );
}
