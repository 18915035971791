import React from "react";
// import shoe_data from "assets/custom/shoe_data";
import { Container, Row, Col } from "reactstrap";
import { Carousel } from "react-responsive-carousel";
import useWindowDimensions from "hooks/useWindowDimensions";
import "./LandingCarousel.css";
import CardLanding from "components/CardFlip/CardLanding";
// import { database } from "../../../firebase.js";

// React.memo to prevent rerender (specially in shoe page):
// https://linguinecode.com/post/prevent-re-renders-react-functional-components-react-memo
const LandingCarousel = React.memo(
  ({ place = "kitchen", mayLike, fluid, loggedIn, JoinNowButton, shoesProp }) => {
    const width = useWindowDimensions().width;

    var shoes = [];
    if (place === "kitchen") {
      shoes = shoesProp;
      // var i;
      // for (i = 0; i <= 20; i++) {
      //   shoes.push(shoe_data[0]);
      // }
    } else {
      if (!loggedIn) {
        // shoes = shoe_data.slice(start, width >= 768 ? start + 4 : start + 2);
        shoes = shoesProp.slice(0, width >= 768 ? 4 : 2);
      } else {
        shoes = shoesProp;
      }
      // ////console.log("chefsPick shoes", shoes);
    }

    function chunkArray(arr, chunk_size) {
      // ////console.log("arr: ", arr);
      var results = [];
      var i, j, temparray;
      for (i = 0, j = arr.length; i < j; i += chunk_size) {
        temparray = arr.slice(i, i + chunk_size);
        results.push(temparray);
      }

      return results;
    }

    // const [fbShoes, setFbShoes] = useState([]);
    // ////console.log(shoes);
    // ////console.log("shoesProp: ", shoesProp);
    var shoeArr = width >= 768 
        ? ((place=="kitchen" && mayLike==false) 
            ? chunkArray(shoes, 8)
            : chunkArray(shoes, 4))
          : ((place=="kitchen" && mayLike==false) 
          ? chunkArray(shoes, 8) 
        : chunkArray(shoes, 2));

    // useEffect(() => {
    //   const ref = database.shoes;
    //   // var shoe_data;
    //   var shoes_fb = [];
    //   ref.once("value", (snapshot) => {
    //     var shoe_data = snapshot.val();
    //     // ////console.log(shoe_data);
    //     // ////console.log(shoe_data.values());
    //     Object.values(shoe_data).forEach((val) => {
    //       if (val.availableAt[place]) {
    //         shoes_fb.push(val);
    //       }
    //     });
    //     setFbShoes(shoes_fb);
    //     // setFlipped(new Array(fbShoes).fill(false));
    //   });

    //   //return () => ref.off();
    // }, []);

    // ////console.log(shoeArr);

    // commented out code for having the carousal stop on card flip
    // trade off for above: flip animation does not play out.

    // const [isFlipped, setIsFlipped] = useState(
    //   new Array(shoes.length).fill(false)
    // );

    // useEffect(() => {
    //   if (isFlipped.includes(true)) {
    //     setAutoPlay(false);
    //   } else {
    //     setAutoPlay(true);
    //   }
    // });

    // const handleGridClick = (grpLen, idx, idx2) => {
    //   let newArray = [...isFlipped];
    //   newArray[grpLen * idx + idx2] = !isFlipped[grpLen * idx + idx2];
    //   setIsFlipped(newArray);
    // };

    // const history = useHistory();
    // const handleMoreClick = (id) => {
    //   history.push(`/sneakers/${id}`);
    // };

    function Item({ shoe }) {
      return (
        <Col xs="6" sm="6" md="3" className="shoeCol">
          {place === "kitchen" && (
            <CardLanding shoe={shoe} />
            // <ReactCardFlip isFlipped={isFlipped[grpLen * idx + idx2]}>
            //   <div onClick={() => handleGridClick(grpLen, idx, idx2)}>
            //     <img src={shoe.imgUrl} alt={shoe.title} />
            //   </div>

            //   <Col onClick={() => handleGridClick(grpLen, idx, idx2)}>
            //     <div className="BackSideShoeTitle">{shoe.title}</div>
            //     <img
            //       src={!shoe.imgUrl2 ? shoe.imgUrl : shoe.imgUrl2}
            //       alt={shoe.title}
            //       className="BackSideShoImg"
            //     />
            //     <div className="StartingAt">Starting at ${shoe.marketValue}</div>
            //     <Button
            //       color="simple"
            //       size="sm"
            //       onClick={() => handleMoreClick(shoe.id)}
            //       className="BackSideShoeTitle"
            //     >
            //       See More
            //     </Button>
            //   </Col>
            // </ReactCardFlip>
          )}
          {place === "chefsPick" && loggedIn && <CardLanding shoe={shoe} />}
          {place === "chefsPick" && !loggedIn && (
            <img
              style={{
                display: "block",
                width: "100%",
                justifyContent: "center",
                marginTop: "auto",
              }}
              src={shoe.shoeImg.lowRes.right}
              alt="sign in to view chef's picks"
              className="greyedOut"
            />
          )}
        </Col>
      );
    }

    return (
      <div
        style={{
          position: "relative",
          overflow: "hidden",
          margin: "0",
        }}
      >
        <div style={{ position: "relative", margin: "0" }}>
          <Carousel
            autoPlay={true}
            interval={6000}
            showArrows={true}
            showThumbs={false}
            showIndicators={false}
            showStatus={false}
            infiniteLoop={true}
          >
            {shoeArr.map((shoeGrp, idx) => (
              <Container key={idx} fluid={fluid} className="shoeContainer">
                <Row key={idx}>
                  {shoeGrp.map((shoe, idx2) => (
                    <Item shoe={shoe} key={shoe.shoeID} />
                  ))}
                </Row>
              </Container>
            ))}
          </Carousel>
        </div>
        {place === "chefsPick" && !loggedIn && (
          <Container
            className="my-auto text-center shoeContainer"
            style={{
              position: "absolute",
              top: "30%",
            }}
            fluid={fluid}
          >
            <Row>
              <Col xs="12">
                <div
                  className="text-white mb-6"
                  style={{
                    fontSize: width > 420 ? "14px" : "12px",
                    marginBottom: "0.5vw",
                  }}
                >
                  Join now to access chef's picks
                </div>
                <JoinNowButton dest={"login"} text={"Join"} />
              </Col>
            </Row>
          </Container>
        )}
      </div>
    );
  }
);

export default LandingCarousel;
