import Navbar from "components/Navbars/Navbar.js";
import { Col, Row } from "reactstrap";
import useWindowDimensions from "hooks/useWindowDimensions";
import { useLocation } from "react-router-dom";

import ShoePageRow from "./ShoePageRow";
import { useHistory } from "react-router-dom";

import React from "react";

export default function TrackShoePage() {
  const location = useLocation();
  const history = useHistory();

  const width = useWindowDimensions().width;
  var mobile;
  if (width <= 420) {
    mobile = true;
  } else {
    mobile = false;
  }
  const order = location.state.order;
  const dateOptions = { year: "numeric", month: "long", day: "numeric" };
  const orderedOn = new Date(order.orderedOn).toLocaleDateString(
    undefined,
    dateOptions
  );

  function getOrderStatus(orderStatus) {
    if (orderStatus === "Processing") {
      return "Finding the perfect pair";
    } else if (orderStatus === "Procuring") {
      return "Applying polish";
    } else if (orderStatus === "Shipping") {
      return "Packing your pair";
    } else if (orderStatus === "Shipped") {
      return "On the way";
    } else if (orderStatus === "Delivered") {
      return "Ready to rock";
    } else {
      return "AWSM";
    }
  }

  const contentRow = (title, data) => {
    return (
      <Row
        style={{
          paddingTop: "10px",
          paddingBottom: "10px",
          borderTop: "1px solid rgba(238, 238, 238, 0.5)",
          borderBottom: "1px solid rgba(238, 238, 238, 0.5)",
        }}
      >
        <Col xs="4" md="1">
          {title}
        </Col>
        <Col style={{ textAlign: "right", fontWeight: "600" }}>{data}</Col>
      </Row>
    );
  };

  return (
    <>
      <Navbar page={"profile"} />

      <div
        className="wrapper"
        style={{
          marginTop: "15vh",
          marginBottom: "5vh",
          marginLeft: "2vh",
          marginRight: "2vh",
        }}
      >
        <center>
          <h3>{getOrderStatus(order.orderStatus)}</h3>
        </center>
        <br />

        {order.items.map((item, i) => {
          return (
            <Col>
              <ShoePageRow
                shoeID={item.id}
                key={item.id}
                isMobile={mobile}
                item={item}
                size={item.size}
                quantity={item.quantity}
                // orderedOn={order.orderedOn}
                // status="Processing"
              />
              {i + 1 !== order.items.length && (
                <>
                  <br />
                </>
              )}
            </Col>
          );
        })}

        <Col>
          {contentRow(
            "Order ID",
            order.orderIDAlias ? order.orderIDAlias : order.orderID
          )}
        </Col>

        <Col>
          <Row
            style={{
              paddingTop: "10px",
              paddingBottom: "10px",
              borderTop: "1px solid rgba(238, 238, 238, 0.5)",
              borderBottom: "1px solid rgba(238, 238, 238, 0.5)",
            }}
          >
            <Col xs="4" md="1">
              Status
            </Col>
            <Col
              style={{
                textAlign: "right",
                fontWeight: "600",
                textDecorationLine: "underline",
              }}
              onClick={() => {
                history.push({
                  pathname: `/trackOrderStatus`,
                  state: { order: order },
                });
              }}
            >
              {order.orderStatus}
            </Col>
          </Row>
        </Col>
        {order.trackingID && (
          <Col>
            <Row
              style={{
                paddingTop: "10px",
                paddingBottom: "10px",
                borderTop: "1px solid rgba(238, 238, 238, 0.5)",
                borderBottom: "1px solid rgba(238, 238, 238, 0.5)",
              }}
            >
              <Col xs="4" md="1">
                Tracking
              </Col>
              <Col
                style={{
                  textAlign: "right",
                  fontWeight: "600",
                  textDecorationLine: "underline",
                }}
                onClick={() => {
                  history.push({
                    pathname: `/trackOrderStatus`,
                    state: { order: order },
                  });
                }}
              >
                {order.trackingID}
              </Col>
            </Row>
          </Col>
        )}

        <Col>
          {contentRow("Total", "$ " + String(parseFloat(order.price) / 100))}
        </Col>

        <Col>{contentRow("Ordered On", orderedOn)}</Col>
        <Col>
          {contentRow(
            "For",
            order.customer.address.firstName +
              " " +
              order.customer.address.lastName
          )}
        </Col>
        <Col>
          {contentRow(
            "Ship To",
            order.customer.address.address1 +
              " " +
              order.customer.address.address2 +
              " " +
              order.customer.address.city +
              ", " +
              order.customer.address.state +
              ", " +
              order.customer.address.zip
          )}
        </Col>
      </div>
    </>
  );
}
