import React from "react";
import useWindowDimensions from "hooks/useWindowDimensions";
import { Col, Container, Row } from "reactstrap";
import { useHistory, useLocation } from "react-router-dom";

const Working = ({fromProduct}) => {
  const { width, height } = useWindowDimensions();
  var mobile, landscape;
  if (width < 768) {
    mobile = true;
    landscape = false;
  } else if (height < 576 || width < 992) {
    mobile = false;
    landscape = true;
  } else {
    mobile = false;
    landscape = false;
  }
  const fluid = true;

  const history = useHistory();

  const redirectToSneakerswipe = () => {
    fromProduct ? 
      history.push({
        pathname: "/",
        state: { product: true },
      }): 
      document.getElementById("mystrytinder").scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
  };

  return (
    <div
      style={{
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Container
        fluid={fluid}
        style={{
          marginTop: !mobile ? "50px" : "5vh",
          overflowX: "hidden",
          paddingLeft: !mobile && "5vw",
          paddingRight: !mobile && "5vw",
        }}
      >
        {mobile && (
          <h3
            className="text-white"
            style={{
              textAlign: "center",
              marginBottom: "5vh",
              fontSize: "30px",
              fontWeight: "800",
            }}
          >
            How does MystryBox work?
          </h3>
        )}
        {!mobile && (
          <p
            className="text-white"
            style={{
              textAlign: "center",
              fontSize: "40px",
              fontWeight: "800",
              marginBottom: "8vh",
            }}
          >
            How does MystryBox work?
          </p>
        )}
        {!mobile && (
          <Row>
            <Col md={6} style={{ borderRight: "4px solid white" }}>
              <Row
                md={12}
                className="d-flex justify-content-center align-items-center"
              >
                <div className="d-flex justify-content-center align-items-center">
                  <video
                    autoPlay
                    loop
                    muted
                    playsInline
                    style={{
                      width: "80%",
                    }}
                  >
                    <source
                      src={
                        require("assets/custom/RotatingShoes/Shoe1.mp4").default
                      }
                      type="video/mp4"
                    ></source>
                  </video>
                </div>
              </Row>
              <Row
                md={12}
                style={{ borderBottom: "4px solid white", height: "25vh" }}
                className="d-flex justify-content-center align-items-center"
              >
                <Row
                  style={{
                    width: "100%",
                    fontSize: "20px",
                    fontWeight: "400",
                  }}
                >
                  <Col md={2}>
                    <div
                      style={{
                        fontSize: "60px",
                        fontWeight: "700",
                      }}
                    >
                      1
                    </div>
                  </Col>
                  <Col md={10}>
                    Select your MystryBox - The MystryBox collection is
                    available for men, women, and kids starting as low as $140.
                  </Col>
                </Row>
              </Row>
            </Col>
            <Col md={6} style={{ borderRight: "4px solid #000000" }}>
              <Row
                md={12}
                className="d-flex justify-content-center align-items-center"
              >
                <div className="d-flex justify-content-center align-items-center">
                  <video
                    autoPlay
                    loop
                    muted
                    playsInline
                    style={{
                      width: "80%",
                    }}
                  >
                    <source
                      src={
                        require("assets/custom/RotatingShoes/Shoe2.mp4").default
                      }
                      type="video/mp4"
                    ></source>
                  </video>
                </div>
              </Row>
              <Row
                md={12}
                style={{ borderBottom: "4px solid white", height: "25vh" }}
                className="d-flex justify-content-center align-items-center"
              >
                <Row
                  style={{
                    width: "100%",
                    fontSize: "20px",
                    fontWeight: "400",
                  }}
                >
                  <Col md={2}>
                    <div
                      className="ml-4"
                      style={{
                        fontSize: "60px",
                        fontWeight: "700",
                      }}
                    >
                      2
                    </div>
                  </Col>
                  <Col md={10}>
                    The sneakers in your Mystry Box are hand-picked using our
                    proprietary AI,{" "} 
                    <span 
                      onClick={redirectToSneakerswipe}
                      style={{
                        cursor: "pointer", 
                        color:"#00b200"
                      }}
                    >
                      Sneakerswipe
                    </span>
                    , and we’ll ensure that we
                    deliver a sneaker you love, every single time.
                  </Col>
                </Row>
              </Row>
            </Col>
          </Row>
        )}
        {mobile && (
          <div className="d-flex justify-content-center align-items-center">
            <Col style={{ maxWidth: "85vw" }}>
              <Row
                style={{
                  borderRight: "4px solid #F9F9F9",
                  borderLeft: "4px solid #F9F9F9",
                }}
              >
                <Row md={12}>
                  <div className="d-flex justify-content-center align-items-center">
                    <video
                      autoPlay
                      loop
                      muted
                      playsInline
                      style={{
                        width: "90%",
                      }}
                    >
                      <source
                        src={
                          require("assets/custom/RotatingShoes/Shoe1.mp4")
                            .default
                        }
                        type="video/mp4"
                      ></source>
                    </video>
                  </div>
                </Row>
                <Row className="d-flex justify-content-center align-items-center">
                  <Row
                    style={{
                      width: "90%",
                      marginTop: "25px",
                      marginBottom: "25px",
                      fontSize: "20px",
                      fontWeight: "400",
                    }}
                  >
                    <Col md={2} xs={2}>
                      <div
                        style={{
                          fontSize: "60px",
                          fontWeight: "700",
                        }}
                      >
                        1
                      </div>
                    </Col>
                    <Col md={10} xs={10} style={{ fontSize: "15px" }}>
                      Select your MystryBox - The MystryBox collection is
                      available for men, women, and kids starting as low as
                      $140.
                    </Col>
                  </Row>
                </Row>
              </Row>
              <Row
                md={6}
                style={{
                  borderRight: "4px solid #F9F9F9",
                  borderLeft: "4px solid #F9F9F9",
                }}
              >
                <Row>
                  <div className="d-flex justify-content-center align-items-center">
                    <video
                      autoPlay
                      loop
                      muted
                      playsInline
                      style={{
                        width: "90%",
                      }}
                    >
                      <source
                        src={
                          require("assets/custom/RotatingShoes/Shoe2.mp4")
                            .default
                        }
                        type="video/mp4"
                      ></source>
                    </video>
                  </div>
                </Row>
                <Row
                  md={12}
                  className="d-flex justify-content-center align-items-center"
                >
                  <Row
                    style={{
                      width: "90%",
                      marginTop: "25px",
                      marginBottom: "25px",
                      fontSize: "20px",
                      fontWeight: "400",
                    }}
                  >
                    <Col md={2} xs={2}>
                      <div
                        style={{
                          fontSize: "60px",
                          fontWeight: "700",
                        }}
                      >
                        2
                      </div>
                    </Col>
                    <Col md={10} xs={10} style={{ fontSize: "15px" }}>
                      The sneakers in your Mystry Box are hand-picked using our
                      proprietary AI,{" "} 
                      <span 
                        onClick={redirectToSneakerswipe}
                        style={{
                          cursor: "pointer", 
                          color:"#00b200"
                        }}
                      >
                        Sneakerswipe
                      </span>
                      , and we’ll ensure that we
                      deliver a sneaker you love, every single time.
                    </Col>
                  </Row>
                </Row>
              </Row>
            </Col>
          </div>
        )}
      </Container>
    </div>
  );
};

export default Working;
