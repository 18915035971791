import React, { useEffect, useState, useRef } from "react";

import {
  Container,
  Row,
  Col,
  Table,
  // UncontrolledCarousel,
} from "reactstrap";

import { Carousel } from "react-responsive-carousel";

import Notify from "./Notify";

import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";

// import shoes from "assets/custom/shoe_data.js";
import "./Shoes.css";
import useWindowDimensions from "hooks/useWindowDimensions";
import { useLocation } from "react-router-dom";
import FloatingActionCart from "../FloatingActionCart/FloatingActionCart";
// import shoes from "assets/custom/shoe_data.js";
// import shoe_list from "assets/custom/shoe_list.js";
import "./Shoes.css";
import { useDispatch, useSelector } from "react-redux";
import { clearCart, addToCart } from "reducers/cartReducer";
import { useHistory } from "react-router";
import ShoeBid from "./ShoeBid";
import Loading from "components/Loading/Loading";
import LandingCarousel from "../Landing/LandingCarousel";
import CookBookCarousel from "../Landing/CookBookCarousel";
import { toast } from "react-toastify";
// import { database } from "../../../firebase.js";
import ShoeSizeChart from "./ShoeSizeChart";
import * as Scroll from "react-scroll";
import ShoeProductDescription from "./ShoeProductDescription";
import ShoeLoginSignUp from "./ShoeLoginSignUp";
import ShoeAddress from "./ShoeAddress";
import ShoeAddressBid from "./ShoeAddressBid";
import ShoePayment from "./ShoePayment";
import OrderDetails from "./OrderDetails";
import { database } from "../../../firebase.js";

//import OrderDetailsCart from './OrderDetailsCart'
import _forIn from "lodash/forIn";
var _ = require("lodash");

const Shoes = ({ match }) => {
  const shoes = useSelector((state) => state.shoes);
  const history = useHistory();

  const location = useLocation();

  const [shoe, setShoe] = useState();

  const [kitchenShoes, setKitchenShoes] = useState(null);
  const [buyNowState, setBuyNowState] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showAddress, setShowAddress] = useState(false);
  const [showAddressBid, setShowAddressBid] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [shippingAddress, setShippingAddress] = useState();
  const [bidNowState, setBidNowState] = useState(false);

  useEffect(() => {
    var shoes_fb_kitchen = [];
    if (shoes) {
      Object.values(shoes).forEach((val) => {
        if (val.availableAt.kitchen) {
          shoes_fb_kitchen.push(val);
        }
      });
      setKitchenShoes(shoes_fb_kitchen);
    }
  }, [shoes]);

  // reference: https://upmostly.com/tutorials/settimeout-in-react-components-using-hooks
  const shoeRef = useRef(shoe);
  shoeRef.current = shoe;

  useEffect(() => {
    // ////console.log(location.state);
    // ////console.log(size);
    // location state can exist without shoe as well (buyNow and size might be there) so doing additional check
    location.state
      ? location.state.shoe
        ? setShoe(location.state.shoe)
        : setShoe(shoes[match.params.id])
      : setShoe(shoes[match.params.id]);

    // if the page rerenders after login/signup
    if (location.state && location.state.buyNow) {
      setSize(location.state.size);
      setBuyNowState(false);
      setShowLogin(false);
      setShowAddress(true);
      setShowPayment(false);
      setShippingAddress();
      // below makes the shoe page return to the default state on refresh
      // reference: https://stackoverflow.com/a/66359848/10401847
      window.history.replaceState({}, document.title);
    }
    if (location.state && location.state.bidNow) {
      setSize(location.state.size);
      setBidNowState(false);
      setShowLogin(false);
      setShowAddressBid(true);
      setShowPayment(false);
      setShippingAddress();
      // below makes the shoe page return to the default state on refresh
      // reference: https://stackoverflow.com/a/66359848/10401847
      window.history.replaceState({}, document.title);
    }
    // reference: https://upmostly.com/tutorials/settimeout-in-react-components-using-hooks
    setTimeout(() => {
      if (!shoeRef.current) {
        history.push("/error");
      }
    }, 10000);
  }, [history, location.state, match.params.id, shoes]);

  // Reset the state of the page if a user goes to a different shoe page from this page
  useEffect(() => {
    if (!location.state || !location.state.buyNow) {
      setSize("");
      setBuyNowState(false);
      setBidNowState(false);
      setShowLogin(false);
      setShowPayment(false);
      setShowAddress(false);
      setShippingAddress();
      setButtonColor(_.fill(Array(21), "simple"));
    }
    if (!location.state || !location.state.bidNow) {
      setSize("");
      setBidNowState(false);
      setBidNowState(false);
      setShowLogin(false);
      setShowPayment(false);
      setShowAddressBid(false);
      setShippingAddress();
      setButtonColor(_.fill(Array(21), "simple"));
    }
  }, [shoe, location.state]);

  const [size, setSize] = useState("");
  const [buttonColor, setButtonColor] = useState(_.fill(Array(21), "simple"));
  const sizes = _.range(5, 16, 1);
  const shoeSizeIdx = [
    "5",
    "5_5",
    "6",
    "6_5",
    "7",
    "7_5",
    "8",
    "8_5",
    "9",
    "9_5",
    "10",
    "10_5",
    "11",
    "11_5",
    "12",
    "12_5",
    "13",
    "13_5",
    "14",
    "14_5",
    "15",
  ];

  const { width, height } = useWindowDimensions();

  var mobile, landscape;
  if (width < 768) {
    mobile = true;
    if (shoe) {
    }
    landscape = false;
  } else if (height < 576 || width < 992) {
    mobile = false;
    landscape = true;
  } else {
    mobile = false;
    landscape = false;
  }

  const cart = useSelector((state) => state.cart.cartShoes);

  //////console.log(cart)
  const handleSizeClick = (currSize, idx) => {
    if (currSize === size) {
      setSize("");
      setButtonColor(_.fill(Array(21), "simple"));
    } else {
      setSize(currSize);
      let colorArr = _.fill(Array(21), "simple");
      colorArr[idx] = "neutral";
      setButtonColor(colorArr);
    }
  };

  const unhide = () => {
    var hid = document.getElementsByClassName("exp");
    if (hid[0].offsetWidth > 0 && hid[0].offsetHeight > 0) {
      hid[0].style.visibility = "visible";
    }
  };
  const putValue = () => {
    if (shoe.check === true) {
    } else {
      let url =
        "https://us-east1-price-scraper-330821.cloudfunctions.net/sneaker-price-estimator/";
      fetch(url, {
        method: "POST",
        body: JSON.stringify({
          // "sku":"716371-040"
          sku: shoe.shoeSKU,
        }),
      })
        .then((response) => response.json())
        .then((json) => {
          // setPosttArray([json]);
          //console.log(shoe.shoeSKU)
          //console.log([json]);
          var min_ = Infinity;
          _forIn([json][0], (value, key) => {
            let str = key;
            var numbers = /^[0-9]+$/;
            if (key[key.length - 1].match(numbers)) {
              //Do nothing men's shoe
            } else {
              str = key.substring(0, key.length - 1);
              str = parseFloat(str) - parseFloat(1.5);
            }
            if (value == null) {
              //object null- block
            } else if (
              parseFloat(str) < parseFloat(15) &&
              parseFloat(str) > parseFloat(4.5)
            ) {
              if (Number(value.lastSale) < min_) {
                min_ = value.lastSale;
              }
            }
          });
          shoe.marketValue = min_;
        });
      shoe.check = true;
    }
    return true;
  };
  const available = () => {
    var i;
    for (i = 0; i < shoeSizeIdx.length; i++) {
      if (
        shoe.shoeInventory &&
        shoe.shoeInventory[getSizeStr(shoeSizeIdx[i])].quantity > 0
      ) {
        return true;
      }
    }
    return false;
  };

  const maxNumInCart = () => {
    var i;
    for (i = 0; i < cart.length; i++) {
      // TODO: MAKE quantity to be the MIN of inventory or 4 after inventory is set
      var sizeStr = getSizeStr(size);
      // TODO: UPDATE AFTER INVENTORY IS UPDATED
      if (
        shoe.shoeInventory &&
        cart[i].shoe.shoeID === shoe.shoeID &&
        cart[i].size === size &&
        cart[i].quantity >= Math.min(shoe.shoeInventory[sizeStr].quantity, 4)
      ) {
        return true;
      } else if (
        cart[i].shoe.shoeID === shoe.shoeID &&
        cart[i].size === size &&
        cart[i].quantity >= 4
      ) {
        return true;
      }
    }
    return false;
  };

  const dispatch = useDispatch();
  const addToCartFunc = () => {
    if (maxNumInCart()) {
      toast.error("The maximum amount already in the cart");
    } else {
      dispatch(addToCart(shoe, size));
    }
    setSize("");
    setButtonColor(_.fill(Array(21), "simple"));
  };

  const buyNow = () => {
    dispatch(clearCart());
    dispatch(addToCart(shoe, size));

    setBuyNowState(true);
    {
      !mobile && Scroll.animateScroll.scrollToTop({ smooth: true });
    }
  };
  const bidNow = () => {
    // dispatch(clearCart());
    // dispatch(addToCart(shoe, size));

    setBidNowState(true);
    {
      !mobile && Scroll.animateScroll.scrollToTop({ smooth: true });
    }
  };
  const handleBuy = (buyType) => {
    if (size === "") {
      toast.error("Please select a size first");
    } else {
      if (buyType === "now") {
        buyNow();
      } else if (buyType === "bid") {
        bidNow();
      } else {
        addToCartFunc();
      }
    }
  };
  const getSizeStr = (size) => {
    var sizeStr = size.toString();
    if (sizeStr.includes(".")) {
      sizeStr = sizeStr.replace(".", "_");
    }
    return sizeStr;
  };

  const fluid = true;
  const containerStyle = {
    marginTop: mobile ? "7.5vw" : "",
    marginBottom: "7.5vw",
    paddingLeft: !mobile && fluid ? "5vw" : mobile && fluid ? "0px" : "",
    paddingRight: !mobile && fluid ? "5vw" : mobile && fluid ? "0px" : "",
  };
  const discountCheckTrue = (discount) => {
    return false;
  };

  const discountCheckFalse = (discount) => {
    return true;
  };

  return (
    <div
      style={{
        position: "relative",
        overflow: "hidden",
        background: "black",
      }}
    >
      <FloatingActionCart />
      <Navbar />
      {!shoe && <Loading />}
      {shoe && (
        <div className="wrapper">
          <Container
            fluid={true}
            style={{
              marginTop: "100px",
              marginBottom: "75px",
            }}
          >
            {!mobile && (
              <>
                <Row>
                  <Col md="6">
                    <Row style={{ marginTop: !showPayment ? "10vh" : "" }} />
                    <div
                      id="scrollbar"
                      style={{
                        overflowY: "auto",
                        height: "900px",
                        overflowX: "hidden",
                      }}
                    >
                      {!showPayment && (
                        <div>
                          <Row>
                            <Col xs="12">
                              <img
                                src={
                                  !landscape
                                    ? shoe.shoeImg.highRes.right
                                    : shoe.shoeImg.highRes.right
                                }
                                className="shoeImage p-5"
                                alt=""
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="12">
                              <img
                                src={
                                  !landscape
                                    ? shoe.shoeImg.highRes.left
                                    : shoe.shoeImg.highRes.left
                                }
                                className="shoeImage p-5"
                                alt=""
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="12">
                              <img
                                src={
                                  !landscape
                                    ? shoe.shoeImg.highRes.front
                                    : shoe.shoeImg.highRes.front
                                }
                                className="shoeImage p-5"
                                alt=""
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="12">
                              <img
                                src={
                                  !landscape
                                    ? shoe.shoeImg.highRes.back
                                    : shoe.shoeImg.highRes.back
                                }
                                className="shoeImage p-5"
                                alt=""
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="12">
                              <img
                                src={
                                  !landscape
                                    ? shoe.shoeImg.highRes.top
                                    : shoe.shoeImg.highRes.top
                                }
                                className="shoeImage p-5"
                                alt=""
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="12">
                              <img
                                src={
                                  !landscape
                                    ? shoe.shoeImg.highRes.sole
                                    : shoe.shoeImg.highRes.sole
                                }
                                className="shoeImage p-5"
                                alt=""
                              />
                            </Col>
                          </Row>
                        </div>
                      )}
                      {/* {showPayment && (
                        <>
                          <OrderDetails
                            shoe={shoe}
                            getSizeStr={getSizeStr}
                            size={size}
                            shippingAddress={shippingAddress}
                          />
                        </>
                      )} */}
                    </div>
                  </Col>
                  {!buyNowState &&
                    !bidNowState &&
                    !showLogin &&
                    !showAddress &&
                    !showAddressBid &&
                    !showPayment && (
                      <Col xs="12" md="6">
                        <div>
                          <h3 style={{ fontFamily: "helveticaneuebold" }}>
                            {shoe.shoeName}
                          </h3>
                          {size === "" && (
                            <h4>
                              Starting at{" "}
                              {putValue() && (
                                <span style={{ color: "#00b200" }}>
                                  ${shoe.marketValue}{" "}
                                </span>
                              )}
                            </h4>
                          )}
                          {size !== "" && !location.state.ebay &&
                            shoe.shoeInventory &&
                            shoe.shoeInventory[getSizeStr(size)].price > 0 && (
                            <h4>
                              ${shoe.shoeInventory[getSizeStr(size)].price}{" "}
                              for {size} M / {parseFloat(size) + 1.5} W{" "}
                            </h4>
                          )}
                          {size !== "" && !location.state.ebay &&
                            !shoe.shoeInventory && (
                            <h4>
                              ${shoe.retailPrice} for {size} M /{" "}
                              {parseFloat(size) + 1.5} W{" "}
                            </h4>
                          )}
                        </div>

                        <div className="shoeDescriptionText">
                          <br />
                          {shoe.shoeDesc}
                        </div>
                        <br />
                        {
                          <ShoeSizeChart
                            shoe={shoe}
                            ebay={location.state.ebay}
                            size={size}
                            setSize={setSize}
                            handleBuy={handleBuy}
                            buttonColor={buttonColor}
                            setButtonColor={setButtonColor}
                          />
                        }
                        {!available() && <Notify sId={shoe.shoeID} />}
                        {!available() && (
                          <p style={{ fontSize: "1rem" }}>
                            <br />
                            <h4>
                              Add this item to your wishlist, and you’ll be the
                              first to be notified when we restock.
                            </h4>
                          </p>
                        )}
                        {available() && <Notify sId={shoe.shoeID} />}
                        {available() && (
                          <p style={{ fontSize: "1rem" }}>
                            <br />
                            <h4>
                              Items are regularly restocked in the Mystry
                              Kitchen. Add unavailable sizes to your wishlist,
                              and you’ll be first on our list to be notified
                              when we restock.
                            </h4>
                          </p>
                        )}
                      </Col>
                    )}
                  {buyNowState && (
                    <ShoeProductDescription
                      shoe={shoe}
                      getSizeStr={getSizeStr}
                      setBuyNowState={setBuyNowState}
                      setSize={setSize}
                      size={size}
                      setButtonColor={setButtonColor}
                      setShowLogin={setShowLogin}
                      setShowPayment={setShowPayment}
                      setShowAddress={setShowAddress}
                    />
                  )}
                  {bidNowState && (
                    <ShoeBid
                      shoe={shoe}
                      ebay={location.state.ebay}
                      getSizeStr={getSizeStr}
                      setBidNowState={setBidNowState}
                      setSize={setSize}
                      size={size}
                      setButtonColor={setButtonColor}
                      setShowLogin={setShowLogin}
                      setShowPayment={setShowPayment}
                      setShowAddressBid={setShowAddressBid}
                    />
                  )}
                  {showLogin && (
                    <ShoeLoginSignUp
                      shoeID={shoe.shoeID}
                      size={size}
                      setShowLogin={setShowLogin}
                      setShowPayment={setShowPayment}
                      setBuyNowState={setBuyNowState}
                    />
                  )}
                  {showAddress && (
                    <ShoeAddress
                      setBuyNowState={setBuyNowState}
                      setShowPayment={setShowPayment}
                      setShowAddress={setShowAddress}
                      setShippingAddress={setShippingAddress}
                    />
                  )}
                  {showAddressBid && (
                    <ShoeAddressBid
                      setBidNowState={setBidNowState}
                      setShowPayment={setShowPayment}
                      setShowAddressBid={setShowAddressBid}
                      setShippingAddress={setShippingAddress}
                      shoe={shoe}
                      size={size}
                      setSize={setSize}
                    />
                  )}
                </Row>
                <Container fluid={true} style={{ marginTop: "30px" }}>
                  <Row className="p-5">
                    <Col xs="12" md="3" className="shoeProductDetails">
                      Brand: {shoe.shoeBrand}
                    </Col>
                    <Col xs="12" md="3" className="shoeProductDetails">
                      Colorway: {shoe.shoeColorway}
                    </Col>
                    <Col xs="12" md="3" className="shoeProductDetails">
                      Silhouette: {shoe.silhouette}
                    </Col>
                    <Col xs="12" md="3" className="shoeProductDetails">
                      SKU: {shoe.shoeSKU}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" className="shoeSizeChart">
                      <img
                        src={shoe.shoeImg.highRes.front}
                        className="shoeImage p-5"
                        alt=""
                        style={{
                          width: "75%",
                        }}
                      />
                    </Col>
                  </Row>
                </Container>
              </>
            )}
            {mobile && (
              <>
                <Row>
                  <Col xs="12">
                    {/*<UncontrolledCarousel
                    items={carouselItems}
                    className="shoeCarousal"
                    controls={false}
                  />*/}
                    <Carousel
                      showArrows={false}
                      autoPlay={false}
                      showThumbs={false}
                      showIndicators={true}
                      showStatus={false}
                      infiniteLoop={true}
                    >
                      <div
                        style={{
                          marginBottom: "5vh",
                          position: "absolute", //Here is the trick
                          bottom: 0,
                        }}
                      >
                        <img
                          src={shoe.shoeImg.highRes.right}
                          alt="right view"
                        />
                      </div>
                      <div
                        style={{
                          marginBottom: "5vh",
                          position: "absolute", //Here is the trick
                          bottom: 0,
                        }}
                      >
                        <img
                          src={shoe.shoeImg.highRes.front}
                          alt="front view"
                        />
                      </div>

                      <div
                        style={{
                          marginBottom: "5vh",
                          position: "absolute", //Here is the trick
                          bottom: 0,
                        }}
                      >
                        <img src={shoe.shoeImg.highRes.left} alt="left view" />
                      </div>
                      <div style={{ marginTop: "2vh", marginBottom: "3vh" }}>
                        <img src={shoe.shoeImg.highRes.back} alt="back view" />
                      </div>
                      {/* <div style={{ marginTop: "2vh", marginBottom: "5vh" }}>
                        <img src={shoe.shoeImg.highRes.top} alt="top view" />
                      </div> */}
                      <div
                        style={{
                          marginBottom: "5vh",
                          position: "absolute", //Here is the trick
                          bottom: 0,
                        }}
                      >
                        <img src={shoe.shoeImg.highRes.sole} alt="sole view" />
                      </div>
                    </Carousel>
                    <br />
                    <h3>{shoe.shoeName}</h3>
                  </Col>
                  <Col xs="12" md="6">
                    <div>
                      <h3 style={{ color: "#dedede" }}>Ingredients</h3>
                      <div
                        // id="scrollbar"
                        style={{
                          width: "95vw",
                          // overflowY: "hidden",
                          overflowX: "auto",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <Table bordered>
                          <tbody>
                            <tr>
                              <td>Brand: {shoe.shoeBrand}</td>
                              <td> | </td>
                              <td>Silhouette: {shoe.silhouette}</td>
                              <td> | </td>
                              <td>Colorway: {shoe.shoeColor}</td>
                              <td> | </td>
                              <td>SKU: {shoe.shoeSKU}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                      <br />
                      <details open>
                        <summary>
                          <h3 style={{ color: "#dedede" }}>
                            Culinary Heritage{" "}
                          </h3>
                        </summary>
                        <p className="shoeDescriptionText text-justify p-1">
                          {shoe.shoeDesc}
                        </p>
                      </details>
                      <br />
                      {size === "" && (
                        <h4>
                          Starting at{" "}
                          {putValue() && (
                            <span style={{ color: "#00b200" }}>
                              ${shoe.marketValue}{" "}
                            </span>
                          )}
                        </h4>
                      )}
                      {size !== "" && !location.state.ebay &&
                        shoe.shoeInventory &&
                        shoe.shoeInventory[getSizeStr(size)].price > 0 && (
                          <h4>
                            ${shoe.shoeInventory[getSizeStr(size)].price} for{" "}
                            {size} M / {parseFloat(size) + 1.5} W{" "}
                          </h4>
                        )}
                      {size !== "" && !location.state.ebay &&
                        !shoe.shoeInventory && (
                        <h4>
                          ${shoe.retailPrice} for {size} M /{" "}
                          {parseFloat(size) + 1.5} W{" "}
                        </h4>
                      )}
                    </div>
                    <br />
                  </Col>
                </Row>
                {
                  //                   available() &&
                  !buyNowState &&
                    !bidNowState &&
                    !showLogin &&
                    !showAddress &&
                    !showAddressBid &&
                    !showPayment && (
                      <Col xs="12" md="6">
                        <ShoeSizeChart
                          shoe={shoe}
                          ebay={location.state.ebay}
                          size={size}
                          setSize={setSize}
                          handleBuy={handleBuy}
                          buttonColor={buttonColor}
                          setButtonColor={setButtonColor}
                        />
                      </Col>
                    )
                }
                <Notify sId={shoe.shoeID} />
                {buyNowState && (
                  <ShoeProductDescription
                    shoe={shoe}
                    getSizeStr={getSizeStr}
                    setBuyNowState={setBuyNowState}
                    setSize={setSize}
                    size={size}
                    setButtonColor={setButtonColor}
                    setShowLogin={setShowLogin}
                    setShowPayment={setShowPayment}
                    setShowAddress={setShowAddress}
                  />
                )}
                {bidNowState && (
                  <ShoeBid
                    shoe={shoe}
                    ebay={location.state.ebay}
                    getSizeStr={getSizeStr}
                    setBidNowState={setBidNowState}
                    setSize={setSize}
                    size={size}
                    setButtonColor={setButtonColor}
                    setShowLogin={setShowLogin}
                    setShowPayment={setShowPayment}
                    setShowAddressBid={setShowAddressBid}
                  />
                )}
                {showLogin && (
                  <ShoeLoginSignUp
                    shoeID={shoe.shoeID}
                    size={size}
                    setShowLogin={setShowLogin}
                    setShowPayment={setShowPayment}
                    setBuyNowState={setBuyNowState}
                  />
                )}
                {showAddress && (
                  <ShoeAddress
                    setBuyNowState={setBuyNowState}
                    setShowPayment={setShowPayment}
                    setShowAddress={setShowAddress}
                    setShippingAddress={setShippingAddress}
                  />
                )}
                {showAddressBid && (
                  <ShoeAddressBid
                    setBidNowState={setBidNowState}
                    setShowPayment={setShowPayment}
                    setShowAddressBid={setShowAddressBid}
                    setShippingAddress={setShippingAddress}
                    shoe={shoe}
                    size={size}
                    setSize={setSize}
                  />
                )}
                <div>
                  <img
                    src={shoe.shoeImg.highRes.front}
                    className="shoeImage p-4"
                    alt=""
                    style={{
                      width: "100%",
                    }}
                  />
                </div>
              </>
            )}
            {kitchenShoes !== null && (
              <Container
                style={{ ...containerStyle, marginBottom: "5vmax" }}
                fluid={true}
              >
                <Row>
                  <Col xs="12">
                    <h3 style={{ color: "#dedede" }} className="pt-3">
                      You May Also Like
                    </h3>
                    <LandingCarousel
                      place="kitchen"
                      mayLike={true}
                      fluid={true}
                      shoesProp={kitchenShoes}
                    />
                  </Col>
                </Row>
              </Container>
            )}
            <Container style={containerStyle} fluid={true}>
              <Row>
                <Col xs="12">
                  <h3 style={{ color: "#dedede" }}>Recommended Cuisines</h3>
                  <CookBookCarousel />
                </Col>
              </Row>
            </Container>
          </Container>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default Shoes;
