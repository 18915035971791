import React, { useEffect, useState } from 'react';
import { Container, Modal, ModalBody, Row } from 'reactstrap';
import Button from '@material-ui/core/Button';
import Card from 'react-bootstrap/Card';
import useWindowDimensions from "hooks/useWindowDimensions";
import Parser from "html-react-parser";
import Faq from "react-faq-component";
import './Faq.css';

const FaqPage = () => {
  const { width, height } = useWindowDimensions();
  var mobile, landscape;
  if (width < 768) {
    mobile = true;
    landscape = false;
  } else if (height < 576 || width < 992) {
    mobile = false;
    landscape = true;
  } else {
    mobile = false;
    landscape = false;
  }
  const fluid = true;
  
  const [confirmModal, setConfirmModal] = useState(false);
  const confirmToggle = () => setConfirmModal(!confirmModal);
  const [modalQuestion, setModalQuestion] = useState("");
  const [modalAnswer, setModalAnswer] = useState("");

  function openFaqModal(question, answer) {
    setModalQuestion(question);
    setModalAnswer(answer);
    confirmToggle();
  }

  const data = {
		rows: [
			{
				title: "Does Mystry ship worldwide?",
        icon: require("assets/custom/faq/question_icon.png").default,
				content: `<br/>We currently ship all over USA only, and are yet to begin international shipping. 
        We plan to begin international shipping by June, 2023. <br/> &nbsp; `,
			},
			{
				title: "Does Mystry cover customs and duties?",
        icon: require("assets/custom/faq/waitlist_icon.png").default,
				content: `<br/>Yes, we handle all charges related to importing and exporting luxury fashion. 
        Nonetheless, we are liable to collect domestic sales tax, which is calculated during checkout. <br/> &nbsp; `,
			},
			{
				title: "How does Mystry guarantee authenticity?",
        icon: require("assets/custom/faq/membership_icon.png").default,
				content: `<br>We work directly with our brand partners and their retail/factory outlets to source our inventory. 
        We also work very closely with a handful of  highly reputable sneaker resellers who have a history of sourcing, 
        legit checking, and selling sneakers. <br/><br/> In order to ensure an additional layer of trust, all sneakers 
        must pass a check-check verification test. /Only once we have received a “PASS/Verified” certification on each pair, 
        do we dispatch your sneakers. All sneakers, once verified, are dispatched in 3-5 working days. <br/> &nbsp; `,
			},
			{
				title: "How long does it take to ship my order?",
        icon: require("assets/custom/faq/shipping_icon.png").default,
				content: `<br/>All orders are dispatched within 24-48 hours after your purchase has been completed. 
        Expected Delivery Time is 4-7 Business Days. <br/> &nbsp; `,
			},
			{
				title: "How do I contact Mystry?",
        icon: require("assets/custom/faq/merchandise_icon.png").default,
				content: `<br/> Feel free to contact us by emailing us at 
        <u style="color: #00b200; cursor: pointer;"><a href="mailto:contact@mystry.io">contact@mystry.io</a></u><br/> &nbsp;`,
			},
			{
				title: "Can I exchange/return my order?",
        icon: require("assets/custom/faq/shipping_icon.png").default,
				content: `<br/>We spend a lot of time and manpower to source and authenticate your sneakers before dispatch. 
        Due to authenticity concerns, we are unable to offer returns, exchanges, or swaps on your order, 
        including if you ordered the wrong size. We will do our best to resolve your issue as quickly as we can. 
        If you do have concerns with your order, you can always contact us at 
        <u style="color: #00b200; cursor: pointer;"><a href="mailto:contact@mystry.io">contact@mystry.io</a></u><br/> &nbsp;`,
			},
			{
				title: mobile? "Can I cancel my order?": 
        `<span style="padding-left: 10px; padding-right: 10px; display: flex; justify-content: center;">Can I cancel my order?</span>`,
        icon: require("assets/custom/faq/membership_icon.png").default,
				content: `<br/>Yes, you may cancel your order within the first 24 hours after your order has been processed. 
        Once your order has been dispatched, you may no longer cancel the order. 
        If you would like to cancel or initiate a cancel order, please email us at 
        <u style="color: #00b200; cursor: pointer;"><a href="mailto:contact@mystry.io">contact@mystry.io</a></u><br/> &nbsp;`,
			},
			{
				title: "Does Mystry offer monthly Mystry Boxes?",
        icon: require("assets/custom/faq/question_icon.png").default,
				content: `<br/>Yes, we do provide monthly Mystry Boxes for $199/month.. You can sign up for as many months as you want, 
        and you will be charged on a month to month basis. If you’d like to receive a Mystry Box every month, 
        then please email us at <u style="color: #00b200; cursor: pointer;">
        <a href="mailto:contact@mystry.io">contact@mystry.io</a></u><br/> &nbsp; `,
			},
      {
				title: "Will I get the products that I have liked in SneakerSwipe?",
        icon: require("assets/custom/faq/membership_icon.png").default,
				content: `<br/>We will try to get you the exact or similar product that you like. We cannot make any guarantees, 
        but we try our absolute best to deliver a product that is catered to your taste and preference.<br/> &nbsp;`,
			},
			{
				title: "What if my order is faulty or defective?",
        icon: require("assets/custom/faq/question_icon.png").default,
				content: `<br/>Where possible, please report any problems like this to us via email 
        (<u style="color: #00b200; cursor: pointer;"><a href="mailto:contact@mystry.io">contact@mystry.io</a></u>) within 48 hours
         from the time your order is delivered and send us photographic evidence to support if necessary.
        If your claim is upheld we will send you a returns label free of charge. Once your Product has been returned, 
        it will be checked and, provided we agree that it is faulty, you will be refunded the price of the 
        faulty Product along with the delivery charges you paid. <br/> &nbsp; `,
			},
		],
	};

  const styles = {
		bgColor: "black",
		rowTitleColor: "#d6cece",
		rowContentColor: "white",
		arrowColor: "white",
	};

  return (
    <div
      id="faq-mystry"
      style={{
        position: "relative",
        marginBottom: mobile ? "5vh": "15vh",
        overflow: "hidden",
      }}
    >
      <Container
        fluid={fluid}
        id='flow'
        style={{
          marginTop: !mobile ? "10vh" : "0px",
          overflowX: "hidden",
          paddingLeft: !mobile && "5vw",
          paddingRight: !mobile && "5vw",
        }}
      >
        {mobile && (
          <h3 
            className="text-white" 
            style={{ 
              marginTop: "8vh", 
              textAlign: "center" ,
              fontSize: "35px",
              fontWeight: "600"
            }}>
            Frequently Asked Questions
          </h3>
        )}
        {!mobile && (
          <h1 
            className="text-white" 
            style={{ 
              marginBottom: "8vh",
              textAlign: "center", 
              fontSize:"45px",
              fontWeight:"600" 
            }}>
            Frequently Asked Questions
          </h1>
        )}
        {mobile && (
          <div className='d-flex justify-content-center align-items-center'>
            <div style={{width: "80vw"}}>
				      <Faq data={data} styles={styles} />
            </div>
          </div>
        )}
        {!mobile && (
          <Row>
            <ul id="scroller">
              {data.rows.map((question) => {
                return(
                  <>
                    <li className='faq-li'>
                      <Card className='faq-card'>
                          <div 
                            className='d-flex justify-content-center'
                            style={{
                              marginTop: "40px"
                            }}
                          >
                            <Card.Img
                              style={{
                                height: "100px",
                                width: "100px",
                              }}
                              src={question.icon}
                            />
                          </div>
                        <Card.Body className='card-body'>
                          <Card.Title>Card Title</Card.Title>
                          <Card.Text className='faq-text'> 
                            {Parser(question.title)}
                          </Card.Text>
                        </Card.Body>
                        <div className='d-flex justify-content-center'>
                          <Button 
                            variant="outlined" 
                            className='faq-btn'
                            onClick={() => openFaqModal(question.title,question.content)}
                          >
                              <u>See More</u>
                          </Button>
                        </div>
                      </Card>
                    </li>
                  </>
                )
              })}
            </ul>
          </Row>
        )}
      </Container>
      <Modal
        isOpen={confirmModal}
        toggle={confirmToggle}
        className="faq-modal"
      >
        <div className='modal-header'>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-hidden="true"
            onClick={confirmToggle}
            style={{ top: "5px" }}
          >
            <i className="tim-icons icon-simple-remove" />
          </button>
        </div>
        <ModalBody className='faq-modal-body'>
          <div className='d-flex justify-content-center text-align-center'>
            <div className='faq-question'>
              <p className='faq-title'>{Parser(modalQuestion)}</p>
            </div>
            <div className='faq-answer'>
              <p className='faq-content'>{Parser(modalAnswer)}</p>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default FaqPage;