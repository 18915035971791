import React from "react";
import Navbar from 'components/Navbars/Navbar.js';
import { useState } from "react";
// reactstrap components
import { Container, Row, Col, Button, UncontrolledCarousel } from "reactstrap";
import { Link } from "react-router-dom";
// import { useAuth } from "contexts/AuthContext";
import CarouselImage1 from "../../../assets/custom/carousel1.JPG";
import CarouselImage2 from "../../../assets/custom/carousel2.JPG";
import CarouselImage3 from "../../../assets/custom/carousel3.JPG";
import CarouselImage4 from "../../../assets/custom/carousel4.JPG";
import * as Scroll from "react-scroll";
import useWindowDimensions from "hooks/useWindowDimensions";
import NavbarV2 from "../pagesV2/Navbar/Navbar";
import Footer from "../pagesV2/Footer/Footer";
const About = () => {
  const { width, height } = useWindowDimensions();
  const [showScrollDown, setShowScrollDown] = useState(false);

  const fluid = true;
  const containerStyle = {
    marginBottom: "7.5vw",
    paddingLeft: !mobile && fluid ? "5vw" : "",
    paddingRight: !mobile && fluid ? "5vw" : "",
  };

  const joinStyle = mobile ? { fontSize: "12px" } : { fontSize: "16px" };
  const btnSize = mobile ? "sm" : "md";
  const titleStyle = mobile
    ? { fontSize: "4vw", marginBottom: ".5vmax", fontFamily: "edosz" }
    : { fontSize: "4vw", marginBottom: ".5vmax", fontFamily: "edosz" };
  // const logoStyle = {
  //   fontSize: "2rem",
  //   marginBottom: ".5vmax",
  //   fontFamily: "edosz",
  // };

  const responsiveHeadStyle = mobile
    ? { fontSize: "1.5rem" }
    : { fontSize: landscape ? "1.5rem" : "2vw" };
  const responsiveBodyStyle = mobile
    ? {
        fontSize: "1rem",
        color: "#7b838a",
      }
    : { fontSize: landscape ? "1rem" : "1.1vmax", color: "#7b838a" };

  const carouselItems = [
    {
      src: CarouselImage1,
      altText: "Slide 1",
      caption: "",
    },
    {
      src: CarouselImage3,
      altText: "Slide 2",
      caption: "",
    },
    {
      src: CarouselImage4,
      altText: "Slide 3",
      caption: "",
    },
    {
      src: CarouselImage2,
      altText: "Slide 4",
      caption: "",
    },
  ];

  const scrollDown = () => {
    Scroll.animateScroll.scrollTo(mobile ? 500 : landscape ? 500 : 1000, {
      smooth: true,
    });
  };

  const toggleScrollDown = (e) => {
    if (e.target.currentTime >= 8) {
      setShowScrollDown(true);
    }
  };

  var mobile, landscape;
  if (width < 768) {
    mobile = true;
    landscape = false;
  } else if (height < 576 || width < 992) {
    mobile = false;
    landscape = true;
  } else {
    mobile = false;
    landscape = false;
  }
  const overlayText = {
    position: "absolute",
    top: !mobile ? "10vh" : "20vh",
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",

    zIndex: 2,
  };
  return (
    <>
      <NavbarV2/>
      <div
        style={{
          position: "relative",
          overflow: "hidden",
        }}
      >
        {/* {mobile && !landscape && (
          <>
          <video
             
              width="100%"
            
              autoPlay
              loop
              muted
              playsInline
              style={{
                zIndex: 1,
                marginTop:'10vh',
                
              }}
              position="relative"
            >
            <source
                  src={
                    require("assets/custom/mobile-errorh265.mp4")
                      .default
                  }
                  type="video/mp4; codecs=hevc"
                ></source>
                 <source
                  src={
                    require("assets/custom/mobile-error.webm")
                      .default
                  }
                  type="video/webm; codecs=vp9"
                ></source>
                <source
                  src={
                    require("assets/custom/mobile-errorh264.mp4")
                      .default
                  }
                  type="video/mp4"
                ></source>
            </video>
         
            <Row
              className="row-grid justify-content-between align-items-center text-left"
              style={overlayText}
            >
            </Row>
            
          </>
        )}
        {!mobile && (
          <>
          <video
             
              width="100%"
            
              autoPlay
              loop
              muted
              playsInline
              style={{
                zIndex: 1,
                marginTop:'10vh',
                
              }}
              position="relative"
            >
            <source
                  src={
                    require("assets/custom/web-errorh265.mp4")
                      .default
                  }
                  type="video/mp4; codecs=hevc"
                ></source>
                 <source
                  src={
                    require("assets/custom/web-error.webm")
                      .default
                  }
                  type="video/webm; codecs=vp9"
                ></source>
                <source
                  src={
                    require("assets/custom/web-errorh264.mp4")
                      .default
                  }
                  type="video/mp4"
                ></source>
            </video>
            
            <Row
              className="row-grid justify-content-between align-items-center text-left"
              style={overlayText}
            >
              <Col
                xs="12"
                s="12"
                lg="12"
                md="12"
                style={{ display: "flex", justifyContent: "center" }}
              >
              </Col>
            </Row>
          </>
        )} */}
        <Container
          fluid={fluid}
          style={{ ...containerStyle, marginTop: "5vmax",width: mobile ? "100%" : "90%" }}
        >
          {/* <Row className="align-items-center">
            <Col style={{ textAlign: "center" }}>
            <div
            className="title text-white"
            style={{
              ...responsiveHeadStyle,
              fontSize: mobile ? "1.2rem" : "2vw",
              marginLeft: "3vw",
              fontStyle: "italic",
              fontWeight: "700",
              textAlign: "center",
            }}
          >
            The founding pillars of Mystry are based upon the story of an old Chinese farmer:
          </div>
          <div className={mobile ? "p-0" :"p-5"} style={{ fontSize: "1rem" }}>
              <p>A farmer and his son had a beloved horse who helped the family earn a living. One day, the horse ran away and their neighbours exclaimed, “Your horse ran away, what terrible luck!” The farmer replied, “Maybe so, maybe not.”</p><br/>
              <p>A few days later, the horse returned home, leading a few wild horses back to the farm as well. The neighbours shouted out, “Your horse has returned, and brought several horses home with him. What great luck!” The farmer replied, “Maybe so, maybe not. It’s a Mystry”</p><br/>
              <p>Later that week, the farmer’s son was trying to ride one of the wild  horses and she threw him to the ground, breaking his leg. The neighbours cried, “Your son broke his leg, what terrible luck!” The farmer replied, “Maybe so, maybe not. It’s Mystry”</p><br/>
              <p>A few weeks later, soldiers from the national army marched through town, recruiting all boys for the army. They did not take the farmer’s son, because he had a broken leg. The neighbours shouted, “Your boy is spared, what tremendous luck!” To which the farmer replied, “Maybe so, maybe not. It’s a Mystry :)”</p><br/>
              <p style={{fontStyle: "italic"}} >Life is truly a Mystry.</p>
              <p style={{fontStyle: "italic"}} >You just have to Keep ‘em Guessing </p>
          </div>
            </Col>
          </Row> */}
        </Container>
        <Container
          fluid={fluid}
          style={{ ...containerStyle, marginTop: "3vmax" }}
        >
          {!mobile && (
            <Row className="align-items-center">
              <Col xs="6" md="6" >
                <div >
              <img
                alt="..."
                className="img-center img-fluid"
                src={require("assets/custom/About_1.jpeg").default}
                width="500" height="300"
              />  
                </div>
                <div
                  style={{
                    ...responsiveBodyStyle,
                    margin: "2vmax",
                    color: "white",
                  }}
                >
                </div>
              </Col>
              <Col xs="6" md="6" lg="5">
                <div
                  className="title text-white"
                  style={{
                    ...responsiveHeadStyle,
                    marginLeft: "3vw",
                    fontWeight: "700",
                    textAlign: "center"
                  }}
                >
                  What is MYSTRY?
                </div>
                <div
                  style={{
                    ...responsiveBodyStyle,
                    display: "inline-block",
                    width: "100%",
                    height: "200px",
                    verticalAlign: "center",
                    color: "#dedede",
                    textAlign: "center"
                  }}
                >Mystry is an upscale sneaker-tech company that uses proprietary Machine Learning models to understand a user’s taste and preference in fashion, primarily sneakers, based upon which we customize luxury sneaker MystryBoxes for our customers. 
                </div>
              </Col>
            </Row>
          )}
          {mobile && (
            <>
              <Row className="align-items-center">
                <Col xs="12" sm="12">
                  <div
                    className="title text-white pt-5 pb-2"
                    style={{
                      ...responsiveHeadStyle,
                      fontSize: "1.65rem",
                      fontWeight: "700",
                      textAlign: "center",
                      marginTop: "-1vh",
                      marginBottom: "1vh",
                    }}
                  >
                    What is MYSTRY?
                  </div>
                  <div
                    style={{
                      ...responsiveBodyStyle,
                      fontSize: "1rem",
                      marginBottom: "1vh",
                      color: "#dedede",
                    }}
                  >Mystry is an upscale sneaker-tech company that uses proprietary Machine Learning models to understand a user’s taste and preference in fashion, primarily sneakers, based upon which we customize luxury sneaker MystryBoxes for our customers. 
                  </div>
                </Col>
                <Col xs="12" sm="12" style={{ marginTop: "1vh" }}>
                  <div>
                  <img
                    alt="..."
                    className="img-center img-fluid pt-2"
                    src={require("assets/custom/About_1.jpeg").default}
                    width="500" height="300"
                  />  
                  </div>
                  <div
                    style={{
                      ...responsiveBodyStyle,
                      margin: "2vmax",
                      color: "white",
                    }}
                  >
                  </div>
                </Col>
              </Row>
            </>
          )}
        </Container> 
        <Container
          fluid={fluid}
          style={{ ...containerStyle, marginTop: "5vmax" }}
        >
          
        </Container>
        
        <Container
          fluid={fluid}
          style={{ ...containerStyle, marginTop: "5vmax" }}
        >
          {!mobile && (
          <Row className="align-items-center">
            <Col xs="6" md="6" style={{ textAlign: "center" }}>
            <div
              className="title text-white"
              style={{
                ...responsiveHeadStyle,
                marginLeft: "3vw",
                fontWeight: "700",
                textAlign: "center",
              }}
            >
              Why we started Mystry:
            </div>
              <div className="p-2" style={{ 
                    // fontSize: mobile ? "0.875rem" : "1rem",
                    ...responsiveBodyStyle,
                    marginLeft: "3vw",
                    display: "inline-block",
                    width: "100%",
                    height: "200px",
                    verticalAlign: "center",
                    color: "#dedede",
                  }}>Off late, sneakers have become more of an asset class for resellers rather than a form of art. At Mystry, we love sneakers, and we love the culture and story associated with sneakers, and we’re here to keep the excitement alive in the sneaker industry. Sign up for your <a href='./mystryBoxProduct' style={{color:"#00b200"}}>Mystrybox </a>to avail only the latest and most hyped sneakers in the market. Hand-picked by our AI, customized for you is a sneaker you will love, every single time. 
              </div>
              <div style={{ textAlign: "center" }}>
                {/* <Button
                  color="green"
                  tag={Link}
                  to={`/membership`}
                  size={btnSize}
                  style={{ ...joinStyle }}
                >
                  Join Waitlist
                </Button> */}
              </div>
            </Col>
            <Col xs="6" md="6">
              <img
                alt="..."
                className="img-center img-fluid"
                src={require("assets/custom/About_2.jpeg").default}
                width="500" height="300"
              />
            </Col>
          </Row>
          )}
          {mobile && (
            <>
              <Row className="align-items-center">
                <Col xs="12" sm="12">
                  <div
                    className="title text-white pt-5 pb-2"
                    style={{
                      ...responsiveHeadStyle,
                      fontSize: "1.65rem",
                      fontWeight: "700",
                      textAlign: "center",
                      marginTop: "-1vh",
                      marginBottom: "1vh",
                    }}
                  >
                    Why we started Mystry:
                  </div>
                  <div
                    style={{
                      ...responsiveBodyStyle,
                      fontSize: "1rem",
                      marginBottom: "1vh",
                      color: "#dedede",
                    }}
                  >Off late, sneakers have become more of an asset class for resellers rather than a form of art. At Mystry, we love sneakers, and we love the culture and story associated with sneakers, and we’re here to keep the excitement alive in the sneaker industry. Sign up for your <a href='./mystryBoxProduct' style={{color:"#00b200"}}>Mystrybox </a>to avail only the latest and most hyped sneakers in the market. Hand-picked by our AI, customized for you is a sneaker you will love, every single time. 
                  </div>
                </Col>
                <Col xs="12" sm="12" style={{ marginTop: "1vh" }}>
                  <div>
                  <img
                    alt="..."
                    className="img-center img-fluid pt-2"
                    src={require("assets/custom/About_2.jpeg").default}
                    width="500" height="300"
                  />  
                  </div>
                  <div
                    style={{
                      ...responsiveBodyStyle,
                      margin: "2vmax",
                      color: "white",
                    }}
                  >
                  </div>
                </Col>
              </Row>
            </>
          )}
        </Container>

        {/* <Container
          fluid={fluid}
          style={{ ...containerStyle, marginTop: "5vmax" }}
        >
          <Row className="align-items-center">
            <Col xs="6" md="6" style={{ textAlign: "center" }}>
              <p style={{ fontSize: mobile ? "0.875rem" : "1rem" }}>
                Gain exclusive access to 3 of the hottest sneakers in our
                ultimate version of the Mystrybox - The Trifecta Box.
              </p>
              <div style={{ textAlign: "center" }}>
                <Button
                  color="green"
                  tag={Link}
                  to={`/membership`}
                  size={btnSize}
                  style={{ ...joinStyle }}
                >
                  Join Waitlist
                </Button>
              </div>
            </Col>
            <Col xs="6" md="6">
              <img
                alt="..."
                className="img-center img-fluid"
                src={require("assets/custom/tier3-3.jpeg").default}
              />
            </Col>
          </Row>
        </Container>
        <Container
          fluid={fluid}
          // style={{ marginBottom: "15vh", marginTop: mobile && "10vh" }}
          style={{
            marginTop: mobile && "15vw",
            marginBottom: !mobile ? "7.5vw" : "15vw",
          }}
        >
          
        </Container> */}
      </div>

      <Footer />
    </>
  );
};

export default About;
