import React, { useState, useEffect } from "react";
import Navbar from "components/Navbars/Navbar.js";
import { Container, Row, Col } from "reactstrap";
import "./LoginSignUp.css";

import SignUp from "components/User/SignUp";
import { userDb, auth } from "../../../firebase.js";
import { useAuth } from "contexts/AuthContext";

import { useLocation, useHistory } from "react-router";
import NavbarV2 from "../pagesV2/Navbar/Navbar";
import Footer from "../pagesV2/Footer/Footer";

const SignUpPage = (props) => {
  const { loggedIn, currentUser } = useAuth();
  const history = useHistory();
  const location = useLocation();

  const waitlist = location.state ? props.location.state.waitlist : false;
  const checkout = location.state ? props.location.state.checkout : false;
  const membershipPage = location.state ? props.location.state.membershipPage : false;
  const tierSelected = location.state ? props.location.state.tier : false;
  const sneakerswipe = location.state ? props.location.state.sneakerswipe : false;

  useEffect(() => {
    auth
      .getRedirectResult()
      .then((authUser) => {
        if (authUser.user) {
          var userInfo;
          const ref = userDb(authUser.user.uid);
          // const ref = userDb(authUser.user.uid);
          // const ref = database.users;
          ref
            .once("value", (snapshot) => {
              // ////console.log("snapshot:", snapshot.val());
              userInfo = snapshot.val();
            })
            .then(() => {
              if (!userInfo) {
                const user = authUser.user;
                const obj = {
                  userID: user.uid,
                  email: user.email,
                };
                // var usersRef = database.users.push(authUser.user.uid);
                // database.users.child(usersRef.key).update({
                //   userID: user.uid,
                //   email: user.email,
                // });
                ref.set(obj);
                // userDb.child(authUser.user.uid).update({
                //   ...obj,
                // });
                // ref.update(obj.userID, obj.email);
                // ref.push();
              }
              //location.search.includes('checkout') ? history.push('/checkout') : history.push('/');
              //props.location.state ? history.push('/checkout') : /*history.push('/');*/ ////console.log('home');
            })
            .catch((error) => {
              ////console.log(error);
            });
        }
      })
      .catch((error) => {
        ////console.log(error);
      });
  }, [location, checkout, history]);

  const [showLogin, setShowLogin] = useState(loggedIn ? false : true);

  useEffect(() => {
    if (!loggedIn) {
      setShowLogin(true);
    } else {
      // if (!currentUser.emailVerified) {
      //   setShowLogin(true);
      // } else {
      window.history.go(-1)
      // }
    }
  }, [loggedIn]);

  return (
    <div
      className="wrapper"
      style={{
        position: "relative",
        overflow: "hidden",
        background: "black",
      }}
    >
      <NavbarV2/>
      <Container
        fluid={true}
        style={{
          marginTop: "100px",
          marginBottom: "75px",
          paddingLeft: "5vw",
          paddingRight: "5vw",
          background: "black",
        }}
      >
        {showLogin && (
          <Row className="loginPageHeading">
            <Col xs={0} md={6}>
              <video
                width="100%"
                autoPlay
                loop
                muted
                playsInline
                style={{
                  zIndex: 1,
                  marginTop: "2vh",
                }}
              >
                <source
                  src={require("assets/custom/aboutuscropped.mp4").default}
                  type="video/mp4; codecs=hevc"
                ></source>
                <source
                  src={require("assets/custom/aboutuscropped.webm").default}
                  type="video/webm; codecs=vp9"
                ></source>
                <source
                  src={require("assets/custom/aboutuscropped.mp4").default}
                  type="video/mp4"
                ></source>
              </video>
            </Col>
            <Col xs={12} md={6}>
              <h3>Sign up</h3>
              <SignUp 
                checkout={checkout} 
                membershipPage={membershipPage} 
                tierSelected={tierSelected} 
                sneakerswipe={sneakerswipe} 
                waitlist={waitlist}
              />
            </Col>
          </Row>
        )}
      </Container>
      <Footer />
    </div>
  );
};

export default SignUpPage;