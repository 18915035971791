import { database } from "../../../../firebase";
import { Col, Row } from "reactstrap";
import { withRouter } from "react-router-dom";
import { useState, useEffect } from "react";

export default function ShoePageRow({
  shoeID,
  key,
  isMobile,
  item,
  size,
  quantity,
}) {
  const [shoe, setShoe] = useState("");
  console.log(shoeID);

  useEffect(() => {
    database.shoes.child(shoeID).once("value", (snapshot) => {
      // this.setState({ shoe: snapshot.val() });
      setShoe(snapshot.val());
      console.log(shoe);
    });
  }, [shoeID]);

  const contentRow = (title, data) => {
    return (
      <Row
        style={{
          paddingTop: "10px",
          paddingBottom: "10px",
          borderTop: "1px solid rgba(238, 238, 238, 0.5)",
          borderBottom: "1px solid rgba(238, 238, 238, 0.5)",
        }}
      >
        <Col xs="4" md="1">
          {title}
        </Col>
        <Col style={{ textAlign: "right", fontWeight: "600" }}>{data}</Col>
      </Row>
    );
  };

  return (
    <div>
      {shoe !== "" && (
        <img
          alt="..."
          className="img-center img-fluid"
          src={shoe.shoeImg.lowRes.front}
          style={{ height: "10vmax" }}
        />
      )}
      <br />
      <br />
      {contentRow("Name", shoe.shoeName)}
      {contentRow("SKU", shoe.shoeSKU)}
      {contentRow("Size", size)}
      {contentRow("Quantity", quantity)}
    </div>
  );
}
