import {React, useMemo, useState, useCallback, useEffect, memo} from "react";
import { Row, Col } from "reactstrap";
import { useAuth } from "contexts/AuthContext";
import { useRanger } from "react-ranger";
import { Link } from 'react-router-dom';
import ShoeBidModal from "./ShoeBidModal";
import Faq from "react-faq-component";
import "./ShoeBid.css";
var _ = require("lodash");
const ShoeBid = ({
  shoe,
  ebay,
  getSizeStr,
  setBidNowState,
  setSize,
  size,
  setButtonColor,
  setShowLogin,
  setShowPayment,
  setShowAddress,
  setShowAddressBid,
}) => {
    const { loggedIn } = useAuth();  
    // const end_,lastSale_,start_;
    const [end_, setEnd] = useState(shoe.end);
    const [start_, setStart] = useState(shoe.start);
    const [lastSale_, setlastSale] = useState(shoe.lastSale);
    
    const styles = {
      bgColor: "black",
      rowTitleColor: "#d6cece",
      rowContentColor: "white",
      arrowColor: "white",
    };
    const getRowOptions= [
      // option for first row item
      {
          close: () => {},
          expand: () => {},
          scrollIntoView: (option) => {}, // option values : https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView#parameters
      },
    
  ];
  const [rows, setRowsOption] = useState(null);
    const data = {
      rows: [
        {
          title: "How placing a bid is absolutely free?",
          content: (
              <ul><br/><li>
              Placing a bid is absolutely free! All bids last for exactly 24 hours.</li><li>
              If your bid is accepted by one of <span style={{fontWeight:"bold"}}>Sneaker Chef’s</span> within 24 hours, only then will the transaction be processed . Learn more about our Sneaker Chef’s <span
                  style={{color:"#32CD32", fontWeight:"bold", cursor:"pointer"}}    
                  onClick={() => {
                    rows[0].close()
                    rows[2].close()
                    rows[1].expand()
                    }}
              >
              here</span></li><li>
    
              If your bid amount is not accepted within 24 hours, your order will expire worthless, and the payment transaction will be reversed -  you will not be charged any amount if the bid expires worthless. </li><li>
              Once your bid has been accepted, we shall try our best to process, authenticate, and dispatch your sneakers all within 24 hours. Learn more about our 2Factor authentication policy <span
                style={{color:"#32CD32", fontWeight:"bold", cursor:"pointer"}}    
                  onClick={() => {
                    rows[0].close()
                    rows[1].close()
                    rows[2].expand()
                    }}
              >
              here</span></li><li>
              Expected delivery time: 3-5 Business Days</li>
              </ul>
            ),
          },
          {
            title: "Who are our Sneaker Chef’s?",
            content: (           
              <div><br/>
               <p style={{color:"white"}}>
               Our aim is to be the most trusted global marketplace for luxury sneakers while still being able to offer the quickest delivery time period (3-5 Business Days) at no additional cost. 
               <br/><br/>In order to ensure guaranteed authenticity on every pair,  Mystry Inc. partners with only the most highly reputable resellers and vendors across USA/Canada from numerous Boutique Stores and Cook Groups. We don’t just let anyone sell on Mystry - we have a very strong vetting process for all our resellers, and we conduct a detailed due diligence before each sneaker is dispatched. 
               <br/><br/>In fact, to offer the golden standard of authenticity, we work with Check-Check to ensure that each sneaker has been digitally reviewed in detail by expert authenticators! Only once we have received a “PASS/Verified” certification on each pair, do we dispatch your sneakers. If we find that the sneaker does not pass our verification standards, we will issue a full refund on the same day. Learn more about our 2-Factor Authentication system over  <span
                style={{color:"#32CD32", fontWeight:"bold", cursor:"pointer"}}    
                  onClick={() => {
                    rows[0].close()
                    rows[1].close()
                    rows[2].expand()
                    }}
              >
              here</span>: 
               <br/><br/>Click <a href="https://getcheckcheck.com/en/check_request/948791" style={{color:"#32CD32", fontWeight:"bold", cursor:"pointer"}}  >here</a> to view a sample “PASS” (verification) certification. 
               </p>
              </div>
              ),
            },
            {
              title: "How we offer 2-Factor authentication on all our Sneakers?",
              content: (
                <ul><li>
                We understand that this industry is all about trust, and we at Mystry Inc. strive to be one of the most trusted global marketplace for luxury sneakers!
                </li><li>
                1-Factor: We don’t let anyone/everyone sell on our platform! In order to eliminate all forms of fake sneakers, we only look to work with a handful of highly trusted Sneaker Chef’s who have years of experience in sourcing and reselling sneakers. We carry out a detailed screening process for all our resellers and vendors! Learn more about our Sneaker Chef’s 
                </li><li>
                2 Factor: Using proprietary digital authentication technology and machine learning, all our sneakers come with a Check-Check verification certification, where each sneaker has been digitally reviewed in detail by expert authenticators! Only once we have received a “PASS/Verified” certification on each pair, do we dispatch your sneakers.
                </li><li>
                Apart from offering the highest authenticity checks, we also try to deliver your sneakers within 3-5 working days. Learn more about how we source, authenticate, and dispatch your sneakers in less than 24 hours <Link to={"../faq"} style={{color:"#32CD32", fontWeight:"bold", cursor:"pointer"}}  >here</Link></li>
                </ul>
                ),
              },
        ],
      };
    if(ebay) {
      let ebay_url = "https://mystry-server-z574yasx7q-uc.a.run.app/scraper_api/ebay_data";
      fetch(ebay_url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          "sku": shoe.shoeSKU,
          "size": `${size}`
        })
      })
      .then((response) => {
        if(!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((data) => {
        const price = data.ebayData.price;
        setEnd(Math.ceil(price));
        setStart(Math.ceil(price-(0.125*price)));
        setlastSale(Math.ceil(price));
      })
      .catch((error) => {
        console.log('error: ' + error);
      })
    } else {
      let url = 'https://us-east1-price-scraper-330821.cloudfunctions.net/sneaker-price-estimator/';
        fetch(url, {
          method: "POST",
          body: JSON.stringify({
            // "sku":"716371-040"
            "sku":shoe.shoeSKU
          }
        )})
        .then(response => response.json())
        .then(json => {
          if ((size in json)==false){
            var size_=parseFloat(parseFloat(size)+1.5)+'W';
            setEnd(parseInt(json[size_].end));
            setStart(parseInt(json[size_].start));
            setlastSale(parseInt(json[size_].lastSale));
          }
          else
          {
            //console.log(json[size])
            setEnd(parseInt(json[size].end));
            setStart(parseInt(json[size].start));
            setlastSale(parseInt(json[size].lastSale));
          }
          //console.log("Start " + start_);
          //console.log("End " + end_);
          //console.log("Last Sale " + lastSale_);
        })
      }
    
      const ShoeRange = () => {
        const [values, setValues] = useState([lastSale_]);
      
        const { getTrackProps, handles } = useRanger({
          min: start_,
          max: Math.ceil(end_+(0.125*end_)),
          stepSize: 1,
          values,
          onDrag: setValues
        });
      
        return (
          <div className="App">
          {(shoe.bidPrice=values[0]) && 
            <h3 style={{textAlign:"center", marginBottom:"5px", paddingTop:"30px"}}>
              Bid Price: <span style={{color:"#32CD32"}}>${ values }</span>
            </h3>
      }
            <br />
            <br />
            <div
              {...getTrackProps({
                style: {
                  height: "4px",
                  background: "#ddd",
                  boxShadow: "inset 0 1px 2px rgba(0,0,0,.6)",
                  background: "linear-gradient(to right, #FF0000 0%, #FDFF8F 50%, green 100% )",
                  borderRadius: "2px"
                }
              })}
            >
              {handles.map(({ getHandleProps }) => (
                <button
                  {...getHandleProps({
                    style: {
                      width: "10px",
                      height: "14px",
                      outline: "none",
                      borderRadius: "100%",
                      background: "linear-gradient(to bottom, #eee 45%, #ddd 55%)",
                      border: "solid 1px #888"
                    }
                  })}
                />
              ))}
            </div>
            <br />
            <br />
            <br />
            <pre
              style={{
                display: "inline-block",
                textAlign: "left"
              }}
            >
              {/* <code>
                {JSON.stringify({
                  values
                })}
              </code> */}
            </pre>
          </div>
        );
      }
   
  return (
    <Col className="shoeBuyNowContainerCol">
      <Row className="shoeProductDetails">
        <h3
          style={{
            marginBottom: "15px",
          }}
        >
          {shoe.shoeName}
        </h3>
      </Row>
      <Row className="shoeProductDetails mb-5">
        <h4>{shoe.releaseYear}</h4>
      </Row>
      <div>
        <Row className="shoeRowBorder">
          <Col xs="6">
            <h4>Size</h4>
          </Col>
          <Col xs="6" className="shoeBuyNowCol">
            <h4>
              US {size} M / {size + 1.5} W
            </h4>
          </Col>
        </Row>
        <Row className="shoeRowBorder">
          <Col xs="6">
            <h4>Color</h4>
          </Col>
          <Col xs="6" className="shoeBuyNowCol">
            <h4>{shoe.shoeColor}</h4>
          </Col>
        </Row>
        {/* HARDCODED */}
        <Row className="shoeRowBorder">
          <Col xs="6">
            <h4>Condition</h4>
          </Col>
          <Col xs="6" className="shoeBuyNowCol">
            <h4>New</h4>
          </Col>
        </Row>
        {/* HARDCODED */}
        <Row className="shoeRowBorder">
            <Col xs="6">
            <h4>Last Sale Price</h4>
          </Col>
          <Col xs="6" className="shoeBuyNowCol">
            <h4>{lastSale_}</h4>
          </Col>
        </Row>
      </div>
      <ShoeRange/>
      <Row style={{justifyContent: "center", marginTop: "-30px"}}> 
        <Col
          xs="5"
          className="shoeProductDetail cancelButton mr-4"
          onClick={() => {
            setBidNowState(false);
            setSize("");
            setButtonColor(_.fill(Array(21), "simple"));
          }}
        >
          <span style={{color: "#32CD32"}}>Cancel</span>
        </Col>
        {!loggedIn && 
           <ShoeBidModal
           shoe={shoe} 
           size={size}         
          />}
          
       {loggedIn && <Col
          xs="5"
          className="shoeProductDetail BidCol placeBidButton"
          onClick={() => {
            setBidNowState(false);
            setButtonColor(_.fill(Array(21), "simple"));
            setShowLogin(false);
            setShowAddressBid(true);
            setShowPayment(false);
            
          }}
        >
         <span style={{color: "white"}}>Place Bid</span>
        </Col>}
      </Row>
      <Row><div><br></br><br></br><br></br>
      <Faq data={data} styles={styles} getRowOptions={setRowsOption} />
      </div>
      </Row>
    </Col>
  );
};
export default ShoeBid;
