const MYSTRY_SERVER_BASE_URL = "https://mystry-server-z574yasx7q-uc.a.run.app";

export const userLoggedInSuccess = (userMail) => {
  fetch(MYSTRY_SERVER_BASE_URL + "/userLoggedIn", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      userMail: userMail,
    }),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      return Promise.reject(response);
    })
    .then((responseText) => {})
    .catch((error) => {
      console.error(error);
    });
};

export const userWaitlistSucess = (userMail) => {
  fetch(MYSTRY_SERVER_BASE_URL + "/addedToWaistlist", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      userMail: userMail,
    }),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      return Promise.reject(response);
    })
    .then((responseText) => {})
    .catch((error) => {
      console.error(error);
    });
};
