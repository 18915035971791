import React, {useState} from 'react';
import { Container } from 'reactstrap';
import "./Contact.css";
import useWindowDimensions from "hooks/useWindowDimensions";
import axios from 'axios';

const Contact = () => {
  const fluid = true;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [compName, setCompName] = useState("");
  const [compWebsite, setCompWebsite] = useState("");
  const [message, setMessage] = useState("");
  const [addedContact, setAddedContact] = useState(false);

  const { width, height } = useWindowDimensions();
  var mobile;
  if (width <= 420 || height <= 420) {
    mobile = true;
  } else {
    mobile = false;
  }

  const addContact = (e) => {
    e.preventDefault();
    axios({
      method: "POST", 
      url: "https://mystry-server-z574yasx7q-uc.a.run.app/sendContact", 
      data: ({
          name: name,   
          email: email,  
          compName: compName,
          compWebsite: compWebsite,
          message: message
      })
    }).then((response)=>{
        document.getElementById('contact-form').reset();
    })
    setAddedContact(true) 
    setName("")
    setEmail("")
    setCompName("")
    setCompWebsite("")
    setMessage("")
  };
  
  return (
    <>
     <Container 
      fluid={fluid}
      className="p-0 m-0"
      style={{minWidth: "100%"}}
    >
      <div className="video-block">
        <p style={{
          fontSize: mobile? "40px" : "4rem",
          fontWeight: "600"
        }}>Contact Us</p>
        <video 
          id="contact-video" 
          autoPlay
          playsInline
          loop
          muted
          className='darken'
        >
          <source
            src={
              require("assets/custom/hero-video-compressed-new.mp4")
                .default
            }
            type="video/mp4"
          ></source>        
        </video>
        <div class="container">
          <section class="contact" id="contact">
            <div class="row clearfix" >
              <div class="offset-sm-2 col-sm-8">
                <form id="contact-form" onSubmit={addContact}>
                  <div class="form-group d-flex justify-content-center">
                    <input 
                      type="text" 
                      name="name" 
                      class="form-control" 
                      placeholder='Name'
                      required
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div class="form-group d-flex justify-content-center">
                    <input 
                      type="email" 
                      name="email" 
                      class="form-control" 
                      placeholder='Email'
                      required
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div class="form-group d-flex justify-content-center">
                    <input 
                      type="text" 
                      name="text"
                      class="form-control" 
                      placeholder='Company Name'
                      required
                      onChange={(e) => setCompName(e.target.value)}
                    />
                  </div>
                  <div class="form-group d-flex justify-content-center">
                    <input 
                      type="text" 
                      name="website" 
                      class="form-control" 
                      placeholder='Company Website'
                      required
                      onChange={(e) => setCompWebsite(e.target.value)}
                    />
                  </div>
                  <div class="form-group d-flex justify-content-center">
                    <textarea 
                      class="form-control text-area"
                      name="message"
                      placeholder='Message'
                      required
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </div>
                  <div class="form-group d-flex justify-content-center">
                    <button class="btn submit_btn pt-3">Submit</button>
                  </div>
                </form>
                {addedContact && <h3 className='contact-message font-face-nexa'>
                  Thanks for contacting us, will get back to you soon!
                </h3>}
              </div>
            </div>
          </section>
        </div>
      </div>
     </Container>
    </>
  );
};
export default Contact;