import React, { useState } from 'react';
import NavbarV2 from '../Navbar/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear, faCaretRight, faCaretLeft } from '@fortawesome/free-solid-svg-icons'
import './HouseOfTech.css'
import Parser from "html-react-parser";
import { useHistory } from 'react-router-dom';
import useWindowDimensions from "hooks/useWindowDimensions";

const HouseOfTech = () => {
  const [slide, setSlide] = useState(1);
  const history = useHistory();
  const { width, height } = useWindowDimensions();

  const handleSlideChange = (slide) => {
    setSlide(slide);
  }

  var mobile, landscape;
  if (width < 768) {
    mobile = true;
    landscape = false;
  } else if (height < 576 || width < 992) {
    mobile = false;
    landscape = true;
  } else {
    mobile = false;
    landscape = false;
  }

  const tech = [
    "Mystry Lens",
    "Sneakerswipe",
    "Mystryverse",
    "Spotify for Sneakers",
    "Sneaker Design Studio",
    "Xilver Labs"
  ];

  const title = [
    "Scan, Compare, and Checkout from 80,000+ sneakers across the globe",
    "Tinder for Sneakers",
    "Shop Sneakers on the Metaverse",
    "Sneakers Handpicked For You",
    "Brining Sneaker Concepts to life - Reimagining the sneakers you see (and are yet to see)",
    "Concept Collaborative Company"
  ]

  const desc = [
    "The most advanced and accurate sneaker lens ever built. Using the Mystry app, you can scan, price compare, and checkout from 80,000+ sneakers from all leading sneaker marketplaces, including GOAT, eBay, Flight Club, and more. You never have to overpay for a sneaker ever again! ",
    `SneakerSwipe uses Machine Learning to understand a user’s taste and preferences in sneakers, based on which we curate a sneaker <a href='/mystryBoxProduct' target="_blank">Mystry Boxes</a> for our clients.`,
    "In a collaborative effort with Meta, we’re launching the first sneaker store on the metaverse - experience sneakers like never before and checkout 1000’s of sneakers in seconds.",
    "Our AI will factor in sneaker brands, silhouettes, colorways, release year, functionality, and price range to recommend sneakers to you based on your likes (and dislikes).",
    "We model, texture, and build sneaker designs and concepts from scratch.",
    "Mystry Inc’s Media House dedicated to showcasing the world’s most influential concepts and collaborations across brands, artists, film, & fashion. ",
  ]

  return (
    <div>
      <NavbarV2/>
      <div className='ht-container'>
        {(slide == 1 || slide==2) && (
          <video 
            className="ht-video" 
            style={{opacity: "80%", height: "100vh"}}
            autoPlay
            playsInline
            loop
            muted
          >
            <source
              src={require("assets/custom/HouseOfTech/HT1.mp4")
                .default}
              type="video/mp4"
            ></source>
          </video>
        )}      
        {(slide == 3) && mobile && (
          <video 
            className="ht-video" 
            style={{
              height: "100vh", 
              width: "100vw",
              top: "55vh"
            }}
            autoPlay
            playsInline
            loop
            muted
          >
            <source
              src={require("assets/custom/HouseOfTech/HT3.mp4")
                .default}
              type="video/mp4"
            ></source>
          </video>
        )} 
        {(slide == 3) && !mobile && (
          <video 
            className="ht-video" 
            style={{
              height: "100vh", 
              width: "25vw",
              left: "70%"
            }}
            autoPlay
            playsInline
            loop
            muted
          >
            <source
              src={require("assets/custom/HouseOfTech/HT3.mp4")
                .default}
              type="video/mp4"
            ></source>
          </video>
        )} 
        {(slide == 4) && (
          <video 
            className="ht-video" 
            style={{opacity: "30%", height: "100vh"}}
            autoPlay
            playsInline
            loop
            muted
          >
            <source
              src={require("assets/custom/HouseOfTech/HT4.mp4")
                .default}
              type="video/mp4"
            ></source>
          </video>
        )} 
        {(slide == 5) && (
          !mobile ? 
            <video 
              className="ht-video" 
              style={{opacity: "30%"}}
              autoPlay
              playsInline
              loop
              muted
            >
              <source
                src={require("assets/custom/HouseOfTech/HT5.mp4")
                  .default}
                type="video/mp4"
              ></source>
            </video>
          : <div className="ht-video" style={{height: "100vh"}}>
              <video 
                style={{
                  opacity: "40%",
                  width: "100%",
                  top: "65vh"
                }}
                autoPlay
                playsInline
                loop
                muted
              >
                <source
                  src={require("assets/custom/HouseOfTech/HT5.mp4")
                    .default}
                  type="video/mp4"
                ></source>
              </video>
            </div>
        )} 
        {(slide == 6) && (
          <div className="ht-video" style={{height: "100vh"}}>
            <video 
              style={{
                opacity: "30%",
                width: "100%",
                top: mobile? "70vh": "40vh"
              }}
              autoPlay
              playsInline
              loop
              muted
            >
              <source
                src={require("assets/custom/HouseOfTech/HT6.mp4")
                  .default}
                type="video/mp4"
              ></source>
            </video>
          </div>
        )} 
        {(slide ==7) && (
          mobile? <div className="ht-video" style={{height: "100vh"}}>
            <video 
              style={{
                opacity: "30%",
                width: "100vw",
                top: "30vh",
                objectFit: "cover",
                height: "40%"
              }}
              autoPlay
              playsInline
              loop
              muted
            >
              <source
                src={require("assets/custom/HouseOfTech/HT7.mp4")
                  .default}
                type="video/mp4"
              ></source>
            </video>
          </div>
         :<video 
            className="ht-video" 
            style={{
              opacity: "30%", 
              height: "100vh",
              padding: "80px"
            }}
            autoPlay
            playsInline
            loop
            muted
          >
            <source
              src={require("assets/custom/HouseOfTech/HT7.mp4")
                .default}
              type="video/mp4"
            ></source>
          </video>
        )}    
        <div className='d-flex justify-content-center align-items-center'>
          <div>
            {slide != 1 && !mobile && (
              <div className='d-flex justify-content-center align-items-center'>
                <p class="ht-overlay-bt">
                  H<span style={{fontSize: mobile? "4vh": "30px"}}>
                    <FontAwesomeIcon icon={faGear} size='2xs' />
                  </span>use.{" "}<span style={{fontSize: mobile? "4vh": "30px"}}>
                    <FontAwesomeIcon icon={faGear} size={'2xs'}/>
                  </span>f. Tech
                </p>
              </div>
            )}
            {slide == 1 && (
              <p class="ht-overlay" id="topText">
                H<span style={{fontSize: mobile? "4vh": "10vh"}}>
                  <FontAwesomeIcon icon={faGear} size='xs' />
                </span>use.{" "}<span style={{fontSize: mobile? "4vh": "10vh"}}>
                  <FontAwesomeIcon icon={faGear} size={'xs'} spin/>
                </span>f. Tech
              </p>
            )}
            {slide == 1 && (
              <p class="desc-overlay">
                Take a sneak peek at all the amazing technology we’re
                building for the sneaker industry at Mystry Inc. 
              </p>
            )}
            {slide!=1 && mobile && (
              <div className='ht-content slide-right'>
                <div className="ht-tech-mob d-flex justify-content-center" id='tech'>
                  {tech[slide-2]}
                </div>
                <div className='d-flex justify-content-center'>
                  <p className="ht-tech-title-mob" id='desc'>
                    {title[slide-2]}
                  </p>
                </div>
                <div className='d-flex justify-content-center'>
                  <p className="ht-description-mob" id='desc'>
                    {Parser(desc[slide-2])}
                  </p>
                </div>
              </div>
            )}
            {slide!=1 && !mobile && (
              <div>
                <div className="ht-tech slide-right" id='tech'>
                  {tech[slide-2]}
                </div>
                <p className="ht-tech-title slide-right" id='desc'>
                  {title[slide-2]}
                </p>
                <p className="ht-description slide-right" id='desc'>
                  {Parser(desc[slide-2])}
                </p>
              </div>
            )}
            {slide==3 && (
              <a href='/mystryBoxProduct' target="_blank">
                <button className='ht-button'>
                  Shop Mystry Boxes
                </button>
              </a>
            )}
            {slide==7 && (
              <a href='https://xilver.io' target="_blank">
                <button className='ht-button'>
                  Visit Xilver Labs
                </button>
              </a>
            )}
            {(slide == 3 && mobile) && (
              <p onClick={() => handleSlideChange(slide+1)} style={{
                position: "absolute", top: "148vh", left: "75%",
                textDecoration: "underline", cursor: "pointer"
              }}>Next <span style={{margin: "10px"}}>
                  <FontAwesomeIcon icon={faCaretRight} />
                </span>
              </p>
            )}
            {(slide == 3 && !mobile) && (
              <p onClick={() => handleSlideChange(slide+1)} className='ht-next'>
                Next <span style={{margin: "10px"}}>
                  <FontAwesomeIcon icon={faCaretRight} />
                </span>
              </p>
            )}
            {slide < 7 && slide!=3 && (
              <p onClick={() => handleSlideChange(slide+1)} className='ht-next'>
                Next <span style={{margin: "10px"}}>
                  <FontAwesomeIcon icon={faCaretRight} />
                </span>
              </p>
            )}
            {(slide == 7) && (
              <p onClick={() => history.push('/')} className='ht-next'>
                Back to Website <span style={{margin: "10px"}}>
                  <FontAwesomeIcon icon={faCaretRight} />
                </span>
              </p>
            )}
            {(slide == 3 && mobile) && (
              <p onClick={() => handleSlideChange(slide-1)} style={{
                position: "absolute", top: "148vh", right: "70%",
                textDecoration: "underline", cursor: "pointer"
              }}><span style={{margin: "10px"}}>
                  <FontAwesomeIcon icon={faCaretLeft} />
                </span>Previous
              </p>
            )}
            {(slide == 3 && !mobile) && (
              <p onClick={() => handleSlideChange(slide-1)} className='ht-prev'>
              <span style={{margin: "10px"}}>
                  <FontAwesomeIcon icon={faCaretLeft} />
                </span>Previous
              </p>
            )}
            {(slide <= 7 && slide !=1 && slide!=3) && (
              <p onClick={() => handleSlideChange(slide-1)} className='ht-prev'>
              <span style={{margin: "10px"}}>
                  <FontAwesomeIcon icon={faCaretLeft} />
                </span>Previous
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HouseOfTech;