import Navbar from "components/Navbars/Navbar.js";
import React, { useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  Container,
} from "reactstrap";
import Footer from "components/Footer/Footer";
import axios from "axios";
import { toast } from "react-toastify";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [compName, setCompName] = useState("");
  const [compWebsite, setCompWebsite] = useState("");
  const [message, setMessage] = useState("");

  const addContact = (e) => {
    e.preventDefault();
    axios({
      method: "POST", 
      url: "https://mystry-server-z574yasx7q-uc.a.run.app/sendContact", 
      data: ({
          name: name,   
          email: email,  
          compName: compName,
          compWebsite: compWebsite,
          message: message
      })
    }).then((response)=>{
        document.getElementById('contact-page').reset();
    })
    setName("")
    setEmail("")
    setCompName("")
    setCompWebsite("")
    setMessage("")
  };

  return (
    <div>
      <Navbar />
      <Container style={{ marginTop: "100px", marginBottom: "75px" }}>
        <Card style={{ backgroundColor: "black" }}>
          <CardBody>
            <h3>Contact us</h3>
            <Form onSubmit={addContact} id="contact-page">
              <FormGroup>
                <Input
                  type="text"
                  placeholder="Name*"
                  onChange={(e) => setName(e.target.value)}
                  name="name"
                  value={name}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Input
                  type="email"
                  placeholder="Email*"
                  onChange={(e) => setEmail(e.target.value)}
                  name="email"
                  value={email}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Input
                  type="text"
                  placeholder="Company Name*"
                  onChange={(e) => setCompName(e.target.value)}
                  name="compName"
                  value={compName}
                  required
                />
              </FormGroup>
              <FormGroup>
              <Input
                  type="text"
                  placeholder="Comapny Website*"
                  onChange={(e) => setCompWebsite(e.target.value)}
                  name="compWebsite"
                  value={compWebsite}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Input
                  type="textarea"
                  placeholder="Message*"
                  onChange={(e) => setMessage(e.target.value)}
                  name="message"
                  value={message}
                  required
                />
              </FormGroup>
              <Button type="submit" color="simple">
                Send Email
              </Button>
            </Form>
          </CardBody>
        </Card>
      </Container>
      <Footer />
    </div>
  );
};

export default Contact;
