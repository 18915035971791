import React from 'react';
import useWindowDimensions from "hooks/useWindowDimensions";
import Countdown from 'views/pages/Landing/Countdown';
import { Container } from 'reactstrap';
import moment from "moment";
import 'moment-timezone';

const NextDrop = () => {
  const currentDate = new Date();
  const year =
    currentDate.getMonth() === 11 && currentDate.getDate() > 23
      ? currentDate.getFullYear() + 1
      : currentDate.getFullYear();

  const { width, height } = useWindowDimensions();
  var mobile, landscape;
  if (width < 768) {
    mobile = true;
    landscape = false;
  } else if (height < 576 || width < 992) {
    mobile = false;
    landscape = true;
  } else {
    mobile = false;
    landscape = false;
  }

  const fluid = true;
  var logoStyle;
  if (width <= 420 || height <= 420) {
    logoStyle = { fontFamily: "edosz", fontSize: "1.5rem" };
  } else {
    logoStyle = { fontFamily: "edosz", fontSize: "2.4rem" };
  }

  const timerContainerStyle = {
    textAlign: "center",
    marginBottom: "7.5vw",
    paddingLeft: !mobile && fluid ? "5vw" : "",
    paddingRight: !mobile && fluid ? "5vw" : "",
  };

  return (
    <Container
      style={{
        ...timerContainerStyle,
        marginBottom: mobile ? "0vh" : "12vh",
        marginTop: mobile? "8vh": "15vh",
        width: mobile? "90vw": "65%",
        border: "1px solid #E9E9E9",
        borderRadius: "18px",
      }}
    >
      <div>
      {/* {mobile ? (
        <h3>World’s first AI-powered luxury sneaker #MystryBox</h3>
      ) : (
        <h2>World’s first AI-powered luxury sneaker #MystryBox</h2>
      )} */}
      <div className="arrow bounce mt-5">
        <h3
          style={{ display: "inline-block", color: "#00b200" }}
          className="fa fa-angle-double-down fa-2x"
        ></h3>
        <h3
          style={{ display: "inline-block", color: "#00b200" }}
          className="m-2"
        >
          <strong>NEXT DROP</strong>
        </h3>
        <h3
          style={{ display: "inline-block", color: "#00b200" }}
          className="fa fa-angle-double-down fa-2x"
        ></h3>
      </div>
      <div className="mb-4">
        {mobile ? (
          <h3>
            <Countdown date={moment(`${year}-09-06T18:00:00Z`).tz("Etc/GMT-5:00").format('lll')} />
          </h3>
        ) : (
          <strong style={{ fontSize: "6rem" }}>
            <Countdown date={moment(`${year}-09-06T18:00:00Z`).tz("Etc/GMT-5:00").format('lll')} />
          </strong>
        )}
        {mobile ? (
          <h4 className="mt-4 mb-4">
            Dropping Wednesday, September 6th, 2023
            <br />
            6:00 PM EST // 3:00 PM PST
          </h4>
        ) : (
          <h4 className="mt-4" style={{marginBottom: "8vh"}}>
            Dropping Wednesday, September 6th, 2023 6:00 PM EST // 3:00 PM PST
          </h4>
        )}
      </div>
      </div>
    </Container>
  );
};

export default NextDrop;
