export default function EmptyCart() {
  return (
    <h4>
      Your cart is empty. Shop exciting shoes on Mystry{" "}
      <a href="/kitchen">Kitchen</a>.
      <img
        src={require("assets/custom/checkout1.jpg").default}
        alt="checkout-img"
        className="checkoutImgEmptyCart"
      />
    </h4>
  );
}
