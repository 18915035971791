import { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { Link } from "react-router-dom";
import React from "react";
import useWindowDimensions from "hooks/useWindowDimensions";
import { useAuth } from "../../../../contexts/AuthContext";
import * as Scroll from "react-scroll";
import MyNavbar from "components/Navbars/Navbar";
import NavbarV2 from "../Navbar/Navbar";
import Footer from "../Footer/Footer";

const MembershipV2 = () => {
  const { width, height } = useWindowDimensions();
  const [showScrollDown, setShowScrollDown] = useState(false);
  const joinStyle = mobile ? { fontSize: "16px" } : { fontSize: "16px" };
  const { logout, loggedIn } = useAuth();
  const [showLogin, setShowLogin] = useState(loggedIn ? false : true);

  var mobile, landscape;
  if (width < 768) {
    mobile = true;
    landscape = false;
  } else if (height < 576 || width < 992) {
    mobile = false;
    landscape = true;
  } else {
    mobile = false;
    landscape = true;
  }

  const boxStyle = {
    height: "auto",
    padding: "0px",
    marginBottom: mobile ? "10vh" : "20vh",
  };

  var logoStyle;
  if (width <= 420 || height <= 420) {
    mobile = true;
    logoStyle = { fontFamily: "edosz", fontSize: "1.2rem" };
  } else {
    mobile = false;
    logoStyle = { fontFamily: "edosz", fontSize: "1.8rem" };
  }

  useEffect(() => {
    if (!loggedIn) {
      setShowLogin(true);
    } else {
      setShowLogin(false);
    }
  }, [loggedIn]);
  const fluid = true;

  const scrollDown = () => {
    Scroll.animateScroll.scrollTo(800, {
      smooth: true,
    });
  };
  const toggleScrollDown = (e) => {
    if (e.target.currentTime >= 3) {
      setShowScrollDown(true);
    }
  };

  return (
    <div
      style={{
        position: "relative",
        overflow: "hidden",
        background: "black",
      }}
    >
      <NavbarV2 />
      <Container
        fluid={fluid}
        style={{
          marginTop: !mobile ? "75px" : "50px",
          overflowX: "hidden",
          paddingLeft: !mobile && "5vw",
          paddingRight: !mobile && "5vw",
        }}
      >
        <Row>
          <div className="wrapper">
            <div
              style={{
                position: "relative",
                overflow: "hidden",
              }}
            >
              {/* reference to crop the video from top: https://stackoverflow.com/questions/18716077/crop-video-in-html */}
              {/*marginTop: !mobile ? `${videoOffset}px` : "0px",*/}
              <video
                width="100%"
                type="video/mp4"
                autoPlay
                loop
                muted
                playsInline
                style={{
                  zIndex: 1,
                }}
                position="relative"
                onTimeUpdate={(e) => toggleScrollDown(e)}
              >
                <source
                  src={require("assets/custom/MystryBox.mp4").default}
                  type="video/mp4; codecs=hevc"
                ></source>
                {/* webm supported by everything besides Apple. Highly compressed, small size */}
                <source
                  src={require("assets/custom/MystryBox.webm").default}
                  type="video/webm; codecs=vp9"
                ></source>
                {/* mp4 video encoded with h264. Widely supported */}
                <source
                  src={require("assets/custom/MystryBox.mp4").default}
                  type="video/mp4"
                ></source>
              </video>
              {showScrollDown && !mobile && (
                <div className="scrollDownContainer" onClick={scrollDown}>
                  <div className="scrollDownChevron"></div>
                  <div className="scrollDownChevron"></div>
                  <div className="scrollDownChevron"></div>
                  <span className="scrollDownText">Scroll down</span>
                </div>
              )}
            </div>
          </div>
        </Row>
        {mobile && (
          <h3
            className="text-white"
            style={{ marginTop: "4vh", textAlign: "center" }}
          >
            Welcome to the MystryBox
          </h3>
        )}
        {!mobile && (
          <h1
            className="text-white"
            style={{ marginTop: "12vh", textAlign: "center" }}
          >
            Welcome to the MystryBox
          </h1>
        )}
        <Row>
          <Col
            style={{
              textAlign: "center",
              marginTop: "0px",
              marginBottom: mobile ? "5vh" : "10vh",
              fontSize: "1.5rem",
            }}
          >
            Pick a tier
          </Col>
        </Row>
        {mobile && (
          <Container fluid={fluid}>
            <Col xs="12">
              <Row
                xs="12"
                md="6"
                className="d-flex justify-content-center align-items-center"
                style={boxStyle}
              >
                <div style={{ textAlign: "center" }}>
                  <Link
                    to={{
                      pathname: !loggedIn ? "/login" : "/infoFormV2",
                    }}
                  >
                    <img
                      src={
                        require("assets/custom/membership/Black.png").default
                      }
                      width="75%"
                      height="50%"
                      style={{ marginBottom: "5vh" }}
                      alt=""
                    />
                  </Link>
                </div>
                <div style={{ textAlign: "center" }}>
                  <p
                    style={{
                      fontSize: mobile ? "1.5rem" : "1.5rem",
                      marginBottom: "5vh",
                    }}
                  >
                    Mystry Box
                  </p>
                  <p style={{ fontSize: mobile ? "1.0rem" : "1rem" }}>
                    Guaranteed Value: $299 - $399
                  </p>
                  <b
                    style={{
                      fontSize: mobile ? "1.2rem" : "1rem",
                      color: "#00b200",
                    }}
                  >
                    Price: $299
                  </b>
                  <div style={{ textAlign: "center", marginTop: "2vh" }}>
                    <Button
                      color="green"
                      tag={Link}
                      to={{
                        pathname: !loggedIn ? "/login" : "/infoFormV2",
                      }}
                      size="sm"
                      style={{ ...joinStyle, marginTop: "10px", width: "30vh" }}
                    >
                      <span style={{ color: "white", padding: "2px" }}>
                        Buy Now
                      </span>
                    </Button>
                  </div>
                </div>
              </Row>
              <Row
                xs="12"
                md="6"
                className="d-flex justify-content-center align-items-center"
                style={boxStyle}
              >
                <div style={{ textAlign: "center" }}>
                  <Link
                    to={{
                      pathname: !loggedIn ? "/login" : "/infoFormV2",
                    }}
                  >
                    <img
                      src={require("assets/custom/membership/Gold.png").default}
                      width="75%"
                      height="50%"
                      style={{ marginBottom: "5vh" }}
                      alt=""
                    />
                  </Link>
                </div>
                <div style={{ textAlign: "center" }}>
                  <p
                    style={{
                      fontSize: mobile ? "1.5rem" : "1.5rem",
                      marginBottom: "5vh",
                    }}
                  >
                    Mystry Box +
                  </p>
                  <p style={{ fontSize: mobile ? "1.0rem" : "1rem" }}>
                    Guaranteed Value: $399 - $699
                  </p>
                  <b
                    style={{
                      fontSize: mobile ? "1.2rem" : "1rem",
                      color: "#00b200",
                    }}
                  >
                    Price: $399
                  </b>
                  <div style={{ textAlign: "center", marginTop: "2vh" }}>
                    <Button
                      color="green"
                      tag={Link}
                      to={{
                        pathname: !loggedIn ? "/login" : "/infoFormV2",
                      }}
                      size="sm"
                      style={{ ...joinStyle, marginTop: "10px", width: "30vh" }}
                    >
                      <span style={{ color: "white", padding: "2px" }}>
                        Buy Now
                      </span>
                    </Button>
                  </div>
                </div>
              </Row>
            </Col>
          </Container>
        )}
        {!mobile && (
          <Container fluid={fluid}>
            <Row>
              <Col
                xs="6"
                md="6"
                className="align-items-center"
                style={boxStyle}
              >
                <div style={{ textAlign: "center" }}>
                  <Link
                    to={{
                      pathname: !loggedIn ? "/login" : "/infoFormV2",
                    }}
                  >
                    <img
                      src={
                        require("assets/custom/membership/Black-Render-Square-removebg-desktop.png")
                          .default
                      }
                      style={{ marginBottom: "8vh" }}
                      alt=""
                    />
                  </Link>
                </div>
                <div style={{ textAlign: "center" }}>
                  <p
                    style={{
                      fontSize: mobile ? "1.2rem" : "1.5rem",
                      marginBottom: "5vh",
                    }}
                  >
                    Mystry Box
                  </p>
                  <p style={{ fontSize: mobile ? "0.7rem" : "1rem" }}>
                    Guaranteed Value: $299 - $399
                  </p>
                  <b
                    style={{
                      fontSize: mobile ? "0.8rem" : "1rem",
                      color: "#00b200",
                    }}
                  >
                    Price: $299
                  </b>
                  <div style={{ textAlign: "center", marginTop: "2vh" }}>
                    <Button
                      color="green"
                      tag={Link}
                      to={{
                        pathname: !loggedIn ? "/login" : "/infoFormV2",
                      }}
                      size="sm"
                      style={{ ...joinStyle, marginTop: "10px", width: "30vh" }}
                    >
                      <span style={{ color: "white", padding: "2px" }}>
                        Buy Now
                      </span>
                    </Button>
                  </div>
                </div>
              </Col>
              <Col
                xs="6"
                md="6"
                className="align-items-center"
                style={boxStyle}
              >
                <div style={{ textAlign: "center" }}>
                  <Link
                    to={{
                      pathname: !loggedIn ? "/login" : "/infoFormV2",
                    }}
                  >
                    <img
                      src={
                        require("assets/custom/membership/Gold-Render-Square-removebg-md.png")
                          .default
                      }
                      style={{ marginBottom: "10vh" }}
                      alt=""
                    />
                  </Link>
                </div>
                <div style={{ textAlign: "center" }}>
                  <p
                    style={{
                      fontSize: mobile ? "1.2rem" : "1.5rem",
                      marginBottom: "5vh",
                    }}
                  >
                    Mystry Box +
                  </p>
                  <p style={{ fontSize: mobile ? "0.7rem" : "1rem" }}>
                    Guaranteed Value: $399 - $699
                  </p>
                  <b
                    style={{
                      fontSize: mobile ? "0.8rem" : "1rem",
                      color: "#00b200",
                    }}
                  >
                    Price: $399
                  </b>
                  <div style={{ textAlign: "center", marginTop: "2vh" }}>
                    <Button
                      color="green"
                      tag={Link}
                      to={{
                        pathname: !loggedIn ? "/login" : "/infoFormV2",
                      }}
                      size="sm"
                      style={{ ...joinStyle, marginTop: "10px", width: "30vh" }}
                    >
                      <span style={{ color: "white", padding: "2px" }}>
                        Buy Now
                      </span>
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        )}
        {mobile && (
          <h3
            className="text-white"
            style={{ textAlign: "center", marginBottom: "5vh" }}
          >
            How does MystryBox work?
          </h3>
        )}
        {!mobile && (
          <h2 className="text-white" style={{ textAlign: "center" }}>
            How does MystryBox work?
          </h2>
        )}
        <div
          style={{
            marginBottom: "10vh",
          }}
        >
          <Container fluid={fluid}>
            <Row className="align-items-center">
              <Col xs="6" md="6">
                <video
                  width="100%"
                  autoPlay
                  loop
                  muted
                  playsInline
                  style={{
                    zIndex: 1,
                    marginTop: "-2vh",
                  }}
                  position="relative"
                >
                  <source
                    src={require("assets/custom/Shoe-1.mp4").default}
                    type="video/mp4; codecs=hevc"
                  ></source>
                  <source
                    src={require("assets/custom/Shoe-1.webm").default}
                    type="video/webm; codecs=vp9"
                  ></source>
                  <source
                    src={require("assets/custom/Shoe-1.mp4").default}
                    type="video/mp4"
                  ></source>
                </video>
              </Col>
              <Col xs="6" md="6" style={{ textAlign: "center" }}>
                <p style={{ fontSize: mobile ? "0.875rem" : "1rem" }}>
                  Select your MystryBox Membership - The MystryBox is available
                  for both men and women, starting as low as $249/month.{" "}
                </p>
                <div style={{ textAlign: "center" }}></div>
              </Col>
            </Row>

            <Row className="align-items-center">
              <Col xs="6" md="6" style={{ textAlign: "center" }}>
                <p style={{ fontSize: mobile ? "0.875rem" : "1rem" }}>
                  The sneakers in your MystryBox are hand-picked using our
                  proprietary AI, and we’ll ensure that we deliver a sneaker you
                  love, every single time.{" "}
                </p>
                <div style={{ textAlign: "center" }}></div>
              </Col>
              <Col xs="6" md="6">
                <video
                  width="100%"
                  autoPlay
                  loop
                  muted
                  playsInline
                  style={{
                    zIndex: 1,
                    marginTop: "2vh",
                  }}
                  position="relative"
                >
                  <source
                    src={require("assets/custom/Shoe-2.mp4").default}
                    type="video/mp4; codecs=hevc"
                  ></source>
                  <source
                    src={require("assets/custom/Shoe-2.webm").default}
                    type="video/webm; codecs=vp9"
                  ></source>
                  <source
                    src={require("assets/custom/Shoe-2.mp4").default}
                    type="video/mp4"
                  ></source>
                </video>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col xs="6" md="6">
                <video
                  width="100%"
                  autoPlay
                  loop
                  muted
                  playsInline
                  style={{
                    zIndex: 1,
                    marginTop: "2vh",
                  }}
                  position="relative"
                >
                  <source
                    src={require("assets/custom/Shoe-3.mp4").default}
                    type="video/mp4; codecs=hevc"
                  ></source>
                  <source
                    src={require("assets/custom/Shoe-3.webm").default}
                    type="video/webm; codecs=vp9"
                  ></source>
                  <source
                    src={require("assets/custom/Shoe-3.mp4").default}
                    type="video/mp4"
                  ></source>
                </video>
              </Col>
              <Col xs="6" md="6" style={{ textAlign: "center" }}>
                <p style={{ fontSize: mobile ? "0.875rem" : "1rem" }}>
                  The market value of the sneakers in your MystryBox can range
                  from $249-$999. Learn more{" "}
                  <a href="http://mystry.io/faq"> here</a>{" "}
                </p>
                <div style={{ textAlign: "center" }}></div>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>
      <Footer />
    </div>
  );
};

export default MembershipV2;
