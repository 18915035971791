import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import "./assets/css/nucleo-icons.css";
import "./assets/scss/blk-design-system-react.scss?v=1.2.0";
import "./assets/demo/demo.css";

import { AuthProvider } from "./contexts/AuthContext";
import ScrollIntoView from "./components/ScrollIntoView";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import { Provider } from "react-redux";
import cartReducer from "./reducers/cartReducer";
import shoesReducer from "./reducers/shoesReducer";
import * as storage from "redux-storage";
import createEngine from "redux-storage-engine-localstorage";
import App from "./App_post_launch";
import "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

// const store = createStore(
//   cartReducer,
//   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
// );

// https://stripe.com/docs/payments/accept-a-payment?platform=web&ui=elements
const stripePromise = loadStripe("pk_test_TYooMQauvdEDq54NiTphI7jx");

// https://github.com/react-stack/redux-storage#usage
const reducer = storage.reducer(
  combineReducers({
    cart: cartReducer,
    shoes: shoesReducer,
  })
);

const engine = createEngine("my-save-key");
const middleware = storage.createMiddleware(engine, ["GET_SHOES", "SET_SHOES"]);
// const createStoreWithMiddleware = applyMiddleware(middleware)(createStore);
// const store = createStoreWithMiddleware(reducer);

const composeEnhancers =
  // (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
  //   // // IMPORTANT FOR DEBUGGING THE REDUX STATE: https://github.com/zalmoxisus/redux-devtools-extension/blob/master/docs/Features/Trace.md
  //   // turn on only when needed to conserve website performance
  //   window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
  //     trace: true,
  //     traceLimit: 25,
  //   })) ||
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const store = createStore(reducer, /* preloadedState, */ composeEnhancers(

const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(middleware))
);

const load = storage.createLoader(engine);
load(store);

// Notice that our load function will return a promise that can also be used
// to respond to the restore event.
// load(store)
//   .then((newState) => {
//     ////console.log("Loaded state:", newState);
//   })
//   .catch(() => ////console.log("Failed to load previous state"));

// ReactDOM.render(
//   <AuthProvider>
//     <BrowserRouter>
//       <Provider store={store}>
//         <ScrollIntoView>
//           <Switch>
//             <Route
//               path="/sneakerswipe"
//               render={(props) => <SneakerTinderMain {...props} />}
//             />
//             <Route
//               path="/comingsoon"
//               render={(props) => <ComingSoonMain {...props} />}
//             />
//             <Route
//               exact
//               path="/"
//               render={(props) => <LandingPage {...props} />}
//             />
//             <Route
//               exact
//               path="/landing"
//               render={(props) => <LandingPage {...props} />}
//             />
//             <Route
//               path="/kitchen"
//               render={(props) => <MarketplacePage {...props} />}
//             />
//             {/*<Route
//             exact path="/kitchen"
//             render={(props) => <KitchenPage {...props} />}
//           />*/}
//             <Route
//               exact
//               path="/cookbook"
//               render={(props) => <Cookbook {...props} />}
//             />
//             <Route
//               exact
//               path="/picks"
//               render={(props) => <Chefspicks {...props} />}
//             />
//             <Route
//               path="/sneakers/:id"
//               render={(props) => <Shoes {...props} />}
//             />
//             <Route
//               path="/cookbook/:brand"
//               render={(props) => <BrandCollection {...props} />}
//             />
//             <Route
//               path="/login"
//               render={(props) => <LoginSignUp {...props} />}
//             />

//             <Route
//               path="/membership"
//               render={(props) => <Membership {...props} />}
//             />
//             <Route path="/faq" render={(props) => <Faqpage {...props} />} />

//             <Route path="/contact" render={(props) => <Contact {...props} />} />
//             <Route path="/returns" render={(props) => <Returns {...props} />} />
//             <Route path="/terms" render={(props) => <Terms {...props} />} />
//             <Route path="/privacy" render={(props) => <Privacy {...props} />} />
//             <Route
//               path="/payment-options"
//               render={(props) => <PaymentOptions {...props} />}
//             />

//             <Route path="/verify" render={(props) => <Verify {...props} />} />
//             <Route
//               path="/forgot-password"
//               render={(props) => <ForgotPassword {...props} />}
//             />
//             <Redirect from="/sneakers" to="/kitchen" />

//             {/*<Redirect from="/" to="/landing" />*/}
//             <Route component={Error} />
//           </Switch>
//         </ScrollIntoView>
//       </Provider>
//     </BrowserRouter>
//   </AuthProvider>,
//   document.getElementById("root")
// );

ReactDOM.render(
  <AuthProvider>
    <Elements stripe={stripePromise}>
      <BrowserRouter>
        <Provider store={store}>
          <ScrollIntoView>
            <App />
          </ScrollIntoView>
        </Provider>
      </BrowserRouter>
    </Elements>
  </AuthProvider>,
  document.getElementById("root")
);
