import { database } from "../../../../firebase.js";
const ref = database.shoes;
var shoes_fb = [];
ref.once("value", (snapshot) => {
  var shoe_data = snapshot.val();

  Object.values(shoe_data).forEach((val) => {
    if (val.availableAt.sneakerswipe) {
      shoes_fb.push(val);
    }
  });
});

export default shoes_fb;
