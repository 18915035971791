import React from 'react';
import { Container, Row, Col, Button, UncontrolledCarousel } from "reactstrap";
import { Carousel } from "react-responsive-carousel";
import { useHistory } from "react-router";
import { Link } from 'react-router-dom';
import useWindowDimensions from "hooks/useWindowDimensions";

const ALaCarteLanding = () => {
    const cookbookStyle = {
        objectFit: "contain",
        display: "block",
        width: "100%",
        marginLeft: "auto",
        marginRight: "auto",
        minHeight: "100%",
        borderRadius: "0px",
    };
    const fluid = true;
    const history = useHistory();

    const { width, height } = useWindowDimensions();
    var mobile, landscape;
    if (width < 768) {
        mobile = true;
        landscape = false;
    } else if (height < 576 || width < 992) {
        mobile = false;
        landscape = true;
    } else {
        mobile = false;
        landscape = false;
    }

    const containerStyle = {
        marginBottom: "7.5vw",
        paddingLeft: !mobile && fluid ? "5vw" : "",
        paddingRight: !mobile && fluid ? "5vw" : "",
    };
    const alcContainerStyle = {
        marginBottom: "4vw",
        paddingLeft: !mobile && fluid ? "5vw" : "",
        paddingRight: !mobile && fluid ? "5vw" : "",
    };
    
    const responsiveHeadStyle = mobile
    ? { fontSize: "1.65rem" }
    : { fontSize: landscape ? "1.5rem" : "2vw" };

    const alc = [
        "pharrell",
        "rocky",
        "kanye",
        "pioneer"
    ];
    
    const handleALC = (chef) => <Link to={{ pathname: history.push(`/aLaCarte/${alc[chef]}`)}}></Link>;

    return (
        <div>
            {!mobile && (
              <Container fluid={fluid} style={{ ...alcContainerStyle }}>
                <Row>
                  <Col xs="12">
                    <div
                      className="title text-white"
                      style={{
                        ...responsiveHeadStyle,
                        // marginLeft: "3vw",
                        marginTop: "0",
                        fontStyle: "italic",
                        fontWeight: "700",
                        textAlign: "center",
                      }}
                    >
                      A LA CARTE
                    </div>
                  </Col>

                  <Container fluid={fluid}>
                    <Carousel
                      showArrows={true}
                      autoPlay={true}
                      interval={6000}
                      showThumbs={false}
                      showIndicators={false}
                      showStatus={false}
                      infiniteLoop={true}
                    >
                      <Row>
                        <Col
                          style={{ paddingRight: "0px", cursor: "pointer" }}
                          onClick={() => handleALC(0)}
                        >
                          <img
                            src={
                              require("assets/custom/alacarte/pharrell.png").default
                            }
                            height="90%"
                            style={{ borderRadius: "0px" , paddingBottom: "0px"}}
                            className={cookbookStyle}
                            alt=""
                          />
                        </Col>
                        <Col
                          style={{ padding: "0px", cursor: "pointer" }}
                          onClick={() => handleALC(1)}
                        >
                          <img
                            src={
                              require("assets/custom/alacarte/rocky.png").default
                            }
                            height="90%"
                            style={{ borderRadius: "0px", paddingBottom: "0px" }}
                            className={cookbookStyle}
                            alt=""
                          />
                        </Col>
                        <Col
                          style={{ padding: "0px", cursor: "pointer" }}
                          onClick={() => handleALC(2)}
                        >
                          <img
                            src={
                              require("assets/custom/alacarte/kanye.png").default
                            }
                            height="90%"
                            style={{ borderRadius: "0px", paddingBottom: "0px" }}
                            className={cookbookStyle}
                            alt=""
                          />
                        </Col>
                        <Col
                          style={{ padding: "0px", cursor: "pointer" }}
                          onClick={() => handleALC(3)}
                        >
                          <img
                            src={
                              require("assets/custom/alacarte/pioneer.png").default
                            }
                            height="90%"
                            style={{ borderRadius: "0px", paddingBottom: "0px" }}
                            className={cookbookStyle}
                            alt=""
                          />
                        </Col>
                      </Row>
                    </Carousel>
                  </Container>
                </Row>
              </Container>
            )}
            {mobile && (
              <Container style={{ ...containerStyle }}>
                <Row>
                  <Col xs="12">
                    {/* <h1>
                  <b className="text-white">Cookbook</b>
                </h1> */}
                    <div
                      className="title text-white"
                      style={{
                        ...responsiveHeadStyle,
                        // marginLeft: "3vw",
                        fontStyle: "italic",
                        fontWeight: "700",
                        textAlign: "center",
                      }}
                    >
                      A LA CARTE
                    </div>
                  </Col>

                  <Container fluid={fluid}>
                    <Carousel
                      showArrows={true}
                      autoPlay={true}
                      interval={6000}
                      showThumbs={false}
                      showIndicators={false}
                      showStatus={false}
                      infiniteLoop={true}
                    >
                      <Row>
                        <Col
                          style={{ paddingRight: "0px", cursor: "pointer" }}
                          onClick={() => handleALC(0)}
                        >
                          <img
                            src={
                              require("assets/custom/alacarte/pharrell.png").default
                            }
                            style={{ borderRadius: "0px" }}
                            className={cookbookStyle}
                            alt=""
                          />
                        </Col>
                        <Col
                          style={{ padding: "0px", cursor: "pointer" }}
                          onClick={() => handleALC(1)}
                        >
                          <img
                            src={
                              require("assets/custom/alacarte/rocky.png").default
                            }
                            style={{ borderRadius: "0px" }}
                            className={cookbookStyle}
                            alt=""
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          style={{ paddingRight: "0px", cursor: "pointer" }}
                          onClick={() => handleALC(2)}
                        >
                          <img
                            src={
                              require("assets/custom/alacarte/kanye.png").default
                            }
                            style={{ borderRadius: "0px" }}
                            className={cookbookStyle}
                            alt=""
                          />
                        </Col>
                        <Col
                          style={{ padding: "0px", cursor: "pointer" }}
                          onClick={() => handleALC(3)}
                        >
                          <img
                            src={require("assets/custom/alacarte/pioneer.png").default}
                            style={{ borderRadius: "0px", height:"100%" }}
                            className={cookbookStyle}
                            alt=""
                          />
                        </Col>
                      </Row>
                    </Carousel>
                  </Container>
                </Row>
              </Container>
            )}
        </div>
    );
};

export default ALaCarteLanding;