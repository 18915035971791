import React from "react";
// import useWindowDimensions from "hooks/useWindowDimensions";
import { Row } from "reactstrap";
import Navbar from "../../../components/Navbars/Navbar";
import Footer from "../../../components/Footer/Footer";
import { Container } from "@material-ui/core";

const Privacy = () => {
  return (
    <>
      <Navbar />
      <div
        className="wrapper"
        style={{
          marginTop: "100px",
          marginBottom: "5vh",
          alignItems: "center",
          textAlign: "center",
          overflowX: "hidden",
        }}
      >
        <Row
          style={{
            justifyContent: "center",
          }}
        >
          <h2>MYSTRY  - PRIVACY POLICY</h2>
        </Row>
        <Container
          style={{
            justifyContent: "center",
          }}
        >
          <div>
            <b>Last updated: 11-November-2021</b>
          </div>
        </Container>
      </div>
      <Container style={{ marginBottom: "75px" }}>
        <h4>1. About this Policy</h4>
        <ul>
          <li>
          1.1. This privacy policy (“Policy”) explains how Mystry Inc. (referred to as “Mystry”, “we”, “our”, “us” in this privacy policy) collect, share and use any information that, alone or in combination with other information, could be used to identify you (“Personal Data”) when you use our website mystryworld.com (the “Site”), or when you call us, interact with us or answer our surveys. 
          </li>
          <li>
          1.2. We treat compliance with privacy obligations seriously. This is why we have developed this Policy, which describes the standards that we apply to protect Personal Data. Reach out to us contact@mystry.world  
          </li>
        </ul>
        <br/>
        <h4>2. What personal data do we collect and why?</h4>
        <ul>
          <li>
            2.1. The types of Personal Data that we collect about you, and the reasons why we process it, are:          
          </li><br/>
          <ul>
            <li>2.1.1. When you create an account with us: When you create an account with us on our Site we will collect your full name, email address and phone number. We do this under our legitimate interest to respond to your queries and may process this information to enter into a contract with you to provide you with any products you might purchase.</li>
            <li>2.1.2. When you sign-up for a waitlist: When you sign-up for our Mystry Box waitlist on our Site we will collect your Country and state of residence, age, shoe size and gender. We do this under our legitimate interest to take note of inventory and needs in order to fulfil your order. </li>
            <li>2.1.2. When you purchase from our Site: When you purchase any product(s) from our Site, in addition to the information detailed above, we will also collect your delivery address and your payment information. We do this under our legitimate interest to fulfil your order.</li>
            <li>2.1.3. To complete your order: When you place an order on our Site we will share your payment details with our third party providers Stripe or Apple Pay who will process your order. We do this to fulfil our contractual obligations. </li>
            <li>2.1.4. When you receive our news updates. We will handle your Personal Data (such as your name and email address) to provide you with our news updates in line with any preferences you have told us about. You can unsubscribe from our updates at any time by clicking the unsubscribe link at the bottom of any of our emails, by emailing contact@mystry.world </li>
            <li>2.1.5. When you apply to work for us. When you enter into the recruitment process with us we may collect your name, email address, phone number, recruitment information (e.g. right to work documentation and references), qualifications, accreditations, test results and any additional information we may receive from our recruitment partners. We will use your Personal Data to assess your suitability for our available roles. We do this to perform a contract or to take steps at your request, before entering into a contract. Where we process your right to work documentation, we will do so to comply with our legal obligations.</li>
            <li>2.1.6. If our business is sold: We process your Personal Data for this purpose because we have a legitimate interest to ensure our business can be continued by the buyer. If you object to our use of your Personal Data in this way, the buyer of our business may not be able to provide services to you. In some circumstances we may also need to share your Personal Data if we are under a duty to disclose or share it to comply with a legal obligation.</li>
          </ul>
          <p></p>
        </ul>

        {/* <br /> */}
        <br />
        <h4>3. Who do we share your personal data with?</h4> 
        <ul>
        <li>3.1. We may disclose your Personal Data to the following categories of recipients:</li>
          <ul>
            <li>3.1.1. to our group companies for purposes consistent with this Policy, and in particular, so that they may contact you regarding products and services that may be of interest to you where you have given your consent for us to do so;</li>
            <li>3.1.2. to our third party vendors, services providers and partners who provide data processing services to us, or who otherwise process Personal Data for purposes that are described in this Policy or notified to you when we collect your Personal Data in order to fulfil our obligations under the contract we enter with them or you; </li>
            <li>3.1.3. to any competent law enforcement body, regulatory, government agency, court or other third party where we believe disclosure is necessary in order to comply with our legal obligations;</li>
            <li>3.1.4. to our auditors, advisors, legal representatives and similar agents in connection with the advisory services they provide to us to comply with our legal obligations;</li>
            <li>3.1.5. to a potential buyer (and its agents and advisers) in connection with any proposed purchase, merger or acquisition of any part of our business, under our legitimate interest to ensure the purchaser can carry on our business; and</li>
            <li>3.1.6. to any other person if you have provided your prior consent to the disclosure.</li>
          </ul>
        </ul>
          <br/>
        <h4>4. What are your rights under data protection law?</h4> 
        <ul>
        <li>4.1. You have various rights under data protection laws which you can exercise by contacting us. The easiest way to do this is by email at contact@mystry.world</li>
        <li>4.2. You have various other rights under applicable data protection laws, including the right to:</li>
          <ul>
            <li>4.2.1. access your personal data (also known as a “subject access request”);</li>
            <li>4.2.2. correct incomplete or inaccurate data we hold about you;</li>
            <li>4.2.3. ask us to erase the personal data we hold about you;</li>
            <li>4.2.4. ask us to restrict our handling of your personal data;</li>
            <li>4.2.5. ask us to transfer your personal data to a third party; </li>
            <li>4.2.6. object to how we are using your personal data; and</li>
            <li>4.2.7. withdraw your consent to us handling your personal data.</li>
          </ul><br/>
        <li>4.3. You also have the right to lodge a complaint with your relevant supervisory authority, you can find which one applies to you here. For the purposes of European data protection law, (the "Data Protection Law"), the data controller is: Firebase, Inc., San Francisco, CA, USA.<br/><br/>
        The data that we collect from you may be transferred to, and stored at, a destination outside the European Economic Area ("EEA") that may not be subject to equivalent Data Protection Law.<br/><br/>
        Where your information is transferred outside the EEA, we will take all steps reasonably necessary to ensure that your data is subject to appropriate safeguards, such as relying on a recognized legal adequacy mechanism, and that it is treated securely and in accordance with this privacy policy and applicable data protection laws. You may obtain a copy of the appropriate safeguard used to transfer your personal information by contacting us a contact@mystry.world<br/><br/>
        </li>
        We may transfer your personal information outside the EEA, to the United States:
        <ul>
        <li>In order to store it.</li>
        <li>In order to enable us to provide goods or services to you and fulfil our contract with you. This includes order fulfilment, processing of payment details, and the provision of support services.</li>
        <li>Where we are legally required to do so.</li>
        <li>In order to facilitate the operation of our group of businesses, where it is in our legitimate interests and we have concluded these are not overridden by your rights.</li>
        </ul>
        </ul>

        <br/>
        <h4>5. Data storage, retention & deletion</h4> 
        <ul>
        <li>5.1. We will only retain your Personal Data for as long as necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, accounting or legal requirements.</li>
        <li>5.2. In some circumstances (such as for product analysis purposes) we may anonymise your Personal Data so that it can no longer be associated with you for research or statistical purposes, in which case we may use this information indefinitely without further notice to you.</li>
        </ul>

        <br/>
        <h4>6. Where do we store your data?</h4> 
        <ul>
          <li>6.1. We will store the data we receive from you in the USA. but will share your data with third parties who support the running of our Site and manage your payments(example, Stripe, Paypal, Apple Pay).
          </li>
          <li>6.2. Whenever we transfer your Personal Data outside of the USA including to Stripe and Apple for the purposes of managing your payment, we ensure it receives additional protection as required by law. To keep this Policy as short and easy to understand as possible, we have not set out the specific circumstances when each of these protection measures are used. You can contact us at contact@mystry.world for the details as to how we protect specific transfer of your Personal Data.</li>
        </ul>

        <br/>
        <h4>7. Updates to this policy</h4> 
        <ul>
          <li>7.1. We may update this Policy from time to time. We will do so by updating our Policy on the Site but will obtain your consent to any changes if and where this is required by applicable data protection laws.</li>
          <li>7.2. You can see when this Policy was last updated by checking the “last updated” date displayed at the top of this Policy.</li>
        </ul>

        <br/>
        <h4>8. How to contact us</h4> 
        <ul>
          <li>8.1 If you have any questions or concerns about our use of your Personal Data, please contact us via email at contact@mystry.world</li>
        </ul>

        <br />
        <br />
        
      </Container>
      <Footer />
    </>
  );
};

export default Privacy;
