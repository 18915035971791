import React from 'react';
import { Link } from 'react-router-dom';
import { NavItem, NavLink, Nav, Container, Row, Col, UncontrolledTooltip } from 'reactstrap';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import './Footer.css';

const bodyStyle = {
  fontSize: '1vmax',
  color: '#FFFFFF',
};

const bodyStyleMobile = {
  marginLeft: "25%",
  fontSize: '1.4vmax',
  marginBottom: "15px",
  color: '#FFFFFF',
  padding: "0%"
};

const headStyle = {
  fontSize: '1.2vmax',
  marginBottom: '1vmax',
  color: '#FFFFFF'
};

const headStyleMobile = {
  fontSize: '2.2vmax',
  marginTop: '5vmax',
  marginBottom: '2vmax',
  color: '#C0C0C0'
};

const copyRightStyle = {
  fontSize: '1vmax',
  color: '#FFFFFF'
};

const copyRightStyleMobile = {
  fontSize: '1.4vmax',
  color: '#FFFFFF'
};

export default function Footer() {
  const { width, height } = useWindowDimensions();
  var mobile, landscape;
  if (width < 768) {
    mobile = true;
    landscape = false;
  } else if (height < 576 || width < 992) {
    mobile = false;
    landscape = true;
  } else {
    mobile = false;
    landscape = false;
  }
  const fluid = mobile ? false : true;
  return (
    <>
      {mobile && (
        <div>
          <footer className="footer">
            <Container fluid={fluid}>
              <Row
                className="text-left"
                style={{ marginLeft: !mobile ? '5vw' : '', marginRight: !mobile ? '5vw' : '' }}
              >
                <Col xs="12" md="12" className='text-center'>
                  <div
                    className="text-center"
                    style={{
                      fontFamily: 'edosz',
                      fontSize: '3vmax',
                      marginBottom: '1vw',
                      color: '#00B200',
                    }}
                  >
                    MYSTRY
                  </div>
                  <div style={copyRightStyleMobile} className="mb-6">
                    &#169; 2023 MYSTRY.
                  </div>
                  <div style={copyRightStyleMobile} className="mb-6">
                    All Rights Reserved.
                  </div>
                </Col>
                <Col xs="6" md="6">
                  <div className="text-center" style={headStyleMobile}>
                    Customer Care
                  </div>
                  <Nav navbar={true}>
                    <NavItem>
                      <NavLink to="/faq" tag={Link} style={bodyStyleMobile}>
                        FAQ
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/privacy" tag={Link} style={bodyStyleMobile}>
                        Privacy
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/contact" tag={Link} style={bodyStyleMobile}>
                        Contact Us
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/blog" tag={Link} style={bodyStyleMobile}>
                        Blog
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Col>
                <Col xs="6" md="6">
                  <div className="text-center" style={headStyleMobile}>
                    Information
                  </div>
                  <Nav navbar={true}>
                    <NavItem>
                      <NavLink to="/about" tag={Link} style={bodyStyleMobile}>
                        About Us
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/terms" tag={Link} style={bodyStyleMobile}>
                        Terms
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/payment-options" tag={Link} style={bodyStyleMobile}>
                        Payment options
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/careers" tag={Link} style={bodyStyleMobile}>
                        Careers
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/partnership" tag={Link} style={bodyStyleMobile}>
                        Partnership
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Col>
                <Col xs="12" md="12" className='text-center'>
                  <div className="text-center mt-4" style={headStyleMobile}>
                    Follow Us
                  </div>
                  <div className="btn-wrapper profile" style={bodyStyle}>
                <a
                  href="https://www.linkedin.com/company/mystry/ "
                  id="tooltip622135962"
                  target="_blank"
                  style={{ marginRight: '5px', color: '#FFFFFF' }}
                  size="sm"
                >
                  <i className="fab fa-linkedin mb-4 mr-2" style={{fontSize: "25px"}}/>
                </a>
                <UncontrolledTooltip delay={0} target="tooltip622135962">
                  Follow us
                </UncontrolledTooltip>
                <a
                  href="https://www.instagram.com/mystry.io/"
                  id="tooltip318450378"
                  target="_blank"
                  style={{ marginRight: '5px', color: '#FFFFFF' }}
                  size="sm"
                >
                  <i className="fab fa-instagram mb-4" style={{fontSize: "25px"}}/>
                </a>
                <UncontrolledTooltip delay={0} target="tooltip318450378">
                  Follow us
                </UncontrolledTooltip>
                </div>
                </Col>
              </Row>
            </Container>
          </footer>
        </div>
      )}
      {!mobile && (
        <div style={{ marginBottom: "5vh" }}>
          <footer className="footer">
            <Container fluid={fluid}>
              <Row
                className="row-grid justify-content-between text-left"
                style={{ 
                  marginLeft: !mobile ? '5vw' : '', 
                  marginRight: !mobile ? '5vw' : '',
                  marginTop: '5vh'
                }}
              >
                <Col xs="4" md="6">
                  <div
                    style={{
                      fontFamily: 'edosz',
                      fontSize: '3.5vmax',
                      marginBottom: '1vw',
                      color: 'white'
                    }}
                  >
                    MYSTRY
                  </div>
                  <div style={copyRightStyle} className="mb-6">
                    &#169; 2023 MYSTRY.
                  </div>
                  <div style={copyRightStyle} className="mb-6">
                    All Rights Reserved.
                  </div>
                </Col>
                <Col xs="4" md="2">
                  <div className="title" style={headStyle}>
                    Customer Care
                  </div>
                  <Nav navbar={true} style={{textAlign:'right'}}>
                    <NavItem>
                      <NavLink to="/faq" tag={Link} style={bodyStyle}>
                        FAQ
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/privacy" tag={Link} style={bodyStyle}>
                        Privacy
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/contact" tag={Link} style={bodyStyle}>
                        Contact Us
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/blog" tag={Link} style={bodyStyle}>
                        Blog
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Col>
                <Col xs="4" md="2">
                  <div className="title" style={headStyle}>
                    Information
                  </div>
                  <Nav navbar={true} style={{textAlign:'right'}}>
                    <NavItem>
                      <NavLink to="/about" tag={Link} style={bodyStyle}>
                        About Us
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/terms" tag={Link} style={bodyStyle}>
                        Terms
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/payment-options" tag={Link} style={bodyStyle}>
                        Payment options
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/careers" tag={Link} style={bodyStyle}>
                        Careers
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/partnership" tag={Link} style={bodyStyle}>
                        Partnership
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Col>
                <Col xs="4" md="2">
                  <div className="title" style={headStyle}>
                    Follow Us
                  </div>
                  <div 
                    className="btn-wrapper profile mt-4"
                    style={{
                      textAlign: "right"
                    }}
                  >
                    <a
                      href="https://www.linkedin.com/company/mystry/ "
                      id="tooltip622135962"
                      target="_blank"
                      style={{ marginRight: '5px', color: '#FFFFFF' }}
                      size="sm"
                    >
                      <i className="fab fa-linkedin mb-4 mr-2" style={{fontSize: "25px"}}/>
                    </a>
                    <UncontrolledTooltip delay={0} target="tooltip622135962">
                      Follow us
                    </UncontrolledTooltip>
                    <a
                      href="https://www.instagram.com/mystry.io/"
                      id="tooltip318450378"
                      target="_blank"
                      style={{ marginRight: '5px', color: '#FFFFFF' }}
                      size="sm"
                    >
                      <i className="fab fa-instagram mb-4" style={{fontSize: "25px"}}/>
                    </a>
                    <UncontrolledTooltip delay={0} target="tooltip318450378">
                      Follow us
                    </UncontrolledTooltip>
                 </div>
                </Col>
              </Row>
            </Container>
          </footer>
        </div>
      )}
    </>
  );
}
