import React from "react";
// import useWindowDimensions from "hooks/useWindowDimensions";
import { Container, Row, Col } from "reactstrap";
import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";

const PaymentOptions = () => {
  // const { width, height } = useWindowDimensions();

  return (
    <>
      <Navbar/>
    <div
          className="wrapper"
          style={{
            marginTop: "100px",
            marginBottom: "75px",
            alignItems: "center",
            textAlign: "center",
            overflowX:'hidden',
          }}
        >
          
          <Row
            style={{
              justifyContent: "center",
            }}
          >
            
            <h2>Payment Options</h2>
          </Row>
          </div>
          <Container fluid={true} style={{marginBottom:'10vh',paddingLeft:'10vw',paddingRight:'10vw'}}>
          
          <Row>
          <div>
          As a global marketplace, we support several payment options for buyers to complete a purchase based on your region and purchase type.
          </div>
          </Row>
          <Row>
          <div style={{marginTop:'20px'}}>
          We currently accept  -
          </div>
          </Row>
          <Row className="align-items-left">
          <Col xs="12" sm="12" md="12">
          <div style={{marginTop:'20px'}}>
          <ul style={{lineHeight:'200%'}}>
              <li>All major credit cards including Visa, MasterCard, American Express &nbsp; <i class="fab fa-cc-visa"></i></li> 
              <li>Paypal &nbsp;<i class="fab fa-paypal"></i></li>
              <li>Apple Pay &nbsp;<i class="fab fa-apple"></i></li>
              <li>Bitcoin, &nbsp;Ethereum, &nbsp;Dogecoin &nbsp;<i class="fab fa-ethereum"></i></li>
          </ul>
          </div>
          </Col>
          </Row>
          </Container>
    <Footer/>
    </>
  );
};

export default PaymentOptions;
