import React from "react";
import { Col, Row } from "reactstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Carousel from 'react-responsive-carousel/lib/js/components/Carousel/index'
import './Explore.css';
import { useHistory } from "react-router-dom";

const ExploreMobile = () => {
  const history = useHistory();
  const carouselItems = [
    {
      src: require('assets/custom/ShoeBoxes/1.png').default,
    },
    {
      src: require('assets/custom/ShoeBoxes/2.png').default,
    },
    {
      src: require('assets/custom/ShoeBoxes/3.png').default,
    },
    {
      src: require('assets/custom/ShoeBoxes/5.png').default,
    },
    {
      src: require('assets/custom/ShoeBoxes/6.png').default,
    },
    {
      src: require('assets/custom/ShoeBoxes/7.png').default,
    },
    {
      src: require('assets/custom/ShoeBoxes/8.png').default,
    },
    {
      src: require('assets/custom/ShoeBoxes/9.png').default,
    },
    {
      src: require('assets/custom/ShoeBoxes/10.png').default,
    },
    {
      src: require('assets/custom/ShoeBoxes/11.png').default,
    },
    {
      src: require('assets/custom/ShoeBoxes/12.png').default,
    },
    {
      src: require('assets/custom/ShoeBoxes/13.png').default,
    },
    {
      src: require('assets/custom/ShoeBoxes/15.png').default,
    }
  ];

  return (
    <Row sm={12} className='explore-div-xs'>
      <Col style={{maxWidth: "40vw"}}>
        <div className="shop-heading-xs">
          <span>SHOP ALL MYSTRY BOXES</span>
        </div>
        <button 
          className="explore-btn-xs"
          onClick={() => history.push('/mystryBoxProduct')}
        >
          Explore
        </button>
      </Col>
      <Col 
        className="d-flex justify-content-center align-items-center"
        style={{maxWidth: "50vw"}} 
      >
        <Carousel
          autoPlay
          interval={2000}
          animationHandler="fade" 
          swipeable={false}
          infiniteLoop
          showIndicators={false}
          showStatus={false}
          showThumbs={false}
          showArrows={false}
        >
          {carouselItems.map((item) => {
            return (
              <div>
                <img 
                  className="shoe"
                  src={item.src}
                  style={{
                    width: "30vw",
                    transform: "translateY(30%)"
                  }}
                />
                <img className='mystrybox'
                  src={require('assets/custom/Shop/MystryBox.png').default}
                />
              </div>
            )
          })}
        </Carousel>
      </Col>
    </Row>
  )
}

export default ExploreMobile;