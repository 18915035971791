import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";
import { Container, Button, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { clearCart } from "reducers/cartReducer";
import { useSelector, useDispatch } from "react-redux";
import useWindowDimensions from "hooks/useWindowDimensions";

export const SuccessPayment = () => {
  const dispatch = useDispatch();
  const { width, height } = useWindowDimensions();
  var mobile;
  if (width <= 420 || height <= 420) {
    mobile = true;
  } else {
    mobile = false;
  }
  dispatch(clearCart());

  return (
    <div
      className="wrapper"
      style={{
        position: "relative",
        overflow: "hidden",
        background: "black",
      }}
    >
      <Navbar />
      <Container style={{ marginTop: "15vh" }}>
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <h1 className="title text-white">Order Successful!</h1>
        </Row>
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          Your order has been successfully placed! Track your order through user
          <br />
          page. Thank you for shopping with us.
          <br />
          <br />
        </Row>
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Button color="green" to="/" tag={Link}>
            <span style={{ color: "white", padding: "2px" }}>Return Home</span>
          </Button>
        </Row>

        {mobile && (
          <>
            <video
              width="100%"
              autoPlay
              loop
              muted
              playsInline
              style={{
                zIndex: 1,
                marginTop: "2vh",
              }}
              position="relative"
            >
              <source
                src={require("assets/custom/aboutuscropped.mp4").default}
                type="video/mp4; codecs=hevc"
              ></source>
              <source
                src={require("assets/custom/aboutuscropped.webm").default}
                type="video/webm; codecs=vp9"
              ></source>
              <source
                src={require("assets/custom/aboutuscropped.mp4").default}
                type="video/mp4"
              ></source>
            </video>
          </>
        )}
        {!mobile && (
          <>
            <video
              width="100%"
              autoPlay
              loop
              muted
              playsInline
              style={{
                zIndex: 1,
                marginTop: "2vh",
              }}
              position="relative"
            >
              <source
                src={require("assets/custom/aboutuscropped.mp4").default}
                type="video/mp4; codecs=hevc"
              ></source>
              <source
                src={require("assets/custom/aboutuscropped.webm").default}
                type="video/webm; codecs=vp9"
              ></source>
              <source
                src={require("assets/custom/aboutuscropped.mp4").default}
                type="video/mp4"
              ></source>
            </video>
          </>
        )}
      </Container>
      <Footer />
    </div>
  );
};
