import { Link, useHistory, useLocation } from "react-router-dom";
import { Col, Button, Row } from "reactstrap";
import useWindowDimensions from "hooks/useWindowDimensions";
import { useState } from "react";
import "./Waitlist.css";

export default function WaitlistedMystrbox() {
  const location = useLocation();
  const stripeCheckout = location.state ? location.state.stripeCheckout : "/";

  const { width, height } = useWindowDimensions();
  var mobile, landscape;
  if (width < 768) {
    mobile = true;
    landscape = false;
  } else if (height < 576 || width < 992) {
    mobile = false;
    landscape = true;
  } else {
    mobile = false;
    landscape = false;
  }
  const overlayText = {
    position: "absolute",
    top: !mobile ? "10vh" : "20vh",
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    zIndex: 2,
  };

  const history = useHistory();

  function congratsTextWidget() {
    return (
      <Col style={{ textAlign: "center", top: mobile ? "10vh" : "74vh" }}>
        <p
          style={{
            color: "#F9F9F9",
            fontSize: "30px",
            marginBottom: mobile ? "5vh" : "3vh",
            fontWeight: "600",
          }}
        >
          Congratulations! Your Mystry Box is ready for purchase
        </p>
        <button
          className="see-more-xs mb-3"
          onClick={() => window.location.replace(stripeCheckout)}
          // onClick={() => history.push('/')}
        >
          Complete Payment
        </button>
        <p>
          {" "}
          *All orders are dispatched within 4-7 business days*
          <br />* You will receive a tracking number once your order is
          confirmed*
        </p>
        <br />
      </Col>
    );
  }

  const [videoId, setVideoId] = useState("");
  const [boxSelected, setBoxSelected] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const handleBoxClick = (id) => {
    if (videoId) {
      document.getElementById(`${videoId}MystryBox`).style.outline = "0px";
      setVideoId(id);
      document.getElementById(`${id}MystryBox`).style.outline =
        "4px solid #E9E9E9";
    } else {
      setVideoId(id);
      setButtonDisabled(false);
      document.getElementById(`${id}MystryBox`).style.outline =
        "4px solid #E9E9E9";
    }
  };

  const handleProceed = () => {
    setBoxSelected(true);
  };

  return (
    <div>
      {boxSelected && (
        <div>
          <div className={mobile ? "" : "videobox-container"}>
            <video autoPlay width="100%" muted loop playsInline>
              <source
                src={
                  require(`assets/custom/ColoredMystryBoxes/${videoId}Full.mp4`)
                    .default
                }
                type="video/mp4"
              ></source>
            </video>
          </div>
          <p>{congratsTextWidget()}</p>
        </div>
      )}
      {!boxSelected && (
        <div
          style={{
            marginTop: "10vh",
            paddingLeft: "5vw",
            paddingRight: "5vw",
          }}
        >
          <div
            style={{
              marginBottom: mobile ? "5vh" : "4vh",
              fontSize: mobile ? "30px" : "40px",
              fontWeight: "600",
              textAlign: "center",
            }}
            className="d-flex justify-content-center align-items-center"
          >
            We have prepared four different Mystry Boxes for you
          </div>
          <div
            style={{
              marginBottom: mobile ? "5vh" : "8vh",
              fontSize: "30px",
              fontWeight: "400",
              textAlign: "center",
            }}
            className="d-flex justify-content-center align-items-center"
          >
            Pick your MystryBox
          </div>
          {mobile && (
            <div>
              <Row sm={12} xs={12}>
                <Col
                  sm={6}
                  xs={6}
                  className="d-flex justify-content-center align-items-center"
                >
                  <video
                    id="PinkMystryBox"
                    width="100%"
                    autoPlay
                    loop
                    muted
                    playsInline
                    onClick={() => handleBoxClick("Pink")}
                    poster={
                      require("assets/custom/ColoredMystryBoxes/BoxImage.jpg")
                        .default
                    }
                    style={{
                      borderRadius: "10px",
                    }}
                  >
                    <source
                      src={
                        require("assets/custom/ColoredMystryBoxes/Pink.mp4")
                          .default
                      }
                      type="video/mp4"
                    ></source>
                  </video>
                </Col>
                <Col
                  sm={6}
                  xs={6}
                  className="d-flex justify-content-center align-items-center"
                >
                  <video
                    id="BlueMystryBox"
                    width="100%"
                    autoPlay
                    loop
                    muted
                    playsInline
                    onClick={() => handleBoxClick("Blue")}
                    poster={
                      require("assets/custom/ColoredMystryBoxes/BoxImage.jpg")
                        .default
                    }
                    style={{
                      borderRadius: "10px",
                    }}
                  >
                    <source
                      src={
                        require("assets/custom/ColoredMystryBoxes/Blue.mp4")
                          .default
                      }
                      type="video/mp4"
                    ></source>
                  </video>
                </Col>
              </Row>
              <Row sm={12} xs={12} style={{ marginTop: "2vh" }}>
                <Col
                  sm={6}
                  xs={6}
                  className="d-flex justify-content-center align-items-center"
                >
                  <video
                    id="GreenMystryBox"
                    width="100%"
                    autoPlay
                    loop
                    muted
                    playsInline
                    onClick={() => handleBoxClick("Green")}
                    poster={
                      require("assets/custom/ColoredMystryBoxes/BoxImage.jpg")
                        .default
                    }
                    style={{
                      borderRadius: "10px",
                    }}
                  >
                    <source
                      src={
                        require("assets/custom/ColoredMystryBoxes/Green.mp4")
                          .default
                      }
                      type="video/mp4"
                    ></source>
                  </video>
                </Col>
                <Col
                  sm={6}
                  xs={6}
                  className="d-flex justify-content-center align-items-center"
                >
                  <video
                    id="GoldMystryBox"
                    width="100%"
                    autoPlay
                    loop
                    muted
                    playsInline
                    onClick={() => handleBoxClick("Gold")}
                    poster={
                      require("assets/custom/ColoredMystryBoxes/BoxImage.jpg")
                        .default
                    }
                    style={{
                      borderRadius: "10px",
                    }}
                  >
                    <source
                      src={
                        require("assets/custom/ColoredMystryBoxes/Gold.mp4")
                          .default
                      }
                      type="video/mp4"
                    ></source>
                  </video>
                </Col>
              </Row>
            </div>
          )}
          {!mobile && (
            <Row md={12}>
              <Col
                md={3}
                className="d-flex justify-content-center align-items-center"
              >
                <video
                  id="PinkMystryBox"
                  width="100%"
                  autoPlay
                  loop
                  muted
                  playsInline
                  onClick={() => handleBoxClick("Pink")}
                  poster={
                    require("assets/custom/ColoredMystryBoxes/BoxImage.jpg")
                      .default
                  }
                  style={{
                    borderRadius: "10px",
                  }}
                >
                  <source
                    src={
                      require("assets/custom/ColoredMystryBoxes/Pink.mp4")
                        .default
                    }
                    type="video/mp4"
                  ></source>
                </video>
              </Col>
              <Col
                md={3}
                className="d-flex justify-content-center align-items-center"
              >
                <video
                  id="BlueMystryBox"
                  width="100%"
                  autoPlay
                  loop
                  muted
                  playsInline
                  onClick={() => handleBoxClick("Blue")}
                  poster={
                    require("assets/custom/ColoredMystryBoxes/BoxImage.jpg")
                      .default
                  }
                  style={{
                    borderRadius: "10px",
                  }}
                >
                  <source
                    src={
                      require("assets/custom/ColoredMystryBoxes/Blue.mp4")
                        .default
                    }
                    type="video/mp4"
                  ></source>
                </video>
              </Col>
              <Col
                md={3}
                className="d-flex justify-content-center align-items-center"
              >
                <video
                  id="GreenMystryBox"
                  width="100%"
                  autoPlay
                  loop
                  muted
                  playsInline
                  onClick={() => handleBoxClick("Green")}
                  poster={
                    require("assets/custom/ColoredMystryBoxes/BoxImage.jpg")
                      .default
                  }
                  style={{
                    borderRadius: "10px",
                  }}
                >
                  <source
                    src={
                      require("assets/custom/ColoredMystryBoxes/Green.mp4")
                        .default
                    }
                    type="video/mp4"
                  ></source>
                </video>
              </Col>
              <Col
                md={3}
                className="d-flex justify-content-center align-items-center"
              >
                <video
                  id="GoldMystryBox"
                  width="100%"
                  autoPlay
                  loop
                  muted
                  playsInline
                  onClick={() => handleBoxClick("Gold")}
                  poster={
                    require("assets/custom/ColoredMystryBoxes/BoxImage.jpg")
                      .default
                  }
                  style={{
                    borderRadius: "10px",
                  }}
                >
                  <source
                    src={
                      require("assets/custom/ColoredMystryBoxes/Gold.mp4")
                        .default
                    }
                    type="video/mp4"
                  ></source>
                </video>
              </Col>
            </Row>
          )}
          {buttonDisabled && (
            <div className="d-flex justify-content-center align-items-center">
              <button
                className="see-more-disabled"
                style={{ marginTop: "8vh", marginBottom: "5vh" }}
              >
                Proceed to Checkout
              </button>
            </div>
          )}
          {!buttonDisabled && (
            <div className="d-flex justify-content-center align-items-center">
              <button
                className="see-more"
                onClick={() => handleProceed()}
                style={{ marginTop: "8vh", marginBottom: "5vh" }}
              >
                Proceed to Checkout
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
