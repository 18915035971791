import React, { useState } from "react";
import { Fab } from "@material-ui/core";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import useWindowDimensions from "hooks/useWindowDimensions";
import "./FloatingActionCart.css";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col,
} from "reactstrap";
import { clearCart, removeFromCart, addToCart } from "reducers/cartReducer";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import RemoveShoppingCartIcon from "@material-ui/icons/RemoveShoppingCart";
import NextWeekIcon from "@material-ui/icons/NextWeek";

const FloatingActionCart = () => {
  const { width, height } = useWindowDimensions();
  const [modal, setModal] = useState(false);
  // const [submitted, setSubmitted] = useState(false);
  const toggle = () => setModal(!modal);
  const dispatch = useDispatch();

  var mobile;
  if (width <= 420 || height <= 420) {
    mobile = true;
  } else {
    mobile = false;
  }

  const cart = useSelector((state) => state.cart.cartShoes);
  const cartLength = useSelector((state) => state.cart.totalItems);
  const shoes = useSelector((state) => state.shoes);
  // ////console.log("cart: ", cart);

  const handleCartClick = () => {
    // ////console.log(cart);
    toggle();
    return;
  };

  const handleClearCart = () => {
    dispatch(clearCart());
    return;
  };

  const getPrice = (id, size, quantity) => {
    // shoes.forEach((shoe) => {
    //   // ////console.log(shoe.shoeID);
    //   ////console.log("helloOut", id);
    //   if (shoe.shoeID === id) {
    //     // TO MODIFY AFTER SHOE API CHANGES
    //     ////console.log("hello", id, quantity * shoe.retailPrice);
    //     return quantity * shoe.retailPrice;
    //   }
    // });
    // return 0;
    // var i;
    // for (i = 0; i < shoes.length; i++) {
    //   if (shoes[i].shoeID === id) {
    //     return quantity * shoes[i].retailPrice;
    //   }
    // }
    // return -1;
    var sizeStr = size.toString();
    if (sizeStr.includes(".")) {
      sizeStr = sizeStr.replace(".", "_");
    }

    // UPDATE AFTER ACTUAL INVENTORY IS PUT IN THE DB
    return shoes[id].shoeInventory
      ? shoes[id].shoeInventory[sizeStr].price * quantity
      : shoes[id].retailPrice * quantity;

    // return shoes[id].retailPrice * quantity;
  };

  const handleRemoveShoe = (shoe, size) => {
    dispatch(removeFromCart(shoe, size));
    return;
  };

  const handleAddShoe = (cartShoe) => {
    // TODO: UPDATE AFTER ACTUAL INVENTORY IS SET
    var sizeStr = cartShoe.size.toString();
    if (sizeStr.includes(".")) {
      sizeStr = sizeStr.replace(".", "_");
    }
    if (
      shoes[cartShoe.shoe.shoeID].shoeInventory &&
      cartShoe.quantity >=
        Math.min(
          4,
          parseInt(shoes[cartShoe.shoe.shoeID].shoeInventory[sizeStr].quantity)
        )
    ) {
      toast.error("The maximum amount already in the cart");
    } else if (
      !shoes[cartShoe.shoe.shoeID].shoesInventory &&
      cartShoe.quantity >= 4
    ) {
      toast.error("The maximum amount already in the cart");
    } else {
      dispatch(addToCart(cartShoe.shoe, cartShoe.size));
    }
    return;
  };

  return (
    <>
      {Object.keys(shoes).length > 0 && cartLength > 0 && (
        <div>
          <Fab
            style={{
              color: "black",
              position: "fixed",
              bottom: "40px",
              right: mobile ? "20px" : "40px",
              zIndex: "2",
              backgroundColor: "#39FF14",
            }}
            size={mobile ? "small" : "medium"}
            onClick={() => handleCartClick()}
          >
            <ShoppingCartIcon
              fontSize={mobile ? "small" : "default"}
              id="shoppingCart"
            />
            <span
              className="cart-basket align-items-center justify-content-center"
              onClick={() => handleCartClick()}
            >
              {cartLength}
            </span>
          </Fab>
          {/* // )}
      // {cartLength > 0 && ( */}
          <Modal
            isOpen={modal}
            toggle={toggle}
            // size="sm"
            className="notifyModal cart-modal-position"
          >
            <div className="modal-header cart-modal">
              <h5
                className="modal-title"
                style={{ color: "white", paddingBottom: 10 }}
              >
                My Cart
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
                onClick={toggle}
              >
                <i
                  className="tim-icons icon-simple-remove"
                  style={{ marginBottom: 25 }}
                />
              </button>
            </div>
            <ModalBody className="cart-modal">
              <div>
                <Row>
                  <Col xs={4}>Shoe</Col>
                  {/* <Col>Title</Col> */}
                  <Col xs={2}>Size</Col>
                  <Col xs={4}>Quantity</Col>
                  <Col xs={2} className="cartImgContainer">
                    Price ($)
                  </Col>
                </Row>
                {cart.map((cartShoe, idx) => (
                  <Row
                    key={idx}
                    style={{
                      alignItems: "center",
                    }}
                  >
                    <Col xs={4} className="cartImgContainer">
                      <Link to={`/sneakers/${cartShoe.shoe.shoeID}`}>
                        <img
                          src={cartShoe.shoe.shoeImg.lowRes.right}
                          alt={cartShoe.shoe.shoeName}
                          className="cartImgContainer"
                        />
                      </Link>
                    </Col>
                    {/* <Col>{cartShoe.shoe.shoeName}</Col> */}
                    <Col xs={2}>{cartShoe.size}</Col>
                    <Col xs={4}>
                      <Button
                        onClick={() =>
                          handleRemoveShoe(cartShoe.shoe, cartShoe.size)
                        }
                        className="cartRemoveButton"
                        color="simple"
                      >
                        -
                      </Button>
                      {cartShoe.quantity}
                      <Button
                        onClick={() => handleAddShoe(cartShoe)}
                        className="cartRemoveButton"
                        color="simple"
                      >
                        +
                      </Button>
                    </Col>
                    <Col xs={2} className="cartImgContainer">
                      {getPrice(
                        cartShoe.shoe.shoeID,
                        cartShoe.size,
                        cartShoe.quantity
                      )}
                    </Col>
                  </Row>
                ))}
              </div>
            </ModalBody>
            <ModalFooter className="cart-modal" style={{ paddingTop: 10 }}>
              <Button
                type="submit"
                color="danger"
                size="sm"
                onClick={handleClearCart}
              >
                <Row style={{ paddingLeft: 10, paddingRight: 10 }}>
                  <RemoveShoppingCartIcon fontSize="small" />
                  <div style={{ paddingLeft: 5 }}>Clear cart</div>
                </Row>
              </Button>
              <Button type="submit" color="info" size="sm">
                <Link to="/checkout" style={{ color: "white" }}>
                  <Row style={{ paddingLeft: 10, paddingRight: 10 }}>
                    <NextWeekIcon fontSize="small" />
                    <div style={{ paddingLeft: 5 }}>Checkout</div>
                  </Row>
                </Link>
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      )}
    </>
  );
};

export default FloatingActionCart;
