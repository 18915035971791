import React from "react";
import useWindowDimensions from "hooks/useWindowDimensions";
import { Container } from "reactstrap";
import BTSCarousel from "./BTSCarousel";

const BehindTheShoes = () => {
  const { width, height } = useWindowDimensions();
  var mobile, landscape;
  if (width < 768) {
    mobile = true;
    landscape = false;
  } else if (height < 576 || width < 992) {
    mobile = false;
    landscape = true;
  } else {
    mobile = false;
    landscape = false;
  }
  const fluid = true;

  return (
    <div
      style={{
        marginTop: !mobile ? "15vh" : "0px",
        marginBottom: mobile ? "5vh" : "10vh",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Container
        fluid={fluid}
        style={{
          marginTop: !mobile ? "5vh" : "10vh",
          overflowX: "hidden",
          paddingLeft: !mobile && "5vw",
          paddingRight: !mobile && "5vw",
        }}
      >
        {mobile && (
          <h2
            className="text-white"
            style={{
              marginBottom: "5vh",
              textAlign: "center",
              fontSize: "35px",
              fontWeight: "800",
            }}
          >
            Behind the Shoe
          </h2>
        )}
        {!mobile && (
          <h1
            className="text-white"
            style={{
              marginBottom: "12vh",
              textAlign: "center",
              fontSize: "45px",
              fontWeight: "800",
            }}
          >
            Behind the Shoe
          </h1>
        )}
        <BTSCarousel />
      </Container>
    </div>
  );
};

export default BehindTheShoes;
