import { Col, Card, Row } from "reactstrap";
import useWindowDimensions from "hooks/useWindowDimensions";
import { useHistory } from "react-router-dom";
import moment from "moment";

export default function MystryBoxOrderCard({ order }) {
  const FIREBASE_STORAGE_MEMBERSHIP_BLACK_BOX_URL =
    require("assets/custom/membership/Black.png").default;
  const MYSTRY_BOX_MAP = {
    1: {
      tier: "Mystry Box +",
      price: 199,
      img: FIREBASE_STORAGE_MEMBERSHIP_BLACK_BOX_URL,
    },
    2: {
      tier: "Air Force Mystry Box",
      price: 140,
      img: FIREBASE_STORAGE_MEMBERSHIP_BLACK_BOX_URL,
    },
    3: {
      tier: "Air Jordan Mystry Box",
      price: 235,
      img: FIREBASE_STORAGE_MEMBERSHIP_BLACK_BOX_URL,
    },
    4: {
      tier: "Nike Dunk Mystry Box",
      price: 169,
      img: FIREBASE_STORAGE_MEMBERSHIP_BLACK_BOX_URL,
    },
    5: {
      tier: "Yeezy Mystry Box",
      price: 269,
      img: FIREBASE_STORAGE_MEMBERSHIP_BLACK_BOX_URL,
    },
  };
  console.log("order.tier");
  // console.log(order.tier);
  console.log(MYSTRY_BOX_MAP[order.tier].img);

  const width = useWindowDimensions().width;
  var mobile;
  if (width <= 420) {
    mobile = true;
  } else {
    mobile = false;
  }
  const history = useHistory();

  function getOrderStatus(orderStatus) {
    if (orderStatus === "Processing") {
      return "Preparing your Box";
    }
    if (orderStatus === "Procuring") {
      return "Applying polish";
    }
    if (orderStatus === "Shipping") {
      return "Packing your pair";
    }
    if (orderStatus === "Shipped") {
      return "On the way";
    }
    if (orderStatus === "Delivered") {
      return "Ready to rock";
    }
  }
  return (
    <Card
      style={{
        padding: "2vmax",
        cursor: "pointer",
        backgroundColor: "#2f2f2f",
      }}
      // onClick={() => {
      //   history.push({
      //     pathname: `/trackOrder`,
      //     state: { order: order },
      //   });
      // }}
    >
      <Row style={{}}>
        <Col md="2" xs="3">
          <img
            src={MYSTRY_BOX_MAP[order.tier].img}
            width="75"
            height="50"
            alt=""
          />
        </Col>
        <Col md="6" xs="9">
          <p>
            {" "}
            {MYSTRY_BOX_MAP[order.tier].tier} -{" "}
            {getOrderStatus(order.orderStatus)}
          </p>
          <p style={{ fontSize: "0.8rem" }} className="text-muted">
            Ordered On -
            {moment(new Date(order.orderedOn)).format("MMMM DD, YY")}
          </p>
        </Col>
      </Row>
    </Card>
  );
}
