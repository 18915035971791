import useWindowDimensions from 'hooks/useWindowDimensions';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Col, Container, Row } from 'reactstrap';
import './MystryPopUp.css'

const MystryPopUp = () => {
  const { width, height } = useWindowDimensions();
  var mobile, landscape;
  if (width < 768) {
    mobile = true;
    landscape = false;
  } else if (height < 576 || width < 992) {
    mobile = false;
    landscape = true;
  } else {
    mobile = false;
    landscape = false;
  }
  const history = useHistory();

  return (
    <Container
      fluid={true}
      style={{
        marginBottom: "5vh",
        marginTop: mobile? "10vh": "15vh",
        border: "2px solid black",
        borderRadius: "18px",
        width: mobile? "90vw": "90vw"
      }}
    >
      <Row md={12} sm={12}
        className = {mobile? 'pop-up-container-mobile': 'pop-up-container'}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: mobile? "": "60vh",
          paddingBottom: mobile? "5vh": "",
          paddingTop: mobile? "5vh": ""
        }}
      >
        {mobile && (
          <Col md={6} xs={10}>
            <img
              src={require('assets/custom/MystryBox/BlackBox.png').default}s
            />
          </Col>
        )}
        <Col md={6} sm={12}
          style={{
            textAlign: mobile? "center": "",
            paddingLeft: mobile? "0": "10vw"
          }}
        >
          <div
            style={{
              fontSize: mobile? "25px": "45px",
              fontWeight: "800",
              lineHeight: mobile? "40px": "60px",
              color: mobile? "#D10000": "#000000",
              marginTop: mobile? "10px": ""
            }}
          >The Best Gifting<br/> Option this Vacation
          </div>
          <div className={mobile? 'd-flex justify-content-center align-items-center': ""}>
            <div
              style={{
                color: "black",
                fontSize: mobile? "18px" : "20px",
                fontWeight: "500"
              }}
              className= {mobile? 'value-mobile': 'mt-3'}
            >Guaranteed Value: $199- $999
            </div>
          </div>
          <div
            style={{
              fontSize: "25px",
              fontWeight: "700",
              color: "#00b200"
            }}
            className='mt-2 mb-4'
          >Price: $199
          </div>
          <button 
            className='explore'
            onClick={() => history.push('/mystryBoxProduct')}
          >
            Explore
          </button>
        </Col>
        {!mobile && (
          <Col md={6}>
            <img
              src={require('assets/custom/MystryBox/BlackBox.png').default}
              style={{
                padding: landscape? "50px": "125px"
              }}
            />
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default MystryPopUp;