import React from "react";
import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";
import ReactCardFlip from "react-card-flip";

import { useHistory } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import { useEffect, useState } from "react";
import useWindowDimensions from "hooks/useWindowDimensions";
import { database } from "../../../firebase.js";
import "../Marketplace/Marketplace.css";
import FloatingActionCart from "../FloatingActionCart/FloatingActionCart";
import "./Kitchen.css";
import { useSelector } from "react-redux";
import _forIn from "lodash/forIn";
var _ = require("lodash");

const BrandCollection = () => {
  const curUrl = window.location.href;
  const brand = curUrl.substring(curUrl.lastIndexOf("/") + 1);
  // ////console.log(brand);
  const [fbShoes, setFbShoes] = useState([]);
  const [isFlipped, setFlipped] = useState([]);
  const shoes = useSelector((state) => state.shoes);

  useEffect(() => {
    var shoes_fb = [];

    Object.values(shoes).forEach((val) => {
      if (val.availableAt.cookbook) {
        if (val.shoeBrand2) {
          val.shoeBrand2 = val.shoeBrand2.replace(/\s/g, "").toLowerCase();
        }

        if (val.shoeBrand2 === "jordan") {
          val.shoeBrand2 = "airjordan";
        }

        if (val.shoeBrand === "Jordan") {
          val.shoeBrand = "airjordan";
        }

        if (
          val.shoeBrand.replace(/\s/g, "").toLowerCase() === brand ||
          val.shoeBrand2 === brand
        )
          shoes_fb.push(val);
      }
    });
    setFbShoes(shoes_fb);
    setFlipped(new Array(fbShoes).fill(false));
  }, [shoes]);

  // ////console.log("fb shoes: ", fbShoes);

  // var db_init = shoes.slice(0, total_shoes); // take first 20 shoes

  const width = useWindowDimensions().width;

  var mobile = false;
  if (width <= 420) {
    mobile = true;
  } else {
    mobile = false;
  }

  const shoeContainerClassName = (row) => {
    if (row === 0) {
      return "MarketplaceShoeContainer MarketplaceShoeContainerTop";
    } else {
      return "MarketplaceShoeContainer";
    }
  };

  const handleGridClick = (idx, shoe) => {
    isFlipped[idx] = !isFlipped[idx];
    setFlipped([...isFlipped]);
  };
  const putValue = (shoe) => {
    if (shoe.check === true) {
    } else {
      let url =
        "https://us-east1-price-scraper-330821.cloudfunctions.net/sneaker-price-estimator/";
      fetch(url, {
        method: "POST",
        body: JSON.stringify({
          // "sku":"716371-040"
          sku: shoe.shoeSKU,
        }),
      })
        .then((response) => response.json())
        .then((json) => {
          // setPosttArray([json]);
          //console.log(shoe.shoeSKU)
          //console.log([json]);
          var min_ = Infinity;
          _forIn([json][0], (value, key) => {
            let str = key;
            var numbers = /^[0-9]+$/;
            if (key[key.length - 1].match(numbers)) {
              //Do nothing men's shoe
            } else {
              str = key.substring(0, key.length - 1);
              str = parseFloat(str) - parseFloat(1.5);
            }
            if (value == null) {
              //object null- block
            } else if (
              parseFloat(str) < parseFloat(15) &&
              parseFloat(str) > parseFloat(4.5)
            ) {
              if (Number(value.lastSale) < min_) {
                min_ = value.lastSale;
              }
            }
          });
          shoe.marketValue = min_;
          shoe.ebay = false;
          if(shoe.marketValue == Infinity) {
            const ebayResults = []
            let ebay_url = "https://mystry-server-z574yasx7q-uc.a.run.app/scraper_api/ebay_all";
            fetch(ebay_url, {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                "sku": shoe.shoeSKU
              })
            })
            .then((response) => {
              if(!response.ok) throw new Error(response.status);
              else return response.json();
            })
            .then((data) => {
              Object.keys(data.ebayData).forEach(function(key) {
                ebayResults.push({size: key, price: data.ebayData[key].price})
              })
              shoe.marketValue = Math.round(
                Math.min(...ebayResults.map(item => item.price))
              )
            })
            .catch((error) => {
              console.log('error: ' + error);
              shoe.remove = true
            })
            shoe.ebay = true;
          }
        });
      shoe.check = true;
    }
    return true;
  };
  // reference: https://stackoverflow.com/a/35354844/10401847
  const history = useHistory();
  const handleMoreClick = (idx, shoe) => {
    history.push({
      pathname: `/sneakers/${fbShoes[idx].shoeID}`,
      state: { shoe: shoe, ebay: shoe.ebay },
    });
  };

  const reactCardFlipStyle = {
    paddingLeft: "10px",
    paddingRight: "10px",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  };
  const fluid = true;

  return (
    //filter the shoes based on brand (ideally better to have 1 shoe page than 8 brand pages)
    <>
      <FloatingActionCart />
      <Navbar page={"kitchen"} />
      <Container
        fluid={fluid}
        style={{ paddingLeft: "5vw", paddingRight: "5vw" }}
      >
        <div
          className="wrapper"
          style={{
            marginTop: "20vh",
            marginBottom: "5vh",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <h1 style={{ fontFamily: "helveticaneuebold" }}>
            Shop {brand.toUpperCase()}
          </h1>
        </div>
      </Container>
      {!mobile && (
        <Container
          fluid={fluid}
          style={{ paddingLeft: "5vw", paddingRight: "5vw" }}
        >
          {[...Array(Math.ceil(fbShoes.length / 4)).keys()].map((row, idx) => (
            <Row key={idx}>
              {fbShoes.slice(4 * row, 4 * (row + 1)).map((shoe, idx) => (
                (shoe.shoeImg.lowRes.right && !shoe.remove) ? 
                  <Col
                    key={idx}
                    xs={6}
                    md={3}
                    className={shoeContainerClassName(row)}
                    onMouseEnter={() => handleGridClick(4 * row + idx, shoe)}
                    onMouseLeave={() => handleGridClick(4 * row + idx, shoe)}
                  >
                    {/* 
                      reference for centering: https://www.w3.org/Style/Examples/007/center.en.html#block
                      useful SO thread: https://stackoverflow.com/questions/7273338/how-to-vertically-align-an-image-inside-a-div/7310398#7310398
                    */}
                    {putValue(shoe) && (
                      <ReactCardFlip
                        isFlipped={isFlipped[4 * row + idx]}
                        containerStyle={reactCardFlipStyle}
                      >
                        <div
                          style={{
                            display: "flex",
                            width: "80%",
                            height: "80%",
                            alignItems: "flex-end",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={shoe.shoeImg.lowRes.right}
                            alt={`Shoe number ${4 * row + idx + 1}`}
                            style={{
                              display: "block",
                              width: "100%",
                              justifyContent: "center",
                              marginLeft: "30%",
                            }}
                          />
                        </div>
                        <Col
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                            verticalAlign: "middle",
                            top: "-25%",
                          }}
                        >
                          <div className="backSideText"                               
                            style={{ fontSize: "1.4rem" }}>
                            {/* {!landscape && <h4>{shoe.title}</h4>} */}
                            {/* {landscape && <h5>{shoe.title}</h5>} */}
                            {shoe.shoeName}
                          </div>
                          <img
                            src={shoe.shoeImg.lowRes.front}
                            alt={`Shoe number ${4 * row + idx + 1}`}
                            className="backSideShoImgFlip"
                          />
                          <div
                            style={{
                              color: "#39FF14",
                              marginBottom: "15px",
                              fontSize: "1.2rem"
                            }}
                            className="backSideText"
                          >
                            Starting at ${shoe.marketValue}
                          </div>
                          <Button
                            color="simple"
                            size="sm"
                            onClick={() => handleMoreClick(4 * row + idx, shoe)}
                            className="backSideText"
                          >
                            See More
                          </Button>
                        </Col>
                      </ReactCardFlip>
                    )}
                  </Col>
                  : null
              ))}
            </Row>
          ))}
        </Container>
      )}
      {mobile && (
        <Container>
          {[...Array(Math.ceil(fbShoes.length / 2)).keys()].map((row, idx) => (
            <Row key={idx} style={{ paddingRight: "5px", paddingLeft: "5px" }}>
              {fbShoes.slice(2 * row, 2 * (row + 1)).map((shoe, idx) => (
                <Col
                  key={idx}
                  xs="6"
                  className={shoeContainerClassName(row)}
                  onClick={() => handleGridClick(2 * row + idx, shoe)}
                >
                  {putValue(shoe) && (
                    <ReactCardFlip
                      isFlipped={isFlipped[2 * row + idx]}
                      containerStyle={reactCardFlipStyle}
                    >
                      <img
                        src={shoe.shoeImg.lowRes.right}
                        alt={`Shoe number ${2 * row + idx + 1}`}
                      />

                      <Col
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                          verticalAlign: "middle",
                        }}
                      >
                        <div className="backSideText">{shoe.shoeName}</div>
                        <img
                          src={shoe.shoeImg.lowRes.front}
                          alt={`Shoe number ${2 * row + idx + 1}`}
                          className="backSideShoImg"
                        />
                        <div>
                          <div
                            style={{
                              color: "#39FF14",
                            }}
                            className="backSideText"
                          >
                            Starting at ${shoe.marketValue}{" "}
                          </div>
                        </div>
                        <Button
                          color="simple"
                          size="sm"
                          onClick={() => handleMoreClick(4 * row + idx, shoe)}
                          className="backSideText"
                        >
                          See More
                        </Button>
                      </Col>
                    </ReactCardFlip>
                  )}
                </Col>
              ))}
            </Row>
          ))}
        </Container>
      )}
      <Footer />
    </>
  );
};

export default BrandCollection;
