import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import useWindowDimensions from "hooks/useWindowDimensions";
import LandingCarousel from 'views/pages/Landing/LandingCarousel';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const LandingKitchen = () => {
  const [kitchenShoes, setKitchenShoes] = useState(null);
  const shoes = useSelector((state) => state.shoes);
  const fluid = true;

  useEffect(() => {
    var shoes_fb_kitchen = [];

    Object.values(shoes).forEach((val) => {
      if (val.availableAt.kitchen) {
        shoes_fb_kitchen.push(val);
      }
    });
    setKitchenShoes(shoes_fb_kitchen);
  }, [shoes]);

  const containerStyle = {
    marginBottom: "7.5vw",
    paddingLeft: !mobile && fluid ? "5vw" : "",
    paddingRight: !mobile && fluid ? "5vw" : "",
  };

  const history = useHistory();
  const joinStyle = mobile ? { fontSize: "12px" } : { fontSize: "16px" };
  const btnSize = mobile ? "sm" : "md";
  const responsiveHeadStyle = mobile
  ? { fontSize: "1.65rem" }
  : { fontSize: landscape ? "1.5rem" : "2vw" };

  const { width, height } = useWindowDimensions();
  var mobile, landscape;
  if (width < 768) {
    mobile = true;
    landscape = false;
  } else if (height < 576 || width < 992) {
    mobile = false;
    landscape = true;
  } else {
    mobile = false;
    landscape = false;
  }

  return (
    <div>
      <Container fluid={true} className='mt-4'>
        <Row>
          <Col style={{ padding: "0px" }}>
            <video
              width="100%"
              autoPlay
              loop
              id="kitchen-video"
              muted
              playsInline
              style={{
                zIndex: 1,
              }}
              position="relative"
            >
              <source
                src={
                  require("assets/custom/Kitchen/kitchen_video.mp4")
                    .default
                }
                type="video/mp4"
              ></source>
            </video>
          </Col>
        </Row>
      </Container>
      {kitchenShoes !== null && (
        <Container style={containerStyle} fluid={fluid}>
          <Row>
            <Col xs="12">
              <Row style={{marginTop: "5vh"}}>
                {mobile && <h4 style={{ color: "#dedede" }} className='d-flex justify-content-center'>
                  <span style={{textAlign: "center"}}>Mystry Kitchen allows you to shop available recent releases</span>
                </h4>}
                {!mobile && <h4 style={{ color: "#dedede" }}>
                  Mystry Kitchen allows you to shop available recent
                  releases
                </h4>}
                <Button
                  color="cusblack"
                  size={btnSize}
                  style={{
                    ...joinStyle,
                    display: "flex",
                    marginLeft: "auto ",
                    marginBottom: "2vmin",
                    textDecoration: 'underline'
                  }}
                  onClick={() => history.push("/kitchen")}
                >
                  See All
                </Button>
              </Row>
              <LandingCarousel
                place="kitchen"
                mayLike={false}
                fluid={fluid}
                shoesProp={kitchenShoes}
              />
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
};

export default LandingKitchen;