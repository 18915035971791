import React from "react";

import { Container, Row, Button } from "reactstrap";
import { Link } from "react-router-dom";
import Navbar from "../../../components/Navbars/Navbar";
import Footer from "../../../components/Footer/Footer";
import useWindowDimensions from "hooks/useWindowDimensions";
import "./Error.css";
const Error = () => {
  // const fluid = false;
  const { width, height } = useWindowDimensions();
  var mobile;
  if (width <= 420 || height <= 420) {
    mobile = true;
  } else {
    mobile = false;
  }
  var maxwidth = false;
  if (width > 1600) maxwidth = true;

  const overlayText = {
    position: "absolute",
    display: "block",
    width: maxwidth ? "80%" : "50%",
    left: "5vw",
    right: "5vw",
    top: "30%",
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
    justifyContent: "center",
    background: "black",

    zIndex: 2,
  };
  const overlayButton = {
    position: "absolute",
    display: "block",

    left: "5vw",
    right: "5vw",
    top: maxwidth ? "45%" : mobile ? (width > 420 ? "50%" : "35%") : "45%",
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
    justifyContent: "center",

    zIndex: 2,
  };
  return (
    <>
      <div  style={{ height: mobile ? "100%" : "100%" }}>
        <Navbar />
        {!mobile &&(
          <video
             
             width="100%"
             
             autoPlay
             loop
             muted
             playsInline
             style={{
               zIndex: 1,
               
               
             }}
             position="relative"
           >
           <source
                 src={
                   require("assets/custom/web-abouth265.mp4")
                     .default
                 }
                 type="video/mp4; codecs=hevc"
               ></source>
                <source
                 src={
                   require("assets/custom/web-about.webm")
                     .default
                 }
                 type="video/webm; codecs=vp9"
               ></source>
               <source
                 src={
                   require("assets/custom/web-abouth264.mp4")
                     .default
                 }
                 type="video/mp4"
               ></source>
           </video>
        )}
        {mobile &&(
          <video
             
             width="100%"
             
             autoPlay
             loop
             muted
             playsInline
             style={{
               zIndex: 1,
             }}
             position="relative"
           >
           <source
                 src={
                   require("assets/custom/mobile-abouth265.mp4")
                     .default
                 }
                 type="video/mp4; codecs=hevc"
               ></source>
                <source
                 src={
                   require("assets/custom/mobile-about.webm")
                     .default
                 }
                 type="video/webm; codecs=vp9"
               ></source>
               <source
                 src={
                   require("assets/custom/mobile-abouth264.mp4")
                     .default
                 }
                 type="video/mp4"
               ></source>
           </video>
        )}
        <Container>
          <Row>
            <span
              style={{
                ...overlayText,
                color: "black",
                fontSize: mobile ? "4vw" : "3.5vw",
                fontWeight: "bolder",
                background: "white",
              }}
            >
              Page cannot be found
            </span>
            <span style={overlayButton}>
              <Link to="/membership">
                <Button
                  size={mobile ? "xl" : "lg"}
                  style={{ fontSize: mobile ? "3vw" : "2vw" }}
                  color="green"
                >
                  MYSTRY
                </Button>
              </Link>
            </span>
          </Row>
        </Container>
      </div>
      <Footer/>
    </>
  );
};

export default Error;
