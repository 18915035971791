import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Pic1 from "./../../../views/pages/ProfilePage/components/pics/first.jpeg";
import Pic2 from "./../../../views/pages/ProfilePage/components/pics/second.jpeg";
import Pic3 from "./../../../views/pages/ProfilePage/components/pics/third.jpeg";
import Pic4 from "./../../../views/pages/ProfilePage/components/pics/fourth.jpeg";
import Pic5 from "./../../../views/pages/ProfilePage/components/pics/fifth.jpeg";
import Pic6 from "./../../../views/pages/ProfilePage/components/pics/sixth.jpeg";

import UserLocationForm from "./UserLocation";
import {
  FormGroup,
  Col,
  Button,
  Input,
  Row,
  Card,
  Container,
  CardBody,
} from "reactstrap";
import { database, userDb } from "../../../firebase";
import TierSelectPage from "./TierSelect";
import UserHandlesPage from "./UserHandlePage";
import { useAuth } from "contexts/AuthContext";
import WaitlistCongratsPage from "./WaitlistCongratsPage";
import useWindowDimensions from "hooks/useWindowDimensions";

import { useLocation, useHistory } from "react-router";
import { userWaitlistSucess } from "mystry_server";

export default function UserInfoForm() {
  const location = useLocation();
  const { currentUser, loggedIn } = useAuth();
  //////console.log(currentUser.email);
  const userMail = currentUser.email;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [userAge, setUserAge] = useState("");
  const [userGender, setUserGender] = useState("");
  const [userSize, setUserSize] = useState("");
  const [profilePic, setProfilePic] = useState(Pic1);

  //const [ instaHandle, setInstaHandle ] = useState('');
  //const [ tickTokHandle, setTickTokHandle ] = useState('');
  const [widgetVisible, setWidgetVisible] = useState(0);
  const [country, setCountry] = useState("United States");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("State*");
  const [zip, setZip] = useState("");
  const [tierSelected, setTierSelected] = useState(location.state.tier);
  const [isLoading, setLoading] = useState(false);

  const { width, height } = useWindowDimensions();
  const mobile = width <= 420 || height <= 420 ? true : false;
  const fluid = true;
  const [userData, setUserData] = useState(null);
  const getProfilePicFromName = (picName) => {
    if (picName === "Pic1") return Pic1;
    else if (picName === "Pic2") return Pic2;
    else if (picName === "Pic3") return Pic3;
    else if (picName === "Pic4") return Pic4;
    else if (picName === "Pic5") return Pic5;
    else if (picName === "Pic6") return Pic6;
    else return Pic1;
  };
  const getProfilePicName = (pic) => {
    if (pic === Pic1) return "Pic1";
    else if (pic === Pic2) return "Pic2";
    else if (pic === Pic3) return "Pic3";
    else if (pic === Pic4) return "Pic4";
    else if (pic === Pic5) return "Pic5";
    else if (pic === Pic6) return "Pic6";
    else return "Pic1";
  };
  useEffect(() => {
    if (loggedIn) {
      const refs = userDb(currentUser.uid);
      refs.once("value", (snapshot) => {
        let userInfo = snapshot.val();
        setUserData(userInfo);

        if (userInfo) {
          if (userInfo.name && userInfo.name.firstName)
            setFirstName(userInfo.name.firstName);
          if (userInfo.name && userInfo.name.lastName)
            setLastName(userInfo.name.lastName);
          if (userInfo.phone && userInfo.phone.phoneNumber)
            if (userInfo.phone) setPhone(userInfo.phone.phoneNumber);
          if (userInfo.profilePic) {
            setProfilePic(getProfilePicFromName(userInfo.profilePic));
          } else {
            setProfilePic(Pic1);
          }
          setUserAge(userInfo.age);
          setUserGender(userInfo.gender);
          setUserSize(userInfo.size);
          if (userInfo.waitlistID) {
            setWidgetVisible(69);
          }
        }
      });
    }
  }, [loggedIn, currentUser.uid]);

  function handleClickNext() {
    // if (userMail !== '') {
    setWidgetVisible(widgetVisible + 1);
    // } else {
    // }
  }

  function handleClickPrevious() {
    setWidgetVisible(widgetVisible - 1);
  }

  function handleClickDone() {
    setLoading(true);
    const now = Date.now();
    const waitlistRef = database.mystrywaitlist.push({
      userID: currentUser.uid,
      userMail: userMail,
      name: { firstName, lastName },
      phone: { phoneNumber: phone },
      userAge: userAge,
      userGender: userGender,
      userSize: userSize,
      country: country,
      address: [
        {
          firstName,
          lastName,
          address1,
          address2,
          city,
          state,
          zip,
        },
      ],
      tierSelected: tierSelected,
      appliedTime: now,
      lastEditedAt: now,
    });
    const ref = userDb(currentUser.uid);
    const obj = {
      name: { firstName, lastName },
      phone: { phoneNumber: phone },
      age: userAge,
      gender: userGender,
      size: userSize,
      country: country,
      address: [
        {
          firstName,
          lastName,
          address1,
          address2,
          city,
          state,
          zip,
        },
      ],
      tierApplied: tierSelected,
      appliedTime: now,
      waitlistID: waitlistRef.key,
      profilePic: getProfilePicName(profilePic),
    };
    ref.update(obj);

    setLoading(false);
    setWidgetVisible(2);

    userWaitlistSucess(userMail);
  }

  // function emailHandleForm() {
  //   return (
  //     <FormGroup>
  //       <Input
  //         placeholder="Email Address*"
  //         type="email"
  //         value={userMail}
  //         onChange={(e) => setUserMail(e.target.value)}
  //       />
  //     </FormGroup>
  //   );
  // }

  function renderWidget() {
    switch (widgetVisible) {
      // case 0:
      //   return emailHandleForm();
      // case 0:
      //   return <TierSelectPage setTierSelected={setTierSelected} tierSelected={tierSelected} />;
      case 0:
        return (
          <UserHandlesPage
            // tickTokHandle={tickTokHandle}
            // setTickTokHandle={setTickTokHandle}
            // instaHandle={instaHandle}
            // setInstaHandle={setInstaHandle}
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            userAge={userAge}
            setUserAge={setUserAge}
            userGender={userGender}
            setUserGender={setUserGender}
            userSize={userSize}
            setUserSize={setUserSize}
            tierSelected={tierSelected}
            setTierSelected={setTierSelected}
            phone={phone}
            setPhone={setPhone}
            profilePic={profilePic}
            setProfilePic={setProfilePic}
          />
        );

      default:
        return <div>AWSM</div>;
    }
  }

  return (
    <div
      style={{
        position: "relative",
        overflow: "hidden",
        background: "black",
      }}
    >
      <Navbar page={"infoForm"} />
      {widgetVisible !== 2 && (
        <div
          className="wrapper"
          style={{
            marginTop: "0vh",
            marginBottom: "30vh",
            alignItems: "center",
            textAlign: "center",
            padding: "2vh",
          }}
        >
          <Container
            fluid={fluid}
            style={{
              marginTop: !mobile ? "75px" : "50px",
              marginBottom: "75px",
              overflowX: "hidden",
              paddingLeft: !mobile && "5vw",
              paddingRight: !mobile && "5vw",
            }}
          >
            <Row>
              <Col
                style={{
                  textAlign: "center",
                  padding: "0px",
                  marginBottom: "3vh",
                }}
              >
                <img
                  src={require("assets/custom/mystryboxmobile.jpeg").default}
                  alt=""
                />
              </Col>
            </Row>
          </Container>

          <Card style={{ backgroundColor: "black", padding: "0vh" }}>
            <CardBody
              style={{
                padding: "0vh",
                paddingTop: "2vh",
                paddingBottom: "2vh",
              }}
            >
              {isLoading && <div>Submitting your response :)</div>}
              {widgetVisible < 1 && (
                <Col>
                  {widgetVisible === 0 && (
                    <h3>
                      Join the
                      <br />
                      MystryBox Waitlist
                    </h3>
                  )}
                  {renderWidget()}

                  <Row>
                    {widgetVisible > 0 && (
                      <Col>
                        <Button onClick={() => handleClickPrevious()} size="sm">
                          Previous
                        </Button>
                      </Col>
                    )}

                    <Col>
                      <Button
                        color="info"
                        onClick={() => handleClickNext()}
                        size="sm"
                        style={{ paddingLeft: "3vh", paddingRight: "3vh" }}
                      >
                        Next
                      </Button>
                    </Col>
                  </Row>
                </Col>
              )}
              {widgetVisible === 1 && (
                <UserLocationForm
                  country={country}
                  setCountry={setCountry}
                  setAddress1={setAddress1}
                  setAddress2={setAddress2}
                  setCity={setCity}
                  setState={setState}
                  setZip={setZip}
                  handleClickPrevious={handleClickPrevious}
                  handleClickDone={handleClickDone}
                />
              )}
              {widgetVisible === 69 && (
                <Col
                  style={{
                    alignItems: "center",
                    alignContent: "center",
                    justifyContent: "center",
                  }}
                >
                  <Row
                    style={{
                      alignItems: "center",
                      alignContent: "center",
                      justifyContent: "center",
                    }}
                  >
                    You have already applied for membership. Please check your
                    membership status on <b>Profile Page</b>.
                  </Row>
                  <br />
                  <Row
                    style={{
                      alignItems: "center",
                      alignContent: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button color="primary" to="/profile-page" tag={Link}>
                      Go to Profile
                    </Button>
                    <Button color="info" to="/" tag={Link}>
                      Return Home
                    </Button>
                  </Row>
                </Col>
              )}
            </CardBody>
          </Card>
        </div>
      )}

      {widgetVisible === 2 && <WaitlistCongratsPage />}

      <Footer />
    </div>
  );
}
