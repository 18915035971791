import React, { useEffect, useState } from "react";
import SneakerTinder from "./SneakerTinder";
import { dbUserReview, database } from "../../../../firebase.js";
import Instructions from "./Instructions";
import Loading from "components/Loading/Loading";
import HandpickedSneakerTinder from "./SneakPages/HandpickedSneakerTinder";
import useWindowDimensions from "hooks/useWindowDimensions";
import FormCompleted from "./FormCompleted";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";

const SneakerTinderMain = ({ currentUserID, stripeURL, chosenBrand }) => {
  const history = useHistory();

  const [modal, setModal] = useState(true);
  const [isResponsesSubmitted, setResponsesSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showButton, setShowButton] = useState(true);
  const [fbShoes, setFbShoes] = useState([]);
  const [allfbShoes, setallFbShoes] = useState([]);
  const { width, height } = useWindowDimensions();
  var mobile, landscape;
  if (width < 768) {
    mobile = true;
    landscape = false;
  } else if (height < 576 || width < 992) {
    mobile = false;
    landscape = true;
  } else {
    mobile = false;
    landscape = false;
  }
  //* make false to allow multiple respones for testing
  const preventMultipleResponses = false;

  const checkIfUserExistsInFB = (cuurentUserID) => {
    // console.log("inside check user exists")
    const ref = dbUserReview(cuurentUserID);
    var length = 0;

    ref
      .once("value", function (snapshot) {
        // console.log(snapshot);
        snapshot.forEach(function (childSnapshot) {
          length = length + 1;
        });
      })
      .then(function (bal) {
        console.log(length);
        if (length >= 30) {
          // console.log(currentUser.uid);
          // console.log(isResponsesSubmitted);
          setResponsesSubmitted(true);
          // window.location.replace(stripeURL);
          setModal(false);
        } else if (length > 0 && length < 30) {
          ref.remove();
        }
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (preventMultipleResponses) {
      checkIfUserExistsInFB(currentUserID);
    } else {
      setIsLoading(false);
    }
    var shoes_fb = [];
    database.shoes.once("value", (snapshot) => {
      var shoe_data = snapshot.val();
      var cnt = 0;

      Object.values(shoe_data).forEach((val) => {
        if (val.availableAt.chosenOne && val.shoeBrand === chosenBrand) {
          shoes_fb.push(val);
          cnt = cnt + 1;
        }
        allfbShoes.push(val);
      });
    });

    setFbShoes(shoes_fb);
  }, []);
  // console.log(isLoading);

  return (
    <div>
      <div>
        {isLoading && <Loading />}
        {!isLoading && !isResponsesSubmitted && showButton && (
          <Instructions modal={modal} setModal={setModal} />
        )}
        {!isLoading &&
          isResponsesSubmitted &&
          history.push({
            pathname: "/waitlist",
            state: { stripeCheckout: stripeURL },
          }) && (
            // <FormCompleted
            //   mobile={mobile}
            //   landscape={landscape}
            //   stripeURL={stripeURL}
            // />
            <></>
          )}
        {!isLoading && !isResponsesSubmitted && fbShoes.length > 0 && (
          <HandpickedSneakerTinder
            isChange={"awsm"}
            setShowButton={setShowButton}
            currentUserID={currentUserID}
            fbShoes={fbShoes.slice(0, 10).sort(() => Math.random() - 0.5)}
            allfbShoes={allfbShoes}
            stripeURL={stripeURL}
            chosenBrand={chosenBrand}
            // charactersState={fbShoes}
          />
        )}
      </div>
    </div>
  );
};

export default SneakerTinderMain;
// <SneakerTinder
//   setShowButton={setShowButton}
//   currentUserID={currentUserID}
//   isSubmit={true}
// />  <FormCompleted({ mobile: mobile, landscape: landscape }) />
