import React, { useEffect, useState, useRef } from "react";
import "./Landing.scss";

// reactstrap components
import { Container, Row, Col, Button, UncontrolledCarousel } from "reactstrap";
import { Link } from "react-router-dom";
import _, { debounce } from "lodash";

// core components
import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";
import useWindowDimensions from "hooks/useWindowDimensions";

import { Carousel } from "react-responsive-carousel";
import LandingCarousel from "./LandingCarousel";
import { useHistory } from "react-router";
// import { database } from "../../../firebase.js";
import { useAuth } from "contexts/AuthContext";
import { useSelector } from "react-redux";
// import { database } from "../../../firebase.js";
import ReactCardFlip from "react-card-flip";
import CarouselImage1 from "../../../assets/custom/carousel1.JPG";
import CarouselImage2 from "../../../assets/custom/carousel2.JPG";
import CarouselImage3 from "../../../assets/custom/carousel3.JPG";
import CarouselImage4 from "../../../assets/custom/carousel4.JPG";
import { database } from "../../../firebase.js";

import "./LandingPage.css";
import * as Scroll from "react-scroll";
import Countdown from "./Countdown";
import BehindTheShoes from "../BehindTheShoes/BehindTheShoes";
import ALaCarteLanding from "../ALaCarte/aLaCarteLanding";
import Subscription from "../Subscription/Subscription";

export default function LandingPage() {
  React.useEffect(() => {
    document.body.classList.toggle("landing-page");
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.toggle("landing-page");
    };
  }, []);

  const overlayText = {
    position: "absolute",
    top: "25vw",
    left: "60%",

    zIndex: 2,
  };
  const cookbookStyle = {
    objectFit: "contain",
    display: "block",
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    minHeight: "100%",
    borderRadius: "0px",
  };

  const toggle = () => setModal(!modal);
  const [modal, setModal] = useState(false);
  const [email, setEmail] = useState("");
  const [isFlipped, setFlipped] = useState([]);
  const [kitchenShoes, setKitchenShoes] = useState(null);
  const [chefsPicksShoes, setChefsPicksShoes] = useState(null);
  const shoes = useSelector((state) => state.shoes);
  const currentDate = new Date();
  const year =
    currentDate.getMonth() === 11 && currentDate.getDate() > 23
      ? currentDate.getFullYear() + 1
      : currentDate.getFullYear();

  useEffect(() => {
    var shoes_fb_kitchen = [];
    var shoes_fb_chefsPicksShoes = [];

    Object.values(shoes).forEach((val) => {
      if (val.availableAt.kitchen) {
        shoes_fb_kitchen.push(val);
      }
      if (val.availableAt.chefsPick) {
        shoes_fb_chefsPicksShoes.push(val);
      }
    });
    setKitchenShoes(shoes_fb_kitchen);
    setChefsPicksShoes(shoes_fb_chefsPicksShoes);
  }, [shoes]);

  const [showScrollDown, setShowScrollDown] = useState(false);
  const { width, height } = useWindowDimensions();

  window.addEventListener("load", videoScroll);
  window.addEventListener("scroll", videoScroll);

  function videoScroll() {
    if (document.querySelectorAll("video[autoplay]").length > 0) {
      var windowHeight = window.innerHeight,
        videoEl = document.querySelectorAll("video[autoplay]");

      for (var i = 0; i < videoEl.length; i++) {
        var thisVideoEl = videoEl[i],
          videoHeight = thisVideoEl.clientHeight,
          videoClientRect = thisVideoEl.getBoundingClientRect().top;

        if (
          videoClientRect <= windowHeight - videoHeight * 0.5 &&
          videoClientRect >= 0 - videoHeight * 0.5
        ) {
          thisVideoEl.play();
        } else {
          thisVideoEl.pause();
        }
      }
    }
  }

  const [playing, setPlaying] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    window.addEventListener("scroll", debounce(handleScroll, 200));
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const startVideo = () => {
    videoRef.current.pause();
    videoRef.current.muted = true;
    setPlaying(false);
  };

  const pauseVideo = () => {
    videoRef.current.play();
    videoRef.current.muted = false;
    setPlaying(true);
  };

  const handleScroll = (e) => {
    if (playing) {
      pauseVideo();
    }
  };

  const handleVideoPress = () => {
    if (playing) {
      startVideo();
    } else {
      pauseVideo();
    }
  };

  var mobile, landscape;
  if (width < 768) {
    mobile = true;
    landscape = false;
  } else if (height < 576 || width < 992) {
    mobile = false;
    landscape = true;
  } else {
    mobile = false;
    landscape = false;
  }

  const fluid = true;
  const { loggedIn, currentUser } = useAuth();
  // const loggedIn = currentUser;

  const containerStyle = {
    marginBottom: "7.5vw",
    paddingLeft: !mobile && fluid ? "5vw" : "",
    paddingRight: !mobile && fluid ? "5vw" : "",
  };
  const timerContainerStyle = {
    textAlign: "center",
    marginBottom: "7.5vw",
    paddingLeft: !mobile && fluid ? "5vw" : "",
    paddingRight: !mobile && fluid ? "5vw" : "",
  };

  const joinStyle = mobile ? { fontSize: "12px" } : { fontSize: "16px" };
  const btnSize = mobile ? "sm" : "md";
  const titleStyle = mobile
    ? { fontSize: "4vw", marginBottom: ".5vmax", fontFamily: "edosz" }
    : { fontSize: "4vw", marginBottom: ".5vmax", fontFamily: "edosz" };

  const responsiveHeadStyle = mobile
    ? { fontSize: "1.65rem" }
    : { fontSize: landscape ? "1.5rem" : "2vw" };
  const responsiveBodyStyle = mobile
    ? {
        fontSize: "1rem",
        color: "#7b838a",
      }
    : { fontSize: landscape ? "1rem" : "1.1vmax", color: "#7b838a" };

  const history = useHistory();
  const brands = [
    "adidas",
    "offwhite",
    "yeezy",
    "nike",
    "airjordan",
    "balenciaga",
    "supreme",
    "newbalance",
    "vans",
    "converse",
  ];
  const handleBrand = (brand) => history.push(`/cookbook/${brands[brand]}`);
  const reactCardFlipStyle = {
    // bottom:'0',
  };
  const handleGridClick = (idx) => {
    isFlipped[idx] = !isFlipped[idx];
    setFlipped([...isFlipped]);
  };

  function JoinNowButton({ dest, text }) {
    return (
      <span>
        <Button
          color="cusblack"
          tag={Link}
          to={`/${dest}`}
          size={btnSize}
          style={joinStyle}
        >
          {text}
        </Button>
      </span>
    );
  }

  function openSneakerswipe() {
    if (loggedIn) {
      const userID = currentUser.uid;
      window.location.replace(`https://mystryworld.com/sneakerswipe/${userID}`);
    } else {
      history.push({
        pathname: `/login`,
        state: { sneakerswipe: true },
      });
    }
  }

  function openMetaverse() {
    history.push({
      pathname: `/metaverse`,
    });
  }

  const carouselItems = [
    {
      src: CarouselImage1,
      altText: "Slide 1",
      caption: "",
    },
    {
      src: CarouselImage3,
      altText: "Slide 2",
      caption: "",
    },
    {
      src: CarouselImage4,
      altText: "Slide 3",
      caption: "",
    },
    {
      src: CarouselImage2,
      altText: "Slide 4",
      caption: "",
    },
  ];

  const scrollDown = () => {
    // window.scroll({
    //   // top: document.body.offsetHeight,
    //   // bottom: "200px",
    //   top: 200,
    //   // left: 0,
    //   behavior: "smooth",
    //   // behavior: "auto",
    // });
    Scroll.animateScroll.scrollTo(mobile ? 500 : landscape ? 500 : 1000, {
      smooth: true,
    });
  };

  const vidRef = useRef();
  const setPlayBack = () => {
    vidRef.current.playbackRate = 1.4;
  };

  const toggleScrollDown = (e) => {
    if (e.target.currentTime >= 8) {
      setShowScrollDown(true);
    }
  };

  var logoStyle;
  if (width <= 420 || height <= 420) {
    mobile = true;
    logoStyle = { fontFamily: "edosz", fontSize: "1.5rem" };
  } else {
    mobile = false;
    logoStyle = { fontFamily: "edosz", fontSize: "2.4rem" };
  }

  const [displayContent, setDisplayContent] = useState(false);
  // Doing this to have the text appear after the video space is occupied.
  // This fixes the issue where text was appearing before video for a split second.
  // Limitation: if the internet speed is slow (for example 3G), then this might not work as expected since
  // 1 sec is not enough to load everything in slow speeds. Will result in text appearing before video
  useEffect(() => {
    setTimeout(() => {
      setDisplayContent(true);
    }, 1000);
  }, []);

  return (
    <>
      <Navbar />
      <div
        className="wrapper"
        style={{
          position: "relative",
          overflow: "hidden",
          background: "black",
        }}
      >
        {/* <div className="page-header"> */}
        <div
          style={{
            position: "relative",
            overflow: "hidden",
            marginBottom: "2vw",
          }}
        >
          {/* reference to crop the video from top: https://stackoverflow.com/questions/18716077/crop-video-in-html */}
          {/*marginTop: !mobile ? `${videoOffset}px` : "0px",*/}
          {!mobile && (
            <>
              <video
                width="100%"
                id="landingVid"
                autoPlay
                loop
                muted
                playsInline
                style={{
                  zIndex: 1,
                }}
                position="relative"
                onTimeUpdate={(e) => toggleScrollDown(e)}
              >
                {/* First tries to play hevc video, then falls back to webm format, 
                then falls back to mp4 format with h264 codec which is widely supported
                Awesome resource for compression and type conversion: 
                https://convertio.co/
                
                Note: HEVC OR H265 files from this website does not work 
                on Apple devices: https://www.freeconvert.com/video-compressor */}

                {/* hevc codec supported by Apple devices. Highly compressed, small size */}
                <source
                  src={
                    require("assets/custom/hero-video-compressed-new-x265.mp4")
                      .default
                  }
                  type="video/mp4; codecs=hvc1"
                ></source>
                {/* webm supported by everything besides Apple. Highly compressed, small size */}
                <source
                  src={
                    require("assets/custom/hero-video-compressed-new.webm")
                      .default
                  }
                  type="video/webm; codecs=vp9"
                ></source>
                {/* mp4 video encoded with h264. Widely supported */}
                <source
                  src={
                    require("assets/custom/hero-video-compressed-new.mp4")
                      .default
                  }
                  type="video/mp4"
                ></source>
              </video>
              <Row
                className="row-grid justify-content-between align-items-center text-left"
                style={overlayText}
              >
                <Col xs="12" s="12" lg="12" md="12">
                  {/*can change color of this specific title by adding a new custom title class*/}
                  <div className="title text-white" style={titleStyle}>
                    KEEP 'EM GUESSING
                  </div>
                </Col>
              </Row>
              {showScrollDown && (
                <div className="scrollDownContainer" onClick={scrollDown}>
                  <div className="scrollDownChevron"></div>
                  <div className="scrollDownChevron"></div>
                  <div className="scrollDownChevron"></div>
                  <span className="scrollDownText">Scroll down</span>
                </div>
              )}
            </>
          )}
          {mobile && !landscape && (
            <>
              <video
                width="100%"
                id="landingVid"
                autoPlay
                loop
                muted
                playsInline
                style={{
                  zIndex: 1,
                }}
                position="relative"
                onTimeUpdate={(e) => toggleScrollDown(e)}
              >
                <source
                  src={
                    require("assets/custom/boxvideo-compressed-x265.mp4")
                      .default
                  }
                  type="video/mp4; codecs=hvc1"
                ></source>
                <source
                  src={require("assets/custom/boxvideo-compressed.mp4").default}
                  type="video/mp4"
                ></source>
              </video>
              {showScrollDown && (
                <div className="scrollDownContainer" onClick={scrollDown}>
                  <div className="scrollDownChevron"></div>
                  <div className="scrollDownChevron"></div>
                  <div className="scrollDownChevron"></div>
                  <span className="scrollDownText">Scroll down</span>
                </div>
              )}
            </>
          )}
        </div>

        {/* </div> */}
        {displayContent && (
          <>
            <Container
              style={{
                ...timerContainerStyle,
                marginTop: "2vh",
                marginBottom: mobile ? "9vh" : "15vh",
              }}
            >
              <div className="mb-4">
                <span style={logoStyle}>MYSTRY </span>
              </div>
              {mobile ? (
                <h3>World’s first AI-powered luxury sneaker #MystryBox</h3>
              ) : (
                <h1>World’s first AI-powered luxury sneaker #MystryBox</h1>
              )}
              <div className="arrow bounce mt-5">
                <h2
                  style={{ display: "inline-block", color: "#00b200" }}
                  className="fa fa-angle-double-down fa-2x"
                ></h2>
                <h2
                  style={{ display: "inline-block", color: "#00b200" }}
                  className="m-2"
                >
                  <strong>NEXT DROP</strong>
                </h2>
                <h2
                  style={{ display: "inline-block", color: "#00b200" }}
                  className="fa fa-angle-double-down fa-2x"
                ></h2>
              </div>
              <div className="mb-4">
                {mobile || landscape ? (
                  <h3>
                    <Countdown date={`${year}-09-25T00:00:00`} />
                  </h3>
                ) : (
                  <strong style={{ fontSize: "7.5rem" }}>
                    <Countdown date={`${year}-09-25T00:00:00`} />
                  </strong>
                )}
                {mobile ? (
                  <h4 className="mt-4 mb-4">
                    Dropping Sunday, September 25th, 2022
                    <br />
                    12:00 PM EST // 3:00 PM PST
                  </h4>
                ) : (
                  <h3 className="mt-4">
                    Dropping Sunday, September 25th, 2022 12:00 PM EST // 3:00 PM
                    PST
                  </h3>
                )}
              </div>
            </Container>
            <Container fluid={fluid} style={{ ...containerStyle }}>
              <div
                className="title text-white"
                style={{
                  ...responsiveHeadStyle,
                  marginLeft: "3vw",
                  fontStyle: "italic",
                  fontWeight: "700",
                  textAlign: "center",
                }}
              >
                Mystry Membership Tiers
              </div>
              {!mobile && (
                <>
                  <div class="accordion">
                    <ul>
                      <li>
                        <div>
                          <Link
                            to={{
                              pathname: "/membership",
                            }}
                          >
                            <h2>Stylite Box</h2>
                            <h3>1 pair of shoes at $249/month</h3>
                          </Link>
                        </div>
                      </li>
                      <li>
                        <div className="align-items-center">
                          <Link
                            to={{
                              pathname: "/membership",
                            }}
                          >
                            <h2>Sole-mate Box</h2>
                            <h3>2 pair of shoes at $475/month</h3>
                          </Link>
                        </div>
                      </li>
                      <li>
                        <div className="align-items-center">
                          <Link
                            to={{
                              pathname: "/membership",
                            }}
                          >
                            <h2>Mystry +</h2>
                            <h3>3 pair of shoes at $690/month</h3>
                          </Link>
                        </div>
                      </li>
                    </ul>
                  </div>
                </>
              )}
            </Container>
            {mobile && (
              <Container
                fluid={fluid}
                style={{
                  paddingLeft: "5vw",
                  paddingRight: "5vw",
                  marginTop: "5vh",
                  marginBottom: "8vh",
                }}
              >
                <Row className="align-items-center">
                  <Col xs="12" style={{ textAlign: "center" }}>
                    <Link
                      to={{
                        pathname: "/membership",
                      }}
                    >
                      <div class="image-holder">
                        <div class="membership_img">
                          <img
                            src={
                              require("../../../assets/custom/membership/Stylite.png")
                                .default
                            }
                          />
                        </div>
                      </div>
                    </Link>
                    <Link
                      to={{
                        pathname: "/membership",
                      }}
                    >
                      <div class="image-holder">
                        <div class="membership_img">
                          <img
                            src={
                              require("../../../assets/custom/membership/Solemate.png")
                                .default
                            }
                          />
                        </div>
                      </div>
                    </Link>
                    <Link
                      to={{
                        pathname: "/membership",
                      }}
                    >
                      <div class="image-holder">
                        <div class="membership_img">
                          <img
                            src={
                              require("../../../assets/custom/membership/mystry+.png")
                                .default
                            }
                          />{" "}
                        </div>
                      </div>
                    </Link>

                    {/* <Link
                      to={{
                        pathname: "/membership"
                      }}
                    >
                      <video
                        width="100%"
                        autoPlay
                        loop
                        muted
                        playsInline
                        webkit-playsinline={true}
                        defaultMuted
                        style={{
                          zIndex: 1,
                        }}
                        ref={vidRef}
                        onCanPlay={() => setPlayBack()}
                        position="relative"
                        onTimeUpdate={(e) => toggleScrollDown(e)}
                      >
                        <source
                          src={
                            require("assets/custom/membership/Black.mp4")
                              .default
                          }
                          type="video/mp4; codecs=hvc1"
                        ></source>
                        <source
                          src={
                            require("assets/custom/membership/Black.webm")
                              .default
                          }
                          type="video/webm; codecs=vp9"
                        ></source>
                        <source
                          src={
                            require("assets/custom/membership/Black.mp4")
                              .default
                          }
                          type="video/mp4"
                        ></source>
                      </video>
                    </Link>
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col xs="12" style={{ textAlign: "center" }}>
                    <Link
                      to={{
                        pathname: "/membership"
                      }}
                    >
                      <video
                        width="100%"
                        type="video/mp4"
                        autoPlay
                        loop
                        muted
                        playsInline
                        webkit-playsinline={true}
                        defaultMuted
                        ref={vidRef}
                        onCanPlay={() => setPlayBack()}
                        style={{
                          zIndex: 1,
                        }}
                        position="relative"
                        onTimeUpdate={(e) => toggleScrollDown(e)}
                      >
                        <source
                          src={
                            require("assets/custom/membership/White.mp4")
                              .default
                          }
                          type="video/mp4; codecs=hvc1"
                        ></source>
                        <source
                          src={
                            require("assets/custom/membership/White.webm")
                              .default
                          }
                          type="video/webm; codecs=vp9"
                        ></source>
                        <source
                          src={
                            require("assets/custom/membership/White.mp4")
                              .default
                          }
                          type="video/mp4"
                        ></source>
                      </video>
                    </Link>
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col xs="12" style={{ textAlign: "center" }}>
                    <Link
                      to={{
                          pathname: "/membership"
                      }}
                    >
                      <video
                        width="100%"
                        autoPlay
                        loop
                        muted
                        playsInline
                        webkit-playsinline={true}
                        defaultMuted
                        ref={vidRef}
                        onCanPlay={() => setPlayBack()}
                        style={{
                          zIndex: 1,
                        }}
                        position="relative"
                        onTimeUpdate={(e) => toggleScrollDown(e)}
                      >
                        <source
                          src={
                            require("assets/custom/membership/Gold.mp4")
                              .default
                          }
                          type="video/mp4; codecs=hvc1"
                        ></source>
                        <source
                          src={
                            require("assets/custom/membership/Gold.webm")
                              .default
                          }
                          type="video/webm; codecs=vp9"
                        ></source>
                        <source
                          src={
                            require("assets/custom/membership/Gold.mp4")
                              .default
                          }
                          type="video/mp4"
                        ></source>
                      </video>
                    </Link> */}
                  </Col>
                </Row>
              </Container>
            )}
            <Subscription />
            <Container
              fluid={fluid}
              className="p-0"
              style={{ marginTop: "3vmax", marginBottom: "8vmax" }}
            >
              <div
                className="title text-white"
                style={{
                  ...responsiveHeadStyle,
                  marginTop: "6vw",
                  fontStyle: "italic",
                  fontWeight: "700",
                  textAlign: "center",
                }}
              >
                Mystryverse
              </div>
              <video
                width="100%"
                autoPlay
                onClick={handleVideoPress}
                id="metaverse"
                loop
                muted
                ref={videoRef}
                playsInline
                style={{
                  zIndex: 1,
                }}
                position="relative"
              >
                <source
                  src={require("assets/custom/mystryverse.mp4").default}
                  type="video/mp4; codecs=hvc1"
                ></source>
                <source
                  src={require("assets/custom/mystryverse.webm").default}
                  type="video/webm; codecs=vp9"
                ></source>
                <source
                  src={require("assets/custom/mystryverse.mp4").default}
                  type="video/mp4"
                ></source>
              </video>
              <div style={{ textAlign: "center" }}>
                <Button
                  color="green"
                  tag={Link}
                  to={`/metaverse`}
                  size="md"
                  style={{ ...joinStyle, marginTop: "20px" }}
                >
                  <span style={{ color: "white" }}>More Info</span>
                </Button>
              </div>
            </Container>
            <Container
              fluid={fluid}
              style={{ ...containerStyle, marginTop: "3vmax" }}
            >
              {!mobile && (
                <Row className="align-items-center">
                  <Col xs="6" sm="6" md="6" lg="7">
                    <div style={{ marginLeft: !mobile && "50px" }}>
                      <UncontrolledCarousel
                        items={carouselItems}
                        interval={4200}
                        controls={false}
                        indicators={false}
                      />
                    </div>
                    <div
                      style={{
                        ...responsiveBodyStyle,
                        margin: "2vmax",
                        color: "white",
                      }}
                    >
                      {/*<Button color="green" size={btnSize} style={joinStyle}>Sign up to Join our Waitlist</Button>*/}
                    </div>
                  </Col>
                  <Col xs="6" md="6" lg="5">
                    <div
                      className="title text-white"
                      style={{
                        ...responsiveHeadStyle,
                        marginLeft: "3vw",
                        fontStyle: "italic",
                        fontWeight: "700",
                      }}
                    >
                      What is MYSTRY?
                    </div>
                    <div
                      style={{
                        ...responsiveBodyStyle,
                        marginLeft: "3vw",
                        color: "#dedede",
                      }}
                    >
                      Mystry is the only luxury sneaker box with a 100%
                      guaranteed value, delivering only the most hyped sneakers
                      from the latest releases.
                      <br />
                      <br />
                      The market value of the sneakers in your MystryBox can
                      range from <span style={{ color: "#00b200" }}>$249</span>-
                      <span style={{ color: "#00b200" }}>$999</span>, and with
                      our cutting-edge AI technology,
                      <a
                        style={{ color: "#00b200", cursor: "pointer" }}
                        onClick={() => openSneakerswipe()}
                      >
                        {" "}
                        Sneakerswipe{" "}
                      </a>
                      rest assured it’s a sneaker you’re going to love, always.
                    </div>
                  </Col>
                </Row>
              )}
              {mobile && (
                <>
                  <Row className="align-items-center">
                    <Col xs="12" sm="12">
                      <div
                        className="title text-white"
                        style={{
                          ...responsiveHeadStyle,
                          fontStyle: "italic",
                          fontWeight: "700",
                          textAlign: "center",
                          marginTop: "-1vh",
                          marginBottom: "1vh",
                        }}
                      >
                        What is MYSTRY?
                      </div>
                      <div
                        style={{
                          ...responsiveBodyStyle,
                          marginBottom: "1vh",
                          color: "#dedede",
                        }}
                      >
                        Mystry is the only luxury sneaker box with a 100%
                        guaranteed value, delivering only the most hyped
                        sneakers from the latest releases.
                        <br />
                        <br /> The market value of the sneakers in your
                        MystryBox can range from{" "}
                        <span style={{ color: "#00b200" }}>$249</span>-
                        <span style={{ color: "#00b200" }}>$999</span>, and with
                        our cutting-edge AI technology,
                        <a
                          style={{ color: "#00b200", cursor: "pointer" }}
                          onClick={() => openSneakerswipe()}
                        >
                          {" "}
                          Sneakerswipe
                        </a>
                        , rest assured it’s a sneaker you’re going to love,
                        always.
                      </div>
                    </Col>
                    <Col xs="12" sm="12" style={{ marginTop: "1vh" }}>
                      <div>
                        <UncontrolledCarousel
                          items={carouselItems}
                          interval={4200}
                          controls={false}
                          indicators={false}
                        />
                      </div>
                      <div
                        style={{
                          ...responsiveBodyStyle,
                          margin: "2vmax",
                          color: "white",
                        }}
                      ></div>
                    </Col>
                  </Row>
                </>
              )}
            </Container>
            {mobile && (
              <Container fluid={fluid}>
                <Row>
                  <Col style={{ padding: "0px" }}>
                    <video
                      width="100%"
                      autoPlay
                      loop
                      id="kitchen-video"
                      muted
                      playsInline
                      style={{
                        zIndex: 1,
                      }}
                      position="relative"
                    >
                      <source
                        src={
                          require("assets/custom/Kitchen/kitchen_video.mp4")
                            .default
                        }
                        type="video/mp4"
                      ></source>
                    </video>
                  </Col>
                </Row>
              </Container>
            )}
            {!mobile && (
              <Container fluid={fluid}>
                <Row>
                  <Col style={{ padding: "0px" }}>
                    <img
                      src={require("assets/custom/imagesection.jpg").default}
                      alt=""
                    />
                  </Col>
                </Row>
              </Container>
            )}
            {kitchenShoes !== null && (
              <Container style={containerStyle} fluid={fluid}>
                <Row>
                  <Col xs="12">
                    {/* <h1>
                  <b
                    className="text-white"
                    style={{ fontFamily: "helveticaneuebold" }}
                  >
                    Mystry Kitchen
                  </b>
                </h1> */}
                    <div
                      className="title text-white"
                      style={{
                        ...responsiveHeadStyle,
                        // marginLeft: "3vw",
                        fontStyle: "italic",
                        fontWeight: "700",
                        textAlign: "center",
                      }}
                    >
                      Mystry Kitchen
                    </div>
                    <h4 style={{ color: "#dedede" }}>
                      Mystry Kitchen allows you to shop available recent
                      releases
                    </h4>
                    <Button
                      color="cusblack"
                      size={btnSize}
                      style={{
                        ...joinStyle,
                        display: "flex",
                        marginLeft: "auto ",
                        marginBottom: "2vmin",
                        // fontSize: mobile ? "12px" : "14px",
                      }}
                      onClick={() => history.push("/kitchen")}
                    >
                      See All
                    </Button>
                    <LandingCarousel
                      place="kitchen"
                      mayLike={false}
                      fluid={fluid}
                      shoesProp={kitchenShoes}
                    />
                  </Col>
                </Row>
              </Container>
            )}
            {/* <Container
              fluid={fluid}
              style={{ marginTop: mobile? "12vh" : "3vh", marginBottom: "3vmax"  }}
            >
              <a href="https://www.snapchat.com/unlock/?type=SNAPCODE&uuid=d7ca94fa4fff4b6683fb9a0bbe5e90e9&metadata=01" className="row d-flex justify-content-center">
                <video
                  autoPlay
                  loop
                  muted
                  playsInline
                  style={{
                    zIndex: 1,
                  }}
                  className="col-md-6"
                >
                  <source
                    src={require("../../../assets/custom/fragment_shoe.mp4").default}
                    type="video/mp4"
                  ></source>
                </video>
              </a>
            </Container> */}
            <ALaCarteLanding />
            {chefsPicksShoes && (
              <Container style={{ ...containerStyle }} fluid={fluid}>
                <Row>
                  <Col xs="12">
                    {/* <h1>
                  <b
                    className="text-white"
                    style={{ fontFamily: "helveticaneuebold" }}
                  >
                    Chef's Picks
                  </b>
                </h1> */}
                    <div
                      className="title text-white"
                      style={{
                        ...responsiveHeadStyle,
                        // marginLeft: "3vw",
                        fontStyle: "italic",
                        fontWeight: "700",
                        textAlign: "center",
                      }}
                    >
                      Chef's Picks
                    </div>
                    <h4 style={{ color: "#dedede" }}>
                      {/* Join our exclusive membership to explore our chef's custom picks
                for you! */}
                      Check out what our sneaker chefs have in store for you!
                    </h4>
                    <LandingCarousel
                      place="chefsPick"
                      mayLike={false}
                      loggedIn={loggedIn}
                      fluid={fluid}
                      JoinNowButton={JoinNowButton}
                      shoesProp={chefsPicksShoes}
                    />
                  </Col>
                </Row>
              </Container>
            )}
            {!mobile && (
              <Container fluid={fluid} style={{ ...containerStyle }}>
                <Row>
                  <Col xs="12">
                    {/* <h1>
                  <b
                    className="text-white"
                    style={{ fontFamily: "helveticaneuebold" }}
                  >
                    Cookbook
                  </b>
                </h1> */}
                    <div
                      className="title text-white"
                      style={{
                        ...responsiveHeadStyle,
                        // marginLeft: "3vw",
                        fontStyle: "italic",
                        fontWeight: "700",
                        textAlign: "center",
                      }}
                    >
                      Cookbook
                    </div>
                    <h4 style={{ color: "#dedede" }}>Shop by cuisine</h4>
                  </Col>

                  <Container fluid={fluid}>
                    <Carousel
                      showArrows={true}
                      autoPlay={true}
                      interval={6000}
                      showThumbs={false}
                      showIndicators={false}
                      showStatus={false}
                      infiniteLoop={true}
                    >
                      <Row>
                        <Col
                          style={{ paddingRight: "0px", cursor: "pointer" }}
                          onClick={() => handleBrand(0)}
                        >
                          <img
                            src={
                              require("assets/custom/cook/adidas.jpg").default
                            }
                            style={{ borderRadius: "0px" }}
                            className={cookbookStyle}
                            alt=""
                          />
                        </Col>
                        <Col
                          style={{ padding: "0px", cursor: "pointer" }}
                          onClick={() => handleBrand(1)}
                        >
                          <img
                            src={
                              require("assets/custom/cook/offwhite.jpg").default
                            }
                            style={{ borderRadius: "0px" }}
                            className={cookbookStyle}
                            alt=""
                          />
                        </Col>
                        <Col
                          style={{ padding: "0px", cursor: "pointer" }}
                          onClick={() => handleBrand(2)}
                        >
                          <img
                            src={
                              require("assets/custom/cook/yeezy.jpg").default
                            }
                            style={{ borderRadius: "0px" }}
                            className={cookbookStyle}
                            alt=""
                          />
                        </Col>
                        <Col
                          style={{ padding: "0px", cursor: "pointer" }}
                          onClick={() => handleBrand(3)}
                        >
                          <img
                            src={require("assets/custom/cook/nike.jpg").default}
                            style={{ borderRadius: "0px" }}
                            className={cookbookStyle}
                            alt=""
                          />
                        </Col>
                        <Col
                          style={{ cursor: "pointer" }}
                          onClick={() => handleBrand(8)}
                        >
                          <img
                            src={require("assets/custom/cook/vans.jpg").default}
                            style={{ borderRadius: "0px" }}
                            className={cookbookStyle}
                            alt=""
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col
                          style={{ paddingRight: "0px", cursor: "pointer" }}
                          onClick={() => handleBrand(4)}
                        >
                          <img
                            src={
                              require("assets/custom/cook/airjordan.jpg")
                                .default
                            }
                            style={{ borderRadius: "0px" }}
                            className={cookbookStyle}
                            alt=""
                          />
                        </Col>
                        <Col
                          style={{ padding: "0px", cursor: "pointer" }}
                          onClick={() => handleBrand(5)}
                        >
                          <img
                            src={
                              require("assets/custom/cook/balenciaga.jpg")
                                .default
                            }
                            style={{ borderRadius: "0px" }}
                            className={cookbookStyle}
                            alt=""
                          />
                        </Col>
                        <Col
                          style={{ padding: "0px", cursor: "pointer" }}
                          onClick={() => handleBrand(6)}
                        >
                          <img
                            src={
                              require("assets/custom/cook/supreme.jpg").default
                            }
                            style={{ borderRadius: "0px" }}
                            className={cookbookStyle}
                            alt=""
                          />
                        </Col>
                        <Col
                          style={{ padding: "0px", cursor: "pointer" }}
                          onClick={() => handleBrand(7)}
                        >
                          <img
                            src={
                              require("assets/custom/cook/newbalance.jpg")
                                .default
                            }
                            style={{ borderRadius: "0px" }}
                            className={cookbookStyle}
                            alt=""
                          />
                        </Col>
                        <Col
                          style={{ padding: "0px", cursor: "pointer" }}
                          onClick={() => handleBrand(9)}
                        >
                          <img
                            src={
                              require("assets/custom/cook/converse.jpg").default
                            }
                            style={{ borderRadius: "0px" }}
                            className={cookbookStyle}
                            alt=""
                          />
                        </Col>
                      </Row>
                    </Carousel>
                  </Container>
                </Row>
              </Container>
            )}
            {mobile && (
              <Container style={{ ...containerStyle }}>
                <Row>
                  <Col xs="12">
                    {/* <h1>
                  <b className="text-white">Cookbook</b>
                </h1> */}
                    <div
                      className="title text-white"
                      style={{
                        ...responsiveHeadStyle,
                        // marginLeft: "3vw",
                        fontStyle: "italic",
                        fontWeight: "700",
                        textAlign: "center",
                      }}
                    >
                      Cookbook
                    </div>
                    <h4 style={{ color: "#dedede" }}>Shop by cuisine</h4>
                  </Col>

                  <Container fluid={fluid}>
                    <Carousel
                      showArrows={true}
                      autoPlay={true}
                      interval={6000}
                      showThumbs={false}
                      showIndicators={false}
                      showStatus={false}
                      infiniteLoop={true}
                    >
                      <Row>
                        <Col
                          style={{ paddingRight: "0px", cursor: "pointer" }}
                          onClick={() => handleBrand(0)}
                        >
                          <img
                            src={
                              require("assets/custom/cook/adidas.jpg").default
                            }
                            style={{ borderRadius: "0px" }}
                            className={cookbookStyle}
                            alt=""
                          />
                        </Col>
                        <Col
                          style={{ padding: "0px", cursor: "pointer" }}
                          onClick={() => handleBrand(1)}
                        >
                          <img
                            src={
                              require("assets/custom/cook/offwhite.jpg").default
                            }
                            style={{ borderRadius: "0px" }}
                            className={cookbookStyle}
                            alt=""
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          style={{ paddingRight: "0px", cursor: "pointer" }}
                          onClick={() => handleBrand(2)}
                        >
                          <img
                            src={
                              require("assets/custom/cook/yeezy.jpg").default
                            }
                            style={{ borderRadius: "0px" }}
                            className={cookbookStyle}
                            alt=""
                          />
                        </Col>
                        <Col
                          style={{ padding: "0px", cursor: "pointer" }}
                          onClick={() => handleBrand(3)}
                        >
                          <img
                            src={require("assets/custom/cook/nike.jpg").default}
                            style={{ borderRadius: "0px" }}
                            className={cookbookStyle}
                            alt=""
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          style={{ paddingRight: "0px", cursor: "pointer" }}
                          onClick={() => handleBrand(8)}
                        >
                          <img
                            src={require("assets/custom/cook/vans.jpg").default}
                            style={{ borderRadius: "0px" }}
                            className={cookbookStyle}
                            alt=""
                          />
                        </Col>
                        <Col
                          style={{ padding: "0px", cursor: "pointer" }}
                          onClick={() => handleBrand(4)}
                        >
                          <img
                            src={
                              require("assets/custom/cook/airjordan.jpg")
                                .default
                            }
                            style={{ borderRadius: "0px" }}
                            className={cookbookStyle}
                            alt=""
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          style={{ paddingRight: "0px", cursor: "pointer" }}
                          onClick={() => handleBrand(5)}
                        >
                          <img
                            src={
                              require("assets/custom/cook/balenciaga.jpg")
                                .default
                            }
                            style={{ borderRadius: "0px" }}
                            className={cookbookStyle}
                            alt=""
                          />
                        </Col>
                        <Col
                          style={{ padding: "0px", cursor: "pointer" }}
                          onClick={() => handleBrand(6)}
                        >
                          <img
                            src={
                              require("assets/custom/cook/supreme.jpg").default
                            }
                            style={{ borderRadius: "0px" }}
                            className={cookbookStyle}
                            alt=""
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          style={{ paddingRight: "0px", cursor: "pointer" }}
                          onClick={() => handleBrand(7)}
                        >
                          <img
                            src={
                              require("assets/custom/cook/newbalance.jpg")
                                .default
                            }
                            style={{ borderRadius: "0px" }}
                            className={cookbookStyle}
                            alt=""
                          />
                        </Col>
                        <Col
                          style={{ padding: "0px", cursor: "pointer" }}
                          onClick={() => handleBrand(9)}
                        >
                          <img
                            src={
                              require("assets/custom/cook/converse.jpg").default
                            }
                            style={{ borderRadius: "0px" }}
                            className={cookbookStyle}
                            alt=""
                          />
                        </Col>
                      </Row>
                    </Carousel>
                  </Container>
                </Row>
              </Container>
            )}
            <BehindTheShoes />
            <Container
              // fluid={fluid}
              // // style={{ marginBottom: "15vh", marginTop: mobile && "10vh" }}
              // style={{
              //   marginTop: mobile && "15vw",
              //   marginBottom: !mobile ? "7.5vw" : "15vw",
              // }}
              style={{ ...containerStyle }}
              fluid={fluid}
            >
              <Row>
                <Col xs="12">
                  {/* <h1>
                <b
                  className="text-white"
                  style={{ fontFamily: "helveticaneuebold" }}
                >
                  SneakerSwipe
                </b>
              </h1> */}
                  <div
                    className="title text-white"
                    style={{
                      ...responsiveHeadStyle,
                      // marginLeft: "3vw",
                      fontStyle: "italic",
                      fontWeight: "700",
                      textAlign: "center",
                    }}
                  >
                    SneakerSwipe
                  </div>
                </Col>
              </Row>
              {mobile && (
                <>
                  <Col style={{ padding: "0px" }}>
                    <video
                      width="100%"
                      id="landingVid"
                      autoPlay
                      loop
                      muted
                      playsInline
                      style={{
                        zIndex: 1,
                      }}
                      position="relative"
                      onTimeUpdate={(e) => toggleScrollDown(e)}
                    >
                      {/* First tries to play hevc video, then falls back to webm format, 
                then falls back to mp4 format with h264 codec which is widely supported
                Awesome resource for compression and type conversion: 
                https://convertio.co/
                
                Note: HEVC OR H265 files from this website does not work 
                on Apple devices: https://www.freeconvert.com/video-compressor */}

                      {/* hevc codec supported by Apple devices. Highly compressed, small size */}
                      <source
                        src={require("assets/custom/sneakerswipe.mp4").default}
                        type="video/mp4; codecs=hvc1"
                      ></source>
                      {/* webm supported by everything besides Apple. Highly compressed, small size */}
                      <source
                        src={require("assets/custom/sneakerswipe.webm").default}
                        type="video/webm; codecs=vp9"
                      ></source>
                      {/* mp4 video encoded with h264. Widely supported */}
                      <source
                        src={require("assets/custom/sneakerswipe.mp4").default}
                        type="video/mp4"
                      ></source>
                    </video>
                  </Col>
                  <Col style={{ marginTop: "2vh", textAlign: "center" }}>
                    <p style={{ marginTop: "2vh", marginBottom: "2vh" }}>
                      Check out SneakerSwipe below to participate in our free
                      Giveaway.
                    </p>
                    <Button
                      color="green"
                      onClick={() => openSneakerswipe()}
                      size={btnSize}
                      style={{ ...joinStyle }}
                    >
                      SneakerSwipe
                    </Button>
                  </Col>
                </>
              )}
              {!mobile && (
                <>
                  <Col style={{ padding: "0px", textAlign: "center" }}>
                    <video
                      width="100%"
                      id="landingVid"
                      autoPlay
                      loop
                      muted
                      playsInline
                      style={{
                        zIndex: 1,
                      }}
                      position="relative"
                      onTimeUpdate={(e) => toggleScrollDown(e)}
                    >
                      {/* First tries to play hevc video, then falls back to webm format, 
                then falls back to mp4 format with h264 codec which is widely supported
                Awesome resource for compression and type conversion: 
                https://convertio.co/
                
                Note: HEVC OR H265 files from this website does not work 
                on Apple devices: https://www.freeconvert.com/video-compressor */}

                      {/* hevc codec supported by Apple devices. Highly compressed, small size */}
                      <source
                        src={require("assets/custom/sneakerswipe.mp4").default}
                        type="video/mp4; codecs=hvc1"
                      ></source>
                      {/* webm supported by everything besides Apple. Highly compressed, small size */}
                      <source
                        src={require("assets/custom/sneakerswipe.webm").default}
                        type="video/webm; codecs=vp9"
                      ></source>
                      {/* mp4 video encoded with h264. Widely supported */}
                      <source
                        src={require("assets/custom/sneakerswipe.mp4").default}
                        type="video/mp4"
                      ></source>
                    </video>
                  </Col>
                  <Col style={{ textAlign: "center", marginTop: "3vh" }}>
                    <p style={{ marginTop: "2vh", marginBottom: "2vh" }}>
                      Check out SneakerSwipe below to participate in our free
                      Giveaway.
                    </p>
                    <Button
                      color="green"
                      onClick={() => openSneakerswipe()}
                      size={btnSize}
                      style={{ ...joinStyle }}
                    >
                      SneakerSwipe
                    </Button>
                  </Col>
                </>
              )}
            </Container>
            <Footer />
          </>
        )}
      </div>
    </>
  );
}
