import React from "react";
import "./ComingSoon.css";
const ComingSoon = () => {
  return (
    <div style={{ background: "black" }}>
      <div className="csContainer">
        <div className="sidecontent">
          <img
            className="lock"
            src={require("assets/custom/lock2.png").default}
            alt=""
          />
          <h1 className="comingsoonH1">MYSTRY</h1>
          <h3 className="comingsoonH3">Coming Soon.</h3>
        </div>
        <div className="image">
          <img
            className="sideimg"
            src={require("assets/custom/jordanwall.jpg").default}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
