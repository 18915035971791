import React from "react";
import Navbar from 'components/Navbars/Navbar.js';
import Footer from 'components/Footer/Footer';
import { Container } from "@material-ui/core";
import OpenPositions from "./OpenPositions";

const Careers = () => {
    return (
        <>
        <Navbar />
        <div 
            style={{
            marginTop: "100px",
            marginBottom: "5vh",
            alignItems: "center",
            textAlign: "center",
            }}
        >
            <h1>Careers</h1>
        </div>
        <Container style={{ marginBottom: "75px" }}>
            {/* <img 
                alt="..."
                className="img-center img-fluid pb-5"
                src={require("assets/custom/Careers.jpeg").default}
                width="600" height="300"
            /> */}
            <div>Apply here: email the position tittle, and a small paragraph or two about why you want to be in the sneaker-tech space, and why you’d be a good fit for this position, along with your resume, to <a href="mailto: contact@mystry.world" style={{color:"#00b200"}}>contact@mytry.world</a></div>
            <h3 style={{marginTop: "50px", marginBottom: "75px"}}>Open Positions</h3>
            <OpenPositions/>
        </Container>
        <Footer />
        </>
    );
};

export default Careers;
