import React, { useState } from "react";
import { useAuth } from "contexts/AuthContext";
import { useHistory } from "react-router";
import "./ShoeBid.css";
import useWindowDimensions from "hooks/useWindowDimensions";
import { useSelector } from "react-redux";
import { auth } from "../../../firebase.js";
import {
  Row,
  Col,
  FormGroup,
  Form,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter
} from "reactstrap";
import states from "states-us";
import { Link } from "react-router-dom";
import ModalLogin from "./ModalLoginSignup/ModalLogin";
import ModalSignUp from "./ModalLoginSignup/ModalSignUp";
import ModalLoginWith from "./ModalLoginSignup/ModalLoginWith";

var _ = require("lodash");

const ShoeCheckoutModal = () => {
  const [loading, setLoading] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [state, setState] = useState("State*");
  const [phoneCountryCode, setPhoneCountryCode] = useState("+1");
  const [zip, setZip] = useState("");
  const [addCheckoutModal, setAddCheckoutModal] = useState(false);
  const [addLoginModal, setAddLoginModal] = useState(false);
  const [addConfirmModal, setAddConfirmModal] = useState(false);
  const confirmToggle = () => setAddConfirmModal(!addConfirmModal);
  const checkoutToggle = () => setAddCheckoutModal(!addCheckoutModal);
  const loginToggle = () => setAddLoginModal(!addLoginModal);
  const [addSignUpModal, setAddSignUpModal] = useState(false);
  const signUpToggle = () => setAddSignUpModal(!addSignUpModal);
  const [guest, setGuest] = useState(null);
  const { anon_login } = useAuth();

  const { width, height } = useWindowDimensions();
  var mobile, landscape;
  if (width < 768) {
    mobile = true;
    landscape = false;
  } else if (height < 576 || width < 992) {
    mobile = false;
    landscape = true;
  } else {
    mobile = false;
    landscape = false;
  }

  const { loggedIn } = useAuth();
  var shoeBody = [];
  useSelector((state) => {
    shoeBody = [];
    state.cart.cartShoes.forEach((item) => {
      shoeBody.push({
        id: item.shoe.shoeID,
        quantity: item.quantity,
        size: String(item.size).replace(".", "_"),
      });
    });
  });

  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const history = useHistory();

  function logout() {
    setLoading(true);
    return auth.signOut();
  }

  const handleLoginModal = (e) => {
    e.preventDefault();
    setAddCheckoutModal(false);
    loginToggle();
  }

  const handleSignUpModal = (e) => {
    e.preventDefault();
    setAddLoginModal(false);
    signUpToggle();
  }

  const handleConfirm = (e) => {
    e.preventDefault();
    anon_login().then((authUser) => {
      var guestObj = guest;
      guestObj.userID = authUser.user.uid;
      fetch(
        "https://mystry-server-z574yasx7q-uc.a.run.app/create-checkout-session",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            items: shoeBody,
            customerID: authUser.user.uid,
            isBid: false,
            customer: guestObj,
          }),
        }
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          return Promise.reject(response);
        })
        .then((responseText) => {
          window.location.replace(responseText["url"]);

        })
        .catch((error) => {
          history.push({ pathname: `/failurePayment` });
          console.error(error);
        });
      history.push({
        pathname: `/payment`,
        state: { userInfo: guestObj },
      });
      logout();
    }
    );
  };

  const handleCheckoutAsGuest = (e) => {
    e.preventDefault();
    if (state === "State*") {
      alert("Please select a valid US state.");
    } else if (!emailRegex.test(String(email).toLowerCase())) {
      alert("Please enter a valid email address");
    } else {
      var guestObj = {
        email,
        name: { firstName, lastName },
        phone: { phoneCountryCode, phoneNumber: phone },
        address: {
          firstName,
          lastName,
          address1,
          address2,
          city,
          state,
          zip,
        },
        guest: true,
      };
      setGuest(guestObj);
      confirmToggle();
      setAddCheckoutModal(false);
    }
  };

  const handleLoginSignUp = () => {
    history.push({
      pathname: `/login`,
    });
  };

  return (
    <>
        <Col
          xs="5"
          className="shoeProductDetails checkoutCol placeBidButton"
          onClick={() => {
            if (!loggedIn) {
              setAddCheckoutModal(true);
            } 
          }}
        >
          Checkout
        </Col>
        {addCheckoutModal && <Modal
          isOpen={addCheckoutModal}
          toggle={checkoutToggle}
          className="notifyModal guestModal"
          id="guest-modal"
        >
          {!mobile && (
            <>
            <div className="modal-header cart-modal">
                <h3 className="mb-4 modal-title" style={{ color: "white" }}>
                  Continue as guest
                </h3>
                <button
                  type="button"
                  className="login_signup_btn m-0"
                  data-dismiss="modal"
                  aria-hidden="true"
                  onClick={handleLoginModal}
                  style={{
                    border:"0px",
                    borderRadius:"10px",
                    width:"150px", 
                    height:"40px", 
                    padding:"0rem", 
                    fontWeight:"bold"
                  }}
                >
                  Login/Sign up
                </button>
              </div>
            </>
          )}
          {mobile && (
            <>
            <div className="modal-header cart-modal">
              <h3 className="mb-4 modal-title" style={{ color: "white" }}>
                Continue as guest
              </h3>
              </div>
              <div className="cart-modal">
              <button
                type="button"
                className="login_signup_btn ml-4"
                data-dismiss="modal"
                aria-hidden="true"
                onClick={handleLoginModal}
                style={{borderRadius:"25px", width:"150px", height:"35px", padding:"0rem", fontWeight:"bold"}}
              >
                Login/Sign up
              </button>
              </div>
            </>
          )}
          <ModalBody className="cart-modal">
            <Form onSubmit={handleCheckoutAsGuest}>
              <FormGroup>
                <Row>
                  <Col xs={6}>
                    <Input
                      type="text"
                      name="firstName"
                      id="firstName"
                      placeholder="First Name*"
                      style={{ backgroundColor: "#1B1B1B" }}
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                    />
                  </Col>
                  <Col xs={6}>
                    <Input
                      type="text"
                      name="lastName"
                      id="lastName"
                      placeholder="Last Name*"
                      style={{ backgroundColor: "#1B1B1B" }}
                      onChange={(e) => setLastName(e.target.value)}
                      required
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email*"
                    style={{ backgroundColor: "#1B1B1B" }}
                    autoComplete="username"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </FormGroup>
              <FormGroup>
                <Row>
                  <Col xs={12}>
                    <Input
                      type="text"
                      name="address1"
                      id="address1"
                      placeholder="Address 1*"
                      style={{ backgroundColor: "#1B1B1B" }}
                      onChange={(e) => setAddress1(e.target.value)}
                      required
                      maxLength={100}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col xs={12}>
                    <Input
                      type="text"
                      name="address2"
                      id="address2"
                      placeholder="Address 2"
                      style={{ backgroundColor: "#1B1B1B" }}
                      onChange={(e) => setAddress2(e.target.value)}
                      maxLength={100}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col xs={6}>
                    <Input
                      type="text"
                      name="city"
                      id="city"
                      placeholder="City*"
                      style={{ backgroundColor: "#1B1B1B" }}
                      onChange={(e) => setCity(e.target.value)}
                      required
                      maxLength={50}
                    />
                  </Col>
                  <Col xs={6}>
                    <Input
                      type="select"
                      name="state"
                      id="state"
                      style={{ backgroundColor: "#1B1B1B" }}
                      onChange={(e) => setState(e.target.value)}
                      required
                    >
                      <option>State*</option>
                      {states.map((state, idx) => (
                        <option key={idx}>{state.abbreviation}</option>
                      ))}
                    </Input>
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Input
                  type="tel"
                  name="zip"
                  id="zip"
                  placeholder="Zip*"
                  style={{ backgroundColor: "#1B1B1B" }}
                  onChange={(e) => setZip(e.target.value)}
                  required
                  maxLength={10}
                />
              </FormGroup>
              <FormGroup>
                  <Row>
                    <Col>
                      <Input
                        type="tel"
                        name="phone"
                        id="phone"
                        placeholder="Phone number*"
                        style={{ backgroundColor: "#1B1B1B" }}
                        onChange={(e) => setPhone(e.target.value)}
                        title="Please only use numbers"
                        pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                        required
                      />
                    </Col>
                  </Row>
                </FormGroup>
              <div className="col-md-12 text-center mt-4 mb-2">
                <button 
                  type="submit" 
                  style={{backgroundColor:"white", width:"170px", height:"40px", borderRadius:"10px", fontWeight:"bold"}}  
                >
                  Checkout
                </button>
              </div>
            </Form>
          </ModalBody>
        </Modal>}
        {!loggedIn && (
          <Modal isOpen={addLoginModal} toggle={loginToggle} size="lg" className="notifyModal">
            <div className="modal-header">
              <h3
                className="modal-title"
                style={{ fontWeight: "bolder", color: "#ffffff" }}
              >
                Login{" "}
              </h3>
            </div>

            <ModalBody className="modal-body">
              <Row className="loginPageHeading">
                <Col>
                  <p style={{ fontSize: "14px", color: "#ffffff" }}>
                    If you don’t have an account, you can
                    <Link onClick={handleSignUpModal}>
                      <span style={{ color: "#00b200" }}>
                      {" "}Sign Up{" "}
                      </span>
                    </Link>
                    here
                  </p>
                  <Row>
                    <br />{" "}
                  </Row>
                  <ModalLogin/>
                  <ModalLoginWith/>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter></ModalFooter>
          </Modal>
        )}
        {!loggedIn && (
          <Modal isOpen={addSignUpModal} toggle={signUpToggle} size="lg" className="notifyModal">
            <div className="modal-header">
              <h3
                className="modal-title"
                style={{ fontWeight: "bolder", color: "#ffffff" }}
              >
                Sign Up{" "}
              </h3>
            </div>
            <ModalBody className="modal-body">
              <Row className="loginPageHeading">
                <Col>
                  <ModalSignUp/>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter></ModalFooter>
          </Modal>
        )}
        {guest && (
            <Modal
              isOpen={addConfirmModal}
              toggle={confirmToggle}
              className="confirmModal"
              id="confirm-modal"
            >
              <div className="modal-header cart-modal">
                <h3 className="modal-title" style={{ color: "white" }}>
                  Confirm details
                </h3>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                  onClick={confirmToggle}
                  style={{ top: "5px" }}
                >
                  <i className="tim-icons icon-simple-remove" />
                </button>
              </div>
              <ModalBody className="cart-modal">
                <div>
                  <div>{guest.name.firstName} {guest.name.lastName}</div>
                  <div>{guest.email}</div>
                  <div>{guest.address.address1}</div>
                  <div>{guest.address.address2}</div>
                  <div>{guest.address.city} {guest.address.state}</div>
                  <div>{guest.address.zip}</div>
                  <div>{guest.phone.phoneCountryCode} {guest.phone.phoneNumber}</div>
                </div>
                <br/>
                <Button size="sm" color="simple" onClick={confirmToggle}>
                  Cancel
                </Button>
                <Button
                  size="sm"
                  color="simple"
                  onClick={(e) => handleConfirm(e)}
                >
                  Confirm
                </Button>
              </ModalBody>
            </Modal>
        )}
    </>
  );
};

export default ShoeCheckoutModal;
