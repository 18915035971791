import React, { useState } from "react";
import { Input, FormGroup, Button, Form } from "reactstrap";
import { InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";

import "./Style.css";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "contexts/AuthContext";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import { Redirect, useLocation } from "react-router";

import VisibilityOff from "@material-ui/icons/VisibilityOff";

const Login = ({ checkout, membershipPage, tierSelected, sneakerswipe, waitlist }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { login, setLoading } = useAuth();
  const location = useLocation();
  const history = useHistory();
  //////console.log(tierSelected);
  toast.configure();
  const handleLogin = (e) => {
    e.preventDefault();
    // const loginObject = { email: email, password: password };
    login(email, password)
      .then((authUser) => {
        // ////console.log(currentUser, authUser);
        // if (!authUser.user.emailVerified) {
        //   history.push("/verify/again");
        // } else {
        // history.push("/");
        // }
        setLoading(true)
        location.state && location.state.shoe ?
          setLoading(false)
          : checkout
          ? history.replace("/checkout")
          : sneakerswipe 
          ?  history.replace({
              pathname: "/infoFormV2",
            })
          : membershipPage
          ? history.replace({
              pathname: "/infoFormV2",
            })
          : waitlist 
          ? history.replace('/infoFormV2')
          : window.history.go(0);
      })
      .catch((error) => {
        setLoading(false);
        if (error.code === "auth/wrong-password") {
          // alert("Your password is invalid.");
          alert("Your password is invalid.");
        } else {
          alert(error.message);
        }
        ////console.log(error);
      });
  };

  return (
    <div>
      <Form onSubmit={handleLogin}>
        <FormGroup>
          <Input
            type="email"
            name="email"
            id="email"
            placeholder="Email*"
            onChange={(e) => setEmail(e.target.value)}
            required={true}
          />
        </FormGroup>
        <FormGroup>
          <InputGroup>
            <Input
              placeholder="Password*"
              type={!showPassword ? "password" : "text"}
              name="loginPassword"
              id="loginPassword"
              autoComplete="current-password"
              onChange={(e) => setPassword(e.target.value)}
              required={true}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText style={{ padding: "0px" }}>
                <IconButton
                  style={{ padding: "0px", marginRight: "10px" }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <Visibility className="icons" style={{ fontSize: "18" }} />
                  ) : (
                    <VisibilityOff
                      className="icons"
                      style={{ fontSize: "18" }}
                    />
                  )}
                </IconButton>
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </FormGroup>

        <div
          className="showPasswordLogin"
          style={{ textAlign: "right", color: "#002b00" }}
        >
          <a href="/forgot-password">Forgot password?</a>
        </div>
        <Button color="green" type="submit">
          Login
        </Button>
      </Form>
    </div>
  );
};

export default Login;
