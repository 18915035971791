import React from "react";
import Faq from "react-faq-component";
import { Container, Row } from "reactstrap";
import Navbar from "components/Navbars/Navbar.js";
import { Link } from 'react-router-dom';
import Footer from "components/Footer/Footer.js";
import shoe from "../../../assets/custom/FAQ1.jpg";

const Faqpage = () => {
	const data = {
		rows: [
			{
				title: "How Does MystryBox Subscription service work? ",
				content: `<br/>Mystry offers luxury <span style=" text-decoration-line: underline">MystryBoxes</span> delivered to your doorstep once every month, and we deliver
         - 1, 2, or 3 sneakers - based on your Membership Tier. MystryBox memberships are billed on a subscription basis. 
         You will be billed in advance on a recurring basis based on the plan. Subscriptions are currently only available to
          residents of USA & Canada. Subscriptions will automatically renew until MYSTRY or the customer cancels the membership.<br/> &nbsp; `,
			},
			{
				title: "How do I skip the MystryBox waitlist?",
				content: `<br/>The current wait time to avail the MystryBox membership is 30-45 days. We do have a long waitlist, 
        and it could be longer than 45 days depending on your location. The only way to get immediate access into the MystryBox
         membership is to avail a Mystry referral code that is shared with an existing customer already using our service.Each 
         customer on the MystryBox Membership receives only one Mystry referral code.<br/> &nbsp; `,
			},
			{
				title: " Is there a cancelation fee on the annual membership? ",
				content: `<br>You can cancel your MystryBox membership at any point in the billing cycle. <span style=" font-weight:bold">There is no cancellation fee.</span>
         If you cancel the membership after the said month’s billing cycle has elapsed, you shall receive your MystryBox for 
         the month, and your membership will be terminated thereafter. <span style="text-decoration:underline ">If you would like to reactivate your Mystry Box
          Membership, you will have to rejoin the waitlist again.</span> If you have further questions, please contact us at 
          <span style=" color:#00b200">contact@mystry.world</span><br/> &nbsp; `,
			},
			{
				title: "When do the packages get delivered? ",
				content: `<br/>-<span style=" font-weight:bold">The delivery fee for MystryBox is included in the subscription fee. You’re welcome. </span>
         <br/><br/>-Orders will be dispatched once payment has been approved and delivery address has been verified by us.  
         <br/><br/>-Tracking numbers will be provided once the order is dispatched. All orders are aimed to be <span style=" font-weight:bold;text-decoration:underline">dispatched within
          3-5 working days from order confirmation.</span><br/><br/>-Customer may be contacted if there is lack of required information to
           com-plete the order. If said information is not provided during a reasonable time or the information is incorrect,
            the contract will be ended.<br/><br/> -All Mystry orders are insured against theft and accidental damage during delivery until
             they are delivered. <span style=" text-decoration:underline">If customer is unavailable, the package will be attempted to be delivered two more times.
              </span>(tentative) <br/> &nbsp; `,
			},
			{
				title: " How do you assure authenticity on your merchandise? ",
				content: `<br/>MYSTRY strives to be one of the most reliable and user-centric platforms for buying luxury sneakers,
         and as such, we only source from the world’s premium boutiques and retailers, as well as our hand-picked network of 
         verified and highly reputable resellers. We also employ a team of individuals who directly work with and source from Nike,
          Adidas, SNKRS, CONFIRMED, as well as highly reputed cook groups across USA and EU.<br/><br/>  In order to FURTHER ensure
           authenticity during transit of your sneaker, all MYSTRY sneakers come with a <span style="font-style: italic;font-weight:bold">NFC Verification tag</span> that you can just 
           tap with your mobile phone and it will redirect you to our verification page. These NFC verification tags are unique
            to each shoe and they cannot be overwritten. <br/> &nbsp;`,
			},
			{
				title: "Does MYSTRY offer worldwide shipping?",
				content: `<br/>We currently ship only to USA and certain parts of Canada. Mystry does not offer worldwide shipping yet,
         but we will start expanding global shipping services by December, 2021. <br/> &nbsp;`,
			},
			{
				title: "Do you process refunds or exchanges?",
				content: `<br/>Since we deal with high-end luxury products with anonymous price volatility,<span style="font-weight:bold"> we are unable to offer returns,
         exchanges, or swaps - including if you ordered the wrong size.</span> The good news is that, we always try to assure the best 
         prices in the market, so you can always look to resell in the after-market and possibly recoup, maybe even profit,
         on your purchase. <br/><br/><span style="font-style: italic"> Refunds for MystryBox:</span> We spend a lot of time and manpower in sourcing the right product 
         for your MystryBox at an unmatched price, and as such there are no returns, exchanges or swaps - including if you ordered 
         the wrong size. Please ensure that you select the right size before signing up for the MystryBox Membership. If 
         you realize that you must change/update your size for different brands, please contact us.<br/> &nbsp;`,
			},
			{
				title: "How do I contact customer service? ",
				content: `<br/>You can click on the Contact Us link on our website, or you can email us at<span style=" color:#00b200"> contact@mystry.world</span> . We will
         reach back to you in 1-3 business days.<br/> &nbsp; `,
			},
			{
				title: "How we offer 2-Factor authentication on all our Sneakers?",
				content: (
				  <ul><li>
				  We understand that this industry is all about trust, and we at Mystry Inc. strive to be one of the most trusted global marketplace for luxury sneakers!
		</li><li>
		1-Factor: We don’t let anyone/everyone sell on our platform! In order to eliminate all forms of fake sneakers, we only look to work with a handful of highly trusted Sneaker Chef’s who have years of experience in sourcing and reselling sneakers. We carry out a detailed screening process for all our resellers and vendors! 
		</li><li>
		
		2 Factor: Using proprietary digital authentication technology and machine learning, all our sneakers come with a Check-Check verification certification, where each sneaker has been digitally reviewed in detail by expert authenticators! Only once we have received a “PASS/Verified” certification on each pair, do we dispatch your sneakers.
		</li><li>
		
		Apart from offering the highest authenticity checks, we also try to deliver your sneakers within 3-5 working days. </li>
				  </ul>
				  ),
				},
				{
				  title: "How we source, authenticate, and dispatch your sneakers in less than 24 hours?",
				  content: (           
					<ul><li>We know you don’t want to wait 7-21 days to receive your sneakers! who does, right? and that’s why at Mystry, we strive to source, authenticate, and dispatch your sneakers all within 24 hours
		</li><li>
		
					Placing a bid on Mystry is absolutely free, and all bids last for exactly 24 hours 
					</li><li>
				 
					In this 24 hour time-frame, we try to work with our network of Sneaker Chef’s to source and authenticate your sneakers. Only once every sneaker passes our 2-Factor authentication standards, do we dispatch your sneakers. 
					</li><li>
				  
					If your bid amount is not accepted within 24 hours, or if we are not able to conclusively authenticate your sneakers, the payment transaction will be reversed - and we’ll issue a full refund on the same day.
					</li><li>
				  
					Your transaction will only be processed once we have sourced, authenticated, and are ready to  deliver your sneakers (3-5 Business days).</li></ul>
					),
				  },
				  {
					title: "How placing a bid is absolutely free?",
					content: (
					  <ul><li>
					  Placing a bid is absolutely free! All bids last for exactly 24 hours.</li><li>
			
					  If your bid is accepted by one of Sneaker Chef’s within 24 hours, only then will the transaction be processed .</li><li>
			
					  If your bid amount is not accepted within 24 hours, your order will expire worthless, and the payment transaction will be reversed -  you will not be charged any amount if the bid expires worthless. </li><li>
			
					  Once your bid has been accepted, we shall try our best to process, authenticate, and dispatch your sneakers all within 24 hours.</li><li>
			
					  Expected delivery time: 3-5 Business Days</li>
					  </ul>
					  ),
					},
					{
					  title: "Who are our Sneaker Chef’s?",
					  content: (           
						<div>
						  <br></br>
						 <br></br> 
						 <p>
						 Our aim is to be the most trusted global marketplace for luxury sneakers while still being able to offer the quickest delivery time period (3-5 Business Days) at no additional cost. 
			
						 <br></br><br></br>In order to ensure guaranteed authenticity on every pair,  Mystry Inc. partners with only the most highly reputable resellers and vendors across USA/Canada from numerous Boutique Stores and Cook Groups. We don’t just let anyone sell on Mystry - we have a very strong vetting process for all our resellers, and we conduct a detailed due diligence before each sneaker is dispatched. 
			
			<br></br><br></br>In fact, to offer the golden standard of authenticity, we work with Check-Check to ensure that each sneaker has been digitally reviewed in detail by expert authenticators! Only once we have received a “PASS/Verified” certification on each pair, do we dispatch your sneakers. If we find that the sneaker does not pass our verification standards, we will issue a full refund on the same day.
			
			<br></br><br></br>Click <a href="https://getcheckcheck.com/en/check_request/948791" style={{color:"#32CD32", fontWeight:"bold", cursor:"pointer"}}  >here</a> to view a sample “PASS” (verification) certification.
						 </p>
						</div>
						),
					  },
		],
	};
	const styles = {
		bgColor: "black",
		rowTitleColor: "#d6cece",
		rowContentColor: "white",
		arrowColor: "white",
	};
	return (
		<>
			<Navbar />
			<div
				className="wrapper"
				style={{
					marginTop: "10vh",
					marginBottom: "5vh",
					alignItems: "center",
					textAlign: "center",
					overflowX: "hidden",
				}}>
				<Row
					style={{
						justifyContent: "center",
					}}>
					<h2>Frequently Asked Questions</h2>
				</Row>
				<img src={shoe} className="img-fluid p-2" width="500" height="300"></img>
			</div>
			<Container
				fluid={true}
				style={{
					marginBottom: "10vh",
					paddingLeft: "10vw",
					paddingRight: "10vw",
				}}>
				<Faq data={data} styles={styles} />
			</Container>
			<Footer />
		</>
	);
};

export default Faqpage;