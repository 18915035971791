import React, { useState } from "react";
import { useAuth } from "contexts/AuthContext";
import "./ShoeBid.css";
import { Row, Col } from "reactstrap";
import ShoeCheckoutModal from "./ShoeCheckoutModal";

var _ = require("lodash");

const ShoeProductDescription = ({
  currentUser,
  shoe,
  getSizeStr,
  setBuyNowState,
  setSize,
  size,
  setButtonColor,
  setShowLogin,
  setShowPayment,
  setShowAddress,
}) => {
  
  const { loggedIn } = useAuth();
  const [addNewModal, setAddNewModal] = useState(false);

  const openCheckoutModal = () => {
    if(!loggedIn)
    return(
      <ShoeCheckoutModal/>
    );
  };

  return (
    <Col className="shoeBuyNowContainerCol">
      <Row className="shoeProductDetails">
        <h3
          style={{
            marginBottom: "15px",
          }}
        >
          {shoe.shoeName}
        </h3>
      </Row>
      <Row className="shoeProductDetails mb-5">
        <h4>{shoe.releaseYear}</h4>
      </Row>
      <div>
        <Row className="shoeRowBorder">
          <Col xs="6">
            <h4>Price</h4>
          </Col>
          <Col xs="6" className="shoeBuyNowCol">
            <h4>${shoe.shoeInventory[getSizeStr(size)].price}</h4>
          </Col>
        </Row>
        <Row className="shoeRowBorder">
          <Col xs="6">
            <h4>Size</h4>
          </Col>
          <Col xs="6" className="shoeBuyNowCol">
            <h4>
              US {size} M / {size + 1.5} W
            </h4>
          </Col>
        </Row>
        <Row className="shoeRowBorder">
          <Col xs="6">
            <h4>Color</h4>
          </Col>
          <Col xs="6" className="shoeBuyNowCol">
            <h4>{shoe.shoeColor}</h4>
          </Col>
        </Row>
        {/* HARDCODED */}
        <Row className="shoeRowBorder">
          <Col xs="6">
            <h4>Condition</h4>
          </Col>
          <Col xs="6" className="shoeBuyNowCol">
            <h4>New</h4>
          </Col>
        </Row>
        {/* HARDCODED */}
        <Row className="shoeRowBorder">
          <Col xs="6">
            <h4>Box</h4>
          </Col>
          <Col xs="6" className="shoeBuyNowCol">
            <h4>Good Condition</h4>
          </Col>
        </Row>
      </div>
      <Row
        className="shoeProductDetails mb-5"
        style={{ marginTop: "10%"}}
      >
        <div style={{ fontSize: ".875rem" }}>
          By proceeding, I confirm I have read and agree to the{" "}
          <a href="/terms" target="_blank">
            terms
          </a>
        </div>
      </Row>
      <Row style={{justifyContent: "center"}}>
        <Col
          xs="5"
          className="shoeProductDetails cancelButton mr-4"
          onClick={() => {
            setBuyNowState(false);
            setSize("");
            setButtonColor(_.fill(Array(21), "simple"));
          }}
        >
          <span style={{color: "#32CD32"}}>Cancel</span>
        </Col>
          {!loggedIn && <ShoeCheckoutModal/>}
          {
            loggedIn && <Col
              xs="5"
              className="shoeProductDetails checkoutCol placeBidButton"
              onClick={() => {
              {
                setBuyNowState(false);
                setButtonColor(_.fill(Array(21), "simple"));
                setShowLogin(false);
                setShowAddress(true);
                setShowPayment(false);
              }
            }}
        >
          Checkout
        </Col>
          }
      </Row>
    </Col>
  );
};

export default ShoeProductDescription;
