import React, {useRef} from 'react';
import "./MystryPlus.css";
import { useAuth } from "contexts/AuthContext";
import { useHistory } from 'react-router-dom';
import { MouseParallaxContainer, MouseParallaxChild } from 'react-parallax-mouse';

const MystryPlus = () => {

  const { loggedIn, currentUser } = useAuth();
  const history = useHistory();

  function joinWaitlist() {
    if (!loggedIn) {
      history.push({
        pathname: `/login`,
        state: {waitlist: true}
      });
    } else {
      history.push('/infoFormV2');
    }
  }

  return (
    <div class="parent-div">
      <img
        alt="mystry-plus"
        className="image1" 
        src={require("assets/custom/mystry_plus_bg.png").default}
      />
      <div className='image-2 floating d-flex justify-content-center'>
        <img 
          alt="mystry-plus"
          src={require("assets/custom/membership/Gold-Render-Square-removebg-md.png").default}
        />
      </div>   
      <div className='d-flex justify-content-center align-items-center'>
        <button 
          className="join-waitlist-btn" 
          onClick={() => joinWaitlist()}
        >
          Join Waitlist
        </button>
      </div>
    </div>
  );
};

export default MystryPlus;