import React, { useState } from "react";
import { Row, Input, FormGroup, Button, Form } from "reactstrap";
import { InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import "./Style.css";
import { useAuth } from "contexts/AuthContext";
import { userDb } from "../../firebase";
import { useHistory, useLocation } from "react-router";
import { toast } from "react-toastify";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import { userLoggedInSuccess } from "mystry_server";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

// edge cases: check if email is already used

const SignUp = ({ checkout, membershipPage, tierSelected, sneakerswipe, waitlist }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const { signup, setLoading } = useAuth();
  // const [ agree, setAgree ] = useState(false);

  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
   
  const location = useLocation();
  const history = useHistory();

  function pushMetaDataToFirebase(user) {
    const ref = userDb(user.uid);
    const obj = {
      userID: user.uid,
      email: email,
    };
    // check if user is verified or not using user.emailVerified
    user.sendEmailVerification();
    ref.set(obj);
    // history.push(`/verify`);
  }

  // // https://stackoverflow.com/questions/57995978/why-is-cleartimeout-not-clearing-the-timeout-in-this-react-component
  // const [message, setMessage] = useState(["", ""]);
  // useEffect(() => {
  //   dispatch(setNotification(message[0], message[1]));
  //   const t = setTimeout(() => {
  //     setMessage(["", ""]);
  //   }, 5000);

  //   return () => {
  //     clearTimeout(t);
  //   };
  // }, [message, dispatch]);

  const handleSignUp = (e) => {
    e.preventDefault();
    if (password !== password2) {
      // alert("The passwords do not match.");
      // dispatch(setNotification("The passwords do not match", "error"));
      // timeout = setTimeout(() => {
      //   dispatch(setNotification());
      // }, 5000);
      toast.error("The passwords do not match");
    } else if (!emailRegex.test(String(email).toLowerCase())) {
      // alert("Please enter a valid email address");
      // dispatch(setNotification("Please enter a valid email address", "error"));
      // timeout = setTimeout(() => {
      //   dispatch(setNotification());
      // }, 5000);
      toast.error("Please enter a valid email address");
    } else {
      // else if (!agree) {
      // alert("Please agree to our terms and conditions");
      // dispatch(
      //   setNotification("Please agree to our terms and conditions", "error")
      // );
      // timeout2 = setTimeout(() => {
      //   dispatch(setNotification());
      // }, 5000);
      // toast.error('Please agree to our terms and conditions');
      //}
      // ////console.log(signup(email, password));
      signup(email, password)
        .then((authUser) => {
          setLoading(true);
          // ////console.log("success", authUser);
          pushMetaDataToFirebase(authUser.user);
          // if (!authUser.user.emailVerified) {
          //   history.push("/verify");
          // } else {
          // history.push("/");
          // }
          userLoggedInSuccess(email);
          location.state && location.state.shoe 
            ? setLoading(true)
            : checkout
            ? history.replace("/checkout")
            : sneakerswipe 
            ?  history.replace({
                pathname: "/infoFormV2",
              })
            : membershipPage
            ? history.replace({
                pathname: "/infoFormV2",
              })
            : waitlist 
            ? history.replace('/infoFormV2')
            : window.history.go(0);
        })
        .catch((error) => {
          // ////console.log("hello");
          ////console.log(error);
          // setLoading(false) was giving errors so removing setLoading from signup in AuthContext
          // setLoading(false);
          if (error.code === "auth/email-already-in-use") {
            toast.error("An account already exists with this email");
          } else {
            toast.error(error.message);
          }
        });
    }
  };

  // const checkIfUserExistsInFB = () => {
  //   // ////console.log("inside check user exists")
  //   const ref = dbUserReview(currentUser.uid);
  //   var length = 0;

  //   ref
  //     .once("value", function (snapshot) {
  //       ////console.log(snapshot);
  //       snapshot.forEach(function (childSnapshot) {
  //         length = length + 1;
  //       });
  //     })
  //     .then(function (bal) {
  //       // ////console.log(length);
  //       if (length === 30) {
  //         // ////console.log(currentUser.uid);
  //         // ////console.log(isResponsesSubmitted);
  //         setResponsesSubmitted(true);
  //         setModal(false);
  //       } else if (length > 0 && length < 30) {
  //         ref.remove();
  //       }
  //       setIsLoading(false);
  //     });
  // };

  // useEffect(() => {
  //   sign_up();
  //   // ////console.log(currentUser);
  //   if (currentUser !== null) {
  //     if (preventMultipleResponses) {
  //       checkIfUserExistsInFB();
  //     } else {
  //       setIsLoading(false);
  //     }
  //   }
  //   // eslint-disable-next-line
  // }, [currentUser]);

  return (
    <div>
      <Form onSubmit={handleSignUp}>
        {/* <FormGroup>
          <Row>
            <Col xs={6}>
              <Input
                type="text"
                name="firstName"
                id="firstName"
                placeholder="First*"
                onChange={(e) => setFirstName(e.target.value)}
                required={true}
              />
            </Col>
            <Col xs={6}>
              <Input
                type="text"
                name="lastName"
                id="lastName"
                placeholder="Last*"
                onChange={(e) => setLastName(e.target.value)}
                required={true}
              />
            </Col>
          </Row>
        </FormGroup> */}
        <FormGroup>
          <Input
            type="email"
            name="email"
            id="email"
            placeholder="Email*"
            autoComplete="username"
            onChange={(e) => setEmail(e.target.value)}
            required={true}
          />
        </FormGroup>
        {/* <br /> */}
        {/* <Label for="password">Password: </Label> */}
        <FormGroup>
          <InputGroup>
            <Input
              type={!showPassword ? "password" : "text"}
              name="password"
              id="password"
              placeholder="Password*"
              autoComplete="new-password"
              onChange={(e) => setPassword(e.target.value)}
              required={true}
              minLength="8"
              // reference: https://gist.github.com/HarishChaudhari/0dd5514ce430991a1b1b8fa04e8b72a4
              pattern="^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[\w!@#$%^&*]{8,}$"
              title="Password must have at least 8 characters and must include at
                  least 1 lowercase, 1 uppercase, 1 number and one of !@#$%^&*"
            />
            <InputGroupAddon addonType="append">
              <InputGroupText style={{ padding: "0px" }}>
                <IconButton
                  style={{ padding: "0px", marginRight: "10px" }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <Visibility className="icons" style={{ fontSize: "18" }} />
                  ) : (
                    <VisibilityOff
                      className="icons"
                      style={{ fontSize: "18" }}
                    />
                  )}
                </IconButton>
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </FormGroup>

        <FormGroup>
          {/* <Label for="confirmPassword">Confirm password: </Label> */}
          <InputGroup>
            <Input
              type={!showPassword2 ? "password" : "text"}
              name="confirmPassword"
              id="confirmPassword"
              autoComplete="new-password"
              placeholder="Retype password*"
              onChange={(e) => setPassword2(e.target.value)}
              required={true}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText style={{ padding: "0px" }}>
                <IconButton
                  style={{ padding: "0px", marginRight: "10px" }}
                  onClick={() => setShowPassword2(!showPassword2)}
                >
                  {showPassword2 ? (
                    <Visibility className="icons" style={{ fontSize: "18" }} />
                  ) : (
                    <VisibilityOff
                      className="icons"
                      style={{ fontSize: "18" }}
                    />
                  )}
                </IconButton>
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </FormGroup>
        {/* <FormGroup>
          <Row>
            <Col xs={3}>
              <Input
                type="tel"
                name="countryCode"
                id="countryCode"
                placeholder="Code"
                onChange={(e) => setCountryCode(e.target.value)}
                value={countryCode}
              />
            </Col>
            <Col xs={9}>
              <Input
                type="tel"
                name="phone"
                id="phone"
                placeholder="Phone number"
                onChange={(e) => setPhone(e.target.value)}
                title="Please only use numbers"
                pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
              />
            </Col>
          </Row>
        </FormGroup> */}

        <Button type="submit" color="green">
          Sign up
        </Button>
        <Row>
          <br />{" "}
        </Row>
        <div className="showPasswordLogin">
          *Password must have at least 8 characters and must include at least 1
          lowercase, 1 uppercase, 1 number and one of !@#$%^&*
        </div>

        <div className="showPasswordLogin">
          *By creating an account you agree to our{" "}
          <a href="/terms" target="_blank" rel="noreferrer">
            terms and conditions .
          </a>
        </div>
        <div
          onClick={() => history.push({
            pathname: "/login", 
            state: {waitlist: location.state.waitlist}
          })}
          style={{
            marginTop: "2vh",
            cursor: "pointer",
            color: "#00b200",
          }}> 
          Already have an account
        </div>
        {/* <div className="showPasswordLogin">
          *By creating or having an account on this website, you are agreeing to
          our terms and conditions
        </div> */}
      </Form>
    </div>
  );
};

export default SignUp;