import React, { useState } from "react";
import { Container, Row, Col } from "reactstrap";
import ReactCardFlip from "react-card-flip";
import { Carousel } from "react-responsive-carousel";
import { btsData as data } from "../pagesV2/BTS/data.js";
import { Link } from "react-router-dom";
import useWindowDimensions from "hooks/useWindowDimensions";

const BehindTheShoes = () => {
  const [isFlipped, setFlipped] = useState([]);
  const { width, height } = useWindowDimensions();

  var mobile, landscape;
  if (width < 768) {
    mobile = true;
    landscape = false;
  } else if (height < 576 || width < 992) {
    mobile = false;
    landscape = true;
  } else {
    mobile = false;
    landscape = false;
  }

  const fluid = true;

  const responsiveHeadStyle = mobile
    ? { fontSize: "1.65rem" }
    : { fontSize: landscape ? "1.5rem" : "2vw" };

  const containerStyle = {
    marginBottom: "10vh",
    paddingLeft: !mobile && fluid ? "5vw" : "",
    paddingRight: !mobile && fluid ? "5vw" : "",
  };

  const reactCardFlipStyle = {
    // bottom:'0',
  };

  const handleGridClick = (idx) => {
    isFlipped[idx] = !isFlipped[idx];
    setFlipped([...isFlipped]);
  };

  return (
    <>
      <Container style={{ ...containerStyle }} fluid={fluid}>
        <Row>
          <Col xs="12">
            <div
              className="title text-white"
              style={{
                ...responsiveHeadStyle,
                marginTop: "10vh",
                marginBottom: "5vh",
                textAlign: "center",
                fontSize: "40px",
                fontWeight: "600",
              }}
            >
              Behind The Shoe
            </div>
          </Col>
        </Row>
        {!mobile && (
          <Container fluid={fluid}>
            <Carousel
              showArrows={true}
              autoPlay={true}
              interval={6000}
              showThumbs={false}
              showIndicators={false}
              showStatus={false}
              infiniteLoop={true}
            >
              <Row>
                <Col
                  style={{ paddingRight: "0px", cursor: "pointer" }}
                  onClick={() => handleGridClick(0)}
                >
                  <ReactCardFlip
                    isFlipped={isFlipped[0]}
                    containerStyle={reactCardFlipStyle}
                  >
                    <div>
                      <img
                        style={{
                          display: "block",
                          width: "100%",
                          justifyContent: "center",
                          marginTop: "auto",
                        }}
                        src={
                          require("assets/custom/bts/TheNikeSBDunks.jpeg")
                            .default
                        }
                        alt={`Shoe number`}
                      />
                    </div>
                    <div
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      <h2>The Nike SB Dunks | Travis Scott</h2>
                      <p>
                        This iconic collaboration between Nike and Travis Scott
                        led to one of the most highly anticipated sneakers of
                        all time. This pair uses details and patterns that are
                        inspired by his hometown of Houston, Texas. The release
                        of this influential shoe had a strong impact on the
                        resurgence of the historical SB Dunks. It is a staple of
                        the sneaker industry and culture, which even grabs the
                        attention of a non-sneaker head.
                      </p>
                    </div>
                  </ReactCardFlip>
                </Col>
                <Col
                  style={{ paddingRight: "0px", cursor: "pointer" }}
                  onClick={() => handleGridClick(1)}
                >
                  <ReactCardFlip
                    isFlipped={isFlipped[1]}
                    containerStyle={reactCardFlipStyle}
                  >
                    <div>
                      <img
                        style={{
                          display: "block",
                          width: "100%",
                          justifyContent: "center",
                          marginTop: "auto",
                        }}
                        src={
                          require("assets/custom/bts/PharrellAdidas.jpeg")
                            .default
                        }
                        alt={`Shoe number`}
                      />
                    </div>
                    <Col
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      <h2>Pharrell X Chanel X Adidas HU NMD</h2>
                      <p>
                        Pharrell Williams’s Chanel Sneakers Are the Logical
                        Culmination of His Collaboration With Karl Lagerfeld.
                        None of the many Pharrell collaborations were more
                        legendary than this ultra-rare adidas NMD Hu Trail in
                        collaboration with famed fashion house Chanel. The
                        resale value of the pair shot up to $32,000 after some
                        120,000 people pre-registered on the website
                        chanelatcolette.fr for the opportunity to buy one of the
                        500 pairs.
                      </p>
                      {/* <h2 >The Air Jordan 1 Retro High University Blue</h2>
                                <p>This iconic pair of sneakers is a tribute to the University of North Carolina where the creator of the shoe-line himself, Michael Jordan, 
                                  attended from 1981-1984.
                                  This powdered blue colour way is only limited to 12,000 pairs in the world and sells for 3 times its market value.</p> */}
                    </Col>
                  </ReactCardFlip>
                </Col>
                <Col
                  style={{ paddingRight: "0px", cursor: "pointer" }}
                  onClick={() => handleGridClick(2)}
                >
                  <ReactCardFlip
                    isFlipped={isFlipped[2]}
                    containerStyle={reactCardFlipStyle}
                  >
                    <div>
                      <img
                        style={{
                          display: "block",
                          width: "100%",
                          justifyContent: "center",
                          marginTop: "auto",
                        }}
                        src={
                          require("assets/custom/bts/Hyper Crimson.jpeg")
                            .default
                        }
                        alt={`Shoe number`}
                      />
                    </div>
                    <Col
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      <h2>Jordan 1 Retro High Hyper Crimson</h2>
                      <p>
                        The trailer for “Spider-Man: No Way Home,” is filled
                        with nostalgic references for longtime fans of the
                        franchise, from Toby Maguire to the resurgence of Doctor
                        Octopus. But at the end of the trailer, sneakerheads got
                        a shoutout as well, as Peter Parker was seen wearing a
                        pair of of Air Jordan 1s ‘Hyper Crimson’ which takes its
                        name from the the shoe’s crimson-colored collar. This
                        January 2019 release features gray suede overlays and a
                        gray leather base
                      </p>
                    </Col>
                  </ReactCardFlip>
                </Col>

                <Col
                  style={{ paddingRight: "0px", cursor: "pointer" }}
                  onClick={() => handleGridClick(3)}
                >
                  <ReactCardFlip
                    isFlipped={isFlipped[3]}
                    containerStyle={reactCardFlipStyle}
                  >
                    <div
                    // style={{
                    //     display: 'flex',
                    //     // width: '80%',
                    //     // height: '80%',
                    //     alignItems:'flex-end',
                    //     justifyContent:'center'
                    //   }}
                    >
                      <img
                        style={{
                          display: "block",
                          width: "100%",
                          justifyContent: "center",
                          marginTop: "auto",
                        }}
                        src={
                          require("assets/custom/bts/University_Blue.jpeg")
                            .default
                        }
                        alt={`Shoe number`}
                      />
                    </div>
                    <Col
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      <h2>The Air Jordan 1 Retro High University Blue</h2>
                      <p>
                        This iconic pair of sneakers is a tribute to the
                        University of North Carolina where the creator of the
                        shoe-line himself, Michael Jordan, attended from
                        1981-1984. This powdered blue colour way is only limited
                        to 12,000 pairs in the world and sells for 3 times its
                        market value.
                      </p>
                      {/* <h2>Jordan 1 Retro High Hyper Crimson</h2>
                                <p>
                                The trailer for “Spider-Man: No Way Home,” is filled with nostalgic references for longtime fans of the franchise, from Toby Maguire to the resurgence of Doctor Octopus.
                                But at the end of the trailer, sneakerheads got a shoutout as well, as Peter Parker was seen wearing a pair of of Air Jordan 1s ‘Hyper Crimson’ which takes its name from the the shoe’s crimson-colored collar.
                                This January 2019 release features gray suede overlays and a gray leather base
                                </p> */}
                    </Col>
                  </ReactCardFlip>
                </Col>
                <Col
                  style={{ paddingRight: "0px", cursor: "pointer" }}
                  onClick={() => handleGridClick(4)}
                >
                  <ReactCardFlip
                    isFlipped={isFlipped[4]}
                    containerStyle={reactCardFlipStyle}
                  >
                    <div>
                      <img
                        style={{
                          display: "block",
                          width: "100%",
                          justifyContent: "center",
                          marginTop: "auto",
                        }}
                        src={
                          require("assets/custom/bts/Air Jordan 1 Retro High OG ‘Dark Mocha’.jpeg")
                            .default
                        }
                        alt={`Shoe number`}
                      />
                    </div>
                    <Col
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      <h2>Air Jordan 1 Retro High OG ‘Dark Mocha’</h2>
                      <p>
                        The Jordan brand continued their Black Toe design theme
                        in 2020 and released the Jordan 1 High Dark Mocha. The
                        Dark Mocha 1 was one of the most anticipated releases in
                        2020 due to its familiar colorblocking that referenced
                        two of the greatest Jordan 1s of all-time, the Jordan 1
                        Travis Scott and the Jordan 1 Black Toe. The pair are
                        now one of the most iconic Jordan releases to date.
                      </p>
                    </Col>
                  </ReactCardFlip>
                </Col>
              </Row>
              <Row>
                <Col
                  style={{ paddingRight: "0px", cursor: "pointer" }}
                  onClick={() => handleGridClick(6)}
                >
                  <ReactCardFlip
                    isFlipped={isFlipped[6]}
                    containerStyle={reactCardFlipStyle}
                  >
                    <div>
                      <img
                        style={{
                          display: "block",
                          width: "100%",
                          justifyContent: "center",
                          marginTop: "auto",
                        }}
                        src={
                          require("assets/custom/bts/Yeezy Foam Runner.jpg")
                            .default
                        }
                        alt={`Shoe number`}
                      />
                    </div>
                    <div
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      <h2>Yeezy Foam Runner</h2>
                      <p>
                        The sneaker prototype was manufactured in Atlanta from a
                        combination of petroleum-based ethylene-vinyl acetate
                        and foam produced from algae. By switching to more
                        eco-friendly materials and processes, players in the
                        fashion industry can do their part to stop this harm
                        from befalling the planet — one ugly sneaker at a time.
                        We may soon see the silhouette exceed even Crocs, thanks
                        to an impending variety and its affordable retail price
                        point. Kanye’s latest venture into the sneaker verse
                        proves to be yet another surprise, but the hype for the
                        foam runners is only on the rise.
                      </p>
                    </div>
                  </ReactCardFlip>
                </Col>
                <Col
                  style={{ paddingRight: "0px", cursor: "pointer" }}
                  onClick={() => handleGridClick(7)}
                >
                  <ReactCardFlip
                    isFlipped={isFlipped[7]}
                    containerStyle={reactCardFlipStyle}
                  >
                    <div>
                      <img
                        style={{
                          display: "block",
                          width: "100%",
                          justifyContent: "center",
                          marginTop: "auto",
                        }}
                        src={
                          require("assets/custom/bts/Nike Blazer Mid ‘77 vintage.jpg")
                            .default
                        }
                        alt={`Shoe number`}
                      />
                    </div>
                    <div
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      <h2>Nike Blazer Mid ‘77 vintage</h2>
                      <p>
                        Nike was the new kid on the block in the 1970s. They
                        were still breaking into the basketball scene and
                        testing prototypes on the feet of their local squad as
                        they were so new. Of course, the design evolved
                        throughout time, but the name remained. The Nike Blazer
                        Mid '77 Vintage has been a classic since its inception.
                        These standout as one of the very few Mids that are
                        popular among sneaker heads due it being a total
                        package.
                      </p>
                    </div>
                  </ReactCardFlip>
                </Col>
                <Col
                  style={{ paddingRight: "0px", cursor: "pointer" }}
                  onClick={() => handleGridClick(8)}
                >
                  <ReactCardFlip
                    isFlipped={isFlipped[8]}
                    containerStyle={reactCardFlipStyle}
                  >
                    <div>
                      <img
                        style={{
                          display: "block",
                          width: "100%",
                          justifyContent: "center",
                          marginTop: "auto",
                        }}
                        src={
                          require("assets/custom/bts/TheNikeSBDunks.jpeg")
                            .default
                        }
                        alt={`Shoe number`}
                      />
                    </div>
                    <div
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      <h2>The Nike SB Dunks | Travis Scott</h2>
                      <p>
                        This iconic collaboration between Nike and Travis Scott
                        led to one of the most highly anticipated sneakers of
                        all time. This pair uses details and patterns that are
                        inspired by his hometown of Houston, Texas. The release
                        of this influential shoe had a strong impact on the
                        resurgence of the historical SB Dunks. It is a staple of
                        the sneaker industry and culture, which even grabs the
                        attention of a non-sneaker head.
                      </p>
                    </div>
                  </ReactCardFlip>
                </Col>
                <Col
                  style={{ paddingRight: "0px", cursor: "pointer" }}
                  onClick={() => handleGridClick(9)}
                >
                  <ReactCardFlip
                    isFlipped={isFlipped[9]}
                    containerStyle={reactCardFlipStyle}
                  >
                    <div>
                      <img
                        style={{
                          display: "block",
                          width: "100%",
                          justifyContent: "center",
                          marginTop: "auto",
                        }}
                        src={
                          require("assets/custom/bts/PharrellAdidas.jpeg")
                            .default
                        }
                        alt={`Shoe number`}
                      />
                    </div>
                    <Col
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      <h2>Pharrell X Chanel X Adidas HU NMD</h2>
                      <p>
                        Pharrell Williams’s Chanel Sneakers Are the Logical
                        Culmination of His Collaboration With Karl Lagerfeld.
                        None of the many Pharrell collaborations were more
                        legendary than this ultra-rare adidas NMD Hu Trail in
                        collaboration with famed fashion house Chanel. The
                        resale value of the pair shot up to $32,000 after some
                        120,000 people pre-registered on the website
                        chanelatcolette.fr for the opportunity to buy one of the
                        500 pairs.
                      </p>
                      {/* <h2 >The Air Jordan 1 Retro High University Blue</h2>
                                <p>This iconic pair of sneakers is a tribute to the University of North Carolina where the creator of the shoe-line himself, Michael Jordan, 
                                  attended from 1981-1984.
                                  This powdered blue colour way is only limited to 12,000 pairs in the world and sells for 3 times its market value.</p> */}
                    </Col>
                  </ReactCardFlip>
                </Col>
                <Col
                  style={{ paddingRight: "0px", cursor: "pointer" }}
                  onClick={() => handleGridClick(10)}
                >
                  <ReactCardFlip
                    isFlipped={isFlipped[10]}
                    containerStyle={reactCardFlipStyle}
                  >
                    <div>
                      <img
                        style={{
                          display: "block",
                          width: "100%",
                          justifyContent: "center",
                          marginTop: "auto",
                        }}
                        src={
                          require("assets/custom/bts/Hyper Crimson.jpeg")
                            .default
                        }
                        alt={`Shoe number`}
                      />
                    </div>
                    <Col
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      <h2>Jordan 1 Retro High Hyper Crimson</h2>
                      <p>
                        The trailer for “Spider-Man: No Way Home,” is filled
                        with nostalgic references for longtime fans of the
                        franchise, from Toby Maguire to the resurgence of Doctor
                        Octopus. But at the end of the trailer, sneakerheads got
                        a shoutout as well, as Peter Parker was seen wearing a
                        pair of of Air Jordan 1s ‘Hyper Crimson’ which takes its
                        name from the the shoe’s crimson-colored collar. This
                        January 2019 release features gray suede overlays and a
                        gray leather base
                      </p>
                    </Col>
                  </ReactCardFlip>
                </Col>
              </Row>
            </Carousel>
          </Container>
        )}
        {mobile && (
          <Container fluid={fluid}>
            <Carousel
              showArrows={true}
              autoPlay={true}
              interval={6000}
              showThumbs={false}
              showIndicators={false}
              showStatus={false}
              infiniteLoop={true}
            >
              <Row>
                <Col
                  style={{ cursor: "pointer" }}
                  onClick={() => handleGridClick(0)}
                >
                  <ReactCardFlip
                    isFlipped={isFlipped[0]}
                    containerStyle={reactCardFlipStyle}
                  >
                    <div>
                      <img
                        style={{
                          display: "block",
                          width: "100%",
                          justifyContent: "center",
                          marginTop: "auto",
                        }}
                        src={
                          require("assets/custom/bts/TheNikeSBDunks.jpeg")
                            .default
                        }
                        alt={`Shoe number`}
                      />
                    </div>
                    <div
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        verticalAlign: "middle",
                        paddingLeft: "10vw",
                        paddingRight: "10vw",
                      }}
                    >
                      <h2>The Nike SB Dunks | Travis Scott</h2>
                      <p>{data[0].para1}</p>
                      <Link
                        to={{
                          pathname: "/blogpage",
                          state: {
                            title1: data[0].title1,
                            title2: data[0].title2,
                            para1: data[0].para1,
                            para2: data[0].para2,
                            para3: data[0].para3,
                            image: data[0].image,
                            shoeLink: data[0].shoeLink,
                          },
                        }}
                      >
                        <button className="read-more-btn">Read More</button>
                      </Link>
                    </div>
                  </ReactCardFlip>
                </Col>
              </Row>
              <Row>
                <Col
                  style={{ cursor: "pointer" }}
                  onClick={() => handleGridClick(1)}
                >
                  <ReactCardFlip
                    isFlipped={isFlipped[1]}
                    containerStyle={reactCardFlipStyle}
                  >
                    <div>
                      <img
                        style={{
                          display: "block",
                          width: "100%",
                          justifyContent: "center",
                          marginTop: "auto",
                        }}
                        src={
                          require("assets/custom/bts/PharrellAdidas.jpeg")
                            .default
                        }
                        alt={`Shoe number`}
                      />
                    </div>
                    <Col
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        verticalAlign: "middle",
                        paddingLeft: "10vw",
                        paddingRight: "10vw",
                      }}
                    >
                      <h2>Pharrell X Chanel X Adidas HU NMD</h2>
                      <p>{data[1].para1}</p>
                      <Link
                        to={{
                          pathname: "/blogpage",
                          state: {
                            title1: data[1].title1,
                            title2: data[1].title2,
                            para1: data[1].para1,
                            para2: data[1].para2,
                            para3: data[1].para3,
                            image: data[1].image,
                            shoeLink: data[1].shoeLink,
                          },
                        }}
                      >
                        <button className="read-more-btn">Read More</button>
                      </Link>
                    </Col>
                  </ReactCardFlip>
                </Col>
              </Row>
              <Row>
                <Col
                  style={{ cursor: "pointer" }}
                  onClick={() => handleGridClick(2)}
                >
                  <ReactCardFlip
                    isFlipped={isFlipped[2]}
                    containerStyle={reactCardFlipStyle}
                  >
                    <div>
                      <img
                        style={{
                          display: "block",
                          width: "100%",
                          justifyContent: "center",
                          marginTop: "auto",
                        }}
                        src={
                          require("assets/custom/bts/Hyper Crimson.jpeg")
                            .default
                        }
                        alt={`Shoe number`}
                      />
                    </div>
                    <Col
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        verticalAlign: "middle",
                        paddingLeft: "10vw",
                        paddingRight: "10vw",
                      }}
                    >
                      <h2>Jordan 1 Retro High Hyper Crimson</h2>
                      <p>{data[2].para1}</p>
                      <Link
                        to={{
                          pathname: "/blogpage",
                          state: {
                            title1: data[2].title1,
                            title2: data[2].title2,
                            para1: data[2].para1,
                            para2: data[2].para2,
                            para3: data[2].para3,
                            image: data[2].image,
                            shoeLink: data[2].shoeLink,
                          },
                        }}
                      >
                        <button className="read-more-btn">Read More</button>
                      </Link>
                    </Col>
                  </ReactCardFlip>
                </Col>
              </Row>
              <Row>
                <Col
                  style={{ cursor: "pointer" }}
                  onClick={() => handleGridClick(3)}
                >
                  <ReactCardFlip
                    isFlipped={isFlipped[3]}
                    containerStyle={reactCardFlipStyle}
                  >
                    <div
                    // style={{
                    //     display: 'flex',
                    //     // width: '80%',
                    //     // height: '80%',
                    //     alignItems:'flex-end',
                    //     justifyContent:'center'
                    //   }}
                    >
                      <img
                        style={{
                          display: "block",
                          width: "100%",
                          justifyContent: "center",
                          marginTop: "auto",
                        }}
                        src={
                          require("assets/custom/bts/University_Blue.jpeg")
                            .default
                        }
                        alt={`Shoe number`}
                      />
                    </div>
                    <Col
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        verticalAlign: "middle",
                        paddingLeft: "10vw",
                        paddingRight: "10vw",
                      }}
                    >
                      <h2>The Air Jordan 1 Retro High University Blue</h2>
                      <p>{data[3].para1}</p>
                      <Link
                        to={{
                          pathname: "/blogpage",
                          state: {
                            title1: data[3].title1,
                            title2: data[3].title2,
                            para1: data[3].para1,
                            para2: data[3].para2,
                            para3: data[3].para3,
                            image: data[3].image,
                            shoeLink: data[3].shoeLink,
                          },
                        }}
                      >
                        <button className="read-more-btn">Read More</button>
                      </Link>
                    </Col>
                  </ReactCardFlip>
                </Col>
              </Row>
              {/* <Row>
                <Col
                  style={{ cursor: "pointer" }}
                  onClick={() => handleGridClick(4)}
                >
                  <ReactCardFlip
                    isFlipped={isFlipped[4]}
                    containerStyle={reactCardFlipStyle}
                  >
                    <div>
                      <img
                        style={{
                          display: "block",
                          width: "100%",
                          justifyContent: "center",
                          marginTop: "auto",
                        }}
                        src={
                          require("assets/custom/bts/Air Jordan 1 Retro High OG ‘Dark Mocha’.jpeg")
                            .default
                        }
                        alt={`Shoe number`}
                      />
                    </div>
                    <Col
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        verticalAlign: "middle",
                        paddingLeft: "10vw",
                        paddingRight: "10vw",
                      }}
                    >
                      <h2>Air Jordan 1 Retro High OG ‘Dark Mocha’</h2>
                      <p>
                        The Jordan brand continued their Black Toe design theme
                        in 2020 and released the Jordan 1 High Dark Mocha. The
                        Dark Mocha 1 was one of the most anticipated releases in
                        2020 due to its familiar colorblocking that referenced
                        two of the greatest Jordan 1s of all-time, the Jordan 1
                        Travis Scott and the Jordan 1 Black Toe. The pair are
                        now one of the most iconic Jordan releases to date.
                      </p>
                      <button className="read-more-btn">Read More</button>
                    </Col>
                  </ReactCardFlip>
                </Col>
              </Row> */}
              <Row>
                <Col
                  style={{ cursor: "pointer" }}
                  onClick={() => handleGridClick(5)}
                >
                  <ReactCardFlip
                    isFlipped={isFlipped[5]}
                    containerStyle={reactCardFlipStyle}
                  >
                    <div>
                      <img
                        style={{
                          display: "block",
                          width: "100%",
                          justifyContent: "center",
                          marginTop: "auto",
                        }}
                        src={
                          require("assets/custom/bts/Yeezy Foam Runner.jpg")
                            .default
                        }
                        alt={`Shoe number`}
                      />
                    </div>
                    <Col
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        verticalAlign: "middle",
                        paddingLeft: "10vw",
                        paddingRight: "10vw",
                      }}
                    >
                      <h2>Yeezy Foam Runner</h2>
                      <p>{data[4].para1}</p>
                      <Link
                        to={{
                          pathname: "/blogpage",
                          state: {
                            title1: data[4].title1,
                            title2: data[4].title2,
                            para1: data[4].para1,
                            para2: data[4].para2,
                            para3: data[4].para3,
                            image: data[4].image,
                            shoeLink: data[4].shoeLink,
                          },
                        }}
                      >
                        <button className="read-more-btn">Read More</button>
                      </Link>
                    </Col>
                  </ReactCardFlip>
                </Col>
              </Row>
              <Row>
                <Col
                  style={{ cursor: "pointer" }}
                  onClick={() => handleGridClick(6)}
                >
                  <ReactCardFlip
                    isFlipped={isFlipped[6]}
                    containerStyle={reactCardFlipStyle}
                  >
                    <div>
                      <img
                        style={{
                          display: "block",
                          width: "100%",
                          justifyContent: "center",
                          marginTop: "auto",
                        }}
                        src={
                          require("assets/custom/bts/Nike Blazer Mid ‘77 vintage.jpg")
                            .default
                        }
                        alt={`Shoe number`}
                      />
                    </div>
                    <Col
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        verticalAlign: "middle",
                        paddingLeft: "10vw",
                        paddingRight: "10vw",
                      }}
                    >
                      <h2>Nike Blazer Mid ‘77 vintage</h2>
                      <p>{data[5].para1}</p>
                      <Link
                        to={{
                          pathname: "/blogpage",
                          state: {
                            title1: data[5].title1,
                            title2: data[5].title2,
                            para1: data[5].para1,
                            para2: data[5].para2,
                            para3: data[5].para3,
                            image: data[5].image,
                            shoeLink: data[5].shoeLink,
                          },
                        }}
                      >
                        <button className="read-more-btn">Read More</button>
                      </Link>
                    </Col>
                  </ReactCardFlip>
                </Col>
              </Row>
            </Carousel>
          </Container>
        )}
      </Container>
    </>
  );
};
export default BehindTheShoes;
