import { LOAD /*, SAVE */ } from "redux-storage";

// redux reducer
const cartReducer = (state = { cartShoes: [], totalItems: 0 }, action) => {
  // ////console.log(action);
  switch (action.type) {
    case LOAD:
      // ////console.log("here:", Object.values(state));
      return {
        cartShoes: Object.values(state.cartShoes),
        totalItems: state.totalItems,
      };

    case "ADD_SHOE":
      var arr = [];
      var i;
      var found = false;
      for (i = 0; i < state.cartShoes.length; i++) {
        if (
          state.cartShoes[i].shoeID === action.data.shoeID &&
          state.cartShoes[i].size === action.data.size
        ) {
          arr.push({
            ...state.cartShoes[i],
            quantity: state.cartShoes[i].quantity + 1,
          });
          found = true;
        } else {
          arr.push(state.cartShoes[i]);
        }
      }
      if (!found) {
        arr.push(action.data);
      }
      // return [...state, action.data];
      return { cartShoes: arr, totalItems: state.totalItems + 1 };

    case "REMOVE_SHOE":
      arr = [];
      for (i = 0; i < state.cartShoes.length; i++) {
        if (
          state.cartShoes[i].shoe.shoeID === action.data.shoe.shoeID &&
          state.cartShoes[i].size === action.data.size
        ) {
          if (state.cartShoes[i].quantity > 1) {
            arr.push({
              ...state.cartShoes[i],
              quantity: state.cartShoes[i].quantity - 1,
            });
          }
        } else {
          arr.push(state.cartShoes[i]);
        }
      }
      return { cartShoes: arr, totalItems: state.totalItems - 1 };

    case "CLEAR_CART":
      return { cartShoes: [], totalItems: 0 };

    // case "REMOVE_SHOE":
    default:
      return state;
  }
};

const wantReducer = (state = { wantShoes: [], totalItems: 0 }, action) => {
  // ////console.log(action);
  switch (action.type) {
    case LOAD:
      // ////console.log("here:", Object.values(state));
      return {
        wantShoes: Object.values(state.wantShoes),
        totalItems: state.totalItems,
      };

    case "ADD_SHOE_WANT":
      var arr = [];
      var i;
      var found = false;
      for (i = 0; i < state.cartShoes.length; i++) {
        if (
          state.wantShoes[i].shoeID === action.data.shoeID &&
          state.wantShoes[i].size === action.data.size
        ) {
          // arr.push({
          //   ...state.cartShoes[i],
          //   quantity: state.cartShoes[i].quantity + 1,
          // });
          found = true;
        } else {
          arr.push(state.wantShoes[i]);
        }
      }
      if (!found) {
        arr.push(action.data);
      }
    // return [...state, action.data];
    // return { wantShoes: arr, totalItems: state.totalItems + 1 };

    case "REMOVE_SHOE_WANT":
      arr = [];
      for (i = 0; i < state.cartShoes.length; i++) {
        if (
          state.wantShoes[i].shoe.shoeID === action.data.shoe.shoeID &&
          state.wantShoes[i].size === action.data.size
        ) {
          // if (state.wantShoes[i].quantity > 1) {
          //   arr.push({
          //     ...state.cartShoes[i],
          //     quantity: state.cartShoes[i].quantity - 1,
          //   });
          // }
        } else {
          arr.push(state.wantShoes[i]);
        }
      }
      return { wantShoes: arr, totalItems: state.totalItems - 1 };

    case "CLEAR_WANT":
      return { wantShoes: [], totalItems: 0 };

    // case "REMOVE_SHOE":
    default:
      return state;
  }
};

// action creators
export const addToCart = (shoe, size) => {
  return {
    type: "ADD_SHOE",
    data: {
      shoe: shoe,
      size: size,
      quantity: 1,
    },
  };
};
export const addTowant = (shoe, size) => {
  return {
    type: "ADD_SHOE_WANT",
    data: {
      shoe: shoe,
      size: size,
      quantity: 1,
    },
  };
};

export const removeFromCart = (shoe, size) => {
  return {
    type: "REMOVE_SHOE",
    data: { shoe, size },
  };
};

export const clearCart = () => {
  return {
    type: "CLEAR_CART",
  };
};

export default cartReducer;
