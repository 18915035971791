import React from "react";
// import { Button } from "reactstrap";
import { userDb, auth, provider } from "../../firebase.js";
import useWindowDimensions from "hooks/useWindowDimensions";
import { useLocation } from "react-router";
import { useHistory } from "react-router";
import { useAuth } from "contexts/AuthContext";
import "./Style.css";
import GoogleButton from "react-google-button";

const LoginWith = ({ checkout, membershipPage, tierSelected, sneakerswipe, waitlist }) => {
  const { width, height } = useWindowDimensions();
  const { setLoading } = useAuth();

  var mobile;
  if (width <= 576 || height <= 576) {
    mobile = true;
  } else {
    mobile = false;
  }

  const location = useLocation();
  const history = useHistory();
  //////console.log(tierSelected);

  // const setQueryParams = (param, value) => {
  //   const history = window.history;
  //   // let currentUrlParams = new URLSearchParams(window.location.search);
  //   // currentUrlParams.set(param, value);

  //   const url = `${"/checkout"}`;
  //   history.replaceState({}, "", url);
  // };

  const handleSignInWithGoogle = () => {
      auth
        .signInWithPopup(provider)
        .then((authUser) => {
          // userDb().
          var userInfo;
          const ref = userDb(authUser.user.uid);
          ref
            .once("value", (snapshot) => {
              userInfo = snapshot.val();
            })
            .then(() => {
              if (!userInfo) {
                const user = authUser.user;
                const obj = {
                  userID: user.uid,
                  email: user.email,
                };
                ref.set(obj);
              }

              location.state && location.state.shoe ?
                setLoading(false)
                : checkout
                ? history.push("/checkout")
                : sneakerswipe 
                ?  history.push({
                    pathname: "/infoFormV2",
                  })
                : membershipPage
                ? history.push({
                    pathname: "/infoFormV2",
                  })
                : waitlist 
                ? history.push('/infoFormV2')
                : window.history.go(0);
            })
            .catch((error) => {
              ////console.log(error);
            });
        })
        .catch((error) => {
          ////console.log(error);
        });
    // } else {
    //   if (checkout) {
    //     // history.replace("/checkout");
    //     // reference: https://stackoverflow.com/a/64147116/10401847
    //     window.history.replaceState(
    //       {},
    //       "",
    //       `${window.location.pathname}?checkout`
    //     );
    //   }
    //   // auth.signInWithPopup(provider).then((authUser) => {
    //   //   window.history.back();
    //   // });
    // }
  };

  return (
    <div>
      {/* <Button color="cusblack" onClick={handleSignInWithGoogle}>
        Login with Google
      </Button> */}
      {/* <div className="signInWithGoogle"> */}
      <GoogleButton
        onClick={handleSignInWithGoogle}
        className="signInWithGoogle"

        // style={{ height: "100px" }}
      />
      {/* </div> */}
      <div className="showPasswordLogin">
        *By having an account on this website, you are agreeing to our{" "}
        <a href="/terms" target="_blank" rel="noreferrer">
          terms and conditions
        </a>
      </div>
    </div>
  );
};

export default LoginWith;
