import React, { useState, useEffect } from "react";
import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";
import { Container } from "reactstrap";
import { userDb } from "../../../firebase.js";
import { useAuth } from "contexts/AuthContext";
import { useSelector } from "react-redux";
import "./Checkout.css";
import FloatingActionCart from "../FloatingActionCart/FloatingActionCart";
import EmptyCart from "./EmptyCart.js";
import GuestCheckout from "./GuestCheckout.js";
import NoUserInfo from "./NoUserInfo.js";
import UserInfoExists from "./UserInfoExists";

const Checkout = () => {
  const { currentUser, loggedIn } = useAuth();
  const cartLength = useSelector((state) => state.cart.totalItems);
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    if (loggedIn) {
      const ref = userDb(currentUser.uid);
      // ////console.log("hello");
      ref.on("value", (snapshot) => {
        var userData = snapshot.val();
        // ////console.log(userData);
        // ////console.log("hello2");
        setUserInfo(userData);
      });

      // .then(() => {
      //   ////console.log("userInfo:", userInfo);
      // })
      // .catch((error) => {
      //   ////console.log(error);
      // });
      return () => ref.off();
    }
    // eslint-disable-next-line
  }, [loggedIn]);

  return (
    <div>
      <Navbar />
      <FloatingActionCart />
      <Container style={{ marginTop: "7.5vmax", marginBottom: "75px" }}>
        <h2>Checkout</h2>
        {cartLength === 0 && <EmptyCart />}
        {/* {////console.log(loggedIn, userInfo)} */}
        {!loggedIn && cartLength > 0 && <GuestCheckout />}
        {loggedIn && userInfo && !userInfo.name && (
          <NoUserInfo currentUser={currentUser} userInfo={userInfo} />
        )}
        {loggedIn && userInfo && userInfo.name && (
          <UserInfoExists currentUser={currentUser} userInfo={userInfo} />
        )}
      </Container>
      <Footer />
    </div>
  );
};

export default Checkout;
