import React, { useState, useCallback, useEffect } from "react";
import Navbar from "components/Navbars/Navbar.js";
import random from "lodash/random";
import Masonry from "react-masonry-component";
import "./aLaCarte.css";
import useWindowDimensions from "hooks/useWindowDimensions";
import Carousel, { Modal, ModalGateway } from "react-images";
import { pharrell, rocky, kanye, pioneer } from "./chefs";
import Parser from "html-react-parser";

const ALaCarte = () => {
    const curUrl = window.location.href;
    const chef = curUrl.substring(curUrl.lastIndexOf("/") + 1);
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    var chefImages = [];
    chef === "rocky" ? chefImages=rocky 
        : chef === "pharrell" ? chefImages=pharrell
        : chef === "pioneer" ? chefImages=pioneer
        : chefImages=kanye;

    var chefNames = [];
    chef === "rocky" ? chefNames="A$AP Rocky" 
    : chef === "pharrell" ? chefNames="Pharrell Williams"
    : chef === "pioneer" ? chefNames="Billie Eilish"
    : chefNames="Kanye West";

    var chefDes = [];
    chef === "rocky" ? chefDes = "A$AP Rocky (Rakim Mayers) is an American Rapper, producer, and director from New York, USA. A$AP made an impact on the world of fashion as seen in Dior Homme's 2016 fall/winter campaign, and he’s also served as Guest Artistic Director for the PacSun brand, and the current creative director of the MTV Labs."
        : chef === "pharrell" ? chefDes="Pharrell Williams is a visionary recording artist, producer, songwriter, philanthropist, fashion designer, entrepreneur, and founder of Humanrace™. <br/> Pharrell Williams collaboration is an extension of Pharrell’s vision to meld fashion, design and functionality to create quality everyday staples."
        : chef === "pioneer" ? chefDes="Billie Eilish is a GRAMMY Award-winning singer and songwriter, and at the age of just 19, she’s reshaping the fashion world by advocating for vegan and cruelty-free sustainable fashion. Billie Eilish is currently partnering with sportswear brand Nike to launch a new vegan sneaker and apparel collection."
        : chefDes="Kanye West, commonly referred as Ye, is an American rapper, record producer, and global fashion designer. Kanye is the genius behind the Yezzy collection, and need we say more about Yeezy’s? <br/><br/> “Shout out to Just Bleezy and Kan-Yeezy. <br/> See how we adjusted the game so easy”";

    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };
    const { width, height } = useWindowDimensions();
    var mobile, landscape, direction;
    if (width < 768) {
      mobile = true;
      landscape = false;
    } else if (height < 576 || width < 992) {
      mobile = false;
      landscape = true;
    } else {
      mobile = false;
      landscape = false;
    }
    direction = mobile ? "row" : "column";

    const [images, setImages] = useState([]);
    useEffect(() => {
        const images = chefImages.map((d) => ({
            url: d
        }));
        setImages(images);
    }, []);

    return (
        <div
            className="wrapper"
            style={{
            position: "relative",
            overflow: "hidden",
            background: "black",
            }}
        >
            <Navbar/>
            <div
                style={{
                    position: "relative",
                    overflow: "hidden",
                    background: "black",
                }}
                >
                {mobile && (
                    <img src={require(`assets/custom/alacarte/${chef}.png`).default}/>
                )}
                {!mobile && (
                  <div>
                    <img className="chef-image1"
                        src={require(`assets/custom/alacarte/${chef}.png`).default}
                    />
                    <img className="chef-image2"
                        src={require(`assets/custom/alacarte/${chef}.png`).default}
                    />
                  </div>
                )}
                <h1 style={{textAlign: "center"}} className="mt-5">{chefNames}</h1>
                <p className=" mr-3 ml-3 text-center">{Parser(chefDes)}</p>
                <h1 className="bold text-center">...</h1>
                <div className="m-3">
                    <Masonry className="gallery" elementType={"ul"}>
                    {chefImages.map((image, i) => {
                        return (
                        <li
                            key={i}
                            className="imgContainer"
                            style={{}}
                        >
                            <img className="chef-img" src= {image} alt="" />
                        </li>
                        );
                    })}
                    </Masonry>
                </div>
            </div>
        </div>
    );
};

export default ALaCarte;