import React from "react";
import "./ComingSoon.css";

const ComingSoonMobile = () => {
  return (
    <div className="body">
      <img className="lock" src={require("assets/custom/lock2.png").default} alt="" />
      <img className="yeezy" src={require("assets/custom/yeezy.jpg").default} alt="" />
      <h1 className="comingsoonH1">MYSTRY</h1>
      <h3 className="comingsoonH3">Coming Soon.</h3>
    </div>
  );
};

export default ComingSoonMobile;
