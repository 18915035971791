import { Component } from "react";
import { database } from "../../../../firebase";
import { Col, Row } from "reactstrap";
import { withRouter } from "react-router-dom";
import moment from "moment";

class ShoeDetailsRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shoe: "",
      content: "",
      readError: null,
    };
  }

  async componentDidMount() {
    this.setState({ readError: null });
    try {
      database.shoes.child(this.props.shoeID).once("value", (snapshot) => {
        this.setState({ shoe: snapshot.val() });
      });
    } catch (error) {
      this.setState({ readError: error.message });
    }
  }

  getOrderStatus({ orderStatus }) {
    if (orderStatus === "Processing") {
      return "Finding the perfect pair";
    }
    if (orderStatus === "Procuring") {
      return "Applying polish";
    }
    if (orderStatus === "Shipping") {
      return "Packing your pair";
    }
    if (orderStatus === "Shipped") {
      return "On the way";
    }
    if (orderStatus === "Delivered") {
      return "Ready to rock";
    }
  }

  render() {
    return (
      <div>
        {this.state.shoe === "" && <div>Fetching Shoe Details</div>}
        {this.state.shoe !== "" && this.props.isMobile === false && (
          <Row>
            <Col md="2">
              <img
                alt="..."
                className="img-center img-fluid"
                src={this.state.shoe.shoeImg.lowRes.front}
                style={{ height: "3vmax" }}
              />
            </Col>
            <Col>
              <p>
                {this.state.shoe.shoeName} - Size {this.props.item.size} - X
                {this.props.item.quantity}
              </p>
            </Col>

            {/* <Col md="2">
              {moment(new Date(this.props.orderedOn)).format("MMMM DD, YY")}{" "}
            </Col> */}

            {/* <Col md="3">
							{this.getOrderStatus({ orderStatus: this.props.status })}{" "}
						</Col> */}
          </Row>
        )}

        {this.state.shoe !== "" && this.props.isMobile && (
          <Row
            style={{
              alignItems: "center",
            }}
          >
            <Col
              xs="4"
              style={{
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              <img
                alt="..."
                className="img-center img-fluid"
                src={this.state.shoe.shoeImg.lowRes.front}
                style={{ height: "6vmax" }}
              />
            </Col>
            <Col>
              <p>
                {this.state.shoe.shoeName} - Size {this.props.item.size} - X
                {this.props.item.quantity}{" "}
              </p>
              {/* <p style={{ fontSize: "0.8rem" }} className="text-muted">
                Ordered On -{" "}
                {moment(new Date(this.props.orderedOn)).format("MMMM DD, YY")}
              </p> */}
              {/* <p style={{ fontSize: "0.85rem" }}>
								{this.getOrderStatus({ orderStatus: this.props.status })}
							</p> */}
              {/* <Row>
								<Col>
									<p style={{ fontSize: "0.85rem" }}>
										{this.getOrderStatus({ orderStatus: this.props.status })}
									</p>
								</Col>
								<Col>
									<p
										style={{ fontSize: "0.85rem", cursor: "pointer" }}
										className="text-info">
										Track
									</p>
								</Col>
							</Row> */}
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

export default withRouter(ShoeDetailsRow);

<Col>
  <Row
    onClick={() => {
      this.props.history.push({
        pathname: `/sneakers/${this.state.shoe.shoeID}`,
        state: { shoe: this.state.shoe },
      });
    }}
    style={{
      cursor: "pointer",
      alignContent: "center",
      justifyContent: "center",
    }}
  >
    {/* <Col md="4">
<p className="text-info">{this.state.shoe.shoeSKU}</p>
</Col> */}
  </Row>
</Col>;
