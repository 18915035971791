import React, { useState } from "react";
import { Input, FormGroup, Button, Form, Container } from "reactstrap";
import "./Style.css";
import { useAuth } from "contexts/AuthContext";
import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";
import { toast } from "react-toastify";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [email2, setEmail2] = useState("");
  const { resetPassword } = useAuth();
  const [submitted, setSubmitted] = useState(false);

  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const handleLogin = (e) => {
    e.preventDefault();
    // const loginObject = { email: email, password: password };
    if (!emailRegex.test(String(email).toLowerCase())) {
      toast.error("Please enter a valid email address");
    } else if (email !== email2) {
      toast.error("Both the emails must match");
    } else {
      resetPassword(email)
        .then(() => {
          setSubmitted(true);
        })
        .catch((error) => {
          ////console.log(error);
          toast.error(error.message);
        });
    }
  };

  return (
    <div>
      <Navbar />
      <Container style={{ marginTop: "100px", marginBottom: "75px" }}>
        {!submitted && (
          <div>
            <h3>Reset Password</h3>
            <Form onSubmit={handleLogin}>
              <FormGroup>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email*"
                  onChange={(e) => setEmail(e.target.value)}
                  required={true}
                />
              </FormGroup>
              <FormGroup>
                <Input
                  type="email"
                  name="email2"
                  id="email2"
                  placeholder="Confirm email*"
                  onChange={(e) => setEmail2(e.target.value)}
                  required={true}
                />
              </FormGroup>
              <Button color="green" type="submit">
                Reset password
              </Button>
            </Form>
          </div>
        )}
        {submitted && (
          <div>
            <div>We have sent a link to your email to reset your password.</div><br/>
            <b style={{color: "#00b200"}}>If you do not see the email in a few minutes, then please check the spam section of your mail. </b>
          </div>
        )}
      </Container>
      <Footer />
    </div>
  );
};

export default ForgotPassword;
