import React from "react";
import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";
import { Container, Row, Col } from "reactstrap";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
// import { Button } from "reactstrap";
//import shoes from "assets/custom/shoe_data.js";
import { ChefspicksZeroState } from "./ChefsPickZeroState";
import useWindowDimensions from "hooks/useWindowDimensions";
import "./Kitchen.css";
import { database, logViewPage } from "../../../firebase.js";
import FloatingActionCart from "../FloatingActionCart/FloatingActionCart";
import { useAuth } from "contexts/AuthContext";
import * as Scroll from "react-scroll";
import { useSelector } from "react-redux";

const Chefspicks = () => {
  const width = useWindowDimensions().width;
  const [fbShoes, setFbShoes] = useState([]);
  const [showScrollDown, setShowScrollDown] = useState(false);
  const { loggedIn } = useAuth();
  const shoes = useSelector((state) => state.shoes);

  useEffect(() => {
    var shoes_fb = [];
    Object.values(shoes).forEach((val) => {
      if (val.availableAt.chefsPick) {
        shoes_fb.push(val);
      }
    });
    setFbShoes(shoes_fb);
  }, [shoes]);
  // ////console.log(fbShoes)

  useEffect(() => {
    logViewPage("chefspicks");
  }, []);

  var mobile;
  if (width <= 420) {
    mobile = true;
  } else {
    mobile = false;
  }
  //var db_init = shoes.slice(0, total_picks); // take first 10 shoes
  var even;
  if (fbShoes.length % 2 === 0) {
    even = true;
  } else {
    even = false;
  }
  const history = useHistory();

  const handleClick = (idx, shoe) => {
    history.push({
      pathname: `/sneakers/${fbShoes[idx].shoeID}`,
      state: { shoe: shoe },
    });
  };
  const scrollDown = () => {
    Scroll.animateScroll.scrollTo(800, {
      smooth: true,
    });
  };
  const toggleScrollDown = (e) => {
    if (e.target.currentTime >= 3) {
      setShowScrollDown(true);
    }
  };
  return (
    <>
      <div
        className="wrapper"
        style={{
          position: "relative",
          overflow: "hidden",
          background: "black",
        }}
      >
        <FloatingActionCart />
        <Navbar page={"picks"} />
        <div
          style={{
            position: "relative",
            overflow: "hidden",
            background: "black",
          }}
        >
          {/* reference to crop the video from top: https://stackoverflow.com/questions/18716077/crop-video-in-html */}
          {/*marginTop: !mobile ? `${videoOffset}px` : "0px",*/}
          <video
            width="100%"
            type="video/mp4"
            autoPlay
            loop
            muted
            playsInline
            style={{
              zIndex: 1,
            }}
            position="relative"
            onTimeUpdate={(e) => toggleScrollDown(e)}
          >
            {/* <source
                  src={
                    require("assets/custom/chefspicKh265.mp4")
                      .default
                  }
                  type="video/mp4; codecs=hevc"
                ></source> */}
            {/* webm supported by everything besides Apple. Highly compressed, small size */}
            {/* <source
                  src={
                    require("assets/custom/chefspicK.webm")
                      .default
                  }
                  type="video/webm; codecs=vp9"
                ></source> */}
            {/* mp4 video encoded with h264. Widely supported */}
            <source
              src={require("assets/custom/chefspicKh264.mp4").default}
              type="video/mp4"
            ></source>
          </video>
          {showScrollDown && !mobile && (
            <div className="scrollDownContainer" onClick={scrollDown}>
              <div className="scrollDownChevron"></div>
              <div className="scrollDownChevron"></div>
              <div className="scrollDownChevron"></div>
              <span className="scrollDownText">Scroll down</span>
            </div>
          )}
        </div>

        <Container
          fluid={true}
          style={{ paddingLeft: "5vw", paddingRight: "5vw" }}
        >
          <div
            className="wrapper"
            style={{
              marginTop: "5vh",
              marginBottom: "5vh",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Row
              style={{
                justifyContent: "center",
              }}
            >
              <div>
                <img
                  alt="..."
                  src={require("assets/custom/chef.png").default}
                  style={{ height: "3.5vmax", marginRight: "1vmax" }}
                />
              </div>
              {!mobile && <h2 className="p-2">Chef's Picks</h2>}
              {mobile && (
                <h2>Chef's Picks</h2>
              )}
            </Row>
            <Row
              style={{
                justifyContent: "center",
              }}
            >
              <h4 className="ml-3 mr-3">
                Get a taste of what our chef’s have handpicked for you
              </h4>
              <br />
            </Row>

            {!loggedIn && ChefspicksZeroState()}
            {loggedIn &&
              mobile &&
              fbShoes.map((shoe, idx) => (
                <Col key={idx}>
                  <Row
                    className="ChefShoeRow"
                    onClick={() => handleClick(idx, shoe)}
                  >
                    {/* <div
                    className="text-white"
                    style={{ marginLeft: "2vmax", marginRight: "1vmax" }}
                  >
                    {idx + 1 < 10 ? "0" + (idx + 1) : idx + 1}
                  </div> */}
                    <Col style={{ maxWidth: "30%" }}>
                      <img
                        src={shoe.shoeImg.lowRes.right}
                        alt={`Shoe number ${idx}`}
                        className="ChefsShoeImage"
                      />
                    </Col>
                    <Col>
                      <div
                        style={{
                          textAlign: "left",
                          color: "white",
                          fontWeight: "normal",
                        }}
                      >
                        {shoe.shoeName}
                      </div>
                    </Col>
                  </Row>
                </Col>
              ))}

            {loggedIn && !mobile && (
              <Row>
                <Col>
                  {fbShoes
                    .slice(
                      0,
                      even ? fbShoes.length / 2 : fbShoes.length / 2 + 1
                    )
                    .map((shoe, idx) => (
                      <Col key={idx}>
                        <Row
                          className="ChefShoeRow"
                          onClick={() => handleClick(idx, shoe)}
                        >
                          {/* <div
                    className="text-white"
                    style={{ marginLeft: "2vmax", marginRight: "1vmax" }}
                  >
                    {idx + 1 < 10 ? "0" + (idx + 1) : idx + 1}
                  </div> */}
                          <Col style={{ maxWidth: "30%" }}>
                            <img
                              src={shoe.shoeImg.lowRes.right}
                              alt={`Shoe number ${idx}`}
                              className="ChefsShoeImage"
                            />
                          </Col>
                          <Col>
                            <div
                              style={{
                                textAlign: "left",
                                color: "white",
                                fontWeight: "normal",
                              }}
                            >
                              {shoe.shoeName}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    ))}
                </Col>
                <Col>
                  {fbShoes
                    .slice(
                      even ? fbShoes.length / 2 : fbShoes.length / 2 + 1,
                      fbShoes.length
                    )
                    .map((shoe, idx) => (
                      <Col key={idx}>
                        <Row
                          className="ChefShoeRow"
                          onClick={() => handleClick(idx, shoe)}
                        >
                          {/* <div
                    className="text-white"
                    style={{ marginLeft: "2vmax", marginRight: "1vmax" }}
                  >
                    {idx + 1 < 10 ? "0" + (idx + 1) : idx + 1}
                  </div> */}
                          <Col style={{ maxWidth: "30%" }}>
                            <img
                              src={shoe.shoeImg.lowRes.right}
                              alt={`Shoe number ${idx}`}
                              className="ChefsShoeImage"
                            />
                          </Col>
                          <Col>
                            <div
                              style={{
                                textAlign: "left",
                                color: "white",
                                fontWeight: "normal",
                              }}
                            >
                              {shoe.shoeName}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    ))}
                </Col>
              </Row>
            )}
          </div>
        </Container>
        <Footer />
      </div>
    </>
  );
};

export default Chefspicks;
