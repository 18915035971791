import React from "react";
import { MDBCarousel, MDBCarouselInner, MDBCarouselItem } from "mdbreact";
import { Container } from "@material-ui/core";
import { Link } from "react-router-dom";
import "./Blog.css";
import useWindowDimensions from "hooks/useWindowDimensions";

const CarouselPage = () => {
  const { width, height } = useWindowDimensions();
  var mobile, landscape;
  if (width < 768) {
    mobile = true;
    landscape = false;
  } else if (height < 576 || width < 992) {
    mobile = false;
    landscape = true;
  } else {
    mobile = false;
    landscape = false;
  }
  return (
    <Container
      style={{
        paddingTop: "50px",
        paddingBottom: "80px",
        paddingRight: "50px",
      }}
    >
      <MDBCarousel
        activeItem={1}
        length={5}
        showControls={false}
        showIndicators={false}
        interval={6000}
        className="z-depth-1"
      >
        <MDBCarouselInner>
          <MDBCarouselItem itemId="1">
            <div
              class="jumbotron jumbotron-fluid mb-3 pt-0 pb-0  position-relative"
              style={{ backgroundColor: "black" }}
            >
              <div class="pl-4 pr-0 h-100 tofront">
                <div class="row justify-content-between">
                  <div class="col-md-8 pt-6 pb-6 align-self-center">
                    <h1
                      class="secondfont mb-3 font-weight-bold pb-4"
                      style={{ color: "lightyellow" }}
                    >
                      Mystry unveils the world’s first AI-powered luxury sneaker
                      “Mystery Box” in the form of an NFT
                    </h1>
                    {mobile && <div className="img-fluid image pl-2"></div>}
                    <p class="pb-4 pt-2" style={{ color: "white" }}>
                      It wasn’t shocking that yet another fashion-tech company
                      decided to launch their own rendition of an NFT, but what
                      really shocked me about Mystry is this company’s founding
                      pillars.
                    </p>
                    <Link
                      to={{
                        pathname: "/blogpage",
                        state: {
                          title1:
                            "Mystry unveils the world’s first AI-powered luxury sneaker “Mystery Box” in the form of an NFT",
                          title2: "",
                          image: require("assets/custom/bts/MystryBlog1.jpeg")
                            .default,
                          para1: `It wasn’t shocking that yet another fashion-tech company decided to launch their own rendition of an NFT, but what really shocked me about Mystry is this company’s founding pillars. <br/><br/> Let me introduce you to Mystry (www.mystry.io),the world’s first luxury sneaker-tech company that is purely focused one thing: Keeping the childlike element of surprise alive in all your lives. Off late, sneakers have become more of an asset class for resellers rather than a form of art, and with bots at an all time high, and raffles getting even more competitive, users are falling out of love with the culture.`,
                          para2:
                            "Mystry was built around the concept that “Mystery Boxes” are a great way to surprise someone, but how can you surprise someone when you don’t know what they like? and that is why Mystry recently launched the world’s first AI-powered luxury sneaker “Mystery Box” where each Mystry Box is hand-picked by their proprietary AI, Sneaker-Swipe. <br/><br/>Sneaker-Swipe is Mystry’s Tinder-like swipe feature that takes into consideration a user’s preference for brands, colorway, silhouette, design, collaborations, etc and curates their flagship product, the MystryBox. Mystry says that, “Sneaker-Swipe was built with the ideology that every Mystry Box should only have sneakers that are curated to a user’s taste, every single time.”",
                          para3:
                            "Mystry also guarantees a 100% ROI on every #MystryBox; matter of fact, they boast that it’s way over a100% as the value of the sneakers in every #MystryBox can range anywhere from $229 — $9,999, from brands such as Jordan’s, Yeezy’s, Nike SB Dunks, etc. <br/><br/>A lot of “Mystery Boxes” in the market are based upon just delivering a guaranteed ROI value with year-end inventory, regardless whether the user likes the product or not. Mystry goes a step further by not just guaranteeing a 100%+ ROI, but by also ensuring that it’s a sneaker that you love, always. <br/><br/>The only downside to Mystry is that since their #MystryBox collections are so exclusive, there’s an active 21–28 day waitlist for all their users. Regardless, I still think it’s definitely worth seeing what they have to offer. I just signed up for their waitlist, so I can’t wait to see what I get in my #MytryBox",
                          shoeLink: "",
                        },
                      }}
                    >
                      <h4 class="btn btn-green">Read More</h4>
                    </Link>
                  </div>
                  <div
                    class="image col-md-4 d-none d-md-block"
                    style={{ height: "350px" }}
                  ></div>
                </div>
              </div>
            </div>
          </MDBCarouselItem>
          <MDBCarouselItem itemId="2">
            <div
              class="jumbotron jumbotron-fluid mb-3 pt-0 pb-0  position-relative"
              style={{ backgroundColor: "black" }}
            >
              <div class="pl-4 pr-0 h-100 tofront">
                <div class="row justify-content-between">
                  <div class="col-md-8 pt-6 pb-6 align-self-center">
                    <h1
                      class="secondfont mb-3 font-weight-bold pb-4"
                      style={{ color: "lightyellow" }}
                    >
                      Justin Bieber and Balenciaga{" "}
                    </h1>
                    {mobile && <div className="img-fluid image2 pl-2"></div>}
                    <p class="pb-4 pt-2" style={{ color: "white" }}>
                      This year has been all about firsts for Demna Gvasalia,
                      the creative director of Balenciaga. It’s evident that
                      he’s clearing the ground for the historic maison’s next
                      phase, from designing a virtual apocalyptic gamer-verse
                      for the Fall/Winter 2021 collection to wiping the
                      Balenciaga Instagram clean ahead of his couture debut.
                    </p>
                    <Link
                      to={{
                        pathname: "/blogpage",
                        state: {
                          title1: "Justin Bieber and Balenciaga",
                          title2: "",
                          image: require("assets/custom/BlogImages/JB_1.jpg")
                            .default,
                          para1:
                            "This year has been all about firsts for Demna Gvasalia, the creative director of Balenciaga. It’s evident that he’s clearing the ground for the historic maison’s next phase, from designing a virtual apocalyptic gamer-verse for the Fall/Winter 2021 collection to wiping the Balenciaga Instagram clean ahead of his couture debut.",
                          para2:
                            "Now, with the release of a new Runner sneaker, the brand has enlisted the help of Justin Bieber for a campaign — and the results are exactly what we expected.",
                          para3:
                            "Bieber appears alongside a slew of celebrities including French actress Isabelle Huppert, Awar Adhiero Odhiang, Anania Orgeas, Isabelle Weldon Herouard, Minttu Vesala, Litay Marcus, Angel aka Emmanuel Culkin Mugisha, Abdou Diop, Taishi Suzuki, Hans Schmidt, and Eliza Douglas, all dressed in oversized leather bombers with hoodies and tapered sweatpants.",
                          shoeLink: "Get this pair at the Mystry Kitchen",
                        },
                      }}
                    >
                      <h4 class="btn btn-green">Read More</h4>
                    </Link>
                  </div>
                  <div
                    class="image2 col-md-4 d-none d-md-block"
                    style={{ height: "350px" }}
                  ></div>
                </div>
              </div>
            </div>
          </MDBCarouselItem>
          <MDBCarouselItem itemId="3">
            <div
              class="jumbotron jumbotron-fluid mb-3 pt-0 pb-0  position-relative"
              style={{ backgroundColor: "black" }}
            >
              <div class="pl-4 pr-0 h-100 tofront">
                <div class="row justify-content-between">
                  <div class="col-md-8 pt-6 pb-6 align-self-center">
                    <h1
                      class="secondfont mb-3 font-weight-bold pb-4"
                      style={{ color: "lightyellow" }}
                    >
                      Nike acquires RTFKT Studios{" "}
                    </h1>
                    {mobile && <div className="img-fluid image3 pl-2"></div>}
                    <p class="pb-4 pt-2" style={{ color: "white" }}>
                      The sportswear giant announced the acquisition of RTFKT
                      Studios, which it claims “a leading brand that harnesses
                      cutting edge innovation to offer next generation
                      collectibles that mix culture and gaming.” RTFKT claims
                      that in February, a collaboration with young artist
                      FEWOCiOUS to sell actual sneakers combined with virtual
                      ones managed to sell approximately 600 pairs/NFTs in just
                      six minutes, making over $3.1 million at the time.{" "}
                    </p>
                    <Link
                      to={{
                        pathname: "/blogpage",
                        state: {
                          title1: "Nike acquires RTFKT Studios ",
                          title2: "",
                          image: require("assets/custom/BlogImages/Blog2.jpg")
                            .default,
                          para1:
                            "The sportswear giant today announced the acquisition of RTFKT Studios, which it claims “a leading brand that harnesses cutting edge innovation to offer next generation collectibles that mix culture and gaming.”",
                          para2:
                            "RTFKT claims that in February, a collaboration with young artist FEWOCiOUS to sell actual sneakers combined with virtual ones managed to sell approximately 600 pairs/NFTs in just six minutes, making over $3.1 million at the time. This was around the same early spring period when most of us were hearing about NFTs for the first time, as Grimes sold roughly $6 million worth of digital artwork on March 1st. ",
                          para3:
                            "The company’s website immediately asks visitors to link their Metamask wallets, which is one of the ways NFT owners can verify their purchases, with the idea that at some point in the future you’ll play games or enter other sorts of VR spaces where your items can materialise, once those spaces have read the blockchain to assess which items you own the rights to. <br/><br/> This is the kind of vision that Nike is buying into, citing the company’s creator Benoit Pagotto, Chris Le, and Steven Vasilev with using “the latest in gaming engines, NFTs, blockchain authentication and augmented reality to create one of a kind virtual products and experiences.” ",
                          shoeLink: "Get this pair at the Mystry Kitchen",
                        },
                      }}
                    >
                      <h4 class="btn btn-green">Read More</h4>
                    </Link>
                  </div>
                  <div
                    class="image3 col-md-4 d-none d-md-block"
                    style={{ height: "350px" }}
                  ></div>
                </div>
              </div>
            </div>
          </MDBCarouselItem>
          <MDBCarouselItem itemId="4">
            <div
              class="jumbotron jumbotron-fluid mb-3 pt-0 pb-0  position-relative"
              style={{ backgroundColor: "black" }}
            >
              <div class="pl-4 pr-0 h-100 tofront">
                <div class="row justify-content-between">
                  <div class="col-md-8 pt-6 pb-6 align-self-center">
                    <h1
                      class="secondfont mb-3 font-weight-bold pb-4"
                      style={{ color: "lightyellow" }}
                    >
                      The Nike SB Dunks | Travis Scott
                    </h1>
                    <h3
                      class="secondfont mb-3 font-weight-bold pb-2"
                      style={{ color: "lightyellow" }}
                    >
                      Behind The Shoes I
                    </h3>
                    {mobile && <div className="img-fluid image5"></div>}
                    <p class="pb-4 pt-4" style={{ color: "white" }}>
                      This iconic collaboration between Nike and Travis Scott
                      led to one of the most highly anticipated sneakers of all
                      time. This pair uses details and patterns that are
                      inspired by his hometown of Houston, Texas. The release of
                      this influential shoe had a strong impact on the
                      resurgence of the historical SB Dunks.
                    </p>
                    <Link
                      to={{
                        pathname: "/blogpage",
                        state: {
                          title1: "THE NIKE SB DUNKS | TRAVIS SCOTT",
                          title2: "BEHIND THE SHOE I",
                          image:
                            require("assets/custom/bts/TheNikeSBDunks.jpeg")
                              .default,
                          para1:
                            "This iconic collaboration between Nike and Travis Scott led to one of the most highly anticipated sneakers of all time. This pair uses details and patterns that are inspired by his hometown of Houston, Texas. The release of this influential shoe had a strong impact on the resurgence of the historical SB Dunks. It is a staple of the sneaker industry and culture, which even grabs the attention of a non-sneaker head.",
                          para2:
                            "This design features a variety of materials and prints, similar to those seen on the Air Force 1 Low Travis Scott Cactus Jack. Unlike previous Travis Scott releases, they were only available at certain Nike SB retailers not on SNKRS.",
                          para3:
                            "Suede upper with paisley canvas and plaid flannel overlays make up this Nike SB Dunk Low. The elephant print emerges as the paisley overlays fade. The style is completed with embroidered writing across the collar, rope laces, and La Flame's Cactus Jack insignia on the puffy tongues. These sneakers were released in February 2020 and cost a retail price of $150. At the time of writing, this pair goes for a market price of $2,700.",
                          shoeLink: "Get this pair at the Mystry Kitchen",
                        },
                      }}
                    >
                      <h4 class="btn btn-green">Read More</h4>
                    </Link>
                  </div>
                  <div
                    class="image5 col-md-4 d-none d-md-block"
                    style={{ height: "350px" }}
                  ></div>
                </div>
              </div>
            </div>
          </MDBCarouselItem>
          <MDBCarouselItem itemId="5">
            <div
              class="jumbotron jumbotron-fluid mb-3 pt-0 pb-0 position-relative"
              style={{ backgroundColor: "black" }}
            >
              <div class="pl-4 pr-0 h-100 tofront">
                <div class="row justify-content-between">
                  <div class="col-md-8 pt-6 pb-6 align-self-center">
                    <h1
                      class="secondfont mb-3 font-weight-bold pb-4"
                      style={{ color: "lightyellow" }}
                    >
                      The Air Jordan 1 Retro High University Blue
                    </h1>
                    <h3
                      class="secondfont mb-3 font-weight-bold pb-2"
                      style={{ color: "lightyellow" }}
                    >
                      Behind The Shoes II
                    </h3>
                    {mobile && <div className="img-fluid image4 pl-2"></div>}
                    <p class="pb-4 pt-4" style={{ color: "white" }}>
                      This iconic pair of sneakers is a tribute to the
                      University of North Carolina where the creator of the
                      shoe-line himself, Michael Jordan, attended from
                      1981-1984. Carolina blue (occasionally referred to as Tar
                      Heel blue) is the shade of blue used as one of the
                      official school colors of the University of North
                      Carolina.
                    </p>
                    <Link
                      to={{
                        pathname: "/blogpage",
                        state: {
                          title1: "THE AIR JORDAN 1 RETRO HIGH UNIVERSITY BLUE",
                          title2: "BEHIND THE SHOE II",
                          image:
                            require("assets/custom/bts/University_Blue.jpeg")
                              .default,
                          para1:
                            "This iconic pair of sneakers is a tribute to the University of North Carolina where the creator of the shoe-line himself, Michael Jordan, attended from 1981-1984. Carolina blue (occasionally referred to as Tar Heel blue) is the shade of blue used as one of the official school colors of the University of North Carolina.This powdered blue colour way is only limited to 12,000 pairs in the world and sells for 3 times its market value.",
                          para2:
                            'Although the colours are different, the "University Blue" upper has a color blocking configuration similar to the Air Jordan 1 "Chicago." Mudguards, eyestays, collar flaps, and heel pieces are all light sky blue, with toe boxes, quarters, and throats in a clean white. The final hue in the trio is black, which can be found on Swooshes, laces, and collars.',
                          para3: "",
                          shoeLink: "Get this pair at the Mystry Kitchen",
                        },
                      }}
                    >
                      <h4 class="btn btn-green">Read More</h4>
                    </Link>
                  </div>
                  <div
                    class="image4 col-md-4 d-none d-md-block"
                    style={{ height: "350px" }}
                  ></div>
                </div>
              </div>
            </div>
          </MDBCarouselItem>
        </MDBCarouselInner>
      </MDBCarousel>
    </Container>
  );
};

export default CarouselPage;
