import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/analytics";

const devConfig = {
  apiKey: "AIzaSyDtJyU_iSvsL62gOcq8DkRpWlLzwmWM51Q",
  authDomain: "mystry-80f51.firebaseapp.com",
  databaseURL: "https://mystry-80f51-default-rtdb.firebaseio.com",
  projectId: "mystry-80f51",
  storageBucket: "mystry-80f51.appspot.com",
  messagingSenderId: "1052613438943",
  appId: "1:1052613438943:web:7187a9da9abff99781686a",
  measurementId: "G-E8GDW29GQE",
};

const prodConfig = {
  apiKey: "AIzaSyDcDkfVRoLY9p7WAUGmIGfDZPvfDYtQfHE",
  authDomain: "mystryworld-4159d.firebaseapp.com",
  databaseURL: "https://mystryworld-4159d-default-rtdb.firebaseio.com",
  projectId: "mystryworld-4159d",
  storageBucket: "mystryworld-4159d.appspot.com",
  messagingSenderId: "836567380565",
  appId: "1:836567380565:web:be33115cf7b10ac0291adc",
  measurementId: "G-JPZP8RPE88",
};

// const config = process.env.REACT_APP_STAGE === "dev" ? devConfig : prodConfig;

// if (process.env.REACT_APP_STAGE === "dev") {
//   ////console.log("deveee");
// } else {
//   ////console.log("prodee");
// }

const app = firebase.initializeApp(prodConfig);

// ////console.log(window.location.hostname);
// var app;
// if (
//   window.location.hostname.includes("deploy-preview") ||
//   (window.location.hostname !== "localhost" &&
//     window.location.host.includes("3000")) ||
//   window.location.hostname.includes("mystry-test")
// ) {
//   app = firebase.initializeApp(devConfig);
// } else {
//   app = firebase.initializeApp(prodConfig);
// }

export const auth = app.auth();
export const analytics = app.analytics();
export const provider = new firebase.auth.GoogleAuthProvider();

const useEmulator = true;

const realtimeDB = firebase.database();
if (useEmulator && window.location.hostname === "localhost") {
}

export const dbUserReview = (userID) => {
  return realtimeDB.ref("sneakerswipe/reviews/" + userID);
};

export const database = {
  shoes: realtimeDB.ref("shoes/"),
  sneakerswipeUsers: realtimeDB.ref("sneakerswipe/users/"),
  orders: realtimeDB.ref("orders/"),
  users: realtimeDB.ref("users/"),
  contact: realtimeDB.ref("contact/"),
  wants: realtimeDB.ref("wants/"),
  aplhaLaunchUsers: realtimeDB.ref("aplhaLaunchUsers/"),
  mystrywaitlist: realtimeDB.ref("mystrywaitlist/"),
  emailSubscriptions: realtimeDB.ref("emailSubscriptions/"),
  mystryBoxSignUpList: realtimeDB.ref("mystryBoxSignUpList"),
  mystryBoxDropStatus: realtimeDB.ref("mystryBoxDropStatus/"),
};

export const userDb = (userID) => {
  return realtimeDB.ref("users/" + userID);
};
export const createOrder = (orders) => {
  var orderIDs = [];
  var cartID;
  orders.forEach((order, idx) => {
    var orderRef = database.orders.push(order);
    if (idx === 0) {
      cartID = orderRef.key;
    }
    database.orders.child(orderRef.key).update({
      orderID: orderRef.key,
      // orderedOn: Date.now(),
      // orderedOn: database.orders.ServerValue.TIMESTAMP,
      // orderedOn: realtimeDB.ServerValue.TIMESTAMP,
      orderedOn: firebase.database.ServerValue.TIMESTAMP,
      cartID: cartID,
    });
    orderIDs.push(orderRef.key);
  });
  return { orderIDs, cartID };
};

export const currentUsersOrders = (userID) => {
  return realtimeDB.ref("orders").orderByChild("customerID").equalTo(userID);
};

export const currentUsersMystryBoxOrders = (userID) => {
  return realtimeDB
    .ref("mystryBoxOrders")
    .orderByChild("customerID")
    .equalTo(userID);
};

export const createWant = (userId, shoeId, shoeSizes) => {
  //  const shoeID = "-MkI_UIKBFkNFyiua4IM";
  //  const shoeSize = "5";
  //  const userID = "kf3LKRAHEtUrEMKhRAqMLQeyRD73";

  const userID = userId.uid.toString();
  const shoeID = shoeId;
  const shoeSize = shoeSizes.toString();
  // database.wants
  //   .orderByChild("shoeID")
  //   .equalTo(shoeID)
  //   .once("value", function (snapshot) {
  //     if (snapshot.val() === null) {
  //       database.wants.push({
  //         shoeID: shoeID,
  //         want: [{ size: shoeSize, quantity: 1 }],
  //       });
  //     } else {
  //       snapshot.forEach(function (data) {
  //         const index = data
  //           .val()
  //           .want.findIndex(
  //             (wantShoe) => wantShoe.size === shoeSize.toString()
  //           );
  //         var newWants = data.val().want;
  //         if (index === -1) {
  //           newWants.push({ size: shoeSize, quantity: 1 });
  //         } else {
  //           var newQuantity = data.val().want[index].quantity + 1;
  //           newWants[index] = { size: shoeSize, quantity: newQuantity };
  //         }
  //         database.wants.child(data.key).update({
  //           want: newWants,
  //         });
  //       });
  //     }
  //   });

  database.wants.push({
    price: 69,
    shoeID: shoeID,
    size: shoeSize,
    status: 0,
    timestamp: Date.now(),
    userID: userID,
  });

  database.users
    .child(userID)
    .child("wants")
    .child(shoeID)
    .child(String(shoeSize).replace(".", "_"))
    .set({ quantity: 1 });
};

export const logViewPage = (page_name) => {
  analytics.logEvent("view_" + page_name);
  analytics.setCurrentScreen(page_name);
};
export default app;
