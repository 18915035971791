import { Component } from "react";
import { currentUsersOrders } from "../../../../firebase";
import { Col } from "reactstrap";
import OrderCard from "../components/OrderCard";

export default class OrderHistoryPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      content: "",
      readError: null,
    };
  }

  async componentDidMount() {
    const userId = this.props.currentUserID;
    this.setState({ readError: null });
    try {
      currentUsersOrders(userId).once("value", (snapshot) => {
        let orders = [];
        snapshot.forEach((snap) => {
          var tempOrder = snap.val();
          if (tempOrder.orderState === "Completed") {
            orders.push(tempOrder);
          }
        });
        this.setState({ orders });
      });
    } catch (error) {
      this.setState({ readError: error.message });
    }
  }

  render() {
    return (
      <div>
        <div>
          <Col>
            {this.state.orders.length === 0 && (
              <h4>
                No order history. Your orders will appear here once completed.
              </h4>
            )}
            {this.state.orders.map((order) => {
              return <OrderCard order={order} />;
            })}
          </Col>
        </div>
      </div>
    );
  }
}
