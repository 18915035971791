import React from 'react';
import useWindowDimensions from "hooks/useWindowDimensions";

const Brands = () => {
  const { width, height } = useWindowDimensions();
  var mobile, landscape;
  if (width < 768) {
    mobile = true;
    landscape = false;
  } else if (height < 576 || width < 992) {
    mobile = false;
    landscape = true;
  } else {
    mobile = false;
    landscape = false;
  }

  return (
    <>
      <div
        style={{
          position: "relative",
          overflow: "hidden",
          background: "black",
        }}
      >
        <h1
          className="text-white"
          style={{
            marginBottom: mobile? "4vh": "8vh",
            marginTop: "2vh",
            textAlign: "center",
            fontSize: mobile? "35px": "40px",
            padding: mobile? "18px": "",
            fontWeight: "800",
          }}
        >
          Brands featured in our Mystry Boxes
        </h1>
        <video
          width="100%"
          autoPlay
          loop
          muted
          playsInline
        >
          <source
            src={
              require("assets/custom/Brands/Brands.mp4")
                .default
            }
            type="video/mp4"
          ></source>
        </video>
      </div>
    </>
  );
};

export default Brands;
