import React, { useState } from "react";
import NavbarV2 from "../Navbar/Navbar";
import Faqpage from "views/pages/pagesV2/FAQ/Faq";
import Footer from "views/pages/pagesV2/Footer/Footer";
import "./MystryBoxProduct.css";
import MystryBoxAlt from "../../../../assets/custom/mystrybox_alt.png";
import yeezyProductPage from "../../../../assets/custom/yeezy_product_page.png";
import airJordanProductPage from "../../../../assets/custom/air_jordan_mystry_page.png";
import nikeDunksProductPage from "../../../../assets/custom/nike_dunks_mystry_page.png";
import nikeAirforceProductPage from "../../../../assets/custom/nike_airforce_product_page.png";
import nikeAirLogo from "../../../../assets/custom/nike_air_logo.png";
import nikeDunksLogo from "../../../../assets/custom/nike_dunks_logo.png";
import yeezyLogo from "../../../../assets/custom/yeezy_logo.png";
import airJordanLogo from "../../../../assets/custom/air_jordan_logo.png";
import authenticity from "../../../../assets/custom/authenticity.png";
import guar from "../../../../assets/custom/guar.png";
import ai from "../../../../assets/custom/ai.png";
import MoreProducts from "../MoreProducts/MoreProducts";
import MystryBoxCard from "../MystryBoxCard/MystryBoxCard";
import { Modal } from "reactstrap";
import SubscriptionModal from "../Subscribe/SubscribeModal";
import { useHistory } from "react-router-dom";
import { useAuth } from "contexts/AuthContext";

export default function MystryBoxProduct() {
  const [modalOpen, setModalOpen] = useState(false);
  const router = useHistory();
  const { loggedIn } = useAuth();

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  function joinWaitlist() {
    if (!loggedIn) {
      router.push({
        pathname: `/login`,
        state: { waitlist: true },
      });
    } else {
      router.push("/infoFormV2");
    }
  }

  const map = {
    "Nike Airforce": {
      logo: nikeAirLogo,
      shoe: nikeAirforceProductPage,
      price: 140,
      guarPrice: [110, 399],
      enabled: true,
    },
    "Nike Dunk": {
      logo: nikeDunksLogo,
      shoe: nikeDunksProductPage,
      price: 169,
      guarPrice: [149, 549],
      enabled: true,
    },
    YEEZY: {
      logo: yeezyLogo,
      shoe: yeezyProductPage,
      price: 269,
      guarPrice: [220, 399],
      enabled: false,
    },
    "Air Jordan": {
      logo: airJordanLogo,
      shoe: airJordanProductPage,
      price: 199,
      guarPrice: [149, 699],
      enabled: true,
    },
  };

  const renderMoreProducts = () => {
    return Object.keys(map).map((key) => {
      return (
        <MoreProducts
          productLogo={map[key].logo}
          shoe={map[key].shoe}
          price={map[key].price}
          name={key}
          guarPrice={map[key].guarPrice}
          enabled={map[key].enabled}
        />
      );
    });
  };

  // const redirectToNotify = () => {
  //   router.push({
  //     pathname: "/",
  //     state: { fromNotify: true },
  //   });
  // };

  return (
    <div className="mystryBoxWrapper">
      <NavbarV2 />
      <div className="headerMystryProductContainer">
        <h1 className="headerName">Shop Mystry Boxes</h1>
        <div
          style={{ paddingLeft: "14px", paddingRight: "14px" }}
          className="mystryBoxProductSection"
        >
          <div className="mystryProductLeft">
            <h1 className="headerName headerMystry">Mystry Box +</h1>
            <h3 className="moreProductsGuarPrice descMystry">
              Items Inside: A Pair of Hype Sneakers + A Mystry Item
            </h3>
            <h3 className="moreProductsGuarPrice decMg">
              Guaranteed Value: $199 - $999
            </h3>
            <h2 className="moreProductsPrice mystryProductPrice">
              Price: $199
            </h2>
            <div className="buttonsProduct">
              <button className="buyButtonProduct" onClick={joinWaitlist}>
                Pre-Order
              </button>
              <button onClick={toggleModal} className="notifyButtonProduct">
                Join Waitlist
              </button>
            </div>
          </div>
          <Modal isOpen={modalOpen} size="lg" className="subscribeModal">
            <div className="m-4">
              <button
                type="button"
                className="close close-subscribe"
                onClick={toggleModal}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <SubscriptionModal onChange={toggleModal} />
            </div>
          </Modal>
          <div className="mystryProductRight">
            <img
              className="mystryProductRightImage"
              src={MystryBoxAlt}
              alt="Mystry"
            />
          </div>
        </div>
        <div className="moreProducts">
          <div className="moreProductsBody">{renderMoreProducts()}</div>
        </div>
        <div className="mystryCards">
          <MystryBoxCard
            logo={authenticity}
            header="100% Authenticity"
            text="We take a lot of proactive measures to ensure that every pair is 100% authentic. 
            In order to ensure authenticity, we work directly with our brand partners and a handful 
            of highly reputable sneaker resellers. We also legit-check every sneaker before it is dispatched."
          />
          <MystryBoxCard
            logo={guar}
            header="Guaranteed Hype Value"
            text="We want to make sure that we send you a pair of sneakers that you’ll absolutely love! 
            Every Mystry Box contains sneakers only from the most recent releases or an iconic pair from previous years. 
            The sneakers in your Mystry Box are usually sold out/ hard to get it, but don't worry, we have you covered."
          />
          <MystryBoxCard
            logo={ai}
            header="AI-Powered Mystry Box"
            text="We use Artificial Intelligence to understand your likes and dislikes when it comes to sneakers, 
            and this allows us to prepare every Mystry Box based purely on your taste and preferences.
            Every order we send it out is handpicked by our AI and our Sneaker Specialists - 
            Play SneakerSwipe here and experience the beauty of technology + the gamification of fashion. "
          />
        </div>
      </div>
      <Faqpage />
      <Footer />
    </div>
  );
}
