import { useAuth } from "contexts/AuthContext";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Modal } from "reactstrap";
import MystryBox from "../../../../assets/custom/MystryBox.png";
import SubscriptionModal from "../Subscribe/SubscribeModal";

export default function MoreProducts({
  shoe,
  productLogo,
  name,
  price,
  guarPrice,
  enabled,
}) {
  const router = useHistory();
  const { loggedIn } = useAuth();
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  function joinWaitlist() {
    if (!loggedIn) {
      router.push({
        pathname: `/login`,
        state: { waitlist: true },
      });
    } else {
      router.push({ pathname: "/infoFormV2", state: { brand: name } });
    }
  }

  const renderButton = () => {
    if (enabled) {
      return (
        <button onClick={joinWaitlist} className="buyButtonProduct">
          Pre-Order
        </button>
      );
    }

    return (
      <button
        style={{ backgroundColor: "#813939" }}
        disabled
        className="buyButtonProduct"
      >
        Sold Out
      </button>
    );
  };

  const navigateToProduct = () => {
    switch (name) {
      case "Nike Airforce":
        router.push("/productPage/NikeAirforceOne");
        break;
      case "Nike Dunk":
        router.push("/productPage/NikeDunks");
        break;
      case "YEEZY":
        router.push("/productPage/YEEZY");
        break;
      case "Air Jordan":
        router.push("/productPage/AirJordan");
        break;
      default:
        break;
    }
  };

  return (
    <div className="moreProductsCard">
      <div onClick={navigateToProduct} className="moreProductsImageWrapper">
        <img className="moreProductsShoeImage" src={shoe} alt="shoe" />
        <img className="moreProductsImage" src={MystryBox} alt="Mystry" />
      </div>
      <div className="logoSection shiftUp">
        <img className="nikeAir" src={productLogo} alt="Product Logo" />
      </div>
      <h2 className="moreProductsName shiftUp">{`${name} Mystry Box`}</h2>
      <h3 className="moreProductsGuarPrice shiftUp">
        {`Guaranteed Value: $${guarPrice[0]} - $${guarPrice[1]}`}
      </h3>
      <h2 className="moreProductsPrice shiftUp">{`Price: $${price}`}</h2>
      <div className="buttonsProduct buttonsProductMore shiftUp">
        {renderButton()}
        <button onClick={toggleModal} className="notifyButtonProduct">
          Join Waitlist
        </button>
      </div>
      <Modal isOpen={modalOpen} size="lg" className="subscribeModal">
        <div className="m-4">
          <button
            type="button"
            className="close close-subscribe"
            onClick={toggleModal}
          >
            <i className="tim-icons icon-simple-remove" />
          </button>
          <SubscriptionModal onChange={toggleModal} />
        </div>
      </Modal>
    </div>
  );
}
