import { useAuth } from "contexts/AuthContext";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Modal } from "reactstrap";
import MystryBoxAlt from "../../../../assets/custom/mystrybox_alt.png";
import SubscriptionModal from "../Subscribe/SubscribeModal";
import "./MystryBox.css";

export default function MystryBox() {
  const [modalOpen, setModalOpen] = useState(false);
  const history = useHistory();
  const { loggedIn } = useAuth();

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  function joinWaitlist() {
    if (!loggedIn) {
      history.push({
        pathname: `/login`,
        state: { waitlist: true },
      });
    } else {
      history.push("/infoFormV2");
    }
  }

  return (
    <div>
      <div className="mystryBoxHomeContainer">
        <div className="mystryBoxProductSection mystryBoxProductSectionHome">
          <div className="mystryProductLeft mb-2">
            <h1 className="headerName headerMystry headerMystryHome">
              Mystry Box +
            </h1>
            <h3 className="moreProductsGuarPrice descMystry">
              Items Inside: A Pair of Hype Sneakers + A Mystry Item
            </h3>
            <h3 className="moreProductsGuarPrice decMg">
              Guaranteed Value: $199 - $999
            </h3>
            <h2 className="moreProductsPrice mystryProductPrice">
              Price: $199
            </h2>
            <div className="buttonsProduct">
              <button className="buyButtonProduct" onClick={joinWaitlist}>
                Pre-Order
              </button>
              <button className="notifyButtonProduct" onClick={toggleModal}>
                Join Waitlist
              </button>
            </div>
          </div>
          <div className="mystryProductRight">
            <img
              className="mystryProductRightImage"
              src={MystryBoxAlt}
              alt="Mystry"
            />
          </div>
          <h4 className="brandsIncluded">
            Brands Included: Air Jordan, Yeezy, Off-White, Nike SB, Nike Air
            Force, and New Balance
          </h4>
        </div>
      </div>
      <Modal isOpen={modalOpen} size="lg" className="subscribeModal">
        <div className="m-4">
          <button
            type="button"
            className="close close-subscribe"
            onClick={toggleModal}
          >
            <i className="tim-icons icon-simple-remove" />
          </button>
          <SubscriptionModal onChange={toggleModal} />
        </div>
      </Modal>
    </div>
  );
}
