import React, { useState } from "react";
import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  Container,
} from "reactstrap";
import axios from "axios";
import { toast } from "react-toastify";


const Partnership = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [compName, setCompName] = useState("");
  const [compWebsite, setCompWebsite] = useState("");
  const [message, setMessage] = useState("");

  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const addPartner = (e) => {
    e.preventDefault();
    axios({
      method: "POST", 
      url: "https://mystry-server-z574yasx7q-uc.a.run.app/sendContact", 
      data: ({
          name: name,   
          email: email,  
          compName: compName,
          compWebsite: compWebsite,
          message: message
      })
    }).then((response)=>{
        document.getElementById('partner-form').reset();
    })
    setName("")
    setEmail("")
    setCompName("")
    setCompWebsite("")
    setMessage("")
  };

  return (
    <>
      <Navbar />
      <div
        style={{
          marginTop: "100px",
          marginBottom: "5vh",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <h1>Partnership</h1>
      </div>
      <Container style={{ fontSize: "1rem" }}>
        <img
          alt="..."
          className="img-center img-fluid pb-5"
          src={require("assets/custom/Careers.jpeg").default}
          width="600"
          height="300"
        />
        <div className="p-2">
          At Mystry Inc, we love working with young and exhilarating companies.
          Our motto at Mystry is to “Keep 'em Guessing,” and to stay true to our
          motto, we love giving away new and exciting products in our
          MystryBoxes for absolutely FREE.{" "}
        </div>
        <br />
        <div className="p-2">
          If you would like to work with us have your products listed in our
          MystryBox, contact us here:
        </div>
      </Container>
      <Container style={{ marginTop: "50px", marginBottom: "25px" }}>
        <Card style={{ backgroundColor: "black" }}>
          <CardBody>
            <Form onSubmit={addPartner} id="partner-form">
              <FormGroup>
                <Input
                  type="text"
                  placeholder="Name*"
                  onChange={(e) => setName(e.target.value)}
                  name="name"
                  value={name}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Input
                  type="text"
                  placeholder="Company Name*"
                  onChange={(e) => setCompName(e.target.value)}
                  name="cname"
                  value={compName}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Input
                  type="email"
                  placeholder="Email*"
                  onChange={(e) => setEmail(e.target.value)}
                  name="email"
                  value={email}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Input
                  type="name"
                  placeholder="Company Website*"
                  onChange={(e) => setCompWebsite(e.target.value)}
                  name="website"
                  value={compWebsite}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Input
                  type="textarea"
                  placeholder="Tell us why you think this product would be exciting for customers to see in their MystryBox:*"
                  onChange={(e) => setMessage(e.target.value)}
                  name="message"
                  value={message}
                  required
                />
              </FormGroup>
              <Button type="submit" color="simple">
                Send Email
              </Button>
            </Form>
          </CardBody>
        </Card>
      </Container>
      <Container style={{ marginBottom: "50px", fontSize: "1rem" }}>
        <div className="p-2">
          We want to keep the element of surprise alive in all of our lives, and
          we look forward to building Mystry as a platform where customers can
          experience a variety of new and exciting products in their
          MystryBoxes.{" "}
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default Partnership;
