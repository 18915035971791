import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Container, Row } from 'reactstrap';
import useWindowDimensions from "hooks/useWindowDimensions";
import { useAuth } from "../../../../components/../contexts/AuthContext";

const MystryBoxes = () => {

  const { width, height } = useWindowDimensions();
  const joinStyle = mobile ? { fontSize: "16px" } : { fontSize: "16px" };
  const { logout, loggedIn } = useAuth();
  const fluid = true;

  var mobile, landscape;
  if (width < 768) {
    mobile = true;
    landscape = false;
  } else if (height < 576 || width < 992) {
    mobile = false;
    landscape = true;
  } else {
    mobile = false;
    landscape = true;
  }

  const boxStyle = {
    height: "auto",
    padding: "0px",
    marginBottom: mobile ? "10vh" : "20vh",
  };

  return (
    <div
      style={{
        position: "relative",
        overflow: "hidden",
        background: "black",
      }}
    >
      <Container
        fluid={fluid}
        style={{
          marginTop: !mobile ? "75px" : "50px",
          overflowX: "hidden",
        }}
      >
        {mobile && (
          <h3 
            className="text-white" 
            style={{ marginTop: "5vh", marginBottom: "8vh", textAlign: "center" }}>
            {" "}
          </h3>
        )}
        {/* {!mobile && (
          <h1 
            className="text-white" 
            style={{ 
              marginBottom: "12vh",
              textAlign: "center", 
              fontSize:"40px",
              fontWeight:"600" 
            }}>
            Welcome to the Mystry Box
          </h1>
        )} */}
        {mobile && (
          <Container fluid={fluid}>
            <Row className="d-flex justify-content-center align-items-center">
              <Col xs="10" className="align-items-center" style={boxStyle}>
                <div style={{ textAlign: "center"}}>
                  <Link
                    to={{
                      pathname: !loggedIn ? "/login" : "/infoFormV2",
                    }}
                  >
                  <img
                    src={require("assets/custom/membership/Black.png").default}
                    width="75%"
                    height="50%"
                    style={{marginBottom: "5vh"}}
                    alt=""
                  />
                  </Link>
                </div>
                <div style={{ textAlign: "center"}}>
                  <p style={{ fontSize: "1.5rem", marginBottom: "2vh", fontWeight:"700"}}>Mystry Box</p>
                  <p style={{ fontSize: "1rem"}}>Guaranteed Value: $299 - $999</p>
                  <b style={{ fontSize: "1.2rem", color: "#00b200"}}>Price: $299</b>
                  <div style={{ textAlign: "center", marginTop: "2vh"}}>
                    <Button
                      color="green"
                      tag={Link}
                      to={{
                        pathname: !loggedIn ? "/login" : "/infoFormV2",
                        }}
                      size="sm"
                      style={{...joinStyle,  marginTop: "10px", width: "250px"}}
                    >
                      <span style={{color: "white", padding: "2px"}}>Join Waitlist</span>
                    </Button>
                  </div>
                </div>
              </Col>
              {/* <Col xs="10" className="align-items-center" style={boxStyle}>
                <div style={{ textAlign: "center"}}>
                  <Link
                    to={{
                      pathname: !loggedIn ? "/login" : "/infoFormV2",
                    }}
                  >
                  <img
                    src={require("assets/custom/membership/Gold.png").default}
                    width="75%"
                    height="50%"
                    style={{marginBottom: "5vh"}}
                    alt=""
                  />
                  </Link>
                </div>
                <div style={{ textAlign: "center" }}>
                  <p style={{ 
                    fontSize: "1.5rem", 
                    marginBottom: "2vh", 
                    fontWeight:"500",
                    fontFamily: 'edosz',
                    color:"#0D0D0D"
                  }}>Mystry +</p>
                  <p style={{ fontSize: "1rem"}}>Guaranteed Value: $399 - $699</p>
                  <b style={{ fontSize: "1.2rem", color: "#00b200" }}>Price: $399</b>
                  <div style={{ textAlign: "center", marginTop: "2vh"}}>
                    <Button
                      color="green"
                      tag={Link}
                      to={{
                        pathname: !loggedIn ? "/login" : "/infoFormV2",
                        }}
                      size="sm"
                      style={{...joinStyle,  marginTop: "10px", width: "250px"}}
                    >
                      <span style={{color: "white", padding: "2px"}}>Buy Now</span>
                    </Button>
                  </div>
                </div>
              </Col> */}
            </Row>
          </Container>
        )}
        {/* {!mobile && (
          <Container fluid={fluid}>
            <Row>
              <Col md="2"></Col>
              <Col md="4" style={boxStyle}>
                <div style={{ textAlign: "center"}}>
                  <Link
                    to={{
                      pathname: !loggedIn ? "/login" : "/infoFormV2",
                    }}
                  >
                  <img
                    src={require("assets/custom/membership/Black.png").default}
                    width="60%"
                    className='img-fluid'
                    style={{marginBottom: "9vh"}}
                    alt=""
                  />
                  </Link>
                </div>
                <div style={{ textAlign: "center"}}>
                  <p style={{ fontSize: "35px", marginBottom: "5vh", fontWeight:"700"}}>Mystry Box</p>
                  <p style={{ fontSize: "1.2rem"}}>Guaranteed Value: $299 - $399</p>
                  <b style={{ fontSize: "1.4rem", color: "#00b200"}}>Price: $299</b>
                  <div style={{ textAlign: "center", marginTop: "2vh"}}>
                    <Button
                      color="green"
                      tag={Link}
                      to={{
                        pathname: !loggedIn ? "/login" : "/infoFormV2",
                        }}
                      size="sm"
                      style={{...joinStyle,  marginTop: "10px", width: "280px"}}
                    >
                      <span style={{color: "white", padding: "2px"}}>Buy Now</span>
                    </Button>
                  </div>
                </div>
              </Col>
              <Col md="4"style={boxStyle}>
                <div style={{ textAlign: "center"}}>
                  <Link
                    to={{
                      pathname: !loggedIn ? "/login" : "/infoFormV2",
                    }}
                  >
                  <img
                    src={require("assets/custom/membership/Gold.png").default}
                    width="60%"
                    className='img-fluid'
                    style={{marginBottom: "10vh"}}
                    alt=""
                  />
                  </Link>
                </div>
                <div style={{ textAlign: "center" }}>
                  <p style={{ 
                    fontSize: "35px", 
                    marginBottom: "5vh", 
                    fontWeight:"500", 
                    fontFamily: 'edosz',
                  }}>Mystry +</p>
                  <p style={{ fontSize: "1.2rem"}}>Guaranteed Value: $399 - $699</p>
                  <b style={{ fontSize: "1.4rem", color: "#00b200" }}>Price: $399</b>
                  <div style={{ textAlign: "center", marginTop: "2vh"}}>
                    <Button
                      color="green"
                      tag={Link}
                      to={{
                        pathname: !loggedIn ? "/login" : "/infoFormV2",
                        }}
                      size="sm"
                      style={{...joinStyle,  marginTop: "10px", width: "280px"}}
                    >
                      <span style={{color: "white", padding: "2px"}}>Buy Now</span>
                    </Button>
                  </div>
                </div>
              </Col>
              <Col xs="0" md="2"></Col>
            </Row>
          </Container>
        )} */}
      </Container>
    </div>
  );
};

export default MystryBoxes;