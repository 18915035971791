import Navbar from "components/Navbars/Navbar.js";
import { Container, Button, Row } from "reactstrap";
import { Link } from "react-router-dom";
import Footer from "../pagesV2/Footer/Footer";

export const FailurePayment = () => {
  return (
    <div>
      <Navbar />
      <Container style={{ marginTop: "15vh", marginBottom: "75px" }}>
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <h1 className="title text-white">Oops!</h1>
        </Row>
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          Something went wrong. Please try again
          <br />
          <br />
        </Row>
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Button color="green" to="/" tag={Link}>
            <span style={{ color: "white", padding: "2px" }}>Return Home</span>
          </Button>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};
