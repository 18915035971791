import React, { useContext, useState, useEffect } from "react";
import { auth } from "../firebase";
import Loading from "../components/Loading/Loading";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const [landing, setLanding] = useState(window.location.pathname === "/");

  // ////console.log(window.location.pathname);

  function signup(email, password) {
    // setLoading(true);
    // return auth.createUserWithEmailAndPassword(email, password);
    // const promise = auth.createUserWithEmailAndPassword(email, password);
    return auth.createUserWithEmailAndPassword(email, password);
    // setLoading(false);
    // return promise;
  }

  function login(email, password) {
    // setLoading(true);
    return auth.signInWithEmailAndPassword(email, password);
    // const promise = auth.signInWithEmailAndPassword(email, password);
    // setLoading(false);
    // return promise;
  }

  function anon_login() {
    // was getting problems with sneakerswipe with below
    // setLoading(true);
    return auth.signInAnonymously();
  }

  function logout() {
    setLoading(true);
    return auth.signOut();
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email);
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email);
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password);
  }

  useEffect(() => {
    setLanding(window.location.pathname === "/");
    const unsubscribe = auth.onAuthStateChanged((user) => {
      // ////console.log("context user:", user);
      setCurrentUser(user);
      setLoading(false);
      if (user && !user.isAnonymous) {
        setLoggedIn(true);
      } else {
        setLoggedIn(false);
      }
    });
    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    loggedIn,
    setLoading,
    login,
    signup,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
    anon_login,
  };

  return (
    <AuthContext.Provider value={value}>
      {loading && !landing && <Loading auth={true} />}
      {!loading && children}
    </AuthContext.Provider>
  );
}
