import React, { useState } from "react";
import { useAuth } from "contexts/AuthContext";
import { InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import "../ShoeBid.css";
import { toast } from "react-toastify";
import {
    FormGroup,
    Form,
    Button,
    Input
} from "reactstrap";

const ModalLogin = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const { login, setLoading } = useAuth();

    const handleLogin = (e) => {
      e.preventDefault();
      login(email, password)
        .then(() => {
            toast.success("Logged In successfully");
        })
        .catch((error) => {
          setLoading(false);
          if (error.code === "auth/wrong-password") {
            alert("Your password is invalid.");
          } else {
            alert(error.message);
          }
        });
    };
  
    return (
      <div>
        <Form onSubmit={handleLogin}>
          <FormGroup>
            <Input
              type="email"
              name="email"
              id="email"
              placeholder="Email*"
              onChange={(e) => setEmail(e.target.value)}
              required={true}
            />
          </FormGroup>
          <FormGroup>
            <InputGroup>
              <Input
                placeholder="Password*"
                type={!showPassword ? "password" : "text"}
                name="loginPassword"
                id="loginPassword"
                autoComplete="current-password"
                onChange={(e) => setPassword(e.target.value)}
                required={true}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText style={{ padding: "0px" }}>
                  <IconButton
                    style={{ padding: "0px", marginRight: "10px" }}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <Visibility className="icons" style={{ fontSize: "18" }} />
                    ) : (
                      <VisibilityOff
                        className="icons"
                        style={{ fontSize: "18" }}
                      />
                    )}
                  </IconButton>
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </FormGroup>
  
          <div
            className="showPasswordLogin"
            style={{ textAlign: "right", color: "white !important" }}
          >
            <a href="/forgot-password">Forgot password?</a>
          </div>
          <Button color="green" type="submit">
            Login
          </Button>
        </Form>
      </div>
    );
  };

  export default ModalLogin;