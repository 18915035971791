import useWindowDimensions from "hooks/useWindowDimensions";
import React from "react";
import { useHistory } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import "./Shop.css";

const Shop = () => {
  const { width, height } = useWindowDimensions();
  var mobile, landscape;
  if (width < 768) {
    mobile = true;
    landscape = false;
  } else if (height < 576 || width < 992) {
    mobile = false;
    landscape = true;
  } else {
    mobile = false;
    landscape = false;
  }
  const history = useHistory();

  const hoverChange = (id) => {
    const logo = document.getElementById(`shoe-logo${id}`);
    if (logo.style.display == "none") logo.style.display = "block";
    else if (logo.style.display == "block") logo.style.display = "none";

    const brand = document.getElementById(`shoe-brand${id}`);
    if (brand.style.display == "none") brand.style.display = "block";
    else if (brand.style.display == "block") brand.style.display = "none";
  };

  return (
    <div
      style={{
        marginBottom: "0px",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Container
        fluid={true}
        style={{
          overflowX: "hidden",
        }}
      >
        <h1
          className="text-white"
          style={{
            marginTop: mobile ? "8vh" : "15vh",
            marginBottom: mobile ? "6vh" : "8vh",
            textAlign: "center",
            fontSize: "40px",
            fontWeight: "800",
          }}
        >
          Shop Mystry Boxes
        </h1>
        {!mobile && (
          <Row style={{ height: "64vh" }}>
            <Col
              className="d-flex justify-content-center align-items-center"
              style={{
                backgroundColor: "#E9E9E9",
                paddingTop: "25px",
                border: "0.5px solid #E9E9E9",
                height: "64vh",
              }}
              id="shop-col"
              onMouseEnter={() => hoverChange(1)}
              onMouseLeave={() => hoverChange(1)}
            >
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ marginBottom: "20px", flexDirection: "column" }}
              >
                <img
                  id="shoe-logo1"
                  className="logo"
                  style={{ display: "block" }}
                  src={require("assets/custom/Shop/Jordan/Logo.png").default}
                />
                <img
                  className="shoe"
                  src={require("assets/custom/Shop/Jordan/Shoe.png").default}
                />
                <img
                  className="mystrybox"
                  src={require("assets/custom/Shop/MystryBox.png").default}
                />
                <div
                  style={{ display: "none" }}
                  id="shoe-brand1"
                  className="shoe-brand"
                >
                  Air Jordan Mystry Box
                </div>
                <button
                  className="shop-now-white"
                  onClick={() => history.push("/productPage/AirJordan")}
                >
                  Shop Now
                </button>
              </div>
            </Col>
            <Col
              className="d-flex justify-content-center align-items-center"
              style={{
                paddingTop: "25px",
                backgroundColor: "#131313",
                border: "0.5px solid #E9E9E9",
                height: "64vh",
              }}
              id="shop-col"
              onMouseEnter={() => hoverChange(2)}
              onMouseLeave={() => hoverChange(2)}
            >
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ marginBottom: "20px", flexDirection: "column" }}
              >
                <img
                  id="shoe-logo2"
                  className="logo"
                  style={{ display: "block" }}
                  src={require("assets/custom/Shop/Yeezy/Logo.png").default}
                />
                <img
                  className="shoe"
                  src={require("assets/custom/Shop/Yeezy/Shoe.png").default}
                />
                <img
                  className="mystrybox"
                  src={require("assets/custom/Shop/MystryBox.png").default}
                />
                <div
                  style={{ display: "none" }}
                  id="shoe-brand2"
                  className="shoe-brand-white"
                >
                  Yeezy Mystry Box
                </div>
                <button
                  disabled
                  className="shop-now-black"
                  onClick={() => history.push("/productPage/YEEZY")}
                >
                  Sold Out
                </button>
              </div>
            </Col>
            <Col
              className="d-flex justify-content-center align-items-center"
              style={{
                backgroundColor: "#E9E9E9",
                paddingTop: "25px",
                border: "0.5px solid #E9E9E9",
                height: "64vh",
              }}
              id="shop-col"
              onMouseEnter={() => hoverChange(3)}
              onMouseLeave={() => hoverChange(3)}
            >
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ marginBottom: "20px", flexDirection: "column" }}
              >
                <img
                  id="shoe-logo3"
                  className="logo"
                  style={{ display: "block" }}
                  src={require("assets/custom/Shop/Nike/Logo.png").default}
                />
                <img
                  className="shoe"
                  src={require("assets/custom/Shop/Nike/Shoe.png").default}
                />
                <img
                  className="mystrybox"
                  src={require("assets/custom/Shop/MystryBox.png").default}
                />
                <div
                  style={{ display: "none" }}
                  id="shoe-brand3"
                  className="shoe-brand"
                >
                  Nike Dunk Mystry Box
                </div>
                <button
                  className="shop-now-white"
                  onClick={() => history.push("/productPage/NikeDunks")}
                >
                  Shop Now
                </button>
              </div>
            </Col>
            <Col
              className="d-flex justify-content-center align-items-center"
              style={{
                paddingTop: "25px",
                backgroundColor: "#131313",
                border: "0.5px solid #E9E9E9",
                height: "64vh",
              }}
              id="shop-col"
              onMouseEnter={() => hoverChange(4)}
              onMouseLeave={() => hoverChange(4)}
            >
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ marginBottom: "20px", flexDirection: "column" }}
              >
                <img
                  id="shoe-logo4"
                  className="logo"
                  style={{ display: "block" }}
                  src={require("assets/custom/Shop/NikeAir/Logo.png").default}
                />
                <img
                  className="shoe"
                  src={require("assets/custom/Shop/NikeAir/Shoe.png").default}
                />
                <img
                  className="mystrybox"
                  src={require("assets/custom/Shop/MystryBox.png").default}
                />
                <div
                  style={{ display: "none" }}
                  id="shoe-brand4"
                  className="shoe-brand-white"
                >
                  Nike Air Force 1 Mystry Box
                </div>
                <button
                  className="shop-now-black"
                  onClick={() => history.push("/productPage/NikeAirforceOne")}
                >
                  Shop Now
                </button>
              </div>
            </Col>
          </Row>
        )}
        {mobile && (
          <div className="d-flex justify-content-center align-items-center">
            <Row style={{ maxWidth: "90vw" }}>
              <Col
                className="d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: "#E9E9E9",
                  paddingTop: "25px",
                  border: "0.5px solid #E9E9E9",
                  height: "60vh",
                  maxWidth: "45vw",
                }}
                id="shop-col"
              >
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ marginBottom: "20px", flexDirection: "column" }}
                >
                  <img
                    id="shoe-logo1"
                    className="logo"
                    style={{
                      display: "block",
                      maxHeight: "10vh",
                      marginBottom: "2vh",
                    }}
                    src={require("assets/custom/Shop/Jordan/Logo.png").default}
                  />
                  <img
                    className="shoe-mobile"
                    style={{ maxHeight: "15vh" }}
                    src={require("assets/custom/Shop/Jordan/Shoe.png").default}
                  />
                  <img
                    tyle={{ maxHeight: "15vh" }}
                    src={require("assets/custom/Shop/Box.png").default}
                  />
                  <div
                    id="shoe-brand1"
                    style={{ height: "6vh" }}
                    className="shoe-brand-xs"
                  >
                    Air Jordan Mystry Box
                  </div>
                  <button
                    className="shop-now-white"
                    style={{ maxHeight: "5vh" }}
                    onClick={() => history.push("/productPage/AirJordan")}
                  >
                    Shop Now
                  </button>
                </div>
              </Col>
              <Col
                className="d-flex justify-content-center align-items-center"
                style={{
                  paddingTop: "25px",
                  backgroundColor: "#131313",
                  border: "0.5px solid #E9E9E9",
                  height: "60vh",
                  maxWidth: "45vw",
                }}
                id="shop-col"
              >
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ marginBottom: "20px", flexDirection: "column" }}
                >
                  <img
                    id="shoe-logo2"
                    className="logo"
                    style={{
                      display: "block",
                      maxHeight: "10vh",
                      marginBottom: "2vh",
                    }}
                    src={require("assets/custom/Shop/Yeezy/Logo.png").default}
                  />
                  <img
                    className="shoe-mobile"
                    style={{ maxHeight: "15vh" }}
                    src={require("assets/custom/Shop/Yeezy/Shoe.png").default}
                  />
                  <img
                    style={{ maxHeight: "15vh" }}
                    src={require("assets/custom/Shop/Box.png").default}
                  />
                  <div
                    id="shoe-brand2"
                    style={{ height: "6vh" }}
                    className="shoe-brand-white-xs"
                  >
                    Yeezy Mystry Box
                  </div>
                  <button
                    disabled
                    className="shop-now-black"
                    style={{ maxHeight: "5vh" }}
                    onClick={() => history.push("/productPage/YEEZY")}
                  >
                    Sold Out
                  </button>
                </div>
              </Col>
              <Col
                className="d-flex justify-content-center align-items-center"
                style={{
                  paddingTop: "25px",
                  backgroundColor: "#131313",
                  border: "0.5px solid #E9E9E9",
                  height: "60vh",
                  maxWidth: "45vw",
                }}
                id="shop-col"
              >
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ marginBottom: "20px", flexDirection: "column" }}
                >
                  <img
                    id="shoe-logo4"
                    className="logo"
                    style={{
                      display: "block",
                      maxHeight: "10vh",
                      marginBottom: "2vh",
                    }}
                    src={require("assets/custom/Shop/NikeAir/Logo.png").default}
                  />
                  <img
                    className="shoe-mobile"
                    style={{ maxHeight: "15vh" }}
                    src={require("assets/custom/Shop/NikeAir/Shoe.png").default}
                  />
                  <img
                    style={{ maxHeight: "15vh" }}
                    src={require("assets/custom/Shop/Box.png").default}
                  />
                  <div
                    id="shoe-brand4"
                    style={{ height: "6vh" }}
                    className="shoe-brand-white-xs"
                  >
                    Nike Air Force 1 Mystry Box
                  </div>
                  <button
                    className="shop-now-black"
                    style={{ maxHeight: "5vh" }}
                    onClick={() => history.push("/productPage/NikeAirforceOne")}
                  >
                    Shop Now
                  </button>
                </div>
              </Col>
              <Col
                className="d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: "#E9E9E9",
                  paddingTop: "25px",
                  border: "0.5px solid #E9E9E9",
                  height: "60vh",
                  maxWidth: "45vw",
                }}
                id="shop-col"
              >
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ marginBottom: "20px", flexDirection: "column" }}
                >
                  <img
                    id="shoe-logo3"
                    className="logo"
                    style={{
                      display: "block",
                      maxHeight: "10vh",
                      marginBottom: "2vh",
                    }}
                    src={require("assets/custom/Shop/Nike/Logo.png").default}
                  />
                  <img
                    className="shoe-mobile"
                    style={{ maxHeight: "15vh" }}
                    src={
                      require("assets/custom/Shop/Nike/ShoeMobile.png").default
                    }
                  />
                  <img
                    style={{ maxHeight: "15vh" }}
                    src={require("assets/custom/Shop/Box.png").default}
                  />
                  <div
                    id="shoe-brand3"
                    style={{ height: "6vh" }}
                    className="shoe-brand-xs"
                  >
                    Nike Dunk Mystry Box
                  </div>
                  <button
                    className="shop-now-white"
                    style={{ maxHeight: "5vh" }}
                    onClick={() => history.push("/productPage/NikeDunks")}
                  >
                    Shop Now
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        )}
      </Container>
    </div>
  );
};

export default Shop;
