import { Link } from "react-router-dom";
import { Col, Button, Row } from "reactstrap";
import useWindowDimensions from "hooks/useWindowDimensions";

export default function WaitlistCongratsPage() {
	const { width, height } = useWindowDimensions();
	var mobile, landscape;
	if (width < 768) {
		mobile = true;
		landscape = false;
	} else if (height < 576 || width < 992) {
		mobile = false;
		landscape = true;
	} else {
		mobile = false;
		landscape = false;
	}
	const overlayText = {
		position: "absolute",
		top: !mobile ? "10vh" : "20vh",
		marginLeft: "auto",
		marginRight: "auto",
		width: "100%",

		zIndex: 2,
	};

	function congratsTextWidget() {
		return (
			<Col style={{ textAlign: "center" }}>
				<h3 style={{ color: "white" }}>
					Congratulations! You have been added to our waitlist!
				</h3>
				<Button color="info" to="/profile-page" tag={Link}>
					Complete your profile
				</Button>
			</Col>
		);
	}

	return (
		<div style={{ backgroundColor: "black" }}>
			{mobile && !landscape && (
				<>
					<video
						src={require("assets/custom/aboutus.mp4").default}
						width="100%"
						type="video/mp4"
						autoPlay
						loop
						muted
						playsInline
						style={{
							zIndex: 1,
						}}
						position="relative"
					/>
					<Row
						className="row-grid justify-content-between align-items-center text-left"
						style={overlayText}>
						<Col
							xs="12"
							s="12"
							lg="12"
							md="12"
							style={{ display: "flex", justifyContent: "center" }}>
							{congratsTextWidget()}
						</Col>
					</Row>
				</>
			)}
			{!mobile && (
				<>
					<video
						src={require("assets/custom/aboutuscropped.mp4").default}
						width="100%"
						type="video/mp4"
						autoPlay
						loop
						muted
						playsInline
						style={{
							zIndex: 1,
						}}
						position="relative"
					/>
					<Row
						className="row-grid justify-content-between align-items-center text-left"
						style={overlayText}>
						<Col
							xs="12"
							s="12"
							lg="12"
							md="12"
							style={{ display: "flex", justifyContent: "center" }}>
							{congratsTextWidget()}
						</Col>
					</Row>
				</>
			)}
		</div>
	);
}
