import { Col, Card, Row } from "reactstrap";
import ShoeRow from "./ShoeRow";
import useWindowDimensions from "hooks/useWindowDimensions";
import { useHistory } from "react-router-dom";
import moment from "moment";

export default function OrderCard({ order }) {
  const width = useWindowDimensions().width;
  var mobile;
  if (width <= 420) {
    mobile = true;
  } else {
    mobile = false;
  }
  const history = useHistory();

  function getOrderStatus(orderStatus) {
    if (orderStatus === "Processing") {
      return "Finding the perfect pair";
    }
    if (orderStatus === "Procuring") {
      return "Applying polish";
    }
    if (orderStatus === "Shipping") {
      return "Packing your pair";
    }
    if (orderStatus === "Shipped") {
      return "On the way";
    }
    if (orderStatus === "Delivered") {
      return "Ready to rock";
    }
  }
  return (
    <Card
      style={{
        padding: "1.5vmax",
        cursor: "pointer",
        marginBottom: "1.5vh",
        backgroundColor: "#2f2f2f",
      }}
      onClick={() => {
        history.push({
          pathname: `/trackOrder`,
          state: { order: order },
        });
      }}
    >
      <p> {getOrderStatus(order.orderStatus)}</p>
      <p style={{ fontSize: "0.8rem" }} className="text-muted">
        Ordered On - {moment(new Date(order.orderedOn)).format("MMMM DD, YY")}
      </p>
      <br />
      {order.items.map((item, i) => {
        ////console.log(i);
        return (
          <Col>
            <ShoeRow
              shoeID={item.id}
              key={item.id}
              isMobile={mobile}
              item={item}
            />
            {i + 1 !== order.items.length && (
              <>
                <br />
              </>
            )}
          </Col>
        );
      })}
      <br />
    </Card>
  );
}
