import React, { useEffect, useState } from "react";
import { useAuth } from "contexts/AuthContext";
import NoUserInfo from "../Checkout/NoUserInfo";
import UserInfoExists from "../Checkout/UserInfoExists";
import { userDb } from "../../../firebase.js";
import { Col, Button } from "reactstrap";
import useWindowDimensions from "hooks/useWindowDimensions";

const ShoeAddress = ({
  setShowAddress,
  setShowPayment,
  setShippingAddress,
  setBuyNowState,
}) => {
  const { currentUser, loggedIn } = useAuth();
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    if (loggedIn) {
      const ref = userDb(currentUser.uid);
      // ////console.log("hello");
      ref.on("value", (snapshot) => {
        var userData = snapshot.val();
        // ////console.log(userData);
        // ////console.log("hello2");
        setUserInfo(userData);
      });

      return () => ref.off();
    }
    // eslint-disable-next-line
  }, [loggedIn]);

  const { width, height } = useWindowDimensions();

  var landscape;

  if (width < 768) {
    // mobile = true;
    landscape = false;
  } else if (height < 576 || width < 992) {
    // mobile = false;
    landscape = true;
  } else {
    // mobile = false;
    landscape = false;
  }

  return (
    <Col>
      <h3>Shipping details</h3>
      {loggedIn && !userInfo && (
        <NoUserInfo
          currentUser={currentUser}
          userInfo={userInfo}
          buyNow={true}
          setShowAddress={setShowAddress}
          setShowPayment={setShowPayment}
          setShippingAddress={setShippingAddress}
        />
      )}
      {loggedIn && userInfo && userInfo.name && (
        <div
          id="scrollbar"
          style={{
            overflowY: "auto",
            height: landscape ? "250px" : "500px",
            overflowX: "hidden",
          }}
        >
          <UserInfoExists
            currentUser={currentUser}
            userInfo={userInfo}
            buyNow={true}
            setShowAddress={setShowAddress}
            setShowPayment={setShowPayment}
            setShippingAddress={setShippingAddress}
          />
        </div>
      )}
      {loggedIn && userInfo && !userInfo.name && (
        <div>
          <NoUserInfo
            currentUser={currentUser}
            userInfo={userInfo}
            buyNow={true}
            setShowAddress={setShowAddress}
            setShowPayment={setShowPayment}
            setShippingAddress={setShippingAddress}
          />
        </div>
      )}
      <Button
        color="simple"
        size="sm"
        onClick={() => {
          setShowAddress(false);
          setBuyNowState(true);
        }}
      >
        Go back
      </Button>
    </Col>
  );
};

export default ShoeAddress;
