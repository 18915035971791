export const pharrell = [
    require("assets/custom/alacarte/pharrell/pharrell1.jpeg").default,
    require("assets/custom/alacarte/pharrell/pharrell8.webp").default,
    require("assets/custom/alacarte/pharrell/pharrell3.jpeg").default,
    require("assets/custom/alacarte/pharrell/pharrell4.webp").default,
    require("assets/custom/alacarte/pharrell/pharrell5.jpeg").default,
    require("assets/custom/alacarte/pharrell/pharrell6.jpeg").default,
    require("assets/custom/alacarte/pharrell/pharrell7.webp").default,
    require("assets/custom/alacarte/pharrell/pharrell2.webp").default,
];
  

export const rocky = [
    require("assets/custom/alacarte/rocky/rocky1.png").default,
    require("assets/custom/alacarte/rocky/rocky2.png").default,
    require("assets/custom/alacarte/rocky/rocky3.webp").default,
    require("assets/custom/alacarte/rocky/rocky4.png").default,
    require("assets/custom/alacarte/rocky/rocky5.png").default,
    require("assets/custom/alacarte/rocky/rocky6.webp").default,
    require("assets/custom/alacarte/rocky/rocky7.jpeg").default,   
];

export const kanye = [
    require("assets/custom/alacarte/kanye/kanye1.jpeg").default,
    require("assets/custom/alacarte/kanye/kanye2.jpeg").default,
    require("assets/custom/alacarte/kanye/kanye10.jpeg").default,
    require("assets/custom/alacarte/kanye/kanye6.jpeg").default,
    require("assets/custom/alacarte/kanye/kanye5.jpeg").default,
    require("assets/custom/alacarte/kanye/kanye4.jpeg").default,
    require("assets/custom/alacarte/kanye/kanye7.png").default,
    require("assets/custom/alacarte/kanye/kanye3.jpeg").default,
    require("assets/custom/alacarte/kanye/kanye9.jpeg").default,
    require("assets/custom/alacarte/kanye/kanye8.webp").default,
];

export const pioneer = [
    require("assets/custom/alacarte/pioneer/pioneer1.jpeg").default,
    require("assets/custom/alacarte/pioneer/pioneer2.jpeg").default,
    require("assets/custom/alacarte/pioneer/pioneer3.jpeg").default,
    require("assets/custom/alacarte/pioneer/pioneer4.jpeg").default,
    require("assets/custom/alacarte/pioneer/pioneer5.webp").default,
    require("assets/custom/alacarte/pioneer/pioneer6.jpeg").default,
    require("assets/custom/alacarte/pioneer/pioneer7.webp").default,
];
