import React from "react";
// import useWindowDimensions from "hooks/useWindowDimensions";
import { Row } from "reactstrap";
import Navbar from "../../../components/Navbars/Navbar";
import Footer from "../../../components/Footer/Footer";
import { Container } from "@material-ui/core";

const Terms = () => {
  return (
    <>
      <Navbar />
      <div
        className="wrapper"
        style={{
          marginTop: "100px",
          marginBottom: "5vh",
          alignItems: "center",
          textAlign: "center",
          overflowX: "hidden",
        }}
      >
        <Row
          style={{
            justifyContent: "center",
          }}
        >
          <h2>Terms and Conditions</h2>
        </Row>
        <Container
          style={{
            justifyContent: "center",
          }}
        >
          <div>
            <b>These Terms of Use are effective from May 1st, 2021.</b>
          </div>
        </Container>
      </div>
      <Container style={{ marginBottom: "75px" }}>
        1661, Inc. d/b/a MYSTRY (“MYSTRY”, “we”, “us”, or “our”) provides a
        marketplace for buying new items through our <u>website</u> (“Site”),
        our mobile applications (“Apps”), and any other properties that link to
        these Terms of Use (collectively, the “Service”).
        <br />
        <br />
        <i>Use of Content:</i> MYSTRY grants a personal, non-exclusive,
        non-transferable, revocable, limited license, only for personal,
        non-commercial use to view; reproduce; print; cache; store; and
        distribute content retrieved from the website. Agree not to modify
        services provided on this site or to use modified versions of the
        services.
        <br />
        <br />
        <i>Copyrights and intellectual property:</i> All contents of this site
        are exclusive property of MYSTRY or its partners.
        Sharing/representation/reproduction are not allowed. Anyone doing so
        will incur penalties under copyright infringement law.
        <br />
        <br />
        <i>Brands and Domain name:</i> Domain names, logos, and trademarks on
        this site are trademarks of MYSTRY or its partners. Anyone
        <span style={{ wordWrap: "break-word" }}>
          producing/nesting/representing/distributing/redistributing
        </span>{" "}
        may incur penalties under copyright infringement law.
        <br />
        <br />
        <i>Delivery:</i>
        <ul>
          <li>
            Cost of delivery will be displayed during the checkout process.{" "}
          </li>
          <li>
            <b>
              The delivery fee for MystryBox is included in the subscription
              fee.
            </b>
          </li>
          <li>
            Order will be dispatched once payment has been approved and delivery
            address has been verified.
          </li>
          <li>
            Customer may be contacted if there is a lack of required information
            to complete the order. If said information is not provided during a
            reasonable time or the information is incorrect, the contract will
            be ended.
          </li>
          <li>
            If the box is delayed due to circumstances out of MYSTRY’s control
            the customer will be contacted. MYSTRY is not liable for any said
            setbacks.
          </li>
          <li>
            All Mystry orders are insured against theft and accidental damage
            during delivery until they are delivered.{" "}
            <u>
              If customer is unavailable, the package will be attempted to be
              delivered two more times.
            </u>{" "}
            (tentative)
          </li>
          <li>
            Tracking numbers will be provided once the order is dispatched. All
            orders are aimed to be{" "}
            <u>dispatched within 4-6 working days from order confirmation.</u>
          </li>
          <li>
            Orders are not dispatched on Saturdays, Sundays, Bank Holidays,
            Christmas Day, Boxing Day, and New Year’s Day.
          </li>
        </ul>
        {/* <br /> */}
        <br />
        <i>Changes to terms and policies:</i> changes in policies and terms may
        take place at anytime. Customers will be notified when this happens.
        Continued service of platform after notification of updates terms
        implies agreement with updates terms. If terms and conditions are not
        acceptable, user must delete their account.
        <br />
        <br />
        <i>Passwords and Security:</i>
        <ul>
          <li>
            Access to certain services on this site may require choosing a
            password. Customers are responsible for the confidentiality of their
            account details and for logging in and out at the end of each
            session.
          </li>
          <li>
            In creating an account, customers agree not to impersonate another
            person, use a name or email address which is subject to the rights
            of any person without their permission or use a name that is
            offensive.{" "}
          </li>
          <li>
            Customers are required to notify MYSTRY in the case of any
            unauthorized use of their account. MYSTRY is not liable for any loss
            or damage that arises from customers failure to comply with this
            paragraph.
          </li>
        </ul>
        {/* <br /> */}
        <br />
        <i>Personal Data:</i> MYSTRY’s activities entails the processing of
        personal data in accordance with Mystry’s website privacy notice.
        <br />
        <br />
        <i>Our Contract with you:</i> Please ensure that all order details and
        forms are correctly filled out. We cannot guarantee that we will correct
        any mistakes for you. Post order placement, customers will receive an
        email confirming that the order has been received not that MYSTRY has
        accepted the order. If order cannot be accepted, customer will be
        contacted immediately. MYSTRY only ships products for domestic and
        private use. MYSTRY is not liable for any loss made when dealing with
        the product in a commercial nature.
        <br />
        <br />
        <i>Other parties who may perform the contract:</i> Customers agree that
        MYSTRY may instruct other people, companies or firms to carry out the
        obligations under the contract on their behalf.
        <br />
        <br />
        <i>Cookies:</i> MYSTRY uses cookies in order to better optimize our SEO
        and Marketing Strategies.
        <br />
        <br />
        <i>Fee Changes:</i> MYSTRY may choose to, at its discretion, modify the
        subscription fees. Any change in fees will become effective at the end
        of the then-current billing cycle. Reasonable notice prior to the change
        will be given allowing for enough time to terminate subscription should
        the customer choose to. Continued use of the service implies agreement.
        <br />
        <br />
        <i>Refunds:</i> Since we deal with high end authentic products with
        anonymous price volatility, we are unable to offer returns, exchanges or
        swaps - including if you ordered the wrong size. The good news is that,
        we always assure the best prices in the market, so you can always resell
        in the after-market and recoup your investment.
        <br />
        <br />
        <i>Refunds for MystryBox:</i> We spend a lot of time and manpower in
        sourcing the right product for you at an unmatched price, and as such we
        are unable to offer returns, exchanges or swaps - including if you
        ordered the wrong size. Please ensure that you select the right size
        before signing up for the MystryBox Membership. If you realize that you
        must change/update your size for different brands, please contact us at{" "}
        <a href="mailto: contact@mystry.world">contact@mystry.world</a>.
        <br />
        <br />
        <i>Subscriptions:</i> Service offered by Mystry is billed on a
        subscription basis. You will be billed in advance on a recurring basis
        based on the plan. Subscriptions only available to residents of USA &
        Canada. Subscriptions will automatically renew until MYSTRY or the
        customer cancel it. By providing a valid payment method and billing
        information the customer authorizes MYSTRY to charge all subscription
        fees to the account. Should the automatic billing fail, MYSTRY will
        issue an electronic invoice. Except when required by law, paid
        subscription fees are non-refundable.
        <br />
        <br />
        <i>Membership Cancellation:</i> You can cancel your Mystrybox
        membership at any point in the billing cycle. If you cancel the
        membership after the said month’s billing cycle has elapsed, you shall
        receive your MystryBox for the month, and your membership will be
        terminated thereafter.{" "}
        <u>
          <i>
            <b>There is no cancellation fee</b>. If you would like to reactivate
            your Mystry Box Membership, you will have to rejoin the waitlist
            again.
          </i>
        </u>{" "}
        If you have further questions, please contact us at{" "}
        <a href="mailto: contact@mystry.world">contact@mystry.world</a>
        <br />
        <br />
        <i>Accounts:</i> When a customer creates an account, they must provide
        information that is accurate, complete, and current at all times.
        Failure to do so constitutes a breach of the terms. Customer responsible
        for safeguarding account details. Customers must notify MYSTRY
        immediately upon becoming aware of any breach of security. Must not use
        a username that has the name of a different person or entity that is not
        lawfully available to use.
      </Container>
      <Footer />
    </>
  );
};

export default Terms;
