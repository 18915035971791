import React, { useEffect, useState } from "react";
import {
  StackedCarousel,
  ResponsiveContainer,
} from "react-stacked-center-carousel";
import './Explore.css'
import { Col, Container, Row } from "reactstrap";
import useWindowDimensions from "hooks/useWindowDimensions";
import { useHistory } from "react-router-dom";

export const data = [
  {
    cover: require('assets/custom/ShoeBoxes/2.png').default,
  },
  {
    cover: require('assets/custom/ShoeBoxes/3.png').default,
  },
  {
    cover: require('assets/custom/ShoeBoxes/5.png').default,
  },
  {
    cover: require('assets/custom/ShoeBoxes/6.png').default,
  },
  {
    cover: require('assets/custom/ShoeBoxes/7.png').default,
  },
  {
    cover: require('assets/custom/ShoeBoxes/8.png').default,
  },
  {
    cover: require('assets/custom/ShoeBoxes/9.png').default,
  },
  {
    cover: require('assets/custom/ShoeBoxes/10.png').default,
  },
  {
    cover: require('assets/custom/ShoeBoxes/11.png').default,
  },
  {
    cover: require('assets/custom/ShoeBoxes/12.png').default,
  },
  {
    cover: require('assets/custom/ShoeBoxes/13.png').default,
  },
  {
    cover: require('assets/custom/ShoeBoxes/15.png').default,
  },
  {
    cover: require('assets/custom/ShoeBoxes/1.png').default,
  }
];


const Explore = () => {
  const { width, height } = useWindowDimensions();
  var mobile, landscape;
  if (width < 768) {
    mobile = true;
    landscape = false;
  } else if (height < 576 || width < 992) {
    mobile = false;
    landscape = true;
  } else {
    mobile = false;
    landscape = false;
  }

  const history = useHistory();
  const ref = React.useRef();
  const [time, setTime] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 2500);
    return () => {
      if(ref.current) {
        ref.current?.goNext()
        clearInterval(interval);
      }
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if(ref.current)
        ref.current.goNext()
    }, 2000);
  });

  return (
    <Container
      fluid={true}
      style={{
        marginBottom: "15vh",
        marginTop: "15vh",
        paddingLeft: "8vw",
        paddingRight: "0vw"
      }}
    >
      <Row>
        <Col md={4}>
          <div className="shop-heading">
            <span>SHOP ALL MYSTRY BOXES</span>
          </div>
          <button 
            className="explore-btn"
            onClick={() => history.push('/mystryBoxProduct')}
          >Explore
          </button>
        </Col>
        <Col md={8}>
          <div 
            style={{ 
              width: "100%", 
              position: "relative", 
              height: "45vh",
            }}>
            <ResponsiveContainer
              carouselRef={ref}
              render={(parentWidth, carouselRef) => {
                // If you want to use a ref to call the method of StackedCarousel, you cannot set the ref directly on the carousel component
                // This is because ResponsiveContainer will not render the carousel before its parent's width is determined
                // parentWidth is determined after your parent component mounts. Thus if you set the ref directly it will not work since the carousel is not rendered
                // Thus you need to pass your ref object to the ResponsiveContainer as the carouselRef prop and in your render function you will receive this ref object
                let currentVisibleSlide = 3;
                if (parentWidth <= 1440) currentVisibleSlide = 3;
                if (parentWidth <= 580) currentVisibleSlide = 1;
                return (
                  <StackedCarousel
                    ref={carouselRef}
                    slideComponent={Card}
                    slideWidth={340}
                    carouselWidth={parentWidth}
                    data={data}
                    customScales={[1, 0.85, 0.7, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5]}
                    currentVisibleSlide={currentVisibleSlide}
                    maxVisibleSlide={15}
                  />
                );
              }}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export const Card = React.memo(function (props) {
  const { data, dataIndex } = props;
  const { cover } = data[dataIndex];
  return (
    <div
      style={{
        userSelect: "none",
      }}
      className="slide-component"
    >
      <img
        draggable={false}
        className='explore-shoe'
        src={cover}
      />
      <img
        draggable={false}
        className='explore-box'
        src={require('assets/custom/Shop/MystryBox.png').default}
      />
    </div>
  );
});

export default Explore;