import { LOAD /*, SAVE */ } from "redux-storage";

// redux reducer
const shoesReducer = (state = {}, action) => {
  // ////console.log(action);
  switch (action.type) {
    case LOAD:
      // ////console.log("here:", Object.values(state));
      return {};

    case "SET_SHOES":
      // ////console.log(action.data.shoes);
      // ////console.log(state);
      // const stateKeys = Object.keys(state);
      // const dataKeys = Object.keys()
      // if (state.keys().length)
      return action.data.shoes;

    case "GET_SHOES":
      return state;

    // case "REMOVE_SHOE":
    default:
      return state;
  }
};

// action creators
export const setShoes = (shoes) => {
  return {
    type: "SET_SHOES",
    data: {
      shoes: shoes,
    },
  };
};

export const getShoes = () => {
  return {
    type: "GET_SHOES",
  };
};

export default shoesReducer;
