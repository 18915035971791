import React from "react";
import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";
import { Container, Button } from "reactstrap";
import { useHistory, useLocation } from "react-router-dom";
import { useAuth } from "contexts/AuthContext";
import NavbarV2 from "../pagesV2/Navbar/Navbar";
// import "./LoginSignUp.css";
// import Login from "components/User/Login";
// import SignUp from "components/User/SignUp";

const Verify = () => {
  // const handleMoreClick = (idx) => {
  //   history.push(`/sneakers/${db_init[idx].id}`);
  // };
  const { currentUser } = useAuth();
  const pathName = useLocation().pathname;
  const history = useHistory();
  // ////console.log(pathName);
  // const shoe_id = match.params.id;
  const handleClick = () => {
    currentUser.sendEmailVerification().catch((error) => {
      if (error === "auth/too-many-requests") {
      } else {
        ////console.log(error);
      }
    });
    history.push("/verify");
  };

  return (
    <div>
      <NavbarV2/>
      <Container style={{ marginTop: "100px", marginBottom: "75px" }}>
        {pathName.includes("again") && (
          <div>
            <div>
              Your email needs to be verified before you can login. Please use
              the button below to request a verification link that will be sent
              to your email.
            </div>
            <Button color="cusblack" onClick={handleClick}>
              Request link
            </Button>
          </div>
        )}
        {!pathName.includes("again") && (
          <div>
            Please check your email for a verification link. You can login after
            the verification is complete.
          </div>
        )}
      </Container>
      <Footer />
    </div>
  );
};

export default Verify;
