import { FormGroup, Input, Col, Button, Row } from "reactstrap";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import states from "states-us";

export default function UserLocationForm({
  country,
  setCountry,
  handleClickPrevious,
  handleClickDone,
  setAddress1,
  setAddress2,
  setCity,
  setState,
  setZip,
}) {
  return (
    <div>
      <Col>
        {/* <h2>Where are you from?</h2> */}
        <Col style={{ marginRight: "20px" }}>
          <Row> Select Your Country</Row>
          <Row>
            <CountryDropdown
              value={country}
              // labelType="short"
              onChange={(val) => setCountry(val)}
              priorityOptions={["US", "IN"]}
              blacklist={["SH", "GS", "CD", "KP"]}
              style={{
                paddingTop: "1vh",
                paddingBottom: "1vh",
                margin: "1vh",
                fontSize: 12.5,
                backgroundColor: "black",
                color: "white",
              }}
            />
          </Row>
          {country !== "" && country === "United States" && (
            <div>
              <FormGroup>
                <Row>
                  <Col xs={12}>
                    <Input
                      type="text"
                      name="address1"
                      id="address1"
                      placeholder="Address 1*"
                      onChange={(e) => setAddress1(e.target.value)}
                      required
                      maxLength={100}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col xs={12}>
                    <Input
                      type="text"
                      name="address2"
                      id="address2"
                      placeholder="Address 2"
                      onChange={(e) => setAddress2(e.target.value)}
                      maxLength={100}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col xs={6}>
                    <Input
                      type="text"
                      name="city"
                      id="city"
                      placeholder="City*"
                      onChange={(e) => setCity(e.target.value)}
                      required
                      maxLength={50}
                    />
                  </Col>
                  <Col xs={6}>
                    <Input
                      type="select"
                      name="state"
                      id="state"
                      style={{ backgroundColor: "#1B1B1B" }}
                      onChange={(e) => setState(e.target.value)}
                      required
                    >
                      <option>State*</option>
                      {states.map((state, idx) => (
                        <option key={idx}>{state.abbreviation}</option>
                      ))}
                    </Input>
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Input
                  type="tel"
                  name="zip"
                  id="zip"
                  placeholder="Zip*"
                  onChange={(e) => setZip(e.target.value)}
                  required
                  maxLength={10}
                />
              </FormGroup>
            </div>
          )}
          {country !== "" && country !== "United States" && (
            <div>
              <br />
              We are currently facilitating deliveries only in USA & Canada.
              <br />
              When we expand to your country, you'll be the first to know!
            </div>
          )}
        </Col>
        <br />
        <Row>
          <Col>
            <Button onClick={() => handleClickPrevious()}>Previous</Button>
          </Col>
          <Col>
            <Button color="info" onClick={() => handleClickDone()}>
              Done
            </Button>
          </Col>
        </Row>
      </Col>
    </div>
  );
}
