import React from 'react';
import '@dotlottie/player-component';
import useWindowDimensions from 'hooks/useWindowDimensions';

const SneakerswipeLottie = () => {
  const { width, height } = useWindowDimensions();
  var mobile, landscape;
  if (width < 768) {
    mobile = true;
    landscape = false;
  } else if (height < 576 || width < 992) {
    mobile = false;
    landscape = true;
  } else {
    mobile = false;
    landscape = false;
  }

  return (
    <div className='d-flex justify-content-center align-items-center'>
      <dotlottie-player
        src={require("assets/custom/Sneakerswipe.lottie").default}
        autoplay
        loop
        style={{ 
          height: mobile? '50vh': '40%', 
          width: mobile? '50vh': '40%' 
        }}
      />
    </div>
  );
}

export default SneakerswipeLottie;