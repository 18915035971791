import React from "react";
import { Container, Col, Row } from "reactstrap";
import { useHistory } from "react-router";
import { Carousel } from "react-responsive-carousel";
import useWindowDimensions from "hooks/useWindowDimensions";

const CookBookCarousel = () => {
  const { width, height } = useWindowDimensions();

  // calculate the height of the video on current screen
  // const vidHeight = (width / 4096) * 2160;
  // const videoOffset = height - vidHeight + 9;

  var mobile;
  if (width <= 420 || height <= 420) {
    mobile = true;
  } else {
    mobile = false;
  }

  const cookbookStyle = {
    objectFit: "contain",
    display: "block",
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    minHeight: "100%",
    borderRadius: "0px",
  };

  const fluid = true;

  const containerStyle = {
    // marginBottom: "7.5vw",
    paddingLeft: !mobile && fluid ? "5vw" : mobile && fluid ? "0px" : "",
    paddingRight: !mobile && fluid ? "5vw" : mobile && fluid ? "0px" : "",
  };

  const history = useHistory();
  const brands = [
    "adidas",
    "offwhite",
    "yeezy",
    "nike",
    "airjordan",
    "balenciaga",
    "supreme",
    "newbalance",
    "vans",
    "converse",
  ];
  const handleBrand = (brand) => history.push(`/cookbook/${brands[brand]}`);

  return (
    <div>
      {!mobile && (
        <Container
          fluid={fluid}
          style={{ ...containerStyle, marginTop: "5vh" }}
        >
          <Carousel
            showArrows={true}
            autoPlay={true}
            interval={6000}
            showThumbs={false}
            showIndicators={false}
            showStatus={false}
            infiniteLoop={true}
          >
            <Row>
              <Col
                style={{ paddingRight: "0px", cursor: "pointer" }}
                onClick={() => handleBrand(0)}
              >
                <img
                  src={require("assets/custom/cook/adidas.jpg").default}
                  style={{ borderRadius: "0px" }}
                  className={cookbookStyle}
                  alt=""
                />
              </Col>
              <Col
                style={{ padding: "0px", cursor: "pointer" }}
                onClick={() => handleBrand(1)}
              >
                <img
                  src={require("assets/custom/cook/offwhite.jpg").default}
                  style={{ borderRadius: "0px" }}
                  className={cookbookStyle}
                  alt=""
                />
              </Col>
              <Col
                style={{ padding: "0px", cursor: "pointer" }}
                onClick={() => handleBrand(2)}
              >
                <img
                  src={require("assets/custom/cook/yeezy.jpg").default}
                  style={{ borderRadius: "0px" }}
                  className={cookbookStyle}
                  alt=""
                />
              </Col>
              <Col
                style={{ padding: "0px", cursor: "pointer" }}
                onClick={() => handleBrand(3)}
              >
                <img
                  src={require("assets/custom/cook/nike.jpg").default}
                  style={{ borderRadius: "0px" }}
                  className={cookbookStyle}
                  alt=""
                />
              </Col>
              <Col style={{ cursor: "pointer" }} onClick={() => handleBrand(8)}>
                <img
                  src={require("assets/custom/cook/vans.jpg").default}
                  style={{ borderRadius: "0px" }}
                  className={cookbookStyle}
                  alt=""
                />
              </Col>
            </Row>

            <Row>
              <Col
                style={{ paddingRight: "0px", cursor: "pointer" }}
                onClick={() => handleBrand(4)}
              >
                <img
                  src={require("assets/custom/cook/airjordan.jpg").default}
                  style={{ borderRadius: "0px" }}
                  className={cookbookStyle}
                  alt=""
                />
              </Col>
              <Col
                style={{ padding: "0px", cursor: "pointer" }}
                onClick={() => handleBrand(5)}
              >
                <img
                  src={require("assets/custom/cook/balenciaga.jpg").default}
                  style={{ borderRadius: "0px" }}
                  className={cookbookStyle}
                  alt=""
                />
              </Col>
              <Col
                style={{ padding: "0px", cursor: "pointer" }}
                onClick={() => handleBrand(6)}
              >
                <img
                  src={require("assets/custom/cook/supreme.jpg").default}
                  style={{ borderRadius: "0px" }}
                  className={cookbookStyle}
                  alt=""
                />
              </Col>
              <Col
                style={{ padding: "0px", cursor: "pointer" }}
                onClick={() => handleBrand(7)}
              >
                <img
                  src={require("assets/custom/cook/newbalance.jpg").default}
                  style={{ borderRadius: "0px" }}
                  className={cookbookStyle}
                  alt=""
                />
              </Col>
              <Col
                style={{ padding: "0px", cursor: "pointer" }}
                onClick={() => handleBrand(9)}
              >
                <img
                  src={require("assets/custom/cook/converse.jpg").default}
                  style={{ borderRadius: "0px" }}
                  className={cookbookStyle}
                  alt=""
                />
              </Col>
            </Row>
          </Carousel>
        </Container>
      )}
      {mobile && (
        <Container
          fluid={fluid}
          style={{ ...containerStyle, marginTop: "5vh" }}
        >
          <Carousel
            showArrows={true}
            autoPlay={true}
            interval={6000}
            showThumbs={false}
            showIndicators={false}
            showStatus={false}
            infiniteLoop={true}
          >
            <Row>
              <Col
                style={{ paddingRight: "0px", cursor: "pointer" }}
                onClick={() => handleBrand(0)}
              >
                <img
                  src={require("assets/custom/cook/adidas.jpg").default}
                  style={{ borderRadius: "0px" }}
                  className={cookbookStyle}
                  alt=""
                />
              </Col>
              <Col
                style={{ padding: "0px", cursor: "pointer" }}
                onClick={() => handleBrand(1)}
              >
                <img
                  src={require("assets/custom/cook/offwhite.jpg").default}
                  style={{ borderRadius: "0px" }}
                  className={cookbookStyle}
                  alt=""
                />
              </Col>
            </Row>
            <Row>
              <Col
                style={{ paddingRight: "0px", cursor: "pointer" }}
                onClick={() => handleBrand(2)}
              >
                <img
                  src={require("assets/custom/cook/yeezy.jpg").default}
                  style={{ borderRadius: "0px" }}
                  className={cookbookStyle}
                  alt=""
                />
              </Col>
              <Col
                style={{ padding: "0px", cursor: "pointer" }}
                onClick={() => handleBrand(3)}
              >
                <img
                  src={require("assets/custom/cook/nike.jpg").default}
                  style={{ borderRadius: "0px" }}
                  className={cookbookStyle}
                  alt=""
                />
              </Col>
            </Row>
            <Row>
              <Col
                style={{ paddingRight: "0px", cursor: "pointer" }}
                onClick={() => handleBrand(8)}
              >
                <img
                  src={require("assets/custom/cook/vans.jpg").default}
                  style={{ borderRadius: "0px" }}
                  className={cookbookStyle}
                  alt=""
                />
              </Col>
              <Col
                style={{ padding: "0px", cursor: "pointer" }}
                onClick={() => handleBrand(4)}
              >
                <img
                  src={require("assets/custom/cook/airjordan.jpg").default}
                  style={{ borderRadius: "0px" }}
                  className={cookbookStyle}
                  alt=""
                />
              </Col>
            </Row>
            <Row>
              <Col
                style={{ paddingRight: "0px", cursor: "pointer" }}
                onClick={() => handleBrand(5)}
              >
                <img
                  src={require("assets/custom/cook/balenciaga.jpg").default}
                  style={{ borderRadius: "0px" }}
                  className={cookbookStyle}
                  alt=""
                />
              </Col>
              <Col
                style={{ padding: "0px", cursor: "pointer" }}
                onClick={() => handleBrand(6)}
              >
                <img
                  src={require("assets/custom/cook/supreme.jpg").default}
                  style={{ borderRadius: "0px" }}
                  className={cookbookStyle}
                  alt=""
                />
              </Col>
            </Row>
            <Row>
              <Col
                style={{ paddingRight: "0px", cursor: "pointer" }}
                onClick={() => handleBrand(7)}
              >
                <img
                  src={require("assets/custom/cook/newbalance.jpg").default}
                  style={{ borderRadius: "0px" }}
                  className={cookbookStyle}
                  alt=""
                />
              </Col>
              <Col
                style={{ padding: "0px", cursor: "pointer" }}
                onClick={() => handleBrand(9)}
              >
                <img
                  src={require("assets/custom/cook/converse.jpg").default}
                  style={{ borderRadius: "0px" }}
                  className={cookbookStyle}
                  alt=""
                />
              </Col>
            </Row>
          </Carousel>
        </Container>
      )}
    </div>
  );
};

export default CookBookCarousel;
