import Navbar from "components/Navbars/Navbar.js";
import useWindowDimensions from "hooks/useWindowDimensions";
import Pic1 from "./components/pics/first.jpeg";
import Pic2 from "./components/pics/second.jpeg";
import Pic3 from "./components/pics/third.jpeg";
import Pic4 from "./components/pics/fourth.jpeg";
import Pic5 from "./components/pics/fifth.jpeg";
import Pic6 from "./components/pics/sixth.jpeg";
import { useAuth } from "contexts/AuthContext";
import { Link } from "react-router-dom";
import { userDb } from "../../../firebase.js";
import ReactDatetime from "react-datetime";
import moment from "moment";

import {
  Container,
  Button,
  Card,
  CardHeader,
  FormGroup,
  Input,
  FormText,
  Modal,
  ModalBody,
  ModalFooter,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
import { useState, useEffect } from "react";
import WishlistPage from "./subpages/Wishlist";
import OrdersPage from "./subpages/Orders";
import { ManageAddressesPgae } from "./subpages/Addresses";
import { MystryBoxStatus } from "./subpages/MystryBoxStatus";
import NavbarV2 from "../pagesV2/Navbar/Navbar";
import Footer from "../pagesV2/Footer/Footer";

export default function ProfilePage() {
  const { width, height } = useWindowDimensions();
  var mobile;
  if (width < 768) {
    mobile = true;
  } else if (height < 576 || width < 992) {
    mobile = false;
  } else {
    mobile = false;
  }
  const [tabs, setTabs] = useState(3);
  const [modal, setModal] = useState(false);
  const [addressModal, setAddressModal] = useState(false);
  const [profilePic, setProfilePic] = useState(Pic1);
  const [showScrollDown, setShowScrollDown] = useState(false);
  const toggle = () => setModal(!modal);
  var _ = require("lodash");

  const toggleScrollDown = (e) => {
    if (e.target.currentTime >= 3) {
      setShowScrollDown(true);
    }
  };
  const [userData, setUserData] = useState(null);

  const { currentUser, loggedIn } = useAuth();
  const [dateOfBirth, setDateOfBirth] = useState("");

  const [waitlistID, setWaitlistID] = useState("NotApplied");
  const [userGender, setUserGender] = useState("");
  const [userSize, setUserSize] = useState("");
  const [userEmail, setUserEmail] = useState(loggedIn ? currentUser.email : "");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneCountryCode, setPhoneCountryCode] = useState("+1");

  // const [userBillAdd, setUserBillAdd] = useState('');
  // const [ userPayInfo, setUserPayInfo ] = useState('payment info');
  const [submitted, setSubmitted] = useState(false);

  const getProfilePicFromName = (picName) => {
    if (picName === "Pic1") return Pic1;
    else if (picName === "Pic2") return Pic2;
    else if (picName === "Pic3") return Pic3;
    else if (picName === "Pic4") return Pic4;
    else if (picName === "Pic5") return Pic5;
    else if (picName === "Pic6") return Pic6;
    else return Pic1;
  };

  useEffect(() => {
    if (loggedIn) {
      const refs = userDb(currentUser.uid);
      refs.once("value", (snapshot) => {
        let userInfo = snapshot.val();
        setUserData(userInfo);
        if (userInfo) {
          if (userInfo.name && userInfo.name.firstName)
            setFirstName(userInfo.name.firstName);
          if (userInfo.name && userInfo.name.lastName)
            setLastName(userInfo.name.lastName);
          if (userInfo.phone && userInfo.phone.phoneNumber)
            if (userInfo.phone) setPhone(userInfo.phone.phoneNumber);
          if (userInfo.profilePic) {
            setProfilePic(getProfilePicFromName(userInfo.profilePic));
          } else {
            setProfilePic(Pic1);
          }
          if (userInfo.waitlistID) {
            setWaitlistID(userInfo.waitlistID);
          }
          setDateOfBirth(userInfo.dateOfBirth);
          setUserGender(userInfo.gender);
          setUserEmail(userInfo.email);

          setUserSize(userInfo.size);
        }
      });
    }
  }, [loggedIn, currentUser.uid]);

  const allSizes = [
    "Select size",
    "5 W",
    "5 M",
    "5.5 W",
    "5.5 M",
    "6 W",
    "6 M",
    "6.5 W",
    "6.5 M",
    "7 W",
    "7 M",
    "7.5 W",
    "7.5 M",
    "8 W",
    "8 M",
    "8.5 W",
    "8.5 M",
    "9 W",
    "9 M",
    "9.5 W",
    "9.5 M",
    "10 W",
    "10 M",
    "10.5 W",
    "10.5 M",
    "11 W",
    "11 M",
    "11.5 W",
    "11.5 M",
    "12 W",
    "12 M",
    "12.5 W",
    "12.5 M",
    "13 W",
    "13 M",
    "13.5 W",
    "14 W",
    "14.5 W",
  ];
  const [shoeOptions, setShoeOptions] = useState(allSizes);
  useEffect(() => {
    if (userGender === "Male") {
      const sizes = _.range(5, 13.5, 0.5);
      sizes.unshift("Select size (M)");
      setShoeOptions(sizes);
    } else if (userGender === "Female") {
      const sizes = _.range(6.5, 15, 0.5);
      sizes.unshift("Select size (W)");
      setShoeOptions(sizes);
    } else {
      setShoeOptions(allSizes);
    }
    if (userSize) {
      const ans = userSize;
      setUserSize(ans);
    }
  }, [userGender]);

  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const handleSubmit = (e) => {
    e.preventDefault();
    // if (userEmail === "" || !emailRegex.test(String(userEmail).toLowerCase())) {
    //   toast.error("Please enter a valid email address");
    // } else {
    setSubmitted(!submitted);
    const ref = userDb(currentUser.uid);
    const obj = {
      name: { firstName, lastName },
      phone: { phoneCountryCode, phoneNumber: phone },
      dateOfBirth: dateOfBirth,
      gender: userGender,
      size: userSize,
      guest: false,
    };
    console.log(obj);
    ref.update(obj);
    setModal(!modal);
    // }
  };

  const getProfilePicName = (pic) => {
    if (pic === Pic1) return "Pic1";
    else if (pic === Pic2) return "Pic2";
    else if (pic === Pic3) return "Pic3";
    else if (pic === Pic4) return "Pic4";
    else if (pic === Pic5) return "Pic5";
    else if (pic === Pic6) return "Pic6";
    else return "Pic1";
  };

  const updateProfilePic = (pic) => {
    setProfilePic(pic);
    const ref = userDb(currentUser.uid);
    const obj = {
      profilePic: getProfilePicName(profilePic),
    };

    ref.update(obj);
  };

  return (
    <>
      <NavbarV2 />
      <div
        className="wrapper"
        style={{
          marginBottom: "5vh",
          background: "black",
          fontFamily: "Exo 2",
          overflow: "hidden"
        }}
      >
        <video
          width="100%"
          height={!mobile === true ? "864px" : "0"}
          type="video/mp4"
          autoPlay
          loop
          muted
          playsInline
          style={{
            zIndex: 1,
          }}
          position="relative"
          onTimeUpdate={(e) => toggleScrollDown(e)}
        >
          {/* webm supported by everything besides Apple. Highly compressed, small size */}
          {/* <source
            src={require("assets/custom/skateboard-compressed.webm").default}
            type="video/webm; codecs=vp9"
          ></source> */}
          {/* mp4 video encoded with h264. Widely supported */}
          {/* <source
            src={require("assets/custom/skate-board.mp4").default}
            type="video/mp4"
          ></source> */}
        </video>
        {!loggedIn && (
          <Container
            fluid={true}
            style={{
              paddingLeft: "0vw",
              paddingRight: "0vw",
              position: !mobile === true ? "absolute" : "relative",
              top: "0",
              left: "0",
              zIndex: 2,
            }}
          >
            <div
              className="wrapper"
              style={{
                marginTop: !mobile === true ? "20vh" : "12vh",
                marginBottom: "5vh",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Button color="info" to="/login" tag={Link}>
                Complete your profile
              </Button>
            </div>
          </Container>
        )}
        {loggedIn && (
          <>
            <Container
              fluid={true}
              style={{
                paddingLeft: "0vw",
                paddingRight: "0vw",
                position: !mobile === true ? "absolute" : "relative",
                top: "0",
                left: "0",
                zIndex: 2,
              }}
            >

              <div 
                style={{marginTop: mobile? "8vh": "14vh"}} 
                className='d-flex justify-content-center align-items-center'
              >
                <h1 style={{fontWeight: "600", color: "white"}} className="mb-2">
                  My Profile
                </h1>
              </div>
              <div
                className="wrapper"
                style={{
                  marginTop: !mobile === true ? "15vh" : "5vh",
                  marginBottom: "5vh",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <Col className="ml-auto mr-auto d-flex justify-content-center align-items-center" lg="8" md="12" xs="12" s="12">
                  <Card
                    className="card-coin card-plain"
                    style={{ 
                      backgroundColor: "#D9D9D9", 
                      width: !mobile? "55vw": "90vw",
                      border: "1px solid #D9D9D9",
                      borderRadius: "28px"
                    }}
                    // style={{ backgroundColor: "rgba(185,185,185,0.45)" }}
                  >
                    <CardHeader>
                      <Row>
                        <Col>
                          <img
                            alt="..."
                            className="img-fluid rounded-circle shadow"
                            src={profilePic}
                            style={{ width: "130px", height: "140px" }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <button
                            type="button"
                            onClick={toggle}
                            class="btn-icon btn-round btn btn-primary"
                          >
                            <i className="tim-icons icon-pencil" />
                          </button>
                          <br />
                        </Col>
                      </Row>
                      <Modal
                        isOpen={modal}
                        toggle={toggle}
                        size="md"
                        className="notifyModal"
                        style={{ top: "-260px" }}
                      >
                        <div className="modal-header">
                          <h4
                            className="modal-title"
                            style={{ fontWeight: "bolder", color: "white" }}
                          >
                            Edit Profile :
                          </h4>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-hidden="true"
                            onClick={toggle}
                          >
                            <i className="tim-icons icon-simple-remove" />
                          </button>
                        </div>
                        <ModalBody>
                          <FormText color="primary">
                            Edit your profile image here :
                          </FormText>
                          {!mobile && (
                            <>
                              <Row>
                                <Col
                                  onClick={() => {
                                    updateProfilePic(Pic1);
                                    setModal(!modal);
                                  }}
                                >
                                  <img
                                    alt="..."
                                    className="img-center img-fluid rounded-circle"
                                    // src={require("./components/pics/first.jpeg").default}
                                    src={Pic1}
                                  />
                                </Col>
                                <Col
                                  onClick={() => {
                                    updateProfilePic(Pic2);
                                    setModal(!modal);
                                  }}
                                >
                                  <img
                                    alt="..."
                                    className="img-center img-fluid rounded-circle"
                                    src={Pic2}
                                  />
                                </Col>
                                <Col
                                  onClick={() => {
                                    updateProfilePic(Pic3);
                                    setModal(!modal);
                                  }}
                                >
                                  <img
                                    alt="..."
                                    className="img-center img-fluid rounded-circle"
                                    src={Pic3}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col
                                  onClick={() => {
                                    updateProfilePic(Pic4);
                                    setModal(!modal);
                                  }}
                                >
                                  <img
                                    alt="..."
                                    className="img-center img-fluid rounded-circle"
                                    src={Pic4}
                                  />
                                </Col>
                                <Col
                                  onClick={() => {
                                    updateProfilePic(Pic5);
                                    setModal(!modal);
                                  }}
                                >
                                  <img
                                    alt="..."
                                    className="img-center img-fluid rounded-circle"
                                    src={Pic5}
                                  />
                                </Col>
                                <Col
                                  onClick={() => {
                                    updateProfilePic(Pic6);
                                    setModal(!modal);
                                  }}
                                >
                                  <img
                                    alt="..."
                                    className="img-center img-fluid rounded-circle"
                                    src={Pic6}
                                  />
                                </Col>
                              </Row>
                            </>
                          )}
                          {mobile && (
                            <>
                              <Row>
                                <Col
                                  onClick={() => {
                                    updateProfilePic(Pic1);
                                    setModal(!modal);
                                  }}
                                >
                                  <img
                                    alt="..."
                                    className="img-center img-fluid rounded-circle"
                                    // src={require("./components/pics/first.jpeg").default}
                                    src={Pic1}
                                  />
                                </Col>
                                <Col
                                  onClick={() => {
                                    updateProfilePic(Pic2);
                                    setModal(!modal);
                                  }}
                                >
                                  <img
                                    alt="..."
                                    className="img-center img-fluid rounded-circle"
                                    src={Pic2}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col
                                  onClick={() => {
                                    updateProfilePic(Pic3);
                                    setModal(!modal);
                                  }}
                                >
                                  <img
                                    alt="..."
                                    className="img-center img-fluid rounded-circle"
                                    src={Pic3}
                                  />
                                </Col>
                                <Col
                                  onClick={() => {
                                    updateProfilePic(Pic4);
                                    setModal(!modal);
                                  }}
                                >
                                  <img
                                    alt="..."
                                    className="img-center img-fluid rounded-circle"
                                    src={Pic4}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col
                                  onClick={() => {
                                    updateProfilePic(Pic5);
                                    setModal(!modal);
                                  }}
                                >
                                  <img
                                    alt="..."
                                    className="img-center img-fluid rounded-circle"
                                    src={Pic5}
                                  />
                                </Col>
                                <Col
                                  onClick={() => {
                                    updateProfilePic(Pic6);
                                    setModal(!modal);
                                  }}
                                >
                                  <img
                                    alt="..."
                                    className="img-center img-fluid rounded-circle"
                                    src={Pic6}
                                  />
                                </Col>
                              </Row>
                            </>
                          )}
                        </ModalBody>
                        <ModalFooter>
                          <Col>
                            <FormText color="primary">
                              Edit your details here :
                            </FormText>
                            <br></br>
                            <FormGroup>
                              <Row>
                                <Col xs={6}>
                                  <Input
                                    value={firstName}
                                    type="text"
                                    name="firstName"
                                    id="firstName"
                                    placeholder="First Name*"
                                    onChange={(e) =>
                                      setFirstName(e.target.value)
                                    }
                                    required
                                  />
                                </Col>
                                <Col xs={6}>
                                  <Input
                                    value={lastName}
                                    type="text"
                                    name="lastName"
                                    id="lastName"
                                    placeholder="Last Name*"
                                    onChange={(e) =>
                                      setLastName(e.target.value)
                                    }
                                    required
                                  />
                                </Col>
                              </Row>
                            </FormGroup>
                            <FormGroup>
                              <Row>
                                <Col>
                                  <Input
                                    value={phone}
                                    type="tel"
                                    name="phone"
                                    id="phone"
                                    placeholder="Phone number*"
                                    onChange={(e) => setPhone(e.target.value)}
                                    title="Please only use numbers"
                                    pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                                    required
                                  />
                                </Col>
                              </Row>
                            </FormGroup>
                            <div className="datepicker-container">
                              <FormGroup>
                                <ReactDatetime
                                  value={moment(dateOfBirth).format(
                                    "DD-MMM-YYYY"
                                  )}
                                  inputProps={{
                                    className: "form-control",
                                    placeholder: "Date of Birth*",
                                  }}
                                  dateFormat="DD-MMM-YYYY"
                                  timeFormat={false}
                                  utc={true}
                                  isValidDate={(current) => {
                                    return current.isBefore(
                                      moment().add(0, "day")
                                    );
                                  }}
                                  onChange={(e) => {
                                    try {
                                      console.log(e.toDate());
                                      setDateOfBirth(e.toDate());
                                    } catch (err) {}
                                  }}
                                />
                              </FormGroup>
                            </div>
                            <FormGroup>
                              <Input
                                placeholder="Gender"
                                type="select"
                                name="select"
                                id="gender"
                                required="true"
                                value={userGender}
                                onChange={(e) => setUserGender(e.target.value)}
                              >
                                <option
                                  style={{
                                    background: "black",
                                    color: "white",
                                  }}
                                >
                                  Select Your Gender*
                                </option>
                                <option
                                  style={{
                                    background: "black",
                                    color: "white",
                                  }}
                                >
                                  Male
                                </option>
                                <option
                                  style={{
                                    background: "black",
                                    color: "white",
                                  }}
                                >
                                  Female
                                </option>
                                <option
                                  style={{
                                    background: "black",
                                    color: "white",
                                  }}
                                >
                                  Other
                                </option>
                              </Input>
                            </FormGroup>
                            <FormGroup>
                              {/* <Input placeholder="Your Size*" type="text" value={userSize} onChange={(e) => setUserSize(e.target.value)} 
														style={{color:""}}
														/> */}
                              <Input
                                placeholder="Select Shoe Size*"
                                type="select"
                                value={userSize}
                                onChange={(e) => setUserSize(e.target.value)}
                              >
                                {shoeOptions.map((val, idx) => (
                                  <option
                                    style={{
                                      background: "black",
                                      color: "white",
                                    }}
                                    key={idx}
                                  >
                                    {val}
                                  </option>
                                ))}
                              </Input>
                            </FormGroup>
                          </Col>
                        </ModalFooter>
                        <ModalFooter>
                          <Button
                            type="submit"
                            color="info"
                            size="sm"
                            onClick={handleSubmit}
                          >
                            Save
                          </Button>
                        </ModalFooter>
                      </Modal>
                      <Modal
                        isOpen={addressModal}
                        toggle={toggle}
                        size="md"
                        className="notifyModal"
                        style={{ top: "0px" }}
                      >
                        <div className="modal-header">
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-hidden="true"
                            onClick={() => {
                              setAddressModal(false);
                            }}
                          >
                            <i className="tim-icons icon-simple-remove" />
                          </button>
                        </div>

                        <ManageAddressesPgae
                          userInfo={userData}
                          style={{ margin: "10px" }}
                        />
                      </Modal>
                      <Col>
                        <br />
                        <h3 style={{ color: "black", fontWeight: "700" }} className="mb-4">Personal Details</h3>
                        <h4 style={{ color: "black", marginBottom: "15px" }}>
                          {firstName + " " + lastName}
                        </h4>
                        <h4 style={{ color: "black", marginBottom: "15px" }}>{currentUser.email}</h4>
                        <h4 style={{ color: "black" }}>{phone}</h4>
                        {/* <h4 style={{ color: "black" }}>{userAddress1}</h4>
                        <h4 style={{ color: "black" }}>{userAddress2}</h4>
                        <h4 style={{ color: "black" }}>{userCity}</h4>
                        <h4 style={{ color: "black" }}>{userState}</h4>
                        <h4 style={{ color: "black" }}>{userZip}</h4> */}
                        {/* <h4 style={{ color: "black" }}>{userBillAdd}</h4> */}
                        <h4 style={{ color: "black", marginBottom: "15px" }}>
                          {moment(dateOfBirth).format("DD-MMM-YYYY")}
                        </h4>
                        <h4 style={{ color: "black", marginBottom: "15px" }}>{userSize}</h4>
                        <h4 style={{ color: "black", marginBottom: "15px" }}>{userGender}</h4>
                        {/* {userData && (
                          <Button
                            color="info"
                            onClick={() => {
                              setAddressModal(true);
                            }}
                          >
                            Manage Addresses
                          </Button>
                        )} */}
                      </Col>

                      {/* <Col>
                    <h3 className="title" style={{ color: "black" }}>
                      {userName}
                    </h3> */}
                      {/* <h4 className="text-info" style={{ color: "black" }}>
                      His and Hers
                    </h4> */}
                      {/* <h4 style={{ color: "black" }}>{userEmail}</h4>
                    <h4 style={{ color: "black" }}>{userPhone}</h4>
                    <h4 style={{ color: "black" }}>{userShipAdd}</h4>
                    <h4 style={{ color: "black" }}>{userBillAdd}</h4>
                    <h4 style={{ color: "black" }}>{userAge}</h4>
                    <h4 style={{ color: "black" }}>{userSize}</h4>
                    <h4 style={{ color: "black" }}>{userGender}</h4>
                  </Col> */}
                    </CardHeader>
                  </Card>
                </Col>
              </div>
            </Container>
            <Container
              fluid={true}
              style={{ paddingLeft: "0vw", paddingRight: "0vw" }}
            >
              <MystryBoxStatus />
              {/* <Nav className="nav-tabs-primary justify-content-center" tabs>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: tabs === 1,
                    })}
                    onClick={(e) => {
                      e.preventDefault();
                      setTabs(1);
                    }}
                  >
                    Orders
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: tabs === 2,
                    })}
                    onClick={(e) => {
                      e.preventDefault();
                      setTabs(2);
                    }}
                  >
                    Cart
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: tabs === 3,
                    })}
                    onClick={(e) => {
                      e.preventDefault();
                      setTabs(3);
                    }}
                  >
                    My Wishlist
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: tabs === 4,
                    })}
                    onClick={(e) => {
                      e.preventDefault();
                      setTabs(4);
                    }}
                  >
                    Mystry Box
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent
                className="tab-subcategories"
                activeTab={"tab" + tabs}
              >
                <TabPane tabId="tab1">
                  <OrdersPage />
                </TabPane>
                <TabPane tabId="tab2">
                  <CartPage />
                </TabPane>
                <TabPane tabId="tab3">
                  <WishlistPage />
                </TabPane>
                <TabPane tabId="tab4">
                  <MystryBoxStatus waitlistID={waitlistID} />
                </TabPane>
              </TabContent> */}
            </Container>
          </>
        )}
        <Footer />
      </div>
    </>
  );
}
