import {
  FormGroup,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Input,
  Row,
} from "reactstrap";
import { useEffect, useState } from "react";
// import Pic1 from "./components/pics/first.jpeg";
import Pic1 from "./../../../views/pages/ProfilePage/components/pics/first.jpeg";
import Pic2 from "./../../../views/pages/ProfilePage/components/pics/second.jpeg";
import Pic3 from "./../../../views/pages/ProfilePage/components/pics/third.jpeg";
import Pic4 from "./../../../views/pages/ProfilePage/components/pics/fourth.jpeg";
import Pic5 from "./../../../views/pages/ProfilePage/components/pics/fifth.jpeg";
import Pic6 from "./../../../views/pages/ProfilePage/components/pics/sixth.jpeg";
import useWindowDimensions from "hooks/useWindowDimensions";

var _ = require("lodash");
export default function UserHandlesPage({
  // tickTokHandle,
  // setTickTokHandle,
  // instaHandle,
  // setInstaHandle,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  userAge,
  setUserAge,
  userGender,
  setUserGender,
  userSize,
  setUserSize,
  tierSelected,
  setTierSelected,
  phone,
  setPhone,
  profilePic,
  setProfilePic,
}) {
  const [modal, setModal] = useState(false);

  const getProfilePicFromName = (picName) => {
    if (picName === "Pic1") return Pic1;
    else if (picName === "Pic2") return Pic2;
    else if (picName === "Pic3") return Pic3;
    else if (picName === "Pic4") return Pic4;
    else if (picName === "Pic5") return Pic5;
    else if (picName === "Pic6") return Pic6;
    else return Pic1;
  };
  const getProfilePicName = (pic) => {
    if (pic === Pic1) return "Pic1";
    else if (pic === Pic2) return "Pic2";
    else if (pic === Pic3) return "Pic3";
    else if (pic === Pic4) return "Pic4";
    else if (pic === Pic5) return "Pic5";
    else if (pic === Pic6) return "Pic6";
    else return "Pic1";
  };
  const width = useWindowDimensions().width;
  var mobile;
  if (width <= 420) {
    mobile = true;
  } else {
    mobile = false;
  }

  const updateProfilePic = (pic) => {
    setProfilePic(pic);
  };
  const allSizes = [
    "Select size",
    "5 W",
    "5 M",
    "5.5 W",
    "5.5 M",
    "6 W",
    "6 M",
    "6.5 W",
    "6.5 M",
    "7 W",
    "7 M",
    "7.5 W",
    "7.5 M",
    "8 W",
    "8 M",
    "8.5 W",
    "8.5 M",
    "9 W",
    "9 M",
    "9.5 W",
    "9.5 M",
    "10 W",
    "10 M",
    "10.5 W",
    "10.5 M",
    "11 W",
    "11 M",
    "11.5 W",
    "11.5 M",
    "12 W",
    "12 M",
    "12.5 W",
    "12.5 M",
    "13 W",
    "13 M",
    "13.5 W",
    "14 W",
    "14.5 W",
  ];
  const [shoeOptions, setShoeOptions] = useState(allSizes);
  useEffect(() => {
    if (userGender === "Male") {
      const sizes = _.range(5, 13.5, 0.5);
      sizes.unshift("Select size (M)");
      setShoeOptions(sizes);
    } else if (userGender === "Female") {
      const sizes = _.range(6.5, 15, 0.5);
      sizes.unshift("Select size (W)");
      setShoeOptions(sizes);
    } else {
      setShoeOptions(allSizes);
    }

    if (userSize) {
      const ans = userSize;
      setUserSize(ans);
    }
  }, [userGender]);

  var tierprint;
  if (tierSelected === "TIER_1") tierprint = "Stylite Box";
  else tierprint = "Solemate Box";
  return (
    <Col>
      <Col style={{ marginTop: "5vh", marginBottom: "5vh" }}>{tierprint} </Col>
      <Row>
        <Col>
          <img
            alt="..."
            className="img-fluid rounded-circle shadow"
            src={profilePic}
            style={{ width: "130px", height: "140px" }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <button
            type="button"
            onClick={() => setModal(!modal)}
            class="btn-icon btn-round btn btn-primary"
          >
            <i className="tim-icons icon-pencil" />
          </button>
          <br />
        </Col>
      </Row>
      <Modal
        isOpen={modal}
        toggle={() => setModal(!modal)}
        size="md"
        className="notifyModal"
      >
        <div className="modal-header">
          <h4
            className="modal-title"
            style={{ fontWeight: "bolder", color: "blue" }}
          >
            Choose your Avatar:
          </h4>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-hidden="true"
            onClick={() => setModal(false)}
          >
            <i className="tim-icons icon-simple-remove" />
          </button>
        </div>
        <ModalBody>
          {!mobile && (
            <>
              <Row>
                <Col
                  onClick={() => {
                    updateProfilePic(Pic1);
                    setModal(!modal);
                  }}
                >
                  <img
                    alt="..."
                    className="img-center img-fluid rounded-circle"
                    // src={require("./components/pics/first.jpeg").default}
                    src={Pic1}
                  />
                </Col>
                <Col
                  onClick={() => {
                    updateProfilePic(Pic2);
                    setModal(!modal);
                  }}
                >
                  <img
                    alt="..."
                    className="img-center img-fluid rounded-circle"
                    src={Pic2}
                  />
                </Col>
                <Col
                  onClick={() => {
                    updateProfilePic(Pic3);
                    setModal(!modal);
                  }}
                >
                  <img
                    alt="..."
                    className="img-center img-fluid rounded-circle"
                    src={Pic3}
                  />
                </Col>
              </Row>
              <Row>
                <Col
                  onClick={() => {
                    updateProfilePic(Pic4);
                    setModal(!modal);
                  }}
                >
                  <img
                    alt="..."
                    className="img-center img-fluid rounded-circle"
                    src={Pic4}
                  />
                </Col>
                <Col
                  onClick={() => {
                    updateProfilePic(Pic5);
                    setModal(!modal);
                  }}
                >
                  <img
                    alt="..."
                    className="img-center img-fluid rounded-circle"
                    src={Pic5}
                  />
                </Col>
                <Col
                  onClick={() => {
                    updateProfilePic(Pic6);
                    setModal(!modal);
                  }}
                >
                  <img
                    alt="..."
                    className="img-center img-fluid rounded-circle"
                    src={Pic6}
                  />
                </Col>
              </Row>
            </>
          )}
          {mobile && (
            <>
              <Row>
                <Col
                  onClick={() => {
                    updateProfilePic(Pic1);
                    setModal(!modal);
                  }}
                >
                  <img
                    alt="..."
                    className="img-center img-fluid rounded-circle"
                    // src={require("./components/pics/first.jpeg").default}
                    src={Pic1}
                  />
                </Col>
                <Col
                  onClick={() => {
                    updateProfilePic(Pic2);
                    setModal(!modal);
                  }}
                >
                  <img
                    alt="..."
                    className="img-center img-fluid rounded-circle"
                    src={Pic2}
                  />
                </Col>
              </Row>
              <Row>
                <Col
                  onClick={() => {
                    updateProfilePic(Pic3);
                    setModal(!modal);
                  }}
                >
                  <img
                    alt="..."
                    className="img-center img-fluid rounded-circle"
                    src={Pic3}
                  />
                </Col>
                <Col
                  onClick={() => {
                    updateProfilePic(Pic4);
                    setModal(!modal);
                  }}
                >
                  <img
                    alt="..."
                    className="img-center img-fluid rounded-circle"
                    src={Pic4}
                  />
                </Col>
              </Row>
              <Row>
                <Col
                  onClick={() => {
                    updateProfilePic(Pic5);
                    setModal(!modal);
                  }}
                >
                  <img
                    alt="..."
                    className="img-center img-fluid rounded-circle"
                    src={Pic5}
                  />
                </Col>
                <Col
                  onClick={() => {
                    updateProfilePic(Pic6);
                    setModal(!modal);
                  }}
                >
                  <img
                    alt="..."
                    className="img-center img-fluid rounded-circle"
                    src={Pic6}
                  />
                </Col>
              </Row>
            </>
          )}
        </ModalBody>
      </Modal>
      <br />
      <FormGroup>
        <Row>
          <Col xs={6}>
            <Input
              value={firstName}
              type="text"
              name="firstName"
              id="firstName"
              placeholder="First Name*"
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
          </Col>
          <Col xs={6}>
            <Input
              value={lastName}
              type="text"
              name="lastName"
              id="lastName"
              placeholder="Last Name*"
              onChange={(e) => setLastName(e.target.value)}
              required
            />
          </Col>
        </Row>
      </FormGroup>
      <FormGroup>
        <Row>
          <Col>
            <Input
              value={phone}
              type="tel"
              name="phone"
              id="phone"
              placeholder="Phone number*"
              onChange={(e) => setPhone(e.target.value)}
              title="Please only use numbers"
              pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
              required
            />
          </Col>
        </Row>
      </FormGroup>
      <FormGroup>
        <Input
          placeholder="Your Date of Birth*"
          type="date"
          value={userAge}
          onChange={(e) => setUserAge(e.target.value)}
          style={{ color: "" }}
        />
      </FormGroup>
      <FormGroup>
        <Input
          placeholder="Gender"
          type="select"
          name="select"
          id="gender"
          required="true"
          value={userGender}
          onChange={(e) => setUserGender(e.target.value)}
        >
          <option
            style={{
              background: "black",
              color: "white",
            }}
          >
            Select Your Gender*
          </option>
          <option
            style={{
              background: "black",
              color: "white",
            }}
          >
            Male
          </option>
          <option
            style={{
              background: "black",
              color: "white",
            }}
          >
            Female
          </option>
          <option
            style={{
              background: "black",
              color: "white",
            }}
          >
            Other
          </option>
        </Input>
      </FormGroup>
      <FormGroup>
        {/* <Input placeholder="Your Size*" type="text" value={userSize} onChange={(e) => setUserSize(e.target.value)} 
														style={{color:""}}
														/> */}
        <Input
          placeholder="Select Shoe Size*"
          type="select"
          value={userSize}
          onChange={(e) => setUserSize(e.target.value)}
        >
          {shoeOptions.map((val, idx) => (
            <option
              style={{
                background: "black",
                color: "white",
              }}
              key={idx}
            >
              {val}
            </option>
          ))}
        </Input>
      </FormGroup>

      <br />
    </Col>
  );
}
