import React from "react";
import { useState, useEffect } from "react";
import { database } from "../../../../firebase.js";
import moment from "moment";
import useWindowDimensions from "hooks/useWindowDimensions";
import "./UserInfo.css";

import {
  Col,
  Container,
  FormGroup,
  Input,
  Row,
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  ButtonGroup,
  ButtonToolbar,
  ModalBody,
  Modal,
  UncontrolledTooltip,
} from "reactstrap";
import { useAuth } from "contexts/AuthContext";
import MyNavbar from "components/Navbars/Navbar.js";
import LoadingPageMystryBox from "./LoadingPageMystryBox";
import { useHistory, useLocation } from "react-router";
import ReactDatetime from "react-datetime";
import SneakerTinderMain from "../Sneakerswipe/SneakerTinderMain.js";
import "react-datepicker/dist/react-datepicker.css";
import { IoInformationCircleSharp } from "react-icons/io5";

export default function UserInfoV2(props) {
  const { loggedIn, currentUser } = useAuth();
  const [name, setName] = useState("");
  const history = useHistory();
  const location = useLocation();

  const map = {
    "Nike Airforce": "Air Force 1",
    "Air Jordan": "Air Jordan",
    "Nike Dunk": "Nike-Dunks",
    YEEZY: "Yeezy",
  };

  const my_api_map = {
    Vanilla: 1,
    "Air Force 1": 2,
    "Air Jordan": 3,
    "Nike-Dunks": 4,
    Yeezy: 5,
  };

  var chosenBrand = "Vanilla";
  var chosenBrandTier = my_api_map[chosenBrand];
  if (location && location.state && location.state.brand) {
    chosenBrand = map[location.state.brand];
    chosenBrandTier = my_api_map[chosenBrand];
  }

  console.log("chosenBrand");
  console.log(chosenBrand);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [countryCode, setCountryCode] = useState("+1");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [receiver, setReceiver] = useState("Myself");
  const [userMessage, setUserMessage] = useState("No Message");

  const BRANDS = [
    "Nike",
    "Adidas",
    "Air Jordan",
    "Off-White",
    "Converse",
    "Yeezy",
    "Nike Air Force",
    "New Balance",
    "Nike Dunk",
    "Supreme",
  ];
  const SIZE_CHARTS = ["US", "EU", "UK"];
  const GENDERS = ["Men", "Women", "Grade School"];
  const SNEAKER_COUNT = ["1-10", "10-25", "25-50", "More than 50"];

  const [prefferedBrand, setPrefferedBrand] = useState([]);
  const [prefferedSizeChart, setPrefferedSizeChart] = useState(SIZE_CHARTS[0]);
  const [prefferedSneakerSize, setPrefferedSneakerSize] =
    useState("Select Size");
  const [prefferedGender, setPrefferedGender] = useState(GENDERS[0]);
  const [preferNewShoes, setPreferNewShoes] = useState("new");
  const [sneakerCount, setSneakerCount] = useState(SNEAKER_COUNT[0]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSSVisible, setIsSSVisible] = useState(false);
  const [isSSSwitchVisible, setIsSSSwitchVisible] = useState(false);
  const [stripeURL, setStripeURL] = useState("");
  const [mystryBoxDropIsLive, setMystryBoxDropIsLive] = useState(true);
  const [allPreferncesSpecified, setAllPreferncesSpecified] = useState(true);
  const { width, height } = useWindowDimensions();

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const handleSizeClick = (currSize, idx) => {
    if (currSize === size) {
      setButtonColor(_.fill(Array(21), "simple"));
    } else {
      setPrefferedSneakerSize(currSize);
      let colorArr = _.fill(Array(21), "simple");
      colorArr[idx] = "neutral";
      setButtonColor(colorArr);
    }
    setModal(!modal);
  };

  const removeBrand = (brand) => {
    var element = document.getElementById(brand);
    element.classList.remove("brand-btn-pressed");
    element.classList.add("brand-btn");
    var brands = prefferedBrand;
    var index = brands.indexOf(brand);
    if (index !== -1) {
      brands.splice(index, 1);
      setPrefferedBrand(brands);
    }
  };

  const addBrand = (brand) => {
    var element = document.getElementById(brand);
    element.classList.remove("brand-btn");
    element.classList.add("brand-btn-pressed");
    setPrefferedBrand((prevBrands) => [...prevBrands, brand]);
  };

  const handlePrefferedBrand = (brand) => {
    prefferedBrand.includes(brand) ? removeBrand(brand) : addBrand(brand);
  };

  var _ = require("lodash");
  const [size, setSize] = useState("");
  const [buttonColor, setButtonColor] = useState(_.fill(Array(21), "simple"));
  const sizes = _.range(5, 16, 1);

  var mobile;
  if (width <= 576 || height <= 576) {
    mobile = true;
  } else {
    mobile = false;
  }

  // const checkIfLive = async () => {
  //   try {
  //     setIsLoading(true);

  //     database.mystryBoxDropStatus.once("value", (snapshot) => {
  //       console.log("status");
  //       console.log(snapshot.val()["status"]);
  //       setMystryBoxDropIsLive(snapshot.val()["status"]);
  //       setIsLoading(false);
  //       // return snapshot.val()["status"];
  //     });
  //   } catch (error) {
  //     setIsLoading(false);
  //     console.log("ffff");
  //     return false;
  //   }
  // };

  // FIXME
  useEffect(() => {
    // console.log("bla");
    setIsLoading(true);

    fetch(
      // "http://localhost:4500/check-mystrybox-waitlist-details",
      "https://mystry-server-z574yasx7q-uc.a.run.app/check-mystrybox-waitlist-details",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: currentUser.email,
        }),
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        return Promise.reject(response);
      })
      .then((responseText) => {
        console.log("drop status ");
        console.log(responseText["dropStatus"]);
        // setMystryBoxDropIsLive(responseText["dropStatus"]);
        setIsLoading(false);

        if (responseText["awsm"] !== "awsm") {
          buyMystryBox(responseText["prefObj"]);
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        // history.push({ pathname: `/failurePayment` });
        console.error(error);
        setIsLoading(false);
      });
  }, []);

  function validateForm() {
    if (
      prefferedBrand !== "Preferred Brand" &&
      prefferedSneakerSize !== "Select Size" &&
      firstName !== "" &&
      lastName !== "" &&
      dateOfBirth !== "" &&
      phoneNumber !== ""
    ) {
      return true;
    }
    return false;
  }

  function handleProceed() {
    if (validateForm()) {
      setAllPreferncesSpecified(true);
      addUserToWaitlist();
    } else {
      setAllPreferncesSpecified(false);
    }
  }

  const addUserToWaitlist = () => {
    var preferencesObj = {
      name: { firstName, lastName },
      dateOfBirth: dateOfBirth,
      email: currentUser.email,
      prefferedBrand: prefferedBrand,
      prefferedSneakerSize: prefferedSneakerSize,
      prefferedSizeChart: prefferedSizeChart,
      prefferedGender: prefferedGender,
      // prefferedShirtSize: prefferedShirtSize,
      phone: { countryCode, phoneNumber },
      sneakerCount: sneakerCount,
      preferNewShoes: preferNewShoes,
      userMessage: userMessage,
      lastEditedAt: Date.now(),
    };

    console.log(preferencesObj);

    database.mystryBoxSignUpList.child(currentUser.uid).update(preferencesObj);
    database.users.child(currentUser.uid).update(preferencesObj);

    buyMystryBox(preferencesObj);
  };

  const buyMystryBox = async (preferencesObj) => {
    if (mystryBoxDropIsLive) {
      setIsLoading(true);
      console.log("proccessing");

      var obj = {
        email: currentUser.email,
        userID: currentUser.uid,
      };

      fetch(
        "https://mystry-server-z574yasx7q-uc.a.run.app/create-mystrybox-session",
        // "http://localhost:4500/create-mystrybox-session",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            tier: chosenBrandTier,
            customerID: currentUser.uid,
            customer: obj,
            preferencesObj: preferencesObj,
          }),
        }
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          return Promise.reject(response);
        })
        .then((responseText) => {
          console.log(responseText["url"]);
          setStripeURL(responseText["url"]);
          setIsSSSwitchVisible(true);

          // window.location.replace(responseText["url"]);
          setIsLoading(false);
        })
        .catch((error) => {
          history.push({ pathname: `/failurePayment` });
          console.error(error);
        });
    } else {
      setStripeURL("waitlist");
      setIsSSSwitchVisible(true);
    }
    console.log("boolLive2");
    // console.log(boolLive);
  };

  const DropdownCustom = (val, setVal, CONST_VAL) => {
    return (
      // <Col>
      <UncontrolledDropdown>
        <DropdownToggle
          caret
          data-toggle="dropdown"
          onClick={(e) => e.preventDefault()}
          style={{
            background: "black",
            borderRadius: 10,
            border: "1px solid #626262",
          }}
        >
          {val}
        </DropdownToggle>
        <DropdownMenu className="dropdown-with-icons">
          {CONST_VAL.map((item, idx) => (
            <DropdownItem onClick={() => setVal(item)} key={`${idx}`}>
              {item}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
      // </Col>
    );
  };

  function handlePreferenceRadio(changeEvent) {
    setPreferNewShoes(changeEvent.target.value);
  }

  function handleReceiver(changeEvent) {
    setReceiver(changeEvent.target.value);
  }
  // return <SneakerTinderMain currentUserID={currentUser.uid} />;

  return (
    <div>
      {loggedIn && isLoading && <LoadingPageMystryBox />}
      {loggedIn && !isLoading && !isSSVisible && !isSSSwitchVisible && (
        <div
          className="wrapper"
          style={{
            position: "relative",
            overflow: "hidden",
            background: "black",
          }}
        >
          <Container
            fluid={true}
            style={{
              marginTop: "100px",
              marginBottom: "75px",
              paddingLeft: "5vw",
              paddingRight: "5vw",
              background: "black",
            }}
          >
            <MyNavbar />
            <Row>
              <Col xs={0} md={6}>
                <video
                  width="100%"
                  autoPlay
                  loop
                  muted
                  playsInline
                  style={{
                    zIndex: 1,
                    marginTop: "2vh",
                  }}
                >
                  <source
                    src={require("assets/custom/aboutuscropped.mp4").default}
                    type="video/mp4; codecs=hevc"
                  ></source>
                  <source
                    src={require("assets/custom/aboutuscropped.webm").default}
                    type="video/webm; codecs=vp9"
                  ></source>
                  <source
                    src={require("assets/custom/aboutuscropped.mp4").default}
                    type="video/mp4"
                  ></source>
                </video>
              </Col>
              <Col xs={12} md={6}>
                <h2 style={{ fontWeight: "600", color: "white" }}>
                  Let us prepare your Mystry Box
                </h2>
                <Row>
                  <Col md={5}>
                    <FormGroup>
                      <Input
                        type="name"
                        name="name"
                        id="firstName"
                        placeholder="First Name*"
                        onChange={(e) => setFirstName(e.target.value)}
                        required={true}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={5}>
                    <FormGroup>
                      <Input
                        type="name"
                        name="name"
                        id="lastName"
                        placeholder="Last Name*"
                        onChange={(e) => setLastName(e.target.value)}
                        required={true}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={5}>
                    <FormGroup>
                      <Input
                        placeholder="DOB*"
                        type="text"
                        id="dob"
                        name="date"
                        onChange={(e) => setDateOfBirth(e.target.value)}
                        onFocus={(e) => (e.target.type = "date")}
                        required
                      />
                    </FormGroup>
                  </Col>
                  {/* <Col md={5}>
                    <div className="datepicker-container">
                      <FormGroup>
                        <ReactDatetime
                          inputProps={{
                            className: "form-control",
                            placeholder: "Date of Birth*",
                          }}
                          dateFormat="DD-MMM-YYYY"
                          timeFormat={false}
                          utc={true}
                          isValidDate={(current) => {
                            return current.isBefore(moment().add(0, "day"));
                          }}
                          onChange={(e) => {
                            try {
                              console.log(e.toDate());
                              setDateOfBirth(e.toDate());
                            } catch (err) {}
                          }}
                        />
                      </FormGroup>
                    </div>
                  </Col> */}
                  <Col md={5}>
                    <Row>
                      <Col md={3} xs={3} className="pr-0">
                        <Input
                          value={countryCode}
                          onChange={(e) => setCountryCode(e.target.value)}
                          type="text"
                          placeholder="+1"
                          className="mt-0"
                          style={{
                            borderTopRightRadius: "0px",
                            borderBottomRightRadius: "0px",
                          }}
                          required
                        />
                      </Col>
                      <Col md={9} xs={9} className="pl-0">
                        <Input
                          type="tel"
                          name="phone"
                          id="phone"
                          placeholder="Phone number*"
                          onChange={(e) => setPhoneNumber(e.target.value)}
                          title="Please only use numbers"
                          pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                          style={{
                            borderTopLeftRadius: "0px",
                            borderBottomLeftRadius: "0px",
                          }}
                          maxLength={10}
                          required
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mt-2 mb-4">
                  <Col md={4} className="mt-2">
                    <span className="mr-2">The Mystry Box is for</span>
                    <i class="fa fa-sharp fa-solid fa-caret-right"></i>
                  </Col>
                  <Col md={2}>
                    <FormGroup check className="form-check-radio">
                      <Label check>
                        <Input
                          defaultChecked={receiver === "Myself"}
                          defaultValue="Myself"
                          id="receiver"
                          name="receiver"
                          type="radio"
                          onChange={(e) => handleReceiver(e)}
                        />
                        <span className="form-check-sign" />
                        <span style={{ color: "white" }}>Myself</span>
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col md={5}>
                    <FormGroup check className="form-check-radio">
                      <Label check>
                        <Input
                          defaultChecked={receiver === "someone-else"}
                          defaultValue="someone-else"
                          id="receiver"
                          name="receiver"
                          type="radio"
                          onChange={(e) => handleReceiver(e)}
                        />
                        <span className="form-check-sign" />
                        <span style={{ color: "white" }}>Someone Else</span>
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>

                <Row className="m-0">
                  <FormGroup className="mr-4">
                    <Input
                      placeholder="Gender"
                      type="select"
                      name="select"
                      id="gender"
                      required="true"
                      value={prefferedGender}
                      onChange={(e) => setPrefferedGender(e.target.value)}
                    >
                      {GENDERS.map((gender) => (
                        <option
                          style={{
                            background: "black",
                            color: "white",
                          }}
                        >
                          {gender}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                  <FormGroup className="mr-4">
                    <Input
                      type="select"
                      name="select"
                      id="size-chart"
                      required="true"
                      value={prefferedSizeChart}
                      onChange={(e) => setPrefferedSizeChart(e.target.value)}
                    >
                      {SIZE_CHARTS.map((size) => (
                        <option
                          style={{
                            background: "black",
                            color: "white",
                          }}
                        >
                          {size}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                  <button className="size-btn" onClick={toggle}>
                    {prefferedGender == GENDERS[1]
                      ? prefferedSneakerSize == "Select Size"
                        ? prefferedSneakerSize
                        : prefferedSneakerSize + 1.5
                      : prefferedSneakerSize}
                  </button>
                  {
                    <Modal
                      isOpen={modal}
                      toggle={toggle}
                      size="sm"
                      className="notifyModal"
                      style={{ top: "-15vh" }}
                    >
                      <div className="modal-header">
                        <h4
                          className="modal-title"
                          style={{ color: "#ffffff" }}
                        >
                          Choose your size
                        </h4>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-hidden="true"
                          onClick={toggle}
                        >
                          <i className="tim-icons icon-simple-remove" />
                        </button>
                      </div>
                      <ModalBody>
                        <div className="shoeSizeChart">
                          <ButtonToolbar>
                            <ButtonGroup vertical>
                              {sizes.map((currSize, idx) => (
                                <ButtonGroup
                                  className="shoeSizeButtonGroup"
                                  key={idx}
                                >
                                  <Button
                                    className="shoeSizeButton"
                                    onClick={() => {
                                      handleSizeClick(currSize, 2 * idx);
                                      console.log(currSize);
                                    }}
                                    key={currSize}
                                    color={buttonColor[2 * idx]}
                                  >
                                    {currSize === size && (
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                          color: "black",
                                        }}
                                      >
                                        {currSize} M / {currSize + 1.5} W
                                      </span>
                                    )}
                                    {currSize !== size &&
                                      `${currSize} M / ${currSize + 1.5} W`}
                                  </Button>
                                  {idx < 10 && (
                                    <Button
                                      className="shoeSizeButton"
                                      onClick={() =>
                                        handleSizeClick(
                                          currSize + 0.5,
                                          2 * idx + 1
                                        )
                                      }
                                      key={currSize + 0.5}
                                      color={buttonColor[2 * idx + 1]}
                                    >
                                      {currSize + 0.5 === size && (
                                        <span
                                          style={{
                                            fontWeight: "bold",
                                            color: "black",
                                          }}
                                        >
                                          {currSize + 0.5} M /{" "}
                                          {currSize + 0.5 + 1.5} W
                                        </span>
                                      )}
                                      {currSize + 0.5 !== size &&
                                        `${currSize + 0.5}M / ${
                                          currSize + 0.5 + 1.5
                                        } W`}
                                    </Button>
                                  )}
                                </ButtonGroup>
                              ))}
                            </ButtonGroup>
                          </ButtonToolbar>
                        </div>
                      </ModalBody>
                    </Modal>
                  }
                </Row>

                <Row>
                  <Col md={3}>
                    <div className="mt-2">
                      <span className="mr-2">Preffered Brands</span>
                      <i class="fa fa-sharp fa-solid fa-caret-right"></i>
                    </div>
                  </Col>
                  <Col md={9} className="p-0">
                    {BRANDS.map((brand) => (
                      <button
                        onClick={(e) => {
                          handlePrefferedBrand(brand);
                        }}
                        id={brand}
                        className="brand-btn"
                      >
                        {brand}
                      </button>
                    ))}
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col md={7} className="mt-2">
                    <p style={{ color: "white" }}>
                      How many sneakers do you have in your collection?
                    </p>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Input
                        type="select"
                        name="select"
                        id="count"
                        required="true"
                        value={sneakerCount}
                        onChange={(e) => setSneakerCount(e.target.value)}
                      >
                        {SNEAKER_COUNT.map((count) => (
                          <option
                            style={{
                              background: "black",
                              color: "white",
                            }}
                          >
                            {count}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <Input
                    type={mobile ? "textarea" : "text"}
                    id="message"
                    className={mobile ? "info-textarea p-4" : "p-4"}
                    required
                    placeholder="If you wear different sizes for different sneakers/brands, please let us know (optional)"
                    onChange={(e) => setUserMessage(e.target.value)}
                  />
                </FormGroup>

                {/* <br />
                <FormGroup>
                  <Col>
                    For Shoes
                    <br />
                    <br />
                    <Row style={{ justifyContent: "space-between" }}>
                      {DropdownCustom(
                        prefferedBrand,
                        setPrefferedBrand,
                        BRANDS
                      )}
                      {DropdownCustom(
                        prefferedSizeChart,
                        setPrefferedSizeChar,
                        SIZE_CHARTS
                      )}
                      {DropdownCustom(
                        prefferedSneakerSize,
                        setPrefferedSneakerSize,
                        SNEAKER_SIZES
                      )}
                      {DropdownCustom(
                        prefferedGender,
                        setPrefferedGender,
                        GENDERS
                      )}
                    </Row>
                    <br />
                    <br />
                    For Shirts
                    <br />
                    <br />
                    <Row>
                      {DropdownCustom(
                        prefferedShirtSize,
                        setPrefferedShirtSize,
                        SHIRT_SIZES
                      )}
                    </Row>
                  </Col>
                </FormGroup>
                <br /> */}

                <FormGroup check className="form-check-radio">
                  <Label check>
                    <Input
                      defaultChecked={preferNewShoes === "new"}
                      defaultValue="new"
                      id="preferNewShoes"
                      name="preferNewShoes"
                      type="radio"
                      onChange={(e) => handlePreferenceRadio(e)}
                    />
                    <span className="form-check-sign" />
                    <span style={{ color: "white" }}>
                      I prefer only new and unworn sneakers (Deadstock
                      Condition) in my Mystry Box
                    </span>
                  </Label>
                </FormGroup>
                <FormGroup check className="form-check-radio">
                  <Label check>
                    <Input
                      defaultChecked={preferNewShoes === "old"}
                      defaultValue="old"
                      id="preferNewShoes"
                      name="preferNewShoes"
                      type="radio"
                      onChange={(e) => handlePreferenceRadio(e)}
                    />
                    <span className="form-check-sign" />
                    <div style={{ color: "white" }}>
                      <span className="mr-2">
                        I don’t mind slightly used sneakers (Very Near Deadstock
                        Condition)
                      </span>
                      <span id="tooltip62213596">
                        <IoInformationCircleSharp />
                      </span>
                    </div>
                    <UncontrolledTooltip
                      className="info-tooltip"
                      placement="down"
                      target="tooltip62213596"
                    >
                      These sneakers have a 4.5/5 condition, and we can source
                      slightly used/high value pairs for your Mystry Box
                    </UncontrolledTooltip>
                  </Label>
                </FormGroup>
                <br />
                {!allPreferncesSpecified && (
                  <div className="mb-3">
                    <p className="text-danger">
                      Please specify all your preferences.
                    </p>
                  </div>
                )}

                <Button
                  color="green"
                  type="submit"
                  onClick={() => handleProceed()}
                >
                  Proceed
                </Button>
                <Row></Row>
              </Col>
            </Row>{" "}
          </Container>
        </div>
      )}

      {loggedIn && !isLoading && isSSVisible && !isSSSwitchVisible && (
        <SneakerTinderMain
          currentUserID={currentUser.uid}
          stripeURL={stripeURL}
          chosenBrand={chosenBrand !== "Vanilla" ? chosenBrand : "Air Jordan"}
        />
      )}
      {loggedIn && !isLoading && isSSSwitchVisible && !isSSVisible && (
        <div
          style={{
            position: "relative",
            overflow: "hidden",
          }}
        >
          <Container
            fluid={true}
            style={{
              marginTop: !mobile ? "5vh" : "10vh",
              overflowX: "hidden",
              paddingLeft: "5vw",
              paddingRight: "5vw",
            }}
            className="d-flex justify-content-center"
          >
            <img
              alt="..."
              src={
                require(`assets/custom/SneakerSwipeImages/SneakerSwipe${chosenBrandTier}.png`)
                  .default
              }
              style={{
                height: mobile ? "auto" : "75vh",
              }}
            />
          </Container>
          <br />
          <div className="d-flex justify-content-center">
            <Button
              color="green"
              style={{ width: mobile ? "150px" : "200px" }}
              onClick={() => {
                setIsSSSwitchVisible(false);
                setIsSSVisible(true);
              }}
            >
              Play Now
            </Button>
          </div>
          <div className="d-flex justify-content-center">
            <Button
              className="btn-link"
              color="green"
              onClick={() => {
                history.push({
                  pathname: "/waitlist",
                  state: { stripeCheckout: stripeURL },
                });
              }}
            >
              Skip for now
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
