import React from "react";
import { btsData as data } from "./data.js";
import { Col, Row } from "reactstrap";
import useWindowDimensions from "hooks/useWindowDimensions";
import { Carousel as CarouselBTS } from "react-responsive-carousel";
import { Link } from "react-router-dom";
import "./BehindTheShoes.css";
import BTSCarouselMobile from "./BTSCarouselMobile.js";

function BTSCarousel() {
  const { width, height } = useWindowDimensions();
  var mobile, landscape;
  if (width < 768) {
    mobile = true;
    landscape = false;
  } else if (height < 576 || width < 992) {
    mobile = false;
    landscape = true;
  } else {
    mobile = false;
    landscape = false;
  }

  return (
    <>
      {mobile && <BTSCarouselMobile />}
      {!mobile && (
        <CarouselBTS
          autoPlay={true}
          interval={8000}
          showArrows={true}
          showThumbs={false}
          showIndicators={false}
          showStatus={false}
          infiniteLoop={true}
        >
          {data.map((shoe) => {
            return (
              <Row>
                <Col xs={6} md={6} className="d-flex justify-content-center">
                  <img
                    src={shoe.image}
                    style={{
                      marginBottom: "50px",
                      width: "50%",
                    }}
                  />
                </Col>
                <Col
                  xs={6}
                  md={6}
                  style={{
                    right: "60px",
                  }}
                >
                  <div
                    style={{
                      marginRight: "50px",
                      marginBottom: "40px",
                      fontSize: "30px",
                      textAlign: "right",
                      fontWeight: "600",
                    }}
                  >
                    {shoe.title1}
                  </div>
                  <br />
                  <div
                    style={{
                      marginRight: "50px",
                      textAlign: "right",
                      fontSize: "18px",
                    }}
                  >
                    {shoe.para1}
                  </div>
                  <div className="read-more-wrapper">
                    <Link
                      to={{
                        pathname: "/blogpage",
                        state: {
                          title1: shoe.title1,
                          title2: shoe.title2,
                          image: shoe.image,
                          para1: shoe.para1,
                          para2: shoe.para2,
                          para3: shoe.para3,
                          shoeLink: shoe.shoeLink,
                        },
                      }}
                    >
                      <button className="read-more-btn">Read More</button>
                    </Link>
                  </div>
                </Col>
              </Row>
            );
          })}
        </CarouselBTS>
      )}
    </>
  );
}

export default BTSCarousel;
