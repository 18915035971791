export const btsData = [
  {
    title1: "THE NIKE SB DUNKS | TRAVIS SCOTT",
    title2: "BEHIND THE SHOE I",
    image: require("assets/custom/bts/TheNikeSBDunks.jpeg").default,
    para1:
      "This iconic collaboration between Nike and Travis Scott led to one of the most highly anticipated sneakers of all time. This pair uses details and patterns that are inspired by his hometown of Houston, Texas. The release of this influential shoe had a strong impact on the resurgence of the historical SB Dunks. It is a staple of the sneaker industry and culture, which even grabs the attention of a non-sneaker head.",
    para2:
      "This design features a variety of materials and prints, similar to those seen on the Air Force 1 Low Travis Scott Cactus Jack. Unlike previous Travis Scott releases, they were only available at certain Nike SB retailers, not on SNKRS. Suede uppers with paisley canvas and plaid flannel overlays make up this Nike SB Dunk Low. The elephant print emerges as the paisley overlays fade. The style is completed with embroidered writing across the collar, rope laces, and La Flame's Cactus Jack insignia on the puffy tongues.",
    para3:
      "The Travis Scott x Dunk Low SB features a unique patchwork motif on the upper featuring a tan leather base with a plaid quarter panel and paisley print overlays. Complementing the dueling pattern are drawstring laces, Cactus Jack Tongue labels, and mismatched black and pink Swoosh on each shoe. A white midsole and tan rubber outsole highlight the intricate design. These sneakers were released in February 2020 and cost a retail price of $150. At the time of writing, this pair goes for a market price of $2,200.",
    shoeLink:
      "You have the opportunity to get your hands on the most anticipated Nike sneakers at Mystry box.",
  },
  {
    title1: "PHARRELL X CHANEL X ADIDAS HU NMD",
    title2: "BEHIND THE SHOE III",
    image: require("assets/custom/bts/PharrellAdidas.jpeg").default,
    para1: `Pharrell x Chanel x Adidas was launched back in November of 2017. It was a limited time collaboration with only 500 pairs sold through the Chanel’s temporary pop-up shop at Colette in Paris. Pharrell Williams’s Chanel Sneakers are the logical culmination of his collaboration with Karl Lagerfeld`,
    para2: `None of the many Pharrell collaborations were more legendary than this ultra-rare adidas NMD Hu Trail in collaboration with famed fashion house Chanel. The new NMD Hu features “Pharrell” and “Chanel” typography, while we also see the addition of a treaded outsole and Chanel branding on the heel.`,
    para3: `They were auctioned for 1,000 EUR (approx. 1,160 USD). The resale value of the pair shot up to 32,000 USD after 120,000 people pre-registered on the website for the opportunity to buy one of 500 pairs. Currently, the shoe is re-selling for more than 11,000 USD from resellers. They are currently the most expensive NMD Hu available from the resellers.`,
    shoeLink: `Get your hands on these super rare shoes from Mystry box.`,
  },
  {
    title1: "JORDAN 1 RETRO HIGH HYPER CRIMSON",
    title2: "BEHIND THE SHOE IV",
    image: require("assets/custom/bts/Hyper Crimson.jpeg").default,
    para1: `The trailer for “Spider-Man: No Way Home,” is filled with nostalgic references for longtime fans of the franchise, from Tobey Maguire to the resurgence of Doctor Octopus. But at the end of the trailer, sneakerheads got a shoutout as well, as Peter Parker was seen wearing a pair of Air Jordan 1s ‘Hyper Crimson’ which takes its name from the shoe’s crimson-colored collar.`,
    para2: `This January 2019 release features grey suede overlays and a grey leather base. On the upper, a black and fuzzy grey suede mix keeps things simple, while the bright crimson ankle collar adds a welcome splash of colour.`,
    para3: `Text branding is also used on the model, which is inspired by OFF-"The WHITE's Ten" partnership with the Swoosh. "SP 19 AIR JORDAN 1 HIGH OG," the text reads. Currently, the shoes cost around 300-750 USD from resellers depending upon the size.`,
    shoeLink: `You have the opportunity to add this shoe to your collection of sneakers at Mystry Box.`,
  },
  {
    title1: "THE AIR JORDAN 1 RETRO HIGH UNIVERSITY BLUE",
    title2: "BEHIND THE SHOE II",
    image: require("assets/custom/bts/University_Blue.jpeg").default,
    para1: `For reasons unknown, the Jordan 1 Retro "University Blue" has captivated many sneakerheads around the world. These pairs feature an iconic light blue silhouette named after the original Jordan Brand color. This pair is an evolution of his patented UNC colorway with full leather trim and classic black signature Swoosh. Please note, this is a modern classic pair.`,
    para2: `This iconic sneaker pays tribute to the line's creator, the University of North Carolina, where Michael Jordan attended from 1981-1984. Carolina Blue (also known as Tar Heel Blue) is a shade of blue used as one of the University of North Carolina's Official School of His Colors. This powder blue colorway is limited to just 12,000 pairs worldwide and sells for three times its market price, around 300-450 USD.`,
    para3: `The Air Jordan 1 Retro High OG "University Blue" uses a familiar color palette reminiscent of Michael Jordan's UNC alma mater. The all-leather upper features a White base with Powder Blue overlays and a signature Black Swoosh. Matching black accents can be seen on the collar, tongue label, and the Wings logo printed on the side collar tab. A lighter shade of blue is applied to the standard AJ1 outsole, which features multi-directional traction and a pivot point under the forefoot.`,
    shoeLink: `At Mystry Box, you might add this shoe to your sneaker collection.`,
  },
  // {
  //   title: "Air Jordan 1 Retro High OG ‘Dark Mocha’",
  //   image:
  //     require("assets/custom/bts/Air Jordan 1 Retro High OG ‘Dark Mocha’.jpeg")
  //       .default,
  //   para1:
  //     "The Jordan brand continued their Black Toe design theme in 2020 and released the Jordan 1 High Dark Mocha. The Dark Mocha 1 was one of the most anticipated releases in 2020 due to its familiar colorblocking that referenced two of the greatest Jordan 1s of all-time, the Jordan 1 Travis Scott and the Jordan 1 Black Toe. The pair are now one of the most iconic Jordan releases to date.",
  // },
  {
    title1: "YEEZY FOAM RUNNER",
    title2: "BEHIND THE SHOE V",
    image: require("assets/custom/bts/Yeezy Foam Runner.jpg").default,
    para1: `The Yeezy Foam Runner was released as a surprise in June 2020 in the Ararat colorway. Yeezy products, as usual, sold out quickly. The Yeezy Foam Runner has been a super popular shoe model for over a year since its initial release, generating constant opinions and discussions. The sneaker prototype was manufactured in Atlanta from a combination of petroleum-based ethylene-vinyl acetate and foam produced from algae.`,
    para2: `Switching to more eco-friendly materials and processes, players in the fashion industry can do their part to stop this harm from befalling the planet-one ugly sneaker at a time. We may soon see the silhouette exceed even Crocs, thanks to the impending variety and its affordable retail price point. Kanye’s latest venture into the sneaker verse proves to be yet another surprise, but the hype for the foam runners is only on the rise.`,
    para3: `Aftermarket prices on foam runners aside, at $80 retail, these are killer. Amazingly comfortable with super soft cushioning that conforms to your foot. It's visually and functionally durable and outlasts other 100% foam shoes. With all their holes they are very well ventilated. Depending on your climate, this can be a big advantage or disadvantage. And most obviously, its aesthetic is unique. This is a real neck breaker. Nothing you see ordinarily in sneaker space.`,
    shoeLink: `Get your hands on these fashion savvy and eco-friendly shoes at Mystry box.`,
  },
  {
    title1: "NIKE BLAZER MID ‘77 VINTAGE",
    title2: "BEHIND THE SHOE VI",
    image: require("assets/custom/bts/Nike Blazer Mid ‘77 vintage.jpg").default,
    para1: `The Nike Blazer Mid '77 Vintage is simple. But it might be too easy for die-hard sneakerheads. A foam-impregnated nylon tongue combines with a leather upper, flat cotton laces, suede accents, and a vulcanized rubber sole to create a look that resembles '70s ads (and athletes). Unconventional writing on the heel and a vintage logo on the tongue enhance this vibe. The Nike Blazer has been around in one form or another for almost 50 years.`,
    para2: `First designed in 1972 for NBA legend George "The Iceman" Garvin. This sneaker has been around since Nike ditched the old Blue-Ribbon Sports name in favor of its iconic logo, the Swoosh. It was his third Nike sneaker released just one year after him.`,
    para3: `Originally a basketball shoe, it's now firmly established as a lifestyle choice. They're super comfortable, look classy in a way that's not cheesy, and go with all kinds of outfits. Like the Chuck Taylor All Star, this blazer is tall enough to be tucked under your pants for fall and winter, and it's easy to wear. A great option for shorts wearers in spring and summer. Moreover, they are priced at an affordable flat price of $100. Considering the suede and leather sneakers, it's a pretty good bargain compared to the often-overpriced Nike mesh sneakers.`,
    shoeLink: `The opportunity to add this shoe to your collection of sneakers is now available at Mystry Box.`,
  },
];
