import React, { useState } from "react";
import Login from "components/User/Login";
import LoginWith from "components/User/LoginWith";
import { Button, Col } from "reactstrap";
import SignUp from "components/User/SignUp";

const ShoeLoginSignUp = ({
  shoeID,
  size,
  setShowLogin,
  setShowPayment,
  setBuyNowState,
}) => {
  const [showLoginSignUp, setShowLoginSignUp] = useState(false);
  const [showLogin, setLogin] = useState(true);
  const [showSignUp, setShowSignUp] = useState(false);

  return (
    <Col xs="12" md="6">
      {showLoginSignUp && (
        <div>
          <div>
            <h3 style={{ margin: "0" }}>I have an account</h3>
            <Button
              color="simple"
              onClick={() => {
                setShowLoginSignUp(false);
                setLogin(true);
                setShowSignUp(false);
              }}
            >
              Login
            </Button>
          </div>
          <br />
          <div>
            <h3 style={{ margin: "0" }}>Create an account</h3>
            <Button
              color="simple"
              onClick={() => {
                setShowLoginSignUp(false);
                setLogin(false);
                setShowSignUp(true);
              }}
            >
              Sign up
            </Button>
          </div>
          <br />
          <div>
            <h3 style={{ margin: "0" }}>Checkout as guest</h3>
            <Button
              color="simple"
              onClick={() => {
                setShowLoginSignUp(false);
                setLogin(false);
                setShowSignUp(false);
                setShowPayment(true);
                setShowLogin(false);
              }}
            >
              Guest checkout
            </Button>
          </div>
        </div>
      )}
      {showLogin && (
        <div>
          <h3>Login</h3>
          <Login shoeID={shoeID} buyNow={true} size={size} />
          <LoginWith shoeID={shoeID} buyNow={true} size={size} />
          <br />
          <button
            size="sm"
            style={{backgroundColor:"white", width:"280px", height:"35px", borderRadius:"5px", fontWeight:"bold"}}              
            onClick={() => {
              setLogin(false);
              setShowSignUp(true);
            }}
          >
            Dont have an account? Sign Up
          </button>
        </div>
      )}
      {showSignUp && (
        <div>
          <h3>Sign up</h3>
          <SignUp shoeID={shoeID} buyNow={true} size={size} />
          <br />
          <button
            size="sm"
            style={{backgroundColor:"white", width:"240px", height:"35px", borderRadius:"5px", fontWeight:"bold"}}
            onClick={() => {
              setLogin(true);
              setShowSignUp(false);
            }}
          >
            Already A User? Login
          </button>
        </div>
      )}
      <br />
      <br />
      {/* {showLoginSignUp && (
        <Button
          color="simple"
          size="sm"
          onClick={() => {
            setShowLogin(false);
            setBuyNowState(true);
          }}
        >
          Go back
        </Button>
      )} */}
    </Col>
  );
};

export default ShoeLoginSignUp;
