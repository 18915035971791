import React from "react";
import { Link } from "react-router-dom";
import { Container } from "@material-ui/core";
import Navbar from "components/Navbars/Navbar.js";
import Footer from "../pagesV2/Footer/Footer.js";
import { MDBCard, MDBCardBody, MDBCardText } from "mdb-react-ui-kit";
import Parser from "html-react-parser";

function BlogPage(props) {
  return (
    <>
      <Navbar />
      <Container
        fluid={true}
        style={{ paddingTop: "100px", paddingBottom: "2vh" }}
      >
        <h1 style={{ textAlign: "center" }} className="p-2">
          {Parser(props.location.state.title1)}
        </h1>
        <h3 style={{ textAlign: "center" }}>{props.location.state.title2}</h3>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            className="blogimage img-fluid p-2"
            src={props.location.state.image}
            alt="Responsive image"
          ></img>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <MDBCard
            className="p-4"
            style={{ width: "64rem", backgroundColor: "black" }}
          >
            <MDBCardBody>
              <MDBCardText className="p-2">
                <h4>{Parser(props.location.state.para1)}</h4>
              </MDBCardText>
              <MDBCardText className="p-2">
                <h4>{Parser(props.location.state.para2)}</h4>
              </MDBCardText>
              <MDBCardText className="p-2">
                <h4>{Parser(props.location.state.para3)}</h4>
              </MDBCardText>
              <MDBCardText>
                <Link to="/mystryboxProduct">
                  <h4
                    className="font-weight-bold spanborder p-2"
                    style={{ color: "#34eb52", textAlign: "center" }}
                  >
                    {props.location.state.shoeLink}
                  </h4>
                </Link>
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </div>
      </Container>
      <Footer />
    </>
  );
}
export default BlogPage;
