import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Form,
  Button,
  Input,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalBody,
  Label,
} from "reactstrap";
import states from "states-us";
import { useHistory } from "react-router";
import { userDb } from "../../../firebase.js";
import useWindowDimensions from "hooks/useWindowDimensions.js";
import { useSelector } from "react-redux";

const UserInfoExists = ({
  currentUser,
  userInfo,
  buyNow,
  bidNow,
  setShowPayment,
  setShowAddress,
  setShippingAddress,
  shoe,
  size,
  setSize,
}) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("State*");
  const [zip, setZip] = useState("");
  const [addNewModal, setAddNewModal] = useState(false);
  const [makeDefault, setMakeDefault] = useState(false);
  const [editAddIdx, setEditAddIdx] = useState(null);
  const [editModal, setEditModal] = useState(false);
  const editToggle = () => setEditModal(!editModal);
  const addNewToggle = () => setAddNewModal(!addNewModal);
  const history = useHistory();
  const cartLength = useSelector((state) => state.cart.totalItems);

  var shoeBody = [];
  useSelector((state) => {
    shoeBody = [];
    state.cart.cartShoes.forEach((item) => {
      shoeBody.push({
        id: item.shoe.shoeID,
        quantity: item.quantity,
        size: String(item.size).replace(".", "_"),
      });
    });
  });

  var mobile;
  if (useWindowDimensions().height < 576) {
    mobile = true;
  } else {
    mobile = false;
  }

  const confirmUserInfo = (addObj, phone) => {
    return (
      <div>
        <div>
          {addObj.firstName} {addObj.lastName}
        </div>
        <div>{addObj.address1}</div>
        <div>{addObj.address2}</div>
        <div>
          {addObj.city} {addObj.state}
        </div>
        <div>{addObj.zip}</div>
        <div>
          {phone.phoneCountryCode} {phone.phoneNumber}
        </div>
      </div>
    );
  };

  const handleAddNewAddress = (e) => {
    e.preventDefault();
    const ref = userDb(currentUser.uid);
    const newAdd = {
      firstName,
      lastName,
      address1,
      address2,
      city,
      state,
      zip,
    };
    // check if currentUser is verified or not using currentUser.emailVerified
    // if (!currentUser.emailVerified) {
    //   currentUser.sendEmailVerification();
    // }

    var addresses = userInfo && userInfo.address ? userInfo.address : [];
    if (makeDefault) {
      addresses.unshift(newAdd);
    } else {
      addresses.push(newAdd);
    }

    ref.child("address").set(addresses);

    addNewToggle();
    setFirstName("");
    setLastName("");
    setAddress1("");
    setAddress2("");
    setCity("");
    setState("State*");
    setZip("");
    setMakeDefault(false);
    // history.push({ pathname: `/payment` });
    // history.push(`/verify`);
  };

  const handleEditOpen = (addObj, idx) => {
    setFirstName(addObj.firstName);
    setLastName(addObj.lastName);
    setAddress1(addObj.address1);
    setAddress2(addObj.address2);
    setCity(addObj.city);
    setState(addObj.state);
    setZip(addObj.zip);
    setEditAddIdx(idx);
    editToggle();
  };

  const handleEditClose = (toggle = true) => {
    if (toggle) editToggle();
    setFirstName("");
    setLastName("");
    setAddress1("");
    setAddress2("");
    setCity("");
    setState("State*");
    setZip("");
    setMakeDefault(false);
    setEditAddIdx(null);
  };

  const handleEditAddress = (e, idx) => {
    e.preventDefault();
    const ref = userDb(currentUser.uid);
    const editedAdd = {
      firstName,
      lastName,
      address1,
      address2,
      city,
      state,
      zip,
    };
    // check if currentUser is verified or not using currentUser.emailVerified
    // if (!currentUser.emailVerified) {
    //   currentUser.sendEmailVerification();
    // }

    var addresses = userInfo.address;
    if (makeDefault) {
      // ////console.log("idx:", idx);
      addresses.splice(idx, 1);
      addresses.unshift(editedAdd);
    } else {
      addresses.splice(idx, 1, editedAdd);
    }

    ref.child("address").set(addresses);

    editToggle();
    setFirstName("");
    setLastName("");
    setAddress1("");
    setAddress2("");
    setCity("");
    setState("State*");
    setZip("");
    setMakeDefault(false);
    setEditAddIdx(null);
    // history.push({ pathname: `/payment` });
    // history.push(`/verify`);
  };

  const handleEditDelete = (e, idx) => {
    e.preventDefault();
    if (idx === 0) {
      alert("Cannot delete default address");
    }
    const ref = userDb(currentUser.uid);
    var addresses = userInfo.address;
    addresses.splice(idx, 1);
    ref.child("address").set(addresses);

    editToggle();
    setFirstName("");
    setLastName("");
    setAddress1("");
    setAddress2("");
    setCity("");
    setState("State*");
    setZip("");
    setMakeDefault(false);
    setEditAddIdx(null);
  };

  const handleSelectBid = (e, idx) => {
    e.preventDefault();
    // const vals = Object.values(userInfo)[0];
    const vals = userInfo;
    var obj = {
      email: currentUser.email,
      name: vals.name,
      phone: vals.phone,
      userID: vals.userID,
      address: vals.address[idx],
      guest: false,
    };
    fetch(
      "https://mystry-server-z574yasx7q-uc.a.run.app/create-checkout-session",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          items: [
            {
              id: shoe.shoeID,
              quantity: 1,
              size: size,
              bidPrice: shoe.bidPrice,
            },
          ],
          isBid: true,
          customerID: currentUser.uid,
          customer: obj,
        }),
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        return Promise.reject(response);
      })
      .then((responseText) => {
        window.location.replace(responseText["url"]);
      })
      .catch((error) => {
        history.push({ pathname: `/failurePayment` });
        console.error(error);
      });

    history.push({ pathname: `/payment`, state: { userInfo: obj } });
  };
  //   //console.log( shoe.bidPrice)
  const handleSelect = (e, idx) => {
    e.preventDefault();
    // const vals = Object.values(userInfo)[0];
    const vals = userInfo;
    var obj = {
      email: currentUser.email,
      name: vals.name,
      phone: vals.phone,
      userID: vals.userID,
      address: vals.address[idx],
      guest: false,
    };

    // * make call to create checkout session
    // * pass shoe info to stripe service
    // * make order in firebase aafter successful payment from stripe
    // ////console.log(
    //   JSON.stringify({
    //     items: shoeBody,
    //     customerID: currentUser.uid,
    //     customer: obj,
    //   })
    // );
    // fetch("http://localhost:4500/create-checkout-session", {
    fetch(
      "https://mystry-server-z574yasx7q-uc.a.run.app/create-checkout-session",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          items: shoeBody,
          customerID: currentUser.uid,
          isBid: false,
          customer: obj,
        }),
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        return Promise.reject(response);
      })
      .then((responseText) => {
        window.location.replace(responseText["url"]);
      })
      .catch((error) => {
        history.push({ pathname: `/failurePayment` });
        console.error(error);
      });

    history.push({ pathname: `/payment`, state: { userInfo: obj } });
  };

  const getColSize = () => {
    if (!buyNow && !bidNow) {
      return mobile ? 6 : 3;
    } else {
      return 6;
    }
  };

  // //console.log(size);
  // //console.log(shoe.size);
  return (
    <div>
      <h4>Select shipping address</h4>
      <Container>
        <Row>
          {/* {Object.values(userInfo)[0].address.map( */}
          {userInfo &&
            userInfo.address &&
            userInfo.address.map(
              (addObj, idx) => (
                <Col xs={9} md={getColSize()} key={idx}>
                  <Card className="checkoutAddressCardStyle">
                    {idx === 0 && (
                      <CardTitle style={{ margin: "0px" }}>
                        Default address
                      </CardTitle>
                    )}
                    <CardBody>
                      {/* {confirmUserInfo(addObj, Object.values(userInfo)[0].phone)} */}
                      {confirmUserInfo(addObj, userInfo.phone)}
                      <Button
                        size="sm"
                        color="simple"
                        onClick={() => handleEditOpen(addObj, idx)}
                      >
                        Edit
                      </Button>

                      <Modal
                        isOpen={editModal}
                        toggle={editToggle}
                        className="notifyModal"
                        onClosed={() => handleEditClose(false)}
                      >
                        <div className="modal-header cart-modal">
                          <h5
                            className="modal-title"
                            style={{ color: "white" }}
                          >
                            Edit address
                          </h5>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-hidden="true"
                            onClick={() => handleEditClose()}
                            style={{ top: "5px" }}
                          >
                            <i className="tim-icons icon-simple-remove" />
                          </button>
                        </div>
                        <ModalBody className="cart-modal">
                          <Form
                            onSubmit={(e) => handleEditAddress(e, editAddIdx)}
                          >
                            <FormGroup>
                              <Row>
                                <Col xs={6}>
                                  <Input
                                    type="text"
                                    name="firstName"
                                    id="firstName"
                                    placeholder="First Name*"
                                    value={firstName}
                                    onChange={(e) =>
                                      setFirstName(e.target.value)
                                    }
                                    required
                                  />
                                </Col>
                                <Col xs={6}>
                                  <Input
                                    type="text"
                                    name="lastName"
                                    id="lastName"
                                    placeholder="Last Name*"
                                    value={lastName}
                                    onChange={(e) =>
                                      setLastName(e.target.value)
                                    }
                                    required
                                  />
                                </Col>
                              </Row>
                            </FormGroup>
                            <FormGroup>
                              <Row>
                                <Col xs={12}>
                                  <Input
                                    type="text"
                                    name="address1"
                                    id="address1"
                                    placeholder="Address 1*"
                                    onChange={(e) =>
                                      setAddress1(e.target.value)
                                    }
                                    value={address1}
                                    required
                                    maxLength={100}
                                  />
                                </Col>
                              </Row>
                            </FormGroup>
                            <FormGroup>
                              <Row>
                                <Col xs={12}>
                                  <Input
                                    type="text"
                                    name="address2"
                                    id="address2"
                                    placeholder="Address 2"
                                    value={address2}
                                    onChange={(e) =>
                                      setAddress2(e.target.value)
                                    }
                                    maxLength={100}
                                  />
                                </Col>
                              </Row>
                            </FormGroup>
                            <FormGroup>
                              <Row>
                                <Col xs={6}>
                                  <Input
                                    type="text"
                                    name="city"
                                    id="city"
                                    placeholder="City*"
                                    value={city}
                                    onChange={(e) => setCity(e.target.value)}
                                    required
                                    maxLength={50}
                                  />
                                </Col>
                                <Col xs={6}>
                                  <Input
                                    type="select"
                                    name="state"
                                    id="state"
                                    style={{ backgroundColor: "#1B1B1B" }}
                                    value={state}
                                    onChange={(e) => setState(e.target.value)}
                                    required
                                  >
                                    <option>State*</option>
                                    {states.map((state, idx2) => (
                                      <option key={idx2}>
                                        {state.abbreviation}
                                      </option>
                                    ))}
                                  </Input>
                                </Col>
                              </Row>
                            </FormGroup>
                            <FormGroup>
                              <Input
                                type="tel"
                                name="zip"
                                id="zip"
                                placeholder="Zip*"
                                value={zip}
                                onChange={(e) => setZip(e.target.value)}
                                required
                                maxLength={10}
                              />
                            </FormGroup>
                            {/* <span> */}
                            {editAddIdx !== 0 && (
                              <FormGroup check style={{ fontSize: "0.875rem" }}>
                                {/* {////console.log(editAddIdx, userInfo)} */}
                                <Label check>
                                  <Input
                                    type="checkbox"
                                    onChange={(e) =>
                                      setMakeDefault(e.target.checked)
                                    }
                                  />{" "}
                                  Make default
                                  <span className="form-check-sign">
                                    <span className="check" />
                                  </span>
                                </Label>
                              </FormGroup>
                            )}
                            {/* </span> */}
                            <Button
                              color="simple"
                              onClick={() => handleEditClose()}
                            >
                              Cancel
                            </Button>
                            {editAddIdx !== 0 && (
                              <Button
                                color="simple"
                                onClick={(e) => handleEditDelete(e, editAddIdx)}
                              >
                                Delete
                              </Button>
                            )}
                            <Button type="submit" color="simple">
                              Done
                            </Button>
                          </Form>
                        </ModalBody>
                      </Modal>
                      {console.log(bidNow, buyNow)}
                      {((buyNow && !bidNow) || (!buyNow && !bidNow)) && (
                        <Button
                          size="sm"
                          color="simple"
                          onClick={(e) => handleSelect(e, idx)}
                        >
                          Select
                        </Button>
                      )}
                      {!buyNow && bidNow && (
                        <Button
                          size="sm"
                          color="simple"
                          onClick={(e) => handleSelectBid(e, idx)}
                        >
                          Select
                        </Button>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              )
              // ))
            )}
          <Col xs={9} md={mobile ? 6 : 3}>
            <Card>
              <CardBody className="checkoutAddressCardStyle">
                <Button color="simple" onClick={addNewToggle}>
                  Add new
                </Button>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal
        isOpen={addNewModal}
        toggle={addNewToggle}
        // size="sm"
        className="notifyModal"
      >
        <div className="modal-header cart-modal">
          <h5 className="modal-title" style={{ color: "white" }}>
            Add new address
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-hidden="true"
            onClick={addNewToggle}
            style={{ top: "5px" }}
          >
            <i className="tim-icons icon-simple-remove" />
          </button>
        </div>
        <ModalBody className="cart-modal">
          <Form onSubmit={handleAddNewAddress}>
            <FormGroup>
              <Row>
                <Col xs={6}>
                  <Input
                    type="text"
                    name="firstName"
                    id="firstName"
                    placeholder="First Name*"
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                </Col>
                <Col xs={6}>
                  <Input
                    type="text"
                    name="lastName"
                    id="lastName"
                    placeholder="Last Name*"
                    onChange={(e) => setLastName(e.target.value)}
                    required
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col xs={12}>
                  <Input
                    type="text"
                    name="address1"
                    id="address1"
                    placeholder="Address 1*"
                    onChange={(e) => setAddress1(e.target.value)}
                    required
                    maxLength={100}
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col xs={12}>
                  <Input
                    type="text"
                    name="address2"
                    id="address2"
                    placeholder="Address 2"
                    onChange={(e) => setAddress2(e.target.value)}
                    maxLength={100}
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col xs={6}>
                  <Input
                    type="text"
                    name="city"
                    id="city"
                    placeholder="City*"
                    onChange={(e) => setCity(e.target.value)}
                    required
                    maxLength={50}
                  />
                </Col>
                <Col xs={6}>
                  <Input
                    type="select"
                    name="state"
                    id="state"
                    style={{ backgroundColor: "#1B1B1B" }}
                    onChange={(e) => setState(e.target.value)}
                    required
                  >
                    <option>State*</option>
                    {states.map((state, idx) => (
                      <option key={idx}>{state.abbreviation}</option>
                    ))}
                  </Input>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Input
                type="tel"
                name="zip"
                id="zip"
                placeholder="Zip*"
                onChange={(e) => setZip(e.target.value)}
                required
                maxLength={10}
              />
            </FormGroup>
            <FormGroup check style={{ fontSize: "0.875rem" }}>
              <Label check>
                <Input
                  type="checkbox"
                  onChange={(e) => setMakeDefault(e.target.checked)}
                />{" "}
                Make default
                <span className="form-check-sign">
                  <span className="check" />
                </span>
              </Label>
            </FormGroup>
            <Button type="submit" color="simple">
              Add
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default UserInfoExists;
