import  {React, useEffect, useState} from "react";
import "./Shoes.css";
import { ButtonToolbar, ButtonGroup, Button } from "reactstrap";
import _forIn from 'lodash/forIn';
var _ = require("lodash");


const ShoeSizeChart = ({
  shoe,
  ebay,
  size,
  setSize,
  handleBuy,
  buttonColor,
  setButtonColor,

}) => {
  const sizes = _.range(5, 16, 1);
  const getSizeStr = (size) => {
    var sizeStr = size.toString();
    if (sizeStr.includes(".")) {
      sizeStr = sizeStr.replace(".", "_");
    }
    return sizeStr;
  };

  const shoeSizeIdx = [
    "5",
    "5_5",
    "6",
    "6_5",
    "7",
    "7_5",
    "8",
    "8_5",
    "9",
    "9_5",
    "10",
    "10_5",
    "11",
    "11_5",
    "12",
    "12_5",
    "13",
    "13_5",
    "14",
    "14_5",
    "15",
  ];
  const map_ = new Map();  
  
  const [postt, setPosttArray] = useState([])
  const [ebayResults, setEbayResults] = useState([])

  if(ebay == false) {
    let url = 'https://us-east1-price-scraper-330821.cloudfunctions.net/sneaker-price-estimator/';
    fetch(url, {
      method: "POST",
      body: JSON.stringify({
        // "sku":"716371-040"
        "sku":shoe.shoeSKU
      }
    )})
    .then(response => response.json())
    .then(json => {
      setPosttArray([json]);
    })
    _forIn(postt[0], (value,key) => {
      var numbers = /^[0-9]+$/;
      if(value== null){
        //object null- block
      }
      else{
      if(key[key.length - 1].match(numbers)){
        map_.set(parseFloat(key),value);
      }
      else{
        let str = key;
        str = key.substring(0, key.length - 1);
        str= (parseFloat(str)-parseFloat(1.5));
        map_.set(str,value);
      }
    }
    })
  }
  
  useEffect(() => {
    if(ebay == true) {
      let ebay_url = "https://mystry-server-z574yasx7q-uc.a.run.app/scraper_api/ebay_all";
      fetch(ebay_url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          "sku": shoe.shoeSKU
        })
      })
      .then((response) => {
        if(!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((data) => {
        const temp = []
        Object.keys(data.ebayData).forEach(function(key) {
          temp.push({size: key, price: data.ebayData[key].price})
        })
        setEbayResults(temp)
        shoe.marketValue = Math.round(
          Math.min(...ebayResults.map(item => item.price))
        )
      })
      .catch((error) => {
        console.log('error: ' + error);
      })
    }
  }, [])

  const handleSizeClick = (currSize, idx) => {
    if (currSize === size) {
      setSize("");
      setButtonColor(_.fill(Array(21), "simple"));
    } else {
      setSize(currSize);
      let colorArr = _.fill(Array(21), "simple");
      colorArr[idx] = "neutral";
      setButtonColor(colorArr);
    }
  };

  return (
    <>
    <div className="shoeSizeChart">
      <ButtonToolbar>
        <ButtonGroup vertical>
          {sizes.map((currSize, idx) => (
            <ButtonGroup className="shoeSizeButtonGroup" key={idx}>
              <Button
                className="shoeSizeButton"
                onClick={() => handleSizeClick(currSize, 2 * idx)}
                key={currSize}
                color={buttonColor[2 * idx]}
                disabled={shoe.shoeInventory && shoe.shoeInventory[shoeSizeIdx[2 * idx]].quantity
                  ? false
                  : ((ebay != true) 
                    ? !map_.has(currSize)
                    : !ebayResults.find(x => (x.size == shoeSizeIdx[2 * idx].replace("_", "."))))
                }                
              >
                {currSize === size && shoe.shoeInventory && shoe.shoeInventory[shoeSizeIdx[2 * idx]].quantity>0 &&
                  <span style={{ fontWeight: "bold", color: "#32CD32"}}>${shoe.shoeInventory[shoeSizeIdx[2 * idx]].price}</span>}
                {currSize === size && shoe.shoeInventory && !shoe.shoeInventory[shoeSizeIdx[2 * idx]].quantity &&
                  <span style={{fontWeight: "bold", color: "black"}}>{currSize} M / {currSize + 1.5} W</span>}
                {currSize === size && !shoe.shoeInventory &&  
                  <span style={{fontWeight: "bold", color: "black"}}>{currSize} M / {currSize + 1.5} W</span>}
                {currSize !== size &&  `${currSize} M / ${currSize + 1.5} W`}
              </Button>
              {idx < 10 && (
                <Button
                  className="shoeSizeButton"
                  onClick={() => handleSizeClick(currSize + 0.5, 2 * idx + 1)}
                  key={currSize + 0.5}
                  color={buttonColor[2 * idx + 1]}
                  disabled={shoe.shoeInventory && shoe.shoeInventory[shoeSizeIdx[2 * idx + 1]].quantity
                    ? false : ((ebay != true) 
                      ? !map_.has(currSize+0.5)
                      : !ebayResults.find(x => (x.size == shoeSizeIdx[2 * idx + 1].replace("_", "."))))
                  }
                >
                  {currSize + 0.5 === size && shoe.shoeInventory && shoe.shoeInventory[shoeSizeIdx[2 * idx + 1]].quantity>0 &&
                    <span style={{ fontWeight: "bold", color: "#32CD32"}}>${shoe.shoeInventory[shoeSizeIdx[2 * idx + 1]].price}</span>}
                  {currSize + 0.5 === size && shoe.shoeInventory && !shoe.shoeInventory[shoeSizeIdx[2 * idx + 1]].quantity &&
                    <span style={{fontWeight: "bold", color: "black"}}>{currSize+ 0.5} M / {currSize + 0.5 + 1.5} W</span>}
                  {currSize + 0.5 === size && !shoe.shoeInventory &&  
                    <span style={{fontWeight: "bold", color: "black"}}>{currSize+ 0.5} M / {currSize + 0.5 + 1.5} W</span>}
                  {currSize + 0.5 !== size && `${currSize + 0.5} M / ${currSize + 0.5 + 1.5} W`}
                </Button>
              )}
            </ButtonGroup>
          ))}
          {size && (!shoe.shoeInventory || (shoe.shoeInventory && !shoe.shoeInventory[getSizeStr(size)].quantity)) &&(
            <ButtonGroup className="shoeSizeButtonGroup">
              <Button
                className="bidButton"
                color="green"
                onClick={() => handleBuy("bid")}
                disabled={size ? false : true}
              >
                <span style={{fontSize: "18px"}}>Bid now</span>
              </Button>
              {/*<Button
                className="cartButton"
                color="simple"
                onClick={() => handleBuy("cart")}
                disabled={size ? false : true}
              >
                Add to cart
              </Button>*/}
            </ButtonGroup>
          )}
        </ButtonGroup>
      </ButtonToolbar>
    </div>
    <div>
      {size && shoe.shoeInventory && shoe.shoeInventory[getSizeStr(size)].quantity>0 &&(
        <div className="shoeSizeChart pt-2">
          <ButtonToolbar>
           <ButtonGroup className="shoeSizeButtonGroup"> 
              <Button
                className="buyButton"
                color="green"
                onClick={() => handleBuy("now")}
                disabled={size ? false : true}
              >
                Buy now
              </Button>
              <Button
                className="cartButtonNew"
                color="simple"
                onClick={() => handleBuy("cart")}
                disabled={size ? false : true}
                style={{ color: "#fff" }}
              >
                Add to cart
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
         </div>
        )}
    </div>
    </>
  );
};

export default ShoeSizeChart;
