import React, { useState } from "react";
import { Row, Input, FormGroup, Button, Form } from "reactstrap";
import { InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import { useAuth } from "contexts/AuthContext";
import { userDb } from "../../../../firebase";
import { toast } from "react-toastify";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import { userLoggedInSuccess } from "mystry_server";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const ModalSignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const { signup, setLoading } = useAuth();

  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  
  function pushMetaDataToFirebase(user) {
    const ref = userDb(user.uid);
    const obj = {
      userID: user.uid,
      email: email,
    };
    user.sendEmailVerification();
    ref.set(obj);
  }

  const handleSignUp = (e) => {
    e.preventDefault();
    setLoading(false);
    if (password !== password2) {
      toast.error("The passwords do not match");
    } else if (!emailRegex.test(String(email).toLowerCase())) {
      toast.error("Please enter a valid email address");
    } else {
      signup(email, password)
        .then((authUser) => { 
          setLoading(false);
          pushMetaDataToFirebase(authUser.user);
          userLoggedInSuccess(email);
          toast.success("Signed up successfully");
        })
        .catch((error) => {
          if (error.code === "auth/email-already-in-use") {
            toast.error("An account already exists with this email");
          } else {
            toast.error(error.message);
          }
        });
    }
  };

  return (
    <div>
      <Form onSubmit={handleSignUp}>
        <FormGroup>
          <Input
            type="email"
            name="email"
            id="email"
            placeholder="Email*"
            autoComplete="username"
            onChange={(e) => setEmail(e.target.value)}
            required={true}
          />
        </FormGroup>
        <FormGroup>
          <InputGroup>
            <Input
              type={!showPassword ? "password" : "text"}
              name="password"
              id="password"
              placeholder="Password*"
              autoComplete="new-password"
              onChange={(e) => setPassword(e.target.value)}
              required={true}
              minLength="8"
              pattern="^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[\w!@#$%^&*]{8,}$"
              title="Password must have at least 8 characters and must include at
                  least 1 lowercase, 1 uppercase, 1 number and one of !@#$%^&*"
            />
            <InputGroupAddon addonType="append">
              <InputGroupText style={{ padding: "0px" }}>
                <IconButton
                  style={{ padding: "0px", marginRight: "10px" }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <Visibility className="icons" style={{ fontSize: "18" }} />
                  ) : (
                    <VisibilityOff
                      className="icons"
                      style={{ fontSize: "18" }}
                    />
                  )}
                </IconButton>
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </FormGroup>

        <FormGroup>
          <InputGroup>
            <Input
              type={!showPassword2 ? "password" : "text"}
              name="confirmPassword"
              id="confirmPassword"
              autoComplete="new-password"
              placeholder="Retype password*"
              onChange={(e) => setPassword2(e.target.value)}
              required={true}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText style={{ padding: "0px" }}>
                <IconButton
                  style={{ padding: "0px", marginRight: "10px" }}
                  onClick={() => setShowPassword2(!showPassword2)}
                >
                  {showPassword2 ? (
                    <Visibility className="icons" style={{ fontSize: "18" }} />
                  ) : (
                    <VisibilityOff
                      className="icons"
                      style={{ fontSize: "18" }}
                    />
                  )}
                </IconButton>
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </FormGroup>
        <Button type="submit" color="green">
          Sign up
        </Button>
        <Row>
          <br />{" "}
        </Row>
        <div className="showPasswordLogin">
          *Password must have at least 8 characters and must include at least 1
          lowercase, 1 uppercase, 1 number and one of !@#$%^&*
        </div>

        <div className="showPasswordLogin">
          *By creating an account you agree to our{" "}
          <a href="/terms" target="_blank" rel="noreferrer">
            terms and conditions .
          </a>
        </div>
      </Form>
    </div>
  );
};

export default ModalSignUp;