import React, { useEffect, useState } from 'react';
import "./Navbar.css";
import { Link } from "react-router-dom";
import useWindowDimensions from "hooks/useWindowDimensions";
import { useAuth } from "contexts/AuthContext";
import { useHistory } from "react-router";
import { clearCart } from "reducers/cartReducer";
import { useDispatch } from "react-redux";

import {
  Collapse,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Button,
} from 'reactstrap';

function MyNavbar() {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [collapseOut, setCollapseOut] = React.useState("");
  const { height, width } = useWindowDimensions();
  const [color, setColor] = React.useState("bg-white");

  const toggleCollapse = () => {
    document.documentElement.classList.toggle("nav-open");
    setCollapseOpen(!collapseOpen);
  };

  const dispatch = useDispatch();
  const history = useHistory();

  const onCollapseExiting = () => {
    setCollapseOut("collapsing-out");
  };

  const onCollapseExited = () => {
    setCollapseOut("");
  };

  const { logout, currentUser } = useAuth();
  const [showLogin, setShowLogin] = useState(currentUser ? false : true);

  useEffect(() => {
    if (!currentUser) {
      setShowLogin(true);
    } else {
      setShowLogin(false);
    }
  }, [currentUser]);
  
  const logoutUser = () => {
    dispatch(clearCart());
    logout();
  };

  const openProfile = () => {
    history.push({
      pathname: `/profile-page`,
    });
  }

  var mobile;
  var logoStyle;
  if (width <= 420 || height <= 420) {
    mobile = true;
    logoStyle = { fontFamily: "edosz", fontSize: "1rem" };
  } else {
    mobile = false;
    logoStyle = { fontFamily: "edosz", fontSize: "2rem" };
  }

  return (
    <div>
      <Navbar
        className={"fixed-top d-flex justify-content-center " + color}
        style={{ paddingTop: "0px", paddingBottom: "0px"}}
      >
        <div className="justify-content-center">
          <NavbarBrand to="/" id="navbar-brand" tag={Link}>
            <span className="text-black" style={logoStyle}>
              MYSTRY{" "}
            </span>
          </NavbarBrand>
        </div>
        {/* {!showLogin && (
          <span class="navbar-toggler-icon-user" onClick={()=>openProfile()}>   
            <i class="fas fa-user" style={{color:"black", fontSize:"20px"}}></i>
          </span>
        )} */}
        <span class="navbar-toggler-icon" onClick={toggleCollapse}>   
          <i class="fas fa-bars" style={{color:"black", fontSize:"25px"}}></i>
        </span>

        {mobile && (
          <Collapse
            className={"justify-content-end " + collapseOut}
            navbar
            isOpen={collapseOpen}
            onExiting={onCollapseExiting}
            onExited={onCollapseExited}
            style={{
              backgroundColor: "black",
              width: "250px",
              left: "auto",
              right: "0vw",
            }}
          >
            <div className="navbar-collapse-header">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <a
                    href="/"
                    style={{
                      color: "#01b100",
                      fontFamily: "edosz",
                      fontSize: "1rem",
                    }}
                  >
                    MYSTRY
                  </a>
                </Col>
                <Col className="collapse-close text-right" xs="6">
                  <button
                    aria-expanded={collapseOpen}
                    className="navbar-toggler"
                    onClick={toggleCollapse}
                  >
                    <i className="tim-icons icon-simple-remove" />
                  </button>
                </Col>
              </Row>
            </div>
            <Nav navbar>
            <NavItem className='mb-2' 
                style={{                    
                  borderBottom: "2px solid grey"
                }}
              >
                <NavLink
                  tag={Link}
                  to="/mystryBoxProduct"
                  title="Membership"
                >
                  <span style={{color:"white"}}>
                    Mystry Box
                  </span>
                </NavLink>
              </NavItem>{" "}
              <NavItem className='mb-2' 
                style={{                    
                  borderBottom: "2px solid grey"
                }}
              >
                <NavLink
                  tag={Link}
                  to="/kitchen"
                  title="kitchen"
                >
                  <span style={{color:"white"}}>
                    Mystry Kitchen
                  </span>
                </NavLink>
              </NavItem>{" "}
              <NavItem className='mb-2' 
                style={{                    
                  borderBottom: "2px solid grey"
                }}
              >
                <NavLink
                  tag={Link}
                  to="/picks"
                  title="picks"
                >
                  <span style={{color:"white"}}>
                    Chef's Pick
                  </span>
                </NavLink>
              </NavItem>{" "}
              <NavItem className='mb-2' 
                style={{                    
                  borderBottom: "2px solid grey"
                }}
              >
                <NavLink
                  tag={Link}
                  to="/cookbook"
                  title="cookbook"
                >
                  <span style={{color:"white"}}>
                    Cookbook
                  </span>
                </NavLink>
              </NavItem>{" "}
              <NavItem className='mb-2' 
                style={{                    
                  borderBottom: "2px solid grey"
                }}
              >
                <NavLink
                  tag={Link}
                  to="/aLaCartePage"
                  title="aLaCartePage"
                >
                  <span style={{color:"white"}}>
                    A la carte
                  </span>
                </NavLink>
              </NavItem>{" "}
              <NavItem className='mb-2' 
                style={{                    
                  borderBottom: "2px solid grey"
                }}
              >
                <NavLink
                  tag={Link}
                  to="/tech"
                  title="tech"
                >
                  <span style={{color:"white"}}>
                    House of Tech
                  </span>
                </NavLink>
              </NavItem>{" "}
              {/* <NavItem className='mb-2' style={{                    
                borderBottom: "2px solid grey"
              }}>
                <NavLink
                  tag={Link}
                  to="/about"
                  title="About Us"
                >
                  <span style={{color:"white"}}>
                    About Us
                  </span>
                </NavLink>
              </NavItem> */}
              {!showLogin && (
                <NavItem className='mb-2' style={{                    
                  borderBottom: "2px solid grey"
                }}>
                  <NavLink
                    tag={Link}
                    to="/profile-page"
                    title="Profile"
                  >
                    <span style={{color:"white"}}>
                      Profile
                    </span>
                  </NavLink>
                </NavItem>
              )}
              <NavItem className='m-3 d-flex justify-content-center'>
                {showLogin && (
                  <NavLink
                    tag={Link}
                    to="/login"
                    title="Login"
                  >
                    <Button color="green">
                      Login
                    </Button>
                  </NavLink>
                )}
                {!showLogin && (
                  <NavLink
                    tag={Link}
                    to="/"
                    title="Logout"
                    onClick={() => logoutUser()}
                  >
                    <Button color="green">
                      Logout
                    </Button>
                  </NavLink>
                )}
              </NavItem>
            </Nav>
          </Collapse>
        )}
        {!mobile && (
          <Collapse
            className={"justify-content-end nav-collapsebar " + collapseOut}
            navbar
            isOpen={collapseOpen}
          >
            <Row>
              <Col>
                <a
                  href="/"
                  className='d-flex justify-content-center mb-4'
                  style={{
                    color: "white",
                    fontFamily: "edosz",
                    fontSize: "2rem",
                  }}
                >
                  MYSTRY
                </a>
              </Col>
              <Col className="collapse-close text-right">
                <button
                  aria-expanded={collapseOpen}
                  className="navbar-toggler"
                  onClick={toggleCollapse}
                >
                  <i className="tim-icons icon-simple-remove" style={{color:"white"}} />
                </button>
              </Col>
            </Row>
            <Nav navbar>
              <NavItem className='mb-2' 
                style={{                    
                  borderBottom: "2px solid grey"
                }}
              >
                <NavLink
                  tag={Link}
                  to="/mystryBoxProduct"
                  title="Membership"
                >
                  <span style={{color:"white"}}>
                    Mystry Box
                  </span>
                </NavLink>
              </NavItem>{" "}
              <NavItem className='mb-2' 
                style={{                    
                  borderBottom: "2px solid grey"
                }}
              >
                <NavLink
                  tag={Link}
                  to="/kitchen"
                  title="kitchen"
                >
                  <span style={{color:"white"}}>
                    Mystry Kitchen
                  </span>
                </NavLink>
              </NavItem>{" "}
              <NavItem className='mb-2' 
                style={{                    
                  borderBottom: "2px solid grey"
                }}
              >
                <NavLink
                  tag={Link}
                  to="/picks"
                  title="picks"
                >
                  <span style={{color:"white"}}>
                    Chef's Pick
                  </span>
                </NavLink>
              </NavItem>{" "}
              <NavItem className='mb-2' 
                style={{                    
                  borderBottom: "2px solid grey"
                }}
              >
                <NavLink
                  tag={Link}
                  to="/cookbook"
                  title="cookbook"
                >
                  <span style={{color:"white"}}>
                    Cookbook
                  </span>
                </NavLink>
              </NavItem>{" "}
              <NavItem className='mb-2' 
                style={{                    
                  borderBottom: "2px solid grey"
                }}
              >
                <NavLink
                  tag={Link}
                  to="/aLaCartePage"
                  title="aLaCartePage"
                >
                  <span style={{color:"white"}}>
                    A la carte
                  </span>
                </NavLink>
              </NavItem>{" "}
              <NavItem className='mb-2' 
                style={{                    
                  borderBottom: "2px solid grey"
                }}
              >
                <NavLink
                  tag={Link}
                  to="/tech"
                  title="tech"
                >
                  <span style={{color:"white"}}>
                    House of Tech
                  </span>
                </NavLink>
              </NavItem>{" "}
              {/* <NavItem className='mb-2' style={{                    
                borderBottom: "2px solid grey"
              }}>
                <NavLink
                  tag={Link}
                  to="/about"
                  title="About Us"
                >
                  <span style={{color:"white"}}>
                    About Us
                  </span>
                </NavLink>
              </NavItem> */}
              {!showLogin && (
                <NavItem className='mb-2' style={{                    
                  borderBottom: "2px solid grey"
                }}>
                  <NavLink
                    onClick={() => history.push({
                      pathname: `/profile-page`,
                    })}
                    style={{cursor: "pointer"}}
                    title="Profile"
                  >
                    <span style={{color:"white"}}>
                      Profile
                    </span>
                  </NavLink>
                </NavItem>
              )}
              <NavItem className='m-3 d-flex justify-content-center'>
                {showLogin && (
                  <NavLink
                    tag={Link}
                    to="/login"
                    title="Login"
                  >
                    <Button color="green">
                      Login
                    </Button>
                  </NavLink>
                )}
                {!showLogin && (
                  <NavLink
                    tag={Link}
                    to="/"
                    title="Logout"
                    onClick={() => logoutUser()}
                  >
                    <Button color="green">
                      Logout
                    </Button>
                  </NavLink>
                )}
              </NavItem>
            </Nav>
          </Collapse>
        )}
      </Navbar>
    </div>
  );
}

export default MyNavbar;

// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// // reactstrap components
// import {
//   Collapse,
//   NavbarBrand,
//   Navbar,
//   NavItem,
//   NavLink,
//   Nav,
//   Container,
//   UncontrolledTooltip,
//   Row,
//   Col,
//   Button,
// } from "reactstrap";
// import "assets/css/nucleo-icons.css";
// import useWindowDimensions from "hooks/useWindowDimensions";
// import { useAuth } from "contexts/AuthContext";
// import { useSelector, useDispatch } from "react-redux";
// import { clearCart } from "reducers/cartReducer";

// export default function MyNavbar({ page }) {
//   const [collapseOpen, setCollapseOpen] = React.useState(false);
//   const [collapseOut, setCollapseOut] = React.useState("");
//   const [color, setColor] = React.useState("navbar-transparent");
//   const dispatch = useDispatch();

//   React.useEffect(() => {
//     window.addEventListener("scroll", changeColor);
//     return function cleanup() {
//       window.removeEventListener("scroll", changeColor);
//     };
//   }, []);
//   const { logout, currentUser } = useAuth();
//   const [showLogin, setShowLogin] = useState(currentUser ? false : true);

//   useEffect(() => {
//     if (!currentUser) {
//       setShowLogin(true);
//     } else {
//       // if (!currentUser.emailVerified) {
//       //   setShowLogin(true);
//       // } else {
//       setShowLogin(false);
//       // }
//     }
//   }, [currentUser]);

//   const { height, width } = useWindowDimensions();

//   var mobile;
//   var logoStyle;
//   if (width <= 420 || height <= 420) {
//     mobile = true;
//     logoStyle = { fontFamily: "edosz", fontSize: "1rem" };
//   } else {
//     mobile = false;
//     logoStyle = { fontFamily: "edosz", fontSize: "2.4rem" };
//   }

//   const logoutUser = () => {
//     dispatch(clearCart());

//     logout();
//   };

//   const changeColor = () => {
//     if (
//       document.documentElement.scrollTop > 99 ||
//       document.body.scrollTop > 99
//     ) {
//       setColor("bg-black");
//     } else if (
//       document.documentElement.scrollTop < 100 ||
//       document.body.scrollTop < 100
//     ) {
//       setColor("navbar-transparent");
//     }
//   };
//   const toggleCollapse = () => {
//     document.documentElement.classList.toggle("nav-open");
//     setCollapseOpen(!collapseOpen);
//   };
//   const onCollapseExiting = () => {
//     setCollapseOut("collapsing-out");
//   };
//   const onCollapseExited = () => {
//     setCollapseOut("");
//   };
//   const highlight = {
//     color: "#FFFF00",
//   };

//   return (
//     <Navbar
//       className={"fixed-top " + color}
//       expand="lg"
//       color-on-scroll="100"
//       style={{ paddingTop: "0px", paddingBottom: "0px" }}
//     >
//       <Container fluid={true}>
//         <div className="navbar-translate">
//           <NavbarBrand to="/" id="navbar-brand" tag={Link}>
//             <span className="text-white" style={logoStyle}>
//               MYSTRY{" "}
//             </span>
//             {/* FIXME - can add taghline here */}
//           </NavbarBrand>
//           <UncontrolledTooltip 
//             placement="bottom" 
//             target="navbar-brand" 
//             style={{backgroundColor:"black", color:"white"}}>
//             Web 3.0 Sneaker-Tech Company
//           </UncontrolledTooltip>

//           <button
//             aria-expanded={collapseOpen}
//             className="navbar-toggler navbar-toggler"
//             onClick={toggleCollapse}
//           >
//             <span className="navbar-toggler-bar bar1" />
//             <span className="navbar-toggler-bar bar2" />
//             <span className="navbar-toggler-bar bar3" />
//           </button>
//         </div>
//         <Collapse
//           className={"justify-content-end " + collapseOut}
//           navbar
//           isOpen={collapseOpen}
//           onExiting={onCollapseExiting}
//           onExited={onCollapseExited}
//           style={{
//             backgroundColor: "black",
//             width: "250px",
//             left: "auto",
//             right: "0vw",
//           }}
//         >
//           <div className="navbar-collapse-header">
//             <Row>
//               <Col className="collapse-brand" xs="6">
//                 <a
//                   href="#pablo"
//                   onClick={(e) => e.preventDefault()}
//                   style={{
//                     color: "#01b100",
//                     fontFamily: "edosz",
//                     fontSize: "1rem",
//                   }}
//                 >
//                   MYSTRY
//                 </a>
//               </Col>
//               <Col className="collapse-close text-right" xs="6">
//                 <button
//                   aria-expanded={collapseOpen}
//                   className="navbar-toggler"
//                   onClick={toggleCollapse}
//                 >
//                   <i className="tim-icons icon-simple-remove" />
//                 </button>
//               </Col>
//             </Row>
//           </div>
//           <Nav navbar>
//             <NavItem>
//               <NavLink
//                 tag={Link}
//                 to="/membership"
//                 title="Membership"
//                 style={page === "membership" ? highlight : {}}
//               >
//                 MystryBox
//               </NavLink>
//             </NavItem>{" "}
//             <NavItem>
//               <NavLink
//                 tag={Link}
//                 to="/kitchen"
//                 title="Kitchen"
//                 style={page === "marketplace" ? highlight : {}}
//               >
//                 Kitchen
//               </NavLink>
//             </NavItem>{" "}
//             <NavItem>
//               <NavLink
//                 tag={Link}
//                 to="/picks"
//                 id="ChefsPicks"
//                 style={page === "picks" ? highlight : {}}
//               >
//                 Chef's Picks
//               </NavLink>
//             </NavItem>
//             <NavItem>
//               <NavLink
//                 tag={Link}
//                 to="/cookbook"
//                 title="Cookbook"
//                 style={page === "cookbook" ? highlight : {}}
//               >
//                 Cookbook
//               </NavLink>
//             </NavItem>
//             <NavItem>
//               <NavLink
//                 tag={Link}
//                 to="/metaverse"
//                 title="Mystryverse"
//                 style={page === "metaverse" ? highlight : {}}
//               >
//                 Mystryverse
//               </NavLink>
//             </NavItem>
//             <NavItem>
//               <NavLink
//                 tag={Link}
//                 to="/aLaCartePage"
//                 title="A La Carte"
//                 style={page === "aLaCartePage" ? highlight : {}}
//               >
//                 A La Carte
//               </NavLink>
//             </NavItem>
//             {!showLogin && (
//               <NavItem>
//                 <NavLink
//                   tag={Link}
//                   to="/profile-page"
//                   title="Profile"
//                   style={page === "profile" ? highlight : {}}
//                 >
//                   Profile
//                 </NavLink>
//               </NavItem>
//             )}
//             <NavItem style={{ marginTop: "-0.4vh", marginLeft: "-1vw" }}>
//               {showLogin && (
//                 <NavLink
//                   tag={Link}
//                   to="/login"
//                   title="Login"
//                   style={page === "login" ? highlight : {}}
//                 >
//                   <Button size="sm" color={mobile ? "green" : "green"}>
//                     Login
//                   </Button>
//                 </NavLink>
//               )}
//               {!showLogin && (
//                 <NavLink
//                   tag={Link}
//                   to="/"
//                   title="Logout"
//                   onClick={() => logoutUser()}
//                   // style={page === "login" ? highlight : {}}
//                 >
//                   <Button size="sm" color={mobile ? "green" : "green"}>
//                     Logout
//                   </Button>
//                 </NavLink>
//               )}
//             </NavItem>
//           </Nav>
//         </Collapse>
//       </Container>
//     </Navbar>
//   );
// }
