import { Button, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { currentUsersMystryBoxOrders } from "../../../../firebase";
import { useAuth } from "contexts/AuthContext";
import { useState, useEffect } from "react";
import MystryBoxOrderCard from "../components/MystryBoxOrderCard";

export const MystryBoxStatus = () => {
  const { currentUser } = useAuth();
  const [mystryOrders, setMystryOrders] = useState([]);

  useEffect(() => {
    const userId = currentUser.uid;
    console.log(userId);
    try {
      currentUsersMystryBoxOrders(userId).once("value", (snapshot) => {
        let orders = [];
        snapshot.forEach((snap) => {
          var tempOrder = snap.val();
          orders.push(tempOrder);

          // if (tempOrder.orderState === "Completed") {
          //   orders.push(tempOrder);
          // }
        });
        setMystryOrders(orders);
        console.log(mystryOrders);
      });
    } catch (error) {}
  }, []);

  return (
    <div>
      <Row className="align-items-center">
        <Col style={{ textAlign: "center" }}>
          <p
            style={{
              margin: "20px",
              marginBottom: "30px",
            }}
          >
            {mystryOrders.length === 0 && (
              <h4>No Mystry Box Orders! Order 
                <a
                  href="/mystryBoxProduct"
                  style={{
                    cursor: "pointer",
                    color: "#00b200",
                  }}
                >#MystryBox</a> {" "}now
              </h4>
            )}
            {mystryOrders.map((order) => {
              return <MystryBoxOrderCard order={order} />;
            })}
          </p>
        </Col>
      </Row>
    </div>
  );
};
