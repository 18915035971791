import React from "react";
import { Container, Button } from "reactstrap";
import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";
import { useHistory, useLocation } from "react-router-dom";
import {
  useSelector,
  // useDispatch
} from "react-redux";
import {
  createOrder,
  userDb,
  // database
} from "../../../firebase";
import { useAuth } from "contexts/AuthContext";
// import { setShoes } from "../../../reducers/shoesReducer";
import Loading from "components/Loading/Loading";
import useWindowDimensions from "hooks/useWindowDimensions";

const Payment = () => {
  // const history = useHistory();
  const location = useLocation();
  const history = useHistory();
  const { currentUser, loggedIn } = useAuth();
  const { width, height } = useWindowDimensions();

  const userInfo = location.state.userInfo;
  // ////console.log(userInfo);
  const cart = useSelector((state) => state.cart.cartShoes);
  const shoes = useSelector((state) => state.shoes);
  // const dispatch = useDispatch();

  var mobile, landscape;
  if (width < 768) {
    mobile = true;
    landscape = false;
  } else if (height < 576 || width < 992) {
    mobile = false;
    landscape = true;
  } else {
    mobile = false;
    landscape = false;
  }
  
  const handleSuccessfulPayment = (e) => {
    e.preventDefault();
    // ////console.log(userInfo);
    if (!userInfo) {
      history.push("/checkout");
    } else {
      var items = [];
      cart.forEach((item) => {
        var orderObj = {};
        orderObj.customerID = userInfo.userID;
        orderObj.phone = userInfo.phone;
        orderObj.deliveryAdress = userInfo.address;
        orderObj.email = userInfo.email;
        orderObj.shoeID = item.shoe.shoeID;
        orderObj.size = item.size;
        orderObj.quantity = item.quantity;
        orderObj.price = item.quantity * shoes[item.shoe.shoeID].retailPrice;
        // if (!shoes || Object.keys(shoes).length === 0) {
        //   const ref = database.shoes;
        //   ref.once("value", (snapshot) => {
        //     var shoe_data = snapshot.val();
        //     dispatch(setShoes(shoe_data));
        //   });
        //   orderObj.price = item.quantity * shoes[item.shoe.shoeID].retailPrice;
        // } else {
        //   orderObj.price = item.quantity * shoes[item.shoe.shoeID].retailPrice;
        // }
        orderObj.orderState = "Pending";
        orderObj.orderStatus = "Processing";
        orderObj.isGuest = userInfo.guest;
        items.push(orderObj);
      });
      var { orderIDs, cartID } = createOrder(items);
      if (loggedIn) {
        const ref = userDb(currentUser.uid);
        // check if currentUser is verified or not using currentUser.emailVerified
        // if (!currentUser.emailVerified) {
        //   currentUser.sendEmailVerification();
        // }

        orderIDs.forEach((id) => {
          ref.child(`orderHistory/${cartID}`).push(id);
        });
      }
      // TODO clear cart and decrement stock from inventory. Use Firebase transaction function for the same.
    }
  };

  return (
    <>
      {!shoes || (Object.keys(shoes).length === 0 && <Loading />)}
      {shoes && Object.keys(shoes).length > 0 && (
        <>
        <div style={{
            position: "relative",
            overflow: "hidden",
            background: "black",
          }}
        >
          <Navbar />
          {mobile && (
            <video
              width="100%"
              autoPlay
              muted
            >
              <source
                src={require("assets/custom/payment/payment_processing_mobile.mp4").default}
              ></source>
            </video>
          )}
          {!mobile && (
            <video
              width="100%"
              autoPlay
              muted
            >
              <source
                src={require("assets/custom/payment/payment_processing_desktop.mp4").default}
              ></source>
            </video>
          )}
            {/* <Button color="simple">Failed Payment</Button>
            <Button color="simple" onClick={(e) => handleSuccessfulPayment(e)}>
              Successful payment
            </Button> */}
          <Footer />
        </div>
        </>
      )}
    </>
  );
};

export default Payment;
