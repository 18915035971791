import {React, useState} from 'react';
import Navbar from "components/Navbars/Navbar.js";
import Footer from 'components/Footer/Footer';
import { Carousel } from "react-responsive-carousel";
import { useHistory } from "react-router";
import * as Scroll from "react-scroll";
import useWindowDimensions from "hooks/useWindowDimensions";
import { Container, Row, Col } from "reactstrap";

const ALaCartePage = () => {
    const cookbookStyle = {
        objectFit: "contain",
        display: "block",
        width: "100%",
        marginLeft: "auto",
        marginRight: "auto",
        minHeight: "100%",
        borderRadius: "0px",
    };

    const fluid = true;
    const { width, height } = useWindowDimensions();

    var mobile, landscape;
    if (width < 768) {
      mobile = true;
      landscape = false;
    } else if (height < 576 || width < 992) {
      mobile = false;
      landscape = true;
    } else {
      mobile = false;
      landscape = false;
    }

    const containerStyle = {
        marginBottom: "7.5vw",
        paddingLeft: !mobile && fluid ? "5vw" : "",
        paddingRight: !mobile && fluid ? "5vw" : "",
      };

    const alcContainerStyle = {
        marginBottom: "4vw",
        paddingLeft: !mobile && fluid ? "5vw" : "",
        paddingRight: !mobile && fluid ? "5vw" : "",
    };
    const history = useHistory();
    const alc = [
        "pharrell",
        "rocky",
        "kanye",
        "pioneer"
    ];
    const [showScrollDown, setShowScrollDown] = useState(false);
    const scrollDown = () => {
      Scroll.animateScroll.scrollTo(800, {
        smooth: true,
      });
    };
    const toggleScrollDown = (e) => {
      if (e.target.currentTime >= 3) {
        setShowScrollDown(true);
      }
    };
    const responsiveHeadStyle = mobile
    ? { marginTop: "60px" }
    : { marginTop: "100px" };

    const handleALC = (chef) => history.push(`/aLaCarte/${alc[chef]}`);

    return (
        <>
            <Navbar/>
            {!mobile && <div>
              <div
                style={{
                  position: "relative",
                  overflow: "hidden",
                  background: "black",
                }}
              >
                <video
                  width="100%"
                  type="video/mp4"
                  autoPlay
                  loop
                  muted
                  playsInline
                  style={{
                    zIndex: 1,
                  }}
                  position="relative"
                  onTimeUpdate={(e) => toggleScrollDown(e)}
                >
                  <source
                    src={require("assets/custom/alacarte/alc_video.webm").default}
                    type="video/mp4"
                  ></source>
                </video>
                {showScrollDown && !mobile && (
                  <div className="scrollDownContainer" onClick={scrollDown}>
                    <div className="scrollDownChevron"></div>
                    <div className="scrollDownChevron"></div>
                    <div className="scrollDownChevron"></div>
                    <span className="scrollDownText">Scroll down</span>
                  </div>
                )}
              </div>
            </div>
            }
            <Container
                fluid={true}
                style={{paddingBottom: "2vh", ...responsiveHeadStyle }}
            >
                <h1 style={{ textAlign: "center", paddingBottom: "2vh" }}>
                   A La Carte
                </h1>
                {!mobile && (
                <Container fluid={fluid} style={{ ...alcContainerStyle }}>
                <Row>
                  <Container fluid={fluid} style={{paddingTop: "2vh"}}>
                    {/* <h3 style={{ paddingTop:"2vh" }}>Style Icons :</h3> */}
                    <Container fluid={fluid}>
                    <Carousel
                      showArrows={true}
                      autoPlay={true}
                      interval={6000}
                      showThumbs={false}
                      showIndicators={false}
                      showStatus={false}
                      infiniteLoop={true}
                    >
                      <Row>
                        <Col
                          style={{ paddingRight: "0px", cursor: "pointer" }}
                          onClick={() => handleALC(0)}
                        >
                          <img
                            src={
                              require("assets/custom/alacarte/pharrell.png").default
                            }
                            height="90%"
                            style={{ borderRadius: "0px" , paddingBottom: "0px"}}
                            className={cookbookStyle}
                            alt=""
                          />
                        </Col>
                        <Col
                          style={{ padding: "0px", cursor: "pointer" }}
                          onClick={() => handleALC(1)}
                        >
                          <img
                            src={
                              require("assets/custom/alacarte/rocky.png").default
                            }
                            height="90%"
                            style={{ borderRadius: "0px", paddingBottom: "0px" }}
                            className={cookbookStyle}
                            alt=""
                          />
                        </Col>
                        <Col
                          style={{ padding: "0px", cursor: "pointer" }}
                          onClick={() => handleALC(2)}
                        >
                          <img
                            src={
                              require("assets/custom/alacarte/kanye.png").default
                            }
                            height="90%"
                            style={{ borderRadius: "0px", paddingBottom: "0px" }}
                            className={cookbookStyle}
                            alt=""
                          />
                        </Col>
                        <Col
                          style={{ padding: "0px", cursor: "pointer" }}
                          onClick={() => handleALC(3)}
                        >
                          <img
                            src={
                              require("assets/custom/alacarte/pioneer.png").default
                            }
                            height="90%"
                            style={{ borderRadius: "0px", paddingBottom: "0px" }}
                            className={cookbookStyle}
                            alt=""
                          />
                        </Col>
                      </Row>
                    </Carousel>
                  </Container>
                  </Container>
                </Row>
              </Container>
            )}
            {mobile && (
              <>
              <Container style={{ ...containerStyle }}>
                <Col
                  style={{ padding: "0px", cursor: "pointer" }}
                  onClick={() => handleALC(1)}
                >
                  <img
                    src={
                      require("assets/custom/alacarte/rocky.png").default
                    }
                    style={{ borderRadius: "0px" }}
                    className={cookbookStyle}
                    alt=""
                  />
                </Col>
              </Container>
              <Container style={{ ...containerStyle }}>
              <h3 style={{ paddingTop:"2vh" }}>Style Icons :</h3>
                <Row>
                  <Container fluid={fluid}>
                    <Carousel
                      showArrows={true}
                      autoPlay={true}
                      interval={6000}
                      showThumbs={false}
                      showIndicators={false}
                      showStatus={false}
                      infiniteLoop={true}
                    >
                      <Row>
                        <Col
                          style={{ paddingRight: "0px", cursor: "pointer" }}
                          onClick={() => handleALC(0)}
                        >
                          <img
                            src={
                              require("assets/custom/alacarte/pharrell.png").default
                            }
                            style={{ borderRadius: "0px" }}
                            className={cookbookStyle}
                            alt=""
                          />
                        </Col>
                        <Col
                          style={{ padding: "0px", cursor: "pointer" }}
                          onClick={() => handleALC(2)}
                        >
                          <img
                            src={
                              require("assets/custom/alacarte/kanye.png").default
                            }
                            style={{ borderRadius: "0px" }}
                            className={cookbookStyle}
                            alt=""
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          style={{ paddingRight: "0px", cursor: "pointer" }}
                          onClick={() => handleALC(3)}
                        >
                          <img
                            src={require("assets/custom/alacarte/pioneer.png").default}
                            style={{ borderRadius: "0px", height: "100%" }}
                            className={cookbookStyle}
                            alt=""
                          />
                        </Col>
                        <Col
                          style={{ padding: "0px", cursor: "pointer" }}
                          onClick={() => handleALC(1)}
                        >
                          <img
                            src={
                              require("assets/custom/alacarte/rocky.png").default
                            }
                            style={{ borderRadius: "0px" }}
                            className={cookbookStyle}
                            alt=""
                          />
                        </Col>
                      </Row>
                    </Carousel>
                  </Container>
                </Row>
              </Container>
              </>
            )}
            </Container>
            <Footer/>
        </>
    );
};

export default ALaCartePage;