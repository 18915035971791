import React, { useEffect, useRef } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import { useHistory, useLocation } from "react-router";
import { useAuth } from "contexts/AuthContext";
import useWindowDimensions from "hooks/useWindowDimensions";

const MystryTinder = () => {
  const fluid = true;
  const { loggedIn, currentUser } = useAuth();
  const history = useHistory();
  const { width, height } = useWindowDimensions();

  var mobile;
  if (width <= 576 || height <= 576) {
    mobile = true;
  } else {
    mobile = false;
  }

  function openSneakerswipe() {
    if (loggedIn) {
      history.push({ pathname: `/infoFormV2` });
    } else {
      history.push({
        pathname: `/login`,
        state: {
          waitlist: false,
          sneakerswipe: true,
        },
      });
    }
  }

  const location = useLocation();
  const ref = useRef();

  useEffect(() => {
    const product = location.state && location.state.product || false;
    if (product && ref.current) {
      window.scrollTo({
        top: mobile? ref.current.offsetTop + 500: ref.current.offsetTop + 250,
        behavior: "smooth",
      });
      window.history.replaceState({}, document.title)
    }
  }, []);

  return (
    <div
      id="mystrytinder"
      ref={ref}
      style={{
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Container
        fluid={fluid}
        style={{
          marginTop: !mobile ? "0px" : "5vh",
          overflowX: "hidden",
          paddingLeft: "10vw",
          paddingRight: mobile ? "10vw" : "5vw",
        }}
      >
        <h1
          className="text-white"
          style={{
            marginTop: mobile? "5vh": "20vh",
            marginBottom: "2vh",
            textAlign: "center",
            fontSize: "40px",
            fontWeight: "800",
          }}
        >
          Sneaker Swipe
        </h1>
        {!mobile && (
          <div>
            <h4 className="d-flex justify-content-center align-items-center m-0">
              Play Tinder for Sneakers 
            </h4>
            <h4 className="d-flex justify-content-center align-items-center"
              style={{ textAlign: "center", marginBottom: "30px"}}
            >
              Sneaker Swipe allows you to swipe left and right 
              (and superlike) sneakers
            </h4>
          </div>
        )}
        {mobile && (
          <div>
            <h5 className="d-flex justify-content-center align-items-center m-0">
              Play Tinder for Sneakers 
            </h5>
            <h5 className="d-flex justify-content-center align-items-center mb-0"
              style={{ textAlign: "center"}}
            >
              Sneaker Swipe allows you to swipe left and right 
              (and superlike) sneakers
            </h5>
          </div>
        )}
        {mobile && (
          <Col md={12}>
            <Row md={12}>
              <img
                alt="..."
                src={
                  require("assets/custom/SneakerTinder/TinderMystryLanding.png")
                    .default
                }
                style={{
                  marginTop: "5vh",
                  marginBottom: "5vh",
                }}
              />
            </Row>
            <Row
              md={6}
              style={{
                fontSize: "20px",
                textAlign: "center",
                marginBottom: "2vh",
              }}
              className="d-flex justify-content-center align-items-center"
            >
              Sneakerswipe is our Machine-Learning algorithm which understands
              your taste and preferences in terms of brands, colorways,
              silhouette, designs, and more - based upon which we curate every
              Mystry Box.
            </Row>
          </Col>
        )}
        {!mobile && (
          <Row md={12}>
            <Col md={6}>
              <img
                alt="..."
                src={
                  require("assets/custom/SneakerTinder/TinderMystryLanding.png")
                    .default
                }
                width='85%'
                style={{
                  marginTop: "2vh",
                  marginBottom: "2vh"
                }}
              />
            </Col>
            <Col
              md={6}
              style={{
                fontSize: "25px",
                textAlign: "right",
                paddingRight: "5vw",
              }}
              className="d-flex justify-content-center align-items-center"
            >
              Sneakerswipe is our Machine-Learning algorithm which understands
              your taste and preferences in terms of brands, colorways,
              silhouette, designs, and more - based upon which we curate every
              Mystry Box.
            </Col>
          </Row>
        )}
      </Container>
      <div className="d-flex justify-content-center">
        <div style={{ textAlign: "center" }}>
          <Button
            color="green"
            style={{
              width: mobile ? "150px" : "200px",
              border: "1px solid white",
              marginTop: mobile? "": "20px"
            }}
            onClick={() => openSneakerswipe()}
            className='mb-3'
          >
            Play Now
          </Button>
          <p style={{ fontSize: "12px" }} className="m-0 p-0">
            Powered By
          </p>
          <img
            alt="..."
            src={
              require("assets/custom/SneakerTinder/TinderMystry.png").default
            }
            className="img-fluid"
          />
        </div>
      </div>
    </div>
  );
};

export default MystryTinder;
