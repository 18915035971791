import Navbar from "components/Navbars/Navbar.js";
import { Col, Card, Row } from "reactstrap";
import useWindowDimensions from "hooks/useWindowDimensions";
import { useLocation } from "react-router-dom";
import ShoeRow from "./ShoeRow";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import { useState } from "react";
import ShoePageRow from "./ShoePageRow";

import Typography from "@material-ui/core/Typography";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

export default function TrackShoeStatusPage() {
  const location = useLocation();
  const order = location.state.order;

  const steps = ["Processing", "Procuring", "Shipping", "Shipped", "Delivered"];

  function getActiveStep(orderStatus) {
    if (orderStatus === "Processing") {
      return 0;
    } else if (orderStatus === "Procuring") {
      return 1;
    } else if (orderStatus === "Shipping") {
      return 2;
    } else if (orderStatus === "Shipped") {
      return 3;
    } else if (orderStatus === "Delivered") {
      return 4;
    } else {
      return 0;
    }
  }

  function getOrderStatus(orderStatus) {
    if (orderStatus === "Processing") {
      return "Finding the perfect pair";
    } else if (orderStatus === "Procuring") {
      return "Applying polish";
    } else if (orderStatus === "Shipping") {
      return "Packing your pair";
    } else if (orderStatus === "Shipped") {
      return "On the way";
    } else if (orderStatus === "Delivered") {
      return "Ready to rock";
    } else {
      return "AWSM";
    }
  }

  const [activeStep, setActiveStep] = useState(
    getActiveStep(order.orderStatus)
  );

  return (
    <div>
      {" "}
      <Navbar page={"profile"} />
      <div
        className="wrapper"
        style={{
          marginTop: "15vh",
          marginBottom: "5vh",
          marginLeft: "2vh",
          marginRight: "2vh",
        }}
      >
        <center>
          <h3>Track your Order</h3>
        </center>
        <br />
        <div>
          <Stepper
            activeStep={activeStep}
            orientation="vertical"
            style={{ backgroundColor: "transparent" }}
          >
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel>
                  <p>{getOrderStatus(label)}</p>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
      </div>
    </div>
  );
}
