import React, {useState, useEffect}  from 'react';
import { Container, Row, Col, Button } from "reactstrap";
import "./Subscription.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useAuth } from "contexts/AuthContext";
import { auth, userDb } from "../../../firebase.js";
import useWindowDimensions from "hooks/useWindowDimensions";
import { Link } from 'react-router-dom';

const Subscription = () => {
    const { width, height } = useWindowDimensions();
    const mobile = width <= 420 || height <= 420 ? true : false;
    const fluid = true;
    const { anon_login, currentUser, loggedIn } = useAuth();
    const [userPhone, setUserPhone] = useState("");
    const [userEmail, setUserEmail] = useState(loggedIn ? currentUser.email : "");
    const [userSubscribed, setUserSubscribed] = useState(false);
    function logout() {
      return auth.signOut();
    }

    const subscribe = (e) => {
      e.preventDefault();
      anon_login().then((authUser) => {
        const ref = userDb(authUser.user.uid);
        const obj = {
          email: userEmail,
          userID: authUser.user.uid,
          phone: { phoneNumber: userPhone },
          subscribe: true,
        };
        ref.update(obj);
        console.log(authUser.user.uid);
        logout();
      })
      setUserSubscribed(true);
      setUserEmail("");
      setUserPhone("");
    };

    return (
        <Container
        fluid={fluid}
        style={{
          marginTop: !mobile ? "0px" : "50px",
          marginBottom: "60px",
        }}
      >
        {!userSubscribed && 
        <>
          {!mobile 
            ? <p className='sub-text'>SIGN UP FOR UPDATES AND GET $10 OFF YOUR FIRST ORDER</p>
            : <p className='sub-text-mobile'>SIGN UP FOR UPDATES AND GET $10 OFF YOUR FIRST ORDER</p>
          }
          <Row>
        <div className="subs-wrapper">
         <div className="subs-content">
            <form className="subs-form mb-4" id="form-subs" onSubmit={subscribe}>
              <input 
                type="text" 
                placeholder="Email Address*" 
                className="subs-field"
                value={userEmail}
                required
                onChange={(e) => setUserEmail(e.target.value)}
              />
              <PhoneInput
                containerStyle={{
                  border: "10px solid black",
                }}
                inputStyle={{
                  background: "black"
                }}
                inputProps={{
                  required: true,
                }}
                value={userPhone}
                onChange={setUserPhone}
                placeholder="Enter Phone Number"
                country={"us"}  
              />
              <input type="submit" value="Subscribe" className='subs-button' id="form_submit"></input>
            </form>
            <Link to={{pathname: "/terms"}} style={{color:"white"}}>Terms & Conditions</Link>
         </div>
        </div>
        </Row>
        </>
        }
        {userSubscribed && <h3 className='sub-text'>Thanks for Subscribing!</h3>}
        </Container>
    );
};

export default Subscription;