import React, { useEffect, useState, useCallback } from "react";
import "./ComingSoon.css";
import ComingSoonMobile from "./ComingSoonMobile";
import ComingSoon from "./ComingSoon";
const ComingSoonMain = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const handleWindowResize = useCallback((event) => {
    setWindowSize(window.innerWidth);
  }, []);
  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize]);
  return <>{windowSize <= 600 ? <ComingSoonMobile /> : <ComingSoon />}</>;
};
export default ComingSoonMain;
