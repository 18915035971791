import React, { useState } from "react";
import {
  Row,
  Col,
  FormGroup,
  Form,
  Button,
  Input,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";
import states from "states-us";
import { useHistory } from "react-router";
import { userDb } from "../../../firebase.js";

const NoUserInfo = ({
  currentUser,
  buyNow, bidNow,
  setShowPayment,
  setShowAddress,
  setShowAddressBid,
  setShippingAddress,
}) => {
  // const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneCountryCode, setPhoneCountryCode] = useState("+1");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("State*");
  const [zip, setZip] = useState("");
  // const [infoObj, setInfoObj] = useState(null);
  const history = useHistory();

  const pushMetaDataToFirebase = () => {
    // ////console.log("curr_user:", currentUser, currentUser.uid, currentUser.emailVerified);
    const ref = userDb(currentUser.uid);
    const obj = {
      // userID: currentUser.uid,
      // email: email,
      name: { firstName, lastName },
      phone: { phoneCountryCode, phoneNumber: phone },
      address: [
        {
          firstName,
          lastName,
          address1,
          address2,
          city,
          state,
          zip,
        },
      ],
      guest: false,
    };
    

    var vals = {};
    vals = { ...vals, ...obj };
    // setInfoObj(vals);
    ref.update(vals);
    return vals;
  };

  const handleInfoCheckout = (e) => {
    e.preventDefault();
    const info = pushMetaDataToFirebase();
    
    setShowAddress(true);
    setShippingAddress({ ...info, address: info.address[0] });
  };

  const handleInfoCheckoutBid = (e) => {
    e.preventDefault();
    const info = pushMetaDataToFirebase();
    
    setShowAddressBid(true);
    setShippingAddress({ ...info, address: info.address[0] });
  };

  return (
    <div>
      {!buyNow && !bidNow && (
        <>
          <img
            src={require("assets/custom/checkout.jpg").default}
            alt="checkout-img"
            className="checkoutImgActual"
          />
          <Card className="checkoutAddressCardStyle">
            <CardBody>
              <CardTitle>Shipping Information</CardTitle>
              <Form onSubmit={handleInfoCheckout}>
                <FormGroup>
                  <Row>
                    <Col xs={6}>
                      <Input
                        type="text"
                        name="firstName"
                        id="firstName"
                        placeholder="First Name*"
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                      />
                    </Col>
                    <Col xs={6}>
                      <Input
                        type="text"
                        name="lastName"
                        id="lastName"
                        placeholder="Last Name*"
                        onChange={(e) => setLastName(e.target.value)}
                        required
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Row>
                    <Col xs={12}>
                      <Input
                        type="text"
                        name="address1"
                        id="address1"
                        placeholder="Address 1*"
                        onChange={(e) => setAddress1(e.target.value)}
                        required
                        maxLength={100}
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Row>
                    <Col xs={12}>
                      <Input
                        type="text"
                        name="address2"
                        id="address2"
                        placeholder="Address 2"
                        onChange={(e) => setAddress2(e.target.value)}
                        maxLength={100}
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Row>
                    <Col xs={6}>
                      <Input
                        type="text"
                        name="city"
                        id="city"
                        placeholder="City*"
                        onChange={(e) => setCity(e.target.value)}
                        required
                        maxLength={50}
                      />
                    </Col>
                    <Col xs={6}>
                      <Input
                        type="select"
                        name="state"
                        id="state"
                        style={{ backgroundColor: "#1B1B1B" }}
                        onChange={(e) => setState(e.target.value)}
                        required
                      >
                        <option>State*</option>
                        {states.map((state, idx) => (
                          <option key={idx}>{state.abbreviation}</option>
                        ))}
                      </Input>
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Input
                    type="tel"
                    name="zip"
                    id="zip"
                    placeholder="Zip*"
                    onChange={(e) => setZip(e.target.value)}
                    required
                    maxLength={10}
                  />
                </FormGroup>
                <FormGroup>
                  <Row>
                    {/* <Col xs={3}>
                      <Input
                        name="phoneCountryCode"
                        id="phoneCountryCode"
                        placeholder="Code"
                        onChange={(e) => setPhoneCountryCode(e.target.value)}
                        value={phoneCountryCode}
                        required
                      />
                    </Col> */}
                    <Col>
                      <Input
                        type="tel"
                        name="phone"
                        id="phone"
                        placeholder="Phone number*"
                        onChange={(e) => setPhone(e.target.value)}
                        title="Please only use numbers"
                        pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                        required
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <Button type="submit" size="sm" color="simple">
                  Proceed
                </Button>
                <div className="showPasswordLogin">
                  *Please only use numbers for entering the phone number
                </div>
              </Form>
            </CardBody>
          </Card>
        </>
      )}
      {buyNow && !bidNow && (
        <Form onSubmit={handleInfoCheckout}>
          <FormGroup>
            <Row>
              <Col xs={6}>
                <Input
                  type="text"
                  name="firstName"
                  id="firstName"
                  placeholder="First Name*"
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
              </Col>
              <Col xs={6}>
                <Input
                  type="text"
                  name="lastName"
                  id="lastName"
                  placeholder="Last Name*"
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col xs={12}>
                <Input
                  type="text"
                  name="address1"
                  id="address1"
                  placeholder="Address 1*"
                  onChange={(e) => setAddress1(e.target.value)}
                  required
                  maxLength={100}
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col xs={12}>
                <Input
                  type="text"
                  name="address2"
                  id="address2"
                  placeholder="Address 2"
                  onChange={(e) => setAddress2(e.target.value)}
                  maxLength={100}
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col xs={6}>
                <Input
                  type="text"
                  name="city"
                  id="city"
                  placeholder="City*"
                  onChange={(e) => setCity(e.target.value)}
                  required
                  maxLength={50}
                />
              </Col>
              <Col xs={6}>
                <Input
                  type="select"
                  name="state"
                  id="state"
                  style={{ backgroundColor: "#1B1B1B" }}
                  onChange={(e) => setState(e.target.value)}
                  required
                >
                  <option>State*</option>
                  {states.map((state, idx) => (
                    <option key={idx}>{state.abbreviation}</option>
                  ))}
                </Input>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Input
              type="tel"
              name="zip"
              id="zip"
              placeholder="Zip*"
              onChange={(e) => setZip(e.target.value)}
              required
              maxLength={10}
            />
          </FormGroup>
          <FormGroup>
            <Row>
              {/* <Col xs={3}>
                <Input
                  name="phoneCountryCode"
                  id="phoneCountryCode"
                  placeholder="Code"
                  onChange={(e) => setPhoneCountryCode(e.target.value)}
                  value={phoneCountryCode}
                  required
                />
              </Col> */}
              <Col>
                <Input
                  type="tel"
                  name="phone"
                  id="phone"
                  placeholder="Phone number*"
                  onChange={(e) => setPhone(e.target.value)}
                  title="Please only use numbers"
                  pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                  required
                />
              </Col>
            </Row>
          </FormGroup>
          <Button type="submit" size="sm" color="simple">
            Proceed
          </Button>
          <div className="showPasswordLogin">
            *Please only use numbers for entering the phone number
          </div>
        </Form>
      )}
      {!buyNow && bidNow && (
        <Form onSubmit={handleInfoCheckoutBid}>
          <FormGroup>
            <Row>
              <Col xs={6}>
                <Input
                  type="text"
                  name="firstName"
                  id="firstName"
                  placeholder="First Name*"
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
              </Col>
              <Col xs={6}>
                <Input
                  type="text"
                  name="lastName"
                  id="lastName"
                  placeholder="Last Name*"
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col xs={12}>
                <Input
                  type="text"
                  name="address1"
                  id="address1"
                  placeholder="Address 1*"
                  onChange={(e) => setAddress1(e.target.value)}
                  required
                  maxLength={100}
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col xs={12}>
                <Input
                  type="text"
                  name="address2"
                  id="address2"
                  placeholder="Address 2"
                  onChange={(e) => setAddress2(e.target.value)}
                  maxLength={100}
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col xs={6}>
                <Input
                  type="text"
                  name="city"
                  id="city"
                  placeholder="City*"
                  onChange={(e) => setCity(e.target.value)}
                  required
                  maxLength={50}
                />
              </Col>
              <Col xs={6}>
                <Input
                  type="select"
                  name="state"
                  id="state"
                  style={{ backgroundColor: "#1B1B1B" }}
                  onChange={(e) => setState(e.target.value)}
                  required
                >
                  <option>State*</option>
                  {states.map((state, idx) => (
                    <option key={idx}>{state.abbreviation}</option>
                  ))}
                </Input>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Input
              type="tel"
              name="zip"
              id="zip"
              placeholder="Zip*"
              onChange={(e) => setZip(e.target.value)}
              required
              maxLength={10}
            />
          </FormGroup>
          <FormGroup>
            <Row>
             
              <Col>
                <Input
                  type="tel"
                  name="phone"
                  id="phone"
                  placeholder="Phone number*"
                  onChange={(e) => setPhone(e.target.value)}
                  title="Please only use numbers"
                  pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                  required
                />
              </Col>
            </Row>
          </FormGroup>
          <Button type="submit" size="sm" color="simple">
            Proceed
          </Button>
          <div className="showPasswordLogin">
            *Please only use numbers for entering the phone number
          </div>
        </Form>
      )}
    </div>
  );
};

export default NoUserInfo;
