import React from "react";
import { Container, Row, Col, UncontrolledCarousel } from "reactstrap";
import useWindowDimensions from "hooks/useWindowDimensions";
import CarouselImage1 from "assets/custom/carousel1.JPG";
import CarouselImage2 from "assets/custom/carousel2.JPG";
import CarouselImage3 from "assets/custom/carousel3.JPG";
import CarouselImage4 from "assets/custom/carousel4.JPG";
import { useAuth } from "contexts/AuthContext";
import { useHistory } from "react-router";

const AboutMystry = () => {
  const { width, height } = useWindowDimensions();
  var mobile, landscape;
  if (width < 768) {
    mobile = true;
    landscape = false;
  } else if (height < 576 || width < 992) {
    mobile = false;
    landscape = true;
  } else {
    mobile = false;
    landscape = false;
  }
  const fluid = true;
  const history = useHistory();
  const { loggedIn, currentUser } = useAuth();

  const containerStyle = {
    marginBottom: "7.5vw",
    paddingLeft: !mobile && fluid ? "5vw" : "",
    paddingRight: !mobile && fluid ? "5vw" : "",
  };

  const responsiveHeadStyle = mobile
    ? { fontSize: "1.65rem" }
    : { fontSize: landscape ? "1.5rem" : "2vw" };

  const responsiveBodyStyle = mobile
    ? {
        fontSize: "1rem",
        color: "#7b838a",
      }
    : { fontSize: landscape ? "1rem" : "1.1vmax", color: "#7b838a" };

  const carouselItems = [
    {
      src: CarouselImage1,
      altText: "Slide 1",
      caption: "",
    },
    {
      src: CarouselImage3,
      altText: "Slide 2",
      caption: "",
    },
    {
      src: CarouselImage4,
      altText: "Slide 3",
      caption: "",
    },
    {
      src: CarouselImage2,
      altText: "Slide 4",
      caption: "",
    },
  ];

  function openSneakerswipe() {
    document.getElementById("mystrytinder").scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  }

  return (
    <div
      style={{
        marginBottom: "0px",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Container
        fluid={fluid}
        style={{
          overflowX: "hidden",
          paddingLeft: !mobile && "5vw",
          paddingRight: !mobile && "5vw",
        }}
      >
        {!mobile && (
          <h1
            className="text-white"
            style={{
              marginTop: "15vh",
              marginBottom: "8vh",
              textAlign: "center",
              fontSize: "40px",
              fontWeight: "800",
            }}
          >
            What is Mystry?
          </h1>
        )}
        <Container
          fluid={fluid}
          style={{ ...containerStyle, marginTop: "3vmax" }}
        >
          {!mobile && (
            <Row className="align-items-center">
              <Col xs="6" sm="6" md="5">
                <div style={{ marginLeft: !mobile && "50px" }}>
                  <UncontrolledCarousel
                    items={carouselItems}
                    interval={4200}
                    controls={false}
                    indicators={false}
                  />
                </div>
                <div
                  style={{
                    ...responsiveBodyStyle,
                    margin: "2vmax",
                    color: "white",
                  }}
                >
                  {/*<Button color="green" size={btnSize} style={joinStyle}>Sign up to Join our Waitlist</Button>*/}
                </div>
              </Col>
              <Col xs="6" md="7">
                <div
                  style={{
                    ...responsiveBodyStyle,
                    marginLeft: "3vw",
                    color: "#dedede",
                    fontSize: "25px",
                    textAlign: "right",
                    fontWeight: "200",
                  }}
                >
                  Mystry is the only luxury sneaker box with a 100% guaranteed
                  value, delivering only the most hyped sneakers from the latest
                  releases.
                  <br />
                  <br />
                  The market value of the sneakers in your MystryBox can range
                  from <span style={{ color: "#00b200" }}>$199</span>-
                  <span style={{ color: "#00b200" }}>$999</span>, and with our
                  cutting-edge AI technology,
                  <a
                    style={{ color: "#00b200", cursor: "pointer" }}
                    onClick={() => openSneakerswipe()}
                  >
                    {" "}
                    Sneakerswipe{""}
                  </a>
                  , rest assured it’s a sneaker you’re going to love, always.
                </div>
              </Col>
            </Row>
          )}
          {mobile && (
            <>
              <Row className="align-items-center">
                <Col xs="12" sm="12">
                  <div
                    className="title text-white"
                    style={{
                      ...responsiveHeadStyle,
                      fontWeight: "800",
                      fontSize: "30px",
                      textAlign: "center",
                      marginBottom: "2vh",
                    }}
                  >
                    What is MYSTRY?
                  </div>
                  <div
                    style={{
                      ...responsiveBodyStyle,
                      marginBottom: "1vh",
                      color: "#dedede",
                      fontWeight: "200",
                    }}
                  >
                    Mystry is the only luxury sneaker box with a 100% guaranteed
                    value, delivering only the most hyped sneakers from the
                    latest releases.
                    <br />
                    <br /> The market value of the sneakers in your MystryBox
                    can range from{" "}
                    <span style={{ color: "#00b200" }}>$199</span>-
                    <span style={{ color: "#00b200" }}>$999</span>, and with our
                    cutting-edge AI technology,
                    <a
                      style={{ color: "#00b200", cursor: "pointer" }}
                      onClick={() => openSneakerswipe()}
                    >
                      {" "}
                      Sneakerswipe
                    </a>
                    , rest assured it’s a sneaker you’re going to love, always.
                  </div>
                </Col>
                <Col xs="12" sm="12" style={{ marginTop: "1vh" }}>
                  <div>
                    <UncontrolledCarousel
                      items={carouselItems}
                      interval={4200}
                      controls={false}
                      indicators={false}
                    />
                  </div>
                  <div
                    style={{
                      ...responsiveBodyStyle,
                      margin: "2vmax",
                      color: "white",
                    }}
                  ></div>
                </Col>
              </Row>
            </>
          )}
        </Container>
      </Container>
    </div>
  );
};

export default AboutMystry;
