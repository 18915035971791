import React, { useState } from "react";
import NavbarV2 from "../Navbar/Navbar";
import MystryBox from "../../../../assets/custom/MystryBox.png";
import Faqpage from "views/pages/pagesV2/FAQ/Faq";
import "./ProductPage.css";
import Footer from "views/pages/pagesV2/Footer/Footer";
import yeezyProductPage from "../../../../assets/custom/yeezy_product_page.png";
import airJordanProductPage from "../../../../assets/custom/air_jordan_product_page.png";
import nikeDunksProductPage from "../../../../assets/custom/nike_dunks_product_page.png";
import nikeAirforceProductPage from "../../../../assets/custom/nike_airforce_product_page.png";
import blueAir from "../../../../assets/custom/blueAir.mp4";
import whiteAir from "../../../../assets/custom/whiteAir.mp4";
import nikeAirLogo from "../../../../assets/custom/nike_air_logo.png";
import nikeDunksLogo from "../../../../assets/custom/nike_dunks_logo.png";
import yeezyLogo from "../../../../assets/custom/yeezy_logo.png";
import airJordanLogo from "../../../../assets/custom/air_jordan_logo.png";
import MoreProducts from "../MoreProducts/MoreProducts";
import Working from "../MystryInfo/Working";
import mb from "../../../../assets/custom/ProductPageMystryBox/MystryBoxNew.mp4";
import { Modal } from "reactstrap";
import SubscriptionModal from "../Subscribe/SubscribeModal";
import { useHistory } from "react-router-dom";
import { useAuth } from "contexts/AuthContext";

export default function ProductPage({ name }) {
  const history = useHistory();
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const map = {
    "Nike Airforce": {
      logo: nikeAirLogo,
      shoe: nikeAirforceProductPage,
      price: 140,
      guarPrice: [110, 399],
      enabled: true,
      description:
        "This MystryBox contains a pair of Air Force 1s along with a Mystry Item",
    },
    "Nike Dunk": {
      logo: nikeDunksLogo,
      shoe: nikeDunksProductPage,
      price: 169,
      guarPrice: [149, 549],
      enabled: true,
      description:
        "This MystryBox contains a pair of Nike Dunks (or Nike SB Dunks) along with a Mystry Item",
    },
    YEEZY: {
      logo: yeezyLogo,
      shoe: yeezyProductPage,
      price: 269,
      guarPrice: [220, 399],
      enabled: false,
      description:
        "This MystryBox contains a pair of Yeezys along with a Mystry Item",
    },
    "Air Jordan": {
      logo: airJordanLogo,
      shoe: airJordanProductPage,
      price: 199,
      guarPrice: [149, 699],
      enabled: true,
      description:
        "This MystryBox contains a pair of Air Jordan 1s along with a Mystry Item",
    },
  };

  const renderMoreProducts = () => {
    return Object.keys(map).map((key) => {
      if (key === name) return null;
      return (
        <MoreProducts
          productLogo={map[key].logo}
          shoe={map[key].shoe}
          price={map[key].price}
          name={key}
          guarPrice={map[key].guarPrice}
          enabled={map[key].enabled}
        />
      );
    });
  };

  // const redirectToNotify = () => {
  //   router.push({
  //     pathname: "/",
  //     state: { fromNotify: true },
  //   });
  // };

  const { loggedIn } = useAuth();

  const joinWaitlist = () => {
    // get brand
    if (!loggedIn) {
      history.push({
        pathname: `/login`,
        state: { waitlist: true },
      });
    } else {
      history.push({ pathname: "/infoFormV2", state: { brand: name } });
    }
  };

  const renderButton = () => {
    if (map[name].enabled) {
      return (
        <button onClick={joinWaitlist} className="buyButtonProduct">
          Pre-Order
        </button>
      );
    }
    return (
      <button
        style={{ backgroundColor: "#813939" }}
        disabled
        className="buyButtonProduct"
      >
        Sold Out
      </button>
    );
  };

  return (
    <div className="ProductPageWrapper">
      {/* <video
        playsInline
        src={mb}
        autoPlay
        loop
        muted
        className="ProductPageMystryBox"
      /> */}
      <div style={{marginBottom: "15vh"}}></div>
      <NavbarV2 />
      <div className="headerProduct">
        <div className="nameDesc">
          <h1 className="headerName productHeader">{`${name} Mystry Box`}</h1>
          <h3 className="headerDesc">{map[name].description}</h3>
          <h3 className="headerPrice">{`Guaranteed Value - $${map[name].guarPrice[0]} - $${map[name].guarPrice[1]}`}</h3>
          <h2 className="productPagePrice">{`Price: $${map[name].price}`}</h2>
          <div className="buttonsProduct">
            {renderButton()}
            <button onClick={toggleModal} className="notifyButtonProduct">
              Join Waitlist
            </button>
          </div>
          <Modal isOpen={modalOpen} size="lg" className="subscribeModal">
            <div className="m-4">
              <button
                type="button"
                className="close close-subscribe"
                onClick={toggleModal}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <SubscriptionModal onChange={toggleModal} />
            </div>
          </Modal>
        </div>
        <div className="imageProduct">
          <img className="yeezyProductPage" src={map[name].shoe} alt="yeezy" />
          <img className="mystryBoxProduct" src={MystryBox} alt="Mystry Box" />
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <div style={{ width: "85vw", color: "#F9F9F9" }}>
          <Working fromProduct={true} />
        </div>
      </div>

      {/* <div className="bodyProduct">
        <h1 className="bodyHeader">How does MystryBox work?</h1>
        <div className="bodyDesc">
          <div className="stepOne">
            <video className="blueAir" src={blueAir} autoPlay loop muted />
            <div className="stepOneSteps">
              <h1 className="stepOneHeader">1</h1>
              <h3 className="stepOneDesc">
                Select your MystryBox Membership - The MystryBox is available
                for both men and women, starting as low as $299/Month.
              </h3>
            </div>
          </div>
          <div className="stepTwo">
            <video className="whiteAir" src={whiteAir} autoPlay loop muted />
            <div className="stepTwoSteps">
              <h1 className="stepTwoHeader">2</h1>
              <h3 className="stepTwoDesc">
                The sneakers in your MystryBox are hand-picked using our
                proprietary AI, Sneakerswipe and we'll ensure that we deliver a
                sneaker you love, every single time.
              </h3>
            </div>
          </div>
        </div>
      </div> */}
      <div className="moreProducts">
        <h1 className="moreProductsHeader">More Products from Mystry</h1>
        <div className="moreProductsBody">{renderMoreProducts()}</div>
      </div>
      <Faqpage />
      <Footer />
    </div>
  );
}
