import React from "react";
import "./Loading.css";
import { Container } from "reactstrap";
import useWindowDimensions from "hooks/useWindowDimensions";

const Loading = ({ auth = false }) => {
  var mobile;
  const width = useWindowDimensions().width;

  if (width < 576) {
    mobile = true;
  } else {
    mobile = false;
  }

  return (
    <Container
      style={{
        marginTop: !auth ? "100px" : "",
        marginBottom: !auth ? (mobile ? "1000px" : "750px") : "",
      }}
    >
      <h1 className="loadingHeading">Mystry</h1>
      <div className="loadingWrapper">
        <div className="loadingCircle"></div>
        <div className="loadingCircle"></div>
        <div className="loadingCircle"></div>
        <div className="loadingCircle"></div>
        <div className="loadingCircle"></div>
        <div className="loadingCircle"></div>
        <span className="loadingSpan">Loading</span>
      </div>
    </Container>
  );
};

export default Loading;
