import { useHistory } from "react-router-dom";
import ReactCardFlip from "react-card-flip";
import Button from "@material-ui/core/Button";
import { Container, Row, Col } from "reactstrap";
import React, { useEffect, useState } from "react";

import * as Scroll from "react-scroll";
import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";

// import shoes from "assets/custom/shoe_data.js";
import "./Marketplace.css";
import useWindowDimensions from "hooks/useWindowDimensions";
import { logViewPage } from "../../../firebase.js";
// import shoes from "assets/custom/shoe_data.js";
import "./Marketplace.css";
// import useWindowDimensions from "hooks/useWindowDimensions";
import FloatingActionCart from "../FloatingActionCart/FloatingActionCart";
import { useSelector } from "react-redux";
import { sortedIndex } from "lodash";
import _forIn from "lodash/forIn";
var _ = require("lodash");

const MarketplacePage = () => {
  // const initIsFlipped = [
  //   false,
  //   false,
  //   false,
  //   false,
  //   false,
  //   false,
  //   false,
  //   false,
  //   false,
  //   false,
  //   false,
  //   false,
  //   false,
  //   false,
  //   false,
  //   false,
  //   false,
  //   false,
  //   false,
  //   false,
  // ];
  // const [isFlipped, setFlipped] = useState(initIsFlipped);
  // const [shoes, setShoes] = useState([]);
  const [fbShoes, setFbShoes] = useState([]);
  // const [isFlipped, setFlipped] = useState(new Array(fbShoes).fill(false));
  const [isFlipped, setFlipped] = useState([]);
  const [index, setIndex] = useState(16);
  const [index_, setIndex_] = useState(8);

  const scrollDown = () => {
    Scroll.animateScroll.scrollTo(800, {
      smooth: true,
    });
  };
  const toggleScrollDown = (e) => {
    if (e.target.currentTime >= 3) {
      setShowScrollDown(true);
    }
  };
  const shoes = useSelector((state) => state.shoes);

  useEffect(() => {
    // const ref = database.shoes;
    // var shoe_data;
    var shoes_fb = [];
    Object.values(shoes).forEach((val) => {
      if (val.availableAt.kitchen) {
        shoes_fb.push(val);
        // console.log(val.position);
      }
    });

    shoes_fb.sort((a, b) => (a.position < b.position ? -1 : 1));
    setFbShoes(shoes_fb);
    setFlipped(new Array(fbShoes).fill(false));

    //return () => ref.off();
    // eslint-disable-next-line
  }, [shoes]);

  useEffect(() => {
    logViewPage("kitchen");
  }, []);

  const width = useWindowDimensions().width;

  var mobile = false;
  if (width <= 420) {
    mobile = true;
  } else {
    mobile = false;
  }

  const shoeContainerClassName = (row) => {
    if (row === 0) {
      return "MarketplaceShoeContainer MarketplaceShoeContainerTop";
    } else {
      return "MarketplaceShoeContainer";
    }
  };

  const handleGridClick = (idx, shoe) => {
    isFlipped[idx] = !isFlipped[idx];
    setFlipped([...isFlipped]);
  };

  // reference: https://stackoverflow.com/a/35354844/10401847
  const history = useHistory();
  const handleMoreClick = (idx, shoe) => {
    history.push({
      pathname: `/sneakers/${fbShoes[idx].shoeID}`,
      state: { shoe: shoe, ebay: shoe.ebay}
    });
  };

  const putValue = (shoe) => {
    if (shoe.check === true) {
    } else {
      let url =
        "https://us-east1-price-scraper-330821.cloudfunctions.net/sneaker-price-estimator/";
      fetch(url, {
        method: "POST",
        body: JSON.stringify({
          // "sku":"716371-040"
          sku: shoe.shoeSKU,
        }),
      })
        .then((response) => response.json())
        .then((json) => {
          // setPosttArray([json]);
          //console.log(shoe.shoeSKU)
          //console.log([json]);
          var min_ = Infinity;
          _forIn([json][0], (value, key) => {
            let str = key;
            var numbers = /^[0-9]+$/;
            if (key[key.length - 1].match(numbers)) {
              //Do nothing men's shoe
            } else {
              str = key.substring(0, key.length - 1);
              str = parseFloat(str) - parseFloat(1.5);
            }
            if (value == null) {
              //object null- block
            } else if (
              parseFloat(str) < parseFloat(15) &&
              parseFloat(str) > parseFloat(4.5)
            ) {
              if (Number(value.lastSale) < min_) {
                min_ = value.lastSale;
              }
            }
          });
          shoe.marketValue = min_;
          shoe.ebay = false;
          if(shoe.marketValue == Infinity) {
            const ebayResults = []
            let ebay_url = "https://mystry-server-z574yasx7q-uc.a.run.app/scraper_api/ebay_all";
            fetch(ebay_url, {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                "sku": shoe.shoeSKU
              })
            })
            .then((response) => {
              if(!response.ok) throw new Error(response.status);
              else return response.json();
            })
            .then((data) => {
              Object.keys(data.ebayData).forEach(function(key) {
                ebayResults.push({size: key, price: data.ebayData[key].price})
              })
              shoe.marketValue = Math.round(
                Math.min(...ebayResults.map(item => item.price))
              )
            })
            .catch((error) => {
              console.log('error: ' + error);
            })
            shoe.ebay = true;
          }
        });
      shoe.check = true;
    }
    return true;
  };
  
  const [showScrollDown, setShowScrollDown] = useState(false);
  const reactCardFlipStyle = {
    paddingLeft: "10px",
    paddingRight: "10px",
    position: "absolute",
    top: "50%",
    left: "50%",
    bottom: "0",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  };

  return (
    <div
      style={{
        position: "relative",
        overflow: "hidden",
        background: "black",
      }}
    >
      <FloatingActionCart />
      <Navbar page={"marketplace"} />
      <div className="wrapper">
        <div
          style={{
            position: "relative",
            overflow: "hidden",
          }}
        >
          {/* reference to crop the video from top: https://stackoverflow.com/questions/18716077/crop-video-in-html */}
          {/*marginTop: !mobile ? `${videoOffset}px` : "0px",*/}
          {mobile && (
            <>
              <video
                width="100%"
                autoPlay
                loop
                muted
                playsInline
                style={{
                  zIndex: 1,
                  marginTop: "10vh",
                }}
                position="relative"
              >
                <source
                  src={require("assets/custom/mobile-errorh265.mp4").default}
                  type="video/mp4; codecs=hevc"
                ></source>
                <source
                  src={require("assets/custom/mobile-error.webm").default}
                  type="video/webm; codecs=vp9"
                ></source>
                <source
                  src={require("assets/custom/mobile-errorh264.mp4").default}
                  type="video/mp4"
                ></source>
              </video>
            </>
          )}
          {!mobile && (
            <>
              <video
                width="100%"
                autoPlay
                loop
                muted
                playsInline
                style={{
                  zIndex: 1,
                  marginTop: "10vh",
                }}
                position="relative"
              >
                <source
                  src={require("assets/custom/web-errorh265.mp4").default}
                  type="video/mp4; codecs=hevc"
                ></source>
                <source
                  src={require("assets/custom/web-error.webm").default}
                  type="video/webm; codecs=vp9"
                ></source>
                <source
                  src={require("assets/custom/web-errorh264.mp4").default}
                  type="video/mp4"
                ></source>
              </video>
            </>
          )}
          {showScrollDown && !mobile && (
            <div className="scrollDownContainer" onClick={scrollDown}>
              <div className="scrollDownChevron"></div>
              <div className="scrollDownChevron"></div>
              <div className="scrollDownChevron"></div>
              <span className="scrollDownText">Scroll down</span>
            </div>
          )}
        </div>
        <div style={{ marginTop: "35px", marginBottom: "50px" }}>
          {!mobile && (
            <Container
              fluid={true}
              style={{ paddingLeft: "5vw", paddingBottom: "2vh" }}
            >
              <h1 style={{ textAlign: "center" }}>
                Welcome to the Mystry Kitchen
              </h1>
              <h3 style={{ textAlign: "center" }}>
                Get a Taste of the top trending shoes across the globe
              </h3>
              <br />
              <h4>Click on a shoe to view more info</h4>
            </Container>
          )}
          {mobile && (
            <Container
              fluid={true}
              style={{ paddingLeft: "5vw", paddingBottom: "1vh" }}
            >
              <h3 className="mb-3" style={{ textAlign: "center" }}>
                Welcome to the Mystry Kitchen
              </h3>
              <p
                style={{
                  paddingLeft: "5vw",
                  paddingRight: "5vw",
                  textAlign: "center",
                }}
              >
                Get a Taste of the top trending shoes across the globe
              </p>
              <br />
              <p>Click on a shoe to view more info</p>
            </Container>
          )}
          {!mobile && (
            <Container
              fluid={true}
              style={{ paddingLeft: "5vw", paddingRight: "5vw" }}
            >
              {[...Array(Math.ceil(index / 4)).keys()].map((row, idx) => (
                <Row key={idx}>
                  {fbShoes.slice(4 * row, 4 * (row + 1)).map((shoe, idx) => (
                    <Col
                      key={idx}
                      xs={6}
                      md={3}
                      className={shoeContainerClassName(row)}
                      onMouseEnter={() => handleGridClick(4 * row + idx, shoe)}
                      onMouseLeave={() => handleGridClick(4 * row + idx, shoe)}
                    >
                      {/* 
                        reference for centering: https://www.w3.org/Style/Examples/007/center.en.html#block
                        useful SO thread: https://stackoverflow.com/questions/7273338/how-to-vertically-align-an-image-inside-a-div/7310398#7310398
                      */}
                      {putValue(shoe) && (
                        <ReactCardFlip
                          isFlipped={isFlipped[4 * row + idx]}
                          containerStyle={reactCardFlipStyle}
                        >
                          <div
                            style={{
                              display: "flex",
                              width: "80%",
                              height: "80%",
                              alignItems: "flex-end",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              style={{
                                display: "block",
                                width: "100%",
                                justifyContent: "center",
                                marginLeft: "30%",
                              }}
                              src={shoe.shoeImg.lowRes.right}
                              alt={`Shoe number ${4 * row + idx + 1}`}
                            />
                          </div>
                          <Col
                            style={{
                              alignItems: "center",
                              justifyContent: "center",
                              textAlign: "center",
                              verticalAlign: "middle",
                              top: "-25%",
                            }}
                          >
                            <div
                              className="backSideText"
                              style={{ fontSize: "1.4rem" }}
                            >
                              {/* {!landscape && <h4>{shoe.title}</h4>} */}
                              {/* {landscape && <h5>{shoe.title}</h5>} */}
                              {shoe.shoeName}
                            </div>
                            <img
                              src={shoe.shoeImg.lowRes.front}
                              alt={`Shoe number ${4 * row + idx + 1}`}
                              className="backSideShoImgFlip"
                            />
                            <div
                              style={{
                                color: "#39FF14",
                                marginBottom: "15px",
                                fontSize: "1.2rem",
                              }}
                              className="backSideText"
                            >
                              Starting at ${shoe.marketValue}
                            </div>
                            <Button
                              variant="outlined"
                              color="inherit"
                              size="small"
                              onClick={() =>
                                handleMoreClick(4 * row + idx, shoe)
                              }
                              className="backSideText"
                            >
                              See More
                            </Button>
                          </Col>
                        </ReactCardFlip>
                      )}
                    </Col>
                  ))}
                </Row>
              ))}
              {index < fbShoes.length && (
                <div className="col-md-12 text-center mt-4 mb-2">
                  <Button
                    size="sm"
                    color="green"
                    onClick={() =>
                      setIndex(Math.min(index + 16, fbShoes.length))
                    }
                    style={{
                      backgroundColor: "#00b200",
                      width: "170px",
                      height: "40px",
                      borderRadius: "10px",
                    }}
                  >
                    <span style={{ color: "white" }}>Load More </span>
                  </Button>
                </div>
              )}
            </Container>
          )}
          {mobile && (
            <Container>
              {[...Array(Math.ceil(index_ / 2)).keys()].map((row, idx) => (
                <Row
                  key={idx}
                  style={{ paddingRight: "5px", paddingLeft: "5px" }}
                >
                  {fbShoes.slice(2 * row, 2 * (row + 1)).map((shoe, idx) => (
                    <Col
                      key={idx}
                      xs="6"
                      className={shoeContainerClassName(row)}
                      onClick={() => handleGridClick(2 * row + idx, shoe)}
                    >
                      {putValue(shoe) && (
                        <ReactCardFlip
                          isFlipped={isFlipped[2 * row + idx]}
                          containerStyle={reactCardFlipStyle}
                        >
                          <div
                            style={{
                              display: "flex",
                              width: "80%",
                              height: "80%",
                              alignItems: "flex-end",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              style={{
                                display: "block",
                                width: "100%",
                                justifyContent: "center",
                                marginTop: "auto",
                                marginLeft: "30%",
                              }}
                              src={shoe.shoeImg.lowRes.right}
                              alt={`Shoe number ${2 * row + idx + 1}`}
                            />
                          </div>
                          <Col
                            style={{
                              alignItems: "center",
                              justifyContent: "center",
                              textAlign: "center",
                              verticalAlign: "middle",
                              top: "-25%",
                            }}
                          >
                            <div className="backSideText">{shoe.shoeName}</div>
                            <img
                              src={shoe.shoeImg.lowRes.front}
                              alt={`Shoe number ${2 * row + idx + 1}`}
                              className="backSideShoImgFlip"
                            />
                            <div>
                              <div
                                style={{
                                  color: "#39FF14",
                                  marginBottom: "5px",
                                }}
                                className="backSideText"
                              >
                                Starting at ${shoe.marketValue}{" "}
                              </div>
                            </div>
                            <Button
                              variant="outlined"
                              color="inherit"
                              size="small"
                              onClick={() =>
                                handleMoreClick(2 * row + idx, shoe)
                              }
                              className="backSideText"
                            >
                              See More
                            </Button>
                          </Col>
                        </ReactCardFlip>
                      )}
                    </Col>
                  ))}
                </Row>
              ))}
              {index_ < fbShoes.length && (
                <div className="col-md-12 text-center mt-4 mb-2">
                  <Button
                    size="sm"
                    color="green"
                    onClick={() =>
                      setIndex_(Math.min(index_ + 8, fbShoes.length))
                    }
                    style={{
                      backgroundColor: "#00b200",
                      width: "170px",
                      height: "40px",
                      borderRadius: "10px",
                    }}
                  >
                    <span style={{ color: "white" }}>Load More </span>
                  </Button>
                </div>
              )}
            </Container>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MarketplacePage;
