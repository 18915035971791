import React, { useState } from "react";
import {
  Row,
  Col,
  FormGroup,
  Form,
  Button,
  Input,
  Modal,
  ModalBody,
} from "reactstrap";
import states from "states-us";
import { useHistory } from "react-router";
import { useAuth } from "contexts/AuthContext";
import useWindowDimensions from "hooks/useWindowDimensions";
import { useSelector } from "react-redux";

const GuestCheckout = () => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneCountryCode, setPhoneCountryCode] = useState("+1");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("State*");
  const [zip, setZip] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);
  const confirmToggle = () => setConfirmModal(!confirmModal);
  const [guest, setGuest] = useState(null);
  const { anon_login } = useAuth();
  var shoeBody = [];
  useSelector((state) => {
    shoeBody = [];
    state.cart.cartShoes.forEach((item) => {
      shoeBody.push({
        id: item.shoe.shoeID,
        quantity: item.quantity,
        size: String(item.size).replace(".", "_"),
      });
    });
  });
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const history = useHistory();

  const handleCheckoutAsGuest = (e) => {
    e.preventDefault();
    if (state === "State*") {
      alert("Please select a valid US state.");
    } else if (!emailRegex.test(String(email).toLowerCase())) {
      alert("Please enter a valid email address");
    } else {
      var guestObj = {
        email,
        name: { firstName, lastName },
        phone: { phoneCountryCode, phoneNumber: phone },
        address: {
          firstName,
          lastName,
          address1,
          address2,
          city,
          state,
          zip,
        },
        guest: true,
      };
      setGuest(guestObj);
      confirmToggle();
    }
  };

  const handleConfirm = (e) => {
    e.preventDefault();
    anon_login().then((authUser) => {
      var guestObj = guest;
      guestObj.userID = authUser.user.uid;
      fetch(
        "https://mystry-server-z574yasx7q-uc.a.run.app/create-checkout-session",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            items: shoeBody,
            isBid: false,
            customerID: authUser.user.uid,
            customer: guestObj,
          }),
        }
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          return Promise.reject(response);
        })
        .then((responseText) => {
          window.location.replace(responseText["url"]);
        })
        .catch((error) => {
          history.push({ pathname: `/failurePayment` });
          console.error(error);
        });
      history.push({
        pathname: `/payment`,
        state: { userInfo: guestObj },
      });
    });
  };

  const handleLoginSignUp = () => {
    history.push({
      pathname: `/login`,
      state: { checkout: true },
    });
  };

  var mobile;
  const width = useWindowDimensions().width;
  if (width < 576) {
    mobile = true;
  } else {
    mobile = false;
  }

  return (
    <Row>
      <img
        src={require("assets/custom/checkout1.jpg").default}
        alt="checkout-img"
        className="checkoutImgActual"
      />
      {mobile && (
        <>
          <Col xs={12} md={6} className="checkoutLoginCol">
            <h3>Login or Sign up</h3>
            <Button color="primary" onClick={() => handleLoginSignUp()}>
              {/* <Link to="/login" style={{ color: "white" }}> */}
              Login/Sign up
              {/* </Link> */}
            </Button>
          </Col>
          <Col xs={12} md={6}>
            <h3>Continue as guest</h3>
            <Form onSubmit={handleCheckoutAsGuest}>
              <FormGroup>
                <Row>
                  <Col xs={6}>
                    <Input
                      type="text"
                      name="firstName"
                      id="firstName"
                      placeholder="First Name*"
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                    />
                  </Col>
                  <Col xs={6}>
                    <Input
                      type="text"
                      name="lastName"
                      id="lastName"
                      placeholder="Last Name*"
                      onChange={(e) => setLastName(e.target.value)}
                      required
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email*"
                  autoComplete="username"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col xs={12}>
                    <Input
                      type="text"
                      name="address1"
                      id="address1"
                      placeholder="Address 1*"
                      onChange={(e) => setAddress1(e.target.value)}
                      required
                      maxLength={100}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col xs={12}>
                    <Input
                      type="text"
                      name="address2"
                      id="address2"
                      placeholder="Address 2"
                      onChange={(e) => setAddress2(e.target.value)}
                      maxLength={100}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col xs={6}>
                    <Input
                      type="text"
                      name="city"
                      id="city"
                      placeholder="City*"
                      onChange={(e) => setCity(e.target.value)}
                      required
                      maxLength={50}
                    />
                  </Col>
                  <Col xs={6}>
                    {/* <Input
                  type="text"
                  name="state"
                  id="state"
                  placeholder="State"
                  onChange={(e) => setState(e.target.value)}
                  required={true}
                  maxLength={50}
                /> */}
                    <Input
                      type="select"
                      name="state"
                      id="state"
                      style={{ backgroundColor: "#1B1B1B" }}
                      onChange={(e) => setState(e.target.value)}
                      required
                    >
                      <option>State*</option>
                      {states.map((state, idx) => (
                        <option key={idx}>{state.abbreviation}</option>
                      ))}
                    </Input>
                  </Col>
                  {/* <Col xs={3}>
                <Input
                  type="tel"
                  name="zip"
                  id="zip"
                  placeholder="Postal/Zip"
                  onChange={(e) => setZip(e.target.value)}
                  required
                  maxLength={10}
                /> 
                  </Col> */}
                </Row>
              </FormGroup>
              <FormGroup>
                <Input
                  type="tel"
                  name="zip"
                  id="zip"
                  placeholder="Zip*"
                  onChange={(e) => setZip(e.target.value)}
                  required
                  maxLength={10}
                />
              </FormGroup>
              <FormGroup>
                <Row>
                  {/* <Col xs={3}>
                    <Input
                      type="tel"
                      name="phoneCountryCode"
                      id="phoneCountryCode"
                      placeholder="Code"
                      onChange={(e) => setPhoneCountryCode(e.target.value)}
                      value={phoneCountryCode}
                      required
                    />
                  </Col> */}
                  <Col>
                    <Input
                      type="tel"
                      name="phone"
                      id="phone"
                      placeholder="Phone number*"
                      onChange={(e) => setPhone(e.target.value)}
                      title="Please only use numbers"
                      pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                      required
                    />
                  </Col>
                </Row>
              </FormGroup>
              <Button type="submit" color="info">
                Proceed
              </Button>
              <div className="showPasswordLogin">
                *Please only use numbers for entering the phone number
              </div>
            </Form>
            {guest && (
              <Modal
                isOpen={confirmModal}
                toggle={confirmToggle}
                className="notifyModal"
              >
                <div className="modal-header cart-modal">
                  <h5 className="modal-title" style={{ color: "white" }}>
                    Confirm details
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                    onClick={confirmToggle}
                    style={{ top: "5px" }}
                  >
                    <i className="tim-icons icon-simple-remove" />
                  </button>
                </div>
                <ModalBody className="cart-modal">
                  <div>
                    <div>
                      {guest.firstName} {guest.lastName}
                    </div>
                    <div>{guest.email}</div>
                    <div>{guest.address.address1}</div>
                    <div>{guest.address.address2}</div>
                    <div>
                      {guest.address.city} {guest.address.state}
                    </div>
                    <div>{guest.address.zip}</div>
                    <div>
                      {guest.phone.phoneCountryCode} {guest.phone.phoneNumber}
                    </div>
                  </div>
                  <Button size="sm" color="simple" onClick={confirmToggle}>
                    Cancel
                  </Button>
                  <Button
                    size="sm"
                    color="simple"
                    onClick={(e) => handleConfirm(e)}
                  >
                    Confirm
                  </Button>
                </ModalBody>
              </Modal>
            )}
          </Col>
        </>
      )}
      {!mobile && (
        <>
          <Col xs={12} md={6}>
            <h3>Continue as guest</h3>
            <Form onSubmit={handleCheckoutAsGuest}>
              <FormGroup>
                <Row>
                  <Col xs={6}>
                    <Input
                      type="text"
                      name="firstName"
                      id="firstName"
                      placeholder="First Name*"
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                    />
                  </Col>
                  <Col xs={6}>
                    <Input
                      type="text"
                      name="lastName"
                      id="lastName"
                      placeholder="Last Name*"
                      onChange={(e) => setLastName(e.target.value)}
                      required
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email*"
                  autoComplete="username"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col xs={12}>
                    <Input
                      type="text"
                      name="address1"
                      id="address1"
                      placeholder="Address 1*"
                      onChange={(e) => setAddress1(e.target.value)}
                      required
                      maxLength={100}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col xs={12}>
                    <Input
                      type="text"
                      name="address2"
                      id="address2"
                      placeholder="Address 2"
                      onChange={(e) => setAddress2(e.target.value)}
                      maxLength={100}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col xs={6}>
                    <Input
                      type="text"
                      name="city"
                      id="city"
                      placeholder="City*"
                      onChange={(e) => setCity(e.target.value)}
                      required
                      maxLength={50}
                    />
                  </Col>
                  <Col xs={6}>
                    {/* <Input
                  type="text"
                  name="state"
                  id="state"
                  placeholder="State"
                  onChange={(e) => setState(e.target.value)}
                  required={true}
                  maxLength={50}
                /> */}
                    <Input
                      type="select"
                      name="state"
                      id="state"
                      style={{ backgroundColor: "#1B1B1B" }}
                      onChange={(e) => setState(e.target.value)}
                      required
                    >
                      <option>State*</option>
                      {states.map((state, idx) => (
                        <option key={idx}>{state.abbreviation}</option>
                      ))}
                    </Input>
                  </Col>
                  {/* <Col xs={3}>
                <Input
                  type="tel"
                  name="zip"
                  id="zip"
                  placeholder="Postal/Zip"
                  onChange={(e) => setZip(e.target.value)}
                  required
                  maxLength={10}
                /> 
                  </Col> */}
                </Row>
              </FormGroup>
              <FormGroup>
                <Input
                  type="tel"
                  name="zip"
                  id="zip"
                  placeholder="Zip*"
                  onChange={(e) => setZip(e.target.value)}
                  required
                  maxLength={10}
                />
              </FormGroup>
              <FormGroup>
                <Row>
                  {/* <Col xs={3}>
                    <Input
                      type="tel"
                      name="phoneCountryCode"
                      id="phoneCountryCode"
                      placeholder="Code"
                      onChange={(e) => setPhoneCountryCode(e.target.value)}
                      value={phoneCountryCode}
                      required
                    />
                  </Col> */}
                  <Col>
                    <Input
                      type="tel"
                      name="phone"
                      id="phone"
                      placeholder="Phone number*"
                      onChange={(e) => setPhone(e.target.value)}
                      title="Please only use numbers"
                      pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                      required
                    />
                  </Col>
                </Row>
              </FormGroup>
              <Button type="submit" color="info">
                Proceed
              </Button>
              <div className="showPasswordLogin">
                *Please only use numbers for entering the phone number
              </div>
            </Form>
            {guest && (
              <Modal
                isOpen={confirmModal}
                toggle={confirmToggle}
                className="notifyModal"
              >
                <div className="modal-header cart-modal">
                  <h5 className="modal-title" style={{ color: "white" }}>
                    Confirm details
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                    onClick={confirmToggle}
                    style={{ top: "5px" }}
                  >
                    <i className="tim-icons icon-simple-remove" />
                  </button>
                </div>
                <ModalBody className="cart-modal">
                  <div>
                    <div>
                      {guest.firstName} {guest.lastName}
                    </div>
                    <div>{guest.email}</div>
                    <div>{guest.address.address1}</div>
                    <div>{guest.address.address2}</div>
                    <div>
                      {guest.address.city} {guest.address.state}
                    </div>
                    <div>{guest.address.zip}</div>
                    <div>{guest.phone.phoneNumber}</div>
                  </div>
                  <Button size="sm" color="simple" onClick={confirmToggle}>
                    Cancel
                  </Button>
                  <Button
                    size="sm"
                    color="simple"
                    onClick={(e) => handleConfirm(e)}
                  >
                    Confirm
                  </Button>
                </ModalBody>
              </Modal>
            )}
          </Col>
          <Col xs={12} md={6} className="checkoutLoginCol">
            <h3>Login or Sign up</h3>
            <Button color="primary" onClick={() => handleLoginSignUp()}>
              {/* <Link to="/login" style={{ color: "white" }}> */}
              Login/Sign up
              {/* </Link> */}
            </Button>
          </Col>
        </>
      )}
    </Row>
  );
};

export default GuestCheckout;
