import React, { useState } from "react";
import "./CardFlip.css";
import { useHistory } from "react-router";
import { Container, Col, Button, row } from "reactstrap";
import useWindowDimensions from "hooks/useWindowDimensions";

const CardLanding = ({ shoe }) => {
  const history = useHistory();
  const handleMoreClick = () => {
    history.push({
      pathname: `/sneakers/${shoe.shoeID}`,
      state: { shoe: shoe },
    });
  };
  const reactCardFlipStyle = {
    bottom: "0",
  };
  const shoeContainerClassName = () => {
    return "CarouselShoeContainer CarouselShoeContainerTop";
  };
  const { width, height } = useWindowDimensions();
  var mobile, landscape;
  if (width < 768) {
    mobile = true;
    if (shoe) {
    }
    landscape = false;
  } else if (height < 576 || width < 992) {
    mobile = false;
    landscape = true;
  } else {
    mobile = false;
    landscape = false;
  }

  return (
    <div>
      <Container fluid={true} containerStyle={reactCardFlipStyle}>
          <Col
            className={shoeContainerClassName()}
            onClick={() => handleMoreClick()}
          >
            {!mobile && (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  height: "85%",
                  alignItems: "flex-end",
                  justifyContent: "center",
                }}
              >
                <img
                  style={{
                    display: "block",
                    width: "80%",
                    justifyContent: "center",
                    marginTop: "auto",
                  }}
                  src={shoe.shoeImg.highRes.right}
                  margin-left="auto"
                  margin-right="auto"
                  alt={shoe.shoeName}
                />
              </div>
            )}
            {mobile && (
              <div
                style={{
                  display: "flex",
                  width: "110%",
                  height: "100%",
                  alignItems: "flex-end",
                  justifyContent: "center",
                }}
              >
                <img
                  style={{
                    display: "block",
                    width: "110%",
                    justifyContent: "center",
                    marginTop: "auto",
                  }}
                  src={shoe.shoeImg.highRes.right}
                  alt={shoe.shoeName}
                />
              </div>
            )}
          </Col>
      </Container>
    </div>
  );
};
export default CardLanding;