import React, { useState, useEffect } from "react";
import Navbar from "components/Navbars/Navbar.js";
import { Container, Row, Col, Button } from "reactstrap";
import "./LoginSignUp.css";
import Login from "components/User/Login";
// import SignUp from 'components/User/SignUp';
import { userDb, auth } from "../../../firebase.js";
import { useAuth } from "contexts/AuthContext";
import LoginWith from "components/User/LoginWith";
import { useLocation, useHistory } from "react-router";
import { Link } from "react-router-dom";
import NavbarV2 from "../pagesV2/Navbar/Navbar";
import Footer from "../pagesV2/Footer/Footer";

const LoginPage = () => {
  //const [ loginWithEmail, setLoginWithEmail ] = useState(false);
  const { loggedIn, currentUser } = useAuth();

  const history = useHistory();
  const location = useLocation();
  const waitlist = location.state ? location.state.waitlist : false;
  const checkout = location.state ? location.state.checkout : false;
  const membershipPage = location.state ? location.state.membershipPage : false;
  const tierSelected = location.state ? location.state.tier : false;
  const sneakerswipe = location.state ? location.state.sneakerswipe :false;  

  //////console.log(membershipPage);
  //////console.log(checkout);
  ////console.log(tierSelected);
  useEffect(() => {
    auth
      .getRedirectResult()
      .then((authUser) => {
        if (authUser.user) {
          var userInfo;
          const ref = userDb(authUser.user.uid);
          // const ref = userDb(authUser.user.uid);
          // const ref = database.users;
          ref
            .once("value", (snapshot) => {
              // ////console.log("snapshot:", snapshot.val());
              userInfo = snapshot.val();
            })
            .then(() => {
              if (!userInfo) {
                const user = authUser.user;
                const obj = {
                  userID: user.uid,
                  email: user.email,
                };
                // var usersRef = database.users.push(authUser.user.uid);
                // database.users.child(usersRef.key).update({
                //   userID: user.uid,
                //   email: user.email,
                // });
                ref.set(obj);
                // userDb.child(authUser.user.uid).update({
                //   ...obj,
                // });
                // ref.update(obj.userID, obj.email);
                // ref.push();
              }
              location.search.includes("checkout")
                ? history.push("/checkout")
                : history.push("/");
            })
            .catch((error) => {
              ////console.log(error);
            });
        }
      })
      .catch((error) => {
        ////console.log(error);
      });
  }, [location, checkout, history]);

  const [showLogin, setShowLogin] = useState(loggedIn ? false : true);

  useEffect(() => {
    if (!loggedIn) {
      setShowLogin(true);
    } else {
      // if (!currentUser.emailVerified) {
      //   setShowLogin(true);
      // } else {
      window.history.go(-1)
      // }
    }
  }, [loggedIn]);

  return (
    <div
      className="wrapper"
      style={{
        position: "relative",
        overflow: "hidden",
        background: "black",
      }}
    >
      <NavbarV2 />
      <Container
        fluid={true}
        style={{
          marginTop: "100px",
          marginBottom: "75px",
          paddingLeft: "5vw",
          paddingRight: "5vw",
          background: "black",
        }}
      >
        {showLogin && (
          <Row className="loginPageHeading">
            <Col xs={0} md={6}>
              <video
                width="100%"
                autoPlay
                loop
                muted
                playsInline
                style={{
                  zIndex: 1,
                  marginTop: "2vh",
                }}
              >
                <source
                  src={require("assets/custom/aboutuscropped.mp4").default}
                  type="video/mp4; codecs=hevc"
                ></source>
                <source
                  src={require("assets/custom/aboutuscropped.webm").default}
                  type="video/webm; codecs=vp9"
                ></source>
                <source
                  src={require("assets/custom/aboutuscropped.mp4").default}
                  type="video/mp4"
                ></source>
              </video>
            </Col>
            <Col xs={12} md={6}>
              <h3>Login</h3>
              <p style={{ fontSize: "2vh" }}>
                If you don’t have an account, you can
                <Link
                  to={{ 
                    pathname: "/signup", 
                    state:{
                      checkout: checkout,
                      membershipPage: membershipPage,
                      tier: tierSelected,
                      sneakerswipe: sneakerswipe,
                      waitlist: waitlist
                    }
                  }}
                  className="link"
                >
                  {" "}
                  Sign Up{" "}
                </Link>
                here
              </p>
              <Row>
                <br />{" "}
              </Row>
              {/* <Button color="cusblack" onClick={() => setLoginWithEmail(!loginWithEmail)}>
                Login with email
              </Button>*/}
              <Login
                checkout={checkout}
                membershipPage={membershipPage}
                tierSelected={tierSelected}
                sneakerswipe={sneakerswipe}
                waitlist={waitlist}
              />
              {/* <Button color="cusblack" onClick={handleSignInWithGoogle}>
              Login with Google
            </Button> */}
              <LoginWith
                checkout={checkout}
                membershipPage={membershipPage}
                tierSelected={tierSelected}
                sneakerswipe={sneakerswipe}
                waitlist={waitlist}
              />
            </Col>
          </Row>
        )}
        {!showLogin && location.search.includes("signup") && (
           window.history.go(-1)
        )}
        {!showLogin && !sneakerswipe && !membershipPage && ( 
           <div style={{textAlign: "center"}} className="p-4">
              <div className="m-2">{currentUser.email}</div>
              <div className="m-2">You are logged in.</div>
              <Button tag={Link} to="/" color="green">Return to Home</Button>
           </div>
        )}
      </Container>
      <Footer />
    </div>
  );
};

export default LoginPage;