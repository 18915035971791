import React, { useEffect, useState } from "react";
import "./SneakerTinder.css";
import { FormGroup, Label, Input, Button, Card, CardBody } from "reactstrap";
import { database } from "../../../../firebase.js";
import FormCompleted from "./FormCompleted";

var _ = require("lodash");

const SneakerTinderSubmit = ({
  setShowButton,
  // make false to show form
  isComplete = true,
  currentUserID,
  mobile,
  landscape,
}) => {
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [size, setSize] = useState("select size");
  const [email, setEmail] = useState("");
  const [feedback, setFeedback] = useState("");
  const [submitted, setSubmitted] = useState(isComplete);
  const allSizes = [
    "select size",
    "5 W",
    "5 M",
    "5.5 W",
    "5.5 M",
    "6 W",
    "6 M",
    "6.5 W",
    "6.5 M",
    "7 W",
    "7 M",
    "7.5 W",
    "7.5 M",
    "8 W",
    "8 M",
    "8.5 W",
    "8.5 M",
    "9 W",
    "9 M",
    "9.5 W",
    "9.5 M",
    "10 W",
    "10 M",
    "10.5 W",
    "10.5 M",
    "11 W",
    "11 M",
    "11.5 W",
    "11.5 M",
    "12 W",
    "12 M",
    "12.5 W",
    "12.5 M",
    "13 W",
    "13 M",
    "13.5 W",
    "14 W",
    "14.5 W",
  ];

  const [shoeOptions, setShoeOptions] = useState(allSizes);

  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  function pushMetaDataToFirebase() {
    const ref = database.sneakerswipeUsers;
    const obj = {
      userID: currentUserID,
      email: email,
      gender: gender,
      age: age,
      size: size,
      feedback: feedback,
    };
    ref.push(obj);
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    if (email !== "" && !emailRegex.test(String(email).toLowerCase())) {
      alert("Please enter a valid email address");
    } else {
      pushMetaDataToFirebase();
      setSubmitted(true);
      setShowButton(false);
    }
  };

  useEffect(() => {
    if (gender === "male") {
      const sizes = _.range(5, 13.5, 0.5);
      sizes.unshift("select size (M)");
      setShoeOptions(sizes);
    } else if (gender === "female") {
      const sizes = _.range(5, 15, 0.5);
      sizes.unshift("select size (W)");
      setShoeOptions(sizes);
    } else {
      setShoeOptions(allSizes);
    }
    // eslint-disable-next-line
  }, [gender]);

  return (
    <>
      {!submitted && (
        <div>
          <Card className="submitCard" style={{ backgroundColor: "#1B1B1B" }}>
            <CardBody>
              <form onSubmit={handleSubmit}>
                <div className="form-row">
                  <FormGroup className="col-md-4">
                    <Label>
                      Age<span className="required">*</span>
                    </Label>
                    <br />
                    <Label style={{ left: "-4px", position: "relative" }}>
                      <Input
                        type="radio"
                        id="0-12"
                        value="0-12"
                        name="age"
                        required
                        onChange={(e) => setAge(e.target.value)}
                      />
                      0-12
                    </Label>
                    <br />
                    <Label style={{ left: "-1px", position: "relative" }}>
                      <Input
                        type="radio"
                        id="13-17"
                        value="13-17"
                        name="age"
                        onChange={(e) => setAge(e.target.value)}
                      />
                      13-17
                    </Label>
                    <br />
                    <Label style={{ left: "-1px", position: "relative" }}>
                      <Input
                        type="radio"
                        id="18-24"
                        value="18-24"
                        name="age"
                        onChange={(e) => setAge(e.target.value)}
                      />
                      18-24
                    </Label>
                    <br />
                    <Label style={{ left: "-1px", position: "relative" }}>
                      <Input
                        type="radio"
                        id="25-30"
                        value="25-30"
                        name="age"
                        onChange={(e) => setAge(e.target.value)}
                      />
                      25-30
                    </Label>
                    <br />
                    <Label style={{ left: "-1px", position: "relative" }}>
                      <Input
                        type="radio"
                        id="30-40"
                        value="30-40"
                        name="age"
                        onChange={(e) => setAge(e.target.value)}
                      />
                      30-40
                    </Label>
                    <br />
                    <Label style={{ left: "-6px", position: "relative" }}>
                      <Input
                        type="radio"
                        id=">40"
                        value=">40"
                        name="age"
                        onChange={(e) => setAge(e.target.value)}
                      />
                      {">"}40
                    </Label>
                  </FormGroup>
                  <FormGroup className="col-md-4">
                    <Label>
                      Gender<span className="required">*</span>
                    </Label>
                    <br />
                    <Label style={{ left: "6px", position: "relative" }}>
                      <Input
                        type="radio"
                        id="female"
                        value="female"
                        name="gender"
                        required
                        onChange={(e) => setGender(e.target.value)}
                      />
                      female
                    </Label>
                    <br />
                    <Label>
                      <Input
                        type="radio"
                        id="male"
                        value="male"
                        name="gender"
                        onChange={(e) => setGender(e.target.value)}
                      />
                      male
                    </Label>
                    <br />
                    <Label style={{ left: "1px", position: "relative" }}>
                      <Input
                        type="radio"
                        id="other"
                        value="other"
                        name="gender"
                        onChange={(e) => setGender(e.target.value)}
                      />
                      other
                    </Label>
                    <br />
                    <Label style={{ left: "32px", position: "relative" }}>
                      <Input
                        type="radio"
                        id="prefer not to say"
                        value="not_prefer"
                        name="gender"
                        onChange={(e) => setGender(e.target.value)}
                      />
                      prefer not to say
                    </Label>
                  </FormGroup>
                  <FormGroup className="col-md-4">
                    <Label for="selectSize">Size (US)</Label>
                    <br />
                    <Input
                      type="select"
                      name="select"
                      id="selectSize"
                      style={{ backgroundColor: "#1B1B1B" }}
                      onChange={(e) => setSize(e.target.value)}
                    >
                      {shoeOptions.map((val, idx) => (
                        <option key={idx}>{val}</option>
                      ))}
                    </Input>
                  </FormGroup>
                </div>
                <div className="form-row">
                  <FormGroup className="col-md-6">
                    <Label for="email">Email address</Label>
                    <Input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="name@example.com"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup className="col-md-6">
                    <Label for="feedback">Feedback</Label>
                    <Input
                      type="textarea"
                      name="feedback"
                      id="feedback"
                      placeholder="..."
                      onChange={(e) => setFeedback(e.target.value)}
                    />
                  </FormGroup>
                </div>
                <Button type="submit" size="sm" color="primary">
                  Submit
                </Button>
              </form>
            </CardBody>
          </Card>
        </div>
      )}
      {submitted && FormCompleted({ mobile: mobile, landscape: landscape })}
    </>
  );
};

export default SneakerTinderSubmit;
