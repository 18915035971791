import React, { useEffect, useState } from "react";
import { useAuth } from "contexts/AuthContext";
import NoUserInfo from "../Checkout/NoUserInfo";
import UserInfoExists from "../Checkout/UserInfoExists";
import { userDb } from "../../../firebase.js";
import Faq from "react-faq-component";
import { Col, Button } from "reactstrap";
import useWindowDimensions from "hooks/useWindowDimensions";
import "./ShoeBid.css";

const ShoeAddressBid = ({
  setShowAddress,
  setShowAddressBid,
  setShowPayment,
  setShippingAddress,
  setBuyNowState,
  setBidNowState,
  bidNow,
  shoe,size,setSize,
}) => {
  const { currentUser, loggedIn } = useAuth();
  const [userInfo, setUserInfo] = useState(null);
  const [seconds, setSeconds] = React.useState(60);
  const [hours, setHours] = React.useState(23);
  const [minutes, setMinutes] = React.useState(59);
  useEffect(() => {
    if (loggedIn) {
      const ref = userDb(currentUser.uid);
      // //console.log("hello");
      ref.on("value", (snapshot) => {
        var userData = snapshot.val();
        // //console.log(userData);
        // //console.log("hello2");
        setUserInfo(userData);
      });

      return () => ref.off();
    }
    // eslint-disable-next-line
  }, [loggedIn]);
  const styles = {
		bgColor: "black",
		rowTitleColor: "#d6cece",
		rowContentColor: "white",
		arrowColor: "white",
	};
  const data = {
    rows: [
           {
              title: "How we source, authenticate, and dispatch your sneakers in less than 24 hours?",
              content: (           
                <ul><li>We know you don’t want to wait 7-21 days to receive your sneakers! who does, right? and that’s why at Mystry, we strive to source, authenticate, and dispatch your sneakers all within 24 hours
    </li><li>
    
                Placing a bid on Mystry is absolutely free, and all bids last for exactly 24 hours 
                </li><li>
             
                In this 24 hour time-frame, we try to work with our network of Sneaker Chef’s to source and authenticate your sneakers. Only once every sneaker passes our 2-Factor authentication standards, do we dispatch your sneakers. Learn more about our Sneaker Chef’s here:
                </li><li>
              
                If your bid amount is not accepted within 24 hours, or if we are not able to conclusively authenticate your sneakers, the payment transaction will be reversed - and we’ll issue a full refund on the same day. Learn more about our 2-Factor authentication here.
                </li><li>
              
                Your transaction will only be processed once we have sourced, authenticated, and are ready to  deliver your sneakers (3-5 Business days).</li></ul>
                ),
              },
      ],
    };
  // useEffect(() => {
  //   if (seconds > 0 ) {
      
  //     setTimeout(() => setSeconds(seconds - 1), 1000);
  //   } 
  //   else if(seconds === 0 && minutes > 0) {
  //       setMinutes(minutes - 1);
  //       setSeconds(59);
  //   }
  //   else if(seconds===0 && minutes===0 && hours>0){
  //       setHours(hours-1);
  //       setMinutes(59);
  //       setSeconds(59);
  //   }
  //   else {
  //     setHours('');
  //     setMinutes('');
  //     setSeconds('BOOOOM!');
  //   }
  // });

  const { width, height } = useWindowDimensions();

  var landscape;

  if (width < 768) {
    // mobile = true;
    landscape = false;
  } else if (height < 576 || width < 992) {
    // mobile = false;
    landscape = true;
  } else {
    // mobile = false;
    landscape = false;
  }
  //console.log(loggedIn);
  //console.log(userInfo);
  return (
    <Col>
      <h3>Shipping details</h3>
      {loggedIn && !userInfo && (
        <NoUserInfo
          currentUser={currentUser}
          userInfo={userInfo}
          bidNow={true}
          setShowAddressBid={setShowAddressBid}
          setShowPayment={setShowPayment}
          setShippingAddress={setShippingAddress}
        />
      )}
      {loggedIn && userInfo && userInfo.name && (
        <div
          id="scrollbar"
          style={{
            overflowY: "auto",
            // height: landscape ? "250px" : "500px",
            overflowX: "hidden",
          }}
        >
          <UserInfoExists
            currentUser={currentUser}
            userInfo={userInfo}
            bidNow={true}
            shoe={shoe}
                          size={size}
                          setSize={setSize}
            setShowAddress={setShowAddress}
            setShowPayment={setShowPayment}
            setShippingAddress={setShippingAddress}
          />
        </div>
      )}
      {loggedIn && userInfo && !userInfo.name && (
        <div>
          <NoUserInfo
            currentUser={currentUser}
            userInfo={userInfo}
            bidNow={true}
            setShowAddressBid={setShowAddressBid}
            setShowPayment={setShowPayment}
            setShippingAddress={setShippingAddress}
          />
        </div>
      )}

      {<div>
        {/* <h2 align="center">Bid ending in </h2> */}
        {/* <h2 align="center" style={{color: "lightgreen"}}> {hours}:{minutes}:{seconds}</h2><br/> */}
        {/* <ul>
          <li>Who are our Sneaker Chef’s? (link to blogpost)</li>
          <li>How Mystry guarantee 100% authenticity every single time (link to blogpost)</li>

        </ul> */}
        <br></br>
        <br></br>
        <Faq data={data} styles={styles} />
      </div>}
        <br></br>
        <br></br>
      <Button
        color="simple"
        size="sm"
        onClick={() => {
          setShowAddressBid(false);
          setBidNowState(true);
        }}
      >
        Go back
      </Button>
    </Col>
  );
};

export default ShoeAddressBid;