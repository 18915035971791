import React, { useEffect, useState, useRef } from "react";
import { Col, Container, Input, FormGroup, Row } from "reactstrap";
import "./Subscription.css";
import "react-phone-input-2/lib/style.css";
import { useAuth } from "contexts/AuthContext";
import { auth } from "../../../../firebase.js";
import useWindowDimensions from "hooks/useWindowDimensions";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import { toast } from "react-toastify";

const SubscriptionModal = (props) => {
  const location = useLocation();
  const ref = useRef();

  function handleChange(event) {
    props.onChange(event.target.value);
  }

  const { width, height } = useWindowDimensions();
  var mobile, landscape;
  if (width < 768) {
    mobile = true;
    landscape = false;
  } else if (height < 576 || width < 992) {
    mobile = false;
    landscape = true;
  } else {
    mobile = false;
    landscape = false;
  }

  const [userName, setUserName] = useState("");
  const [countryCode, setCountryCode] = useState("+1");
  const [userPhone, setUserPhone] = useState();
  const [userEmail, setUserEmail] = useState("");
  const [userSubscribed, setUserSubscribed] = useState(false);
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const subscribe = (e) => {
    e.preventDefault();
    if (!emailRegex.test(String(userEmail).toLowerCase())) {
      toast.error("Please enter a valid email address");
    } else {
      fetch(
        // "http://localhost:4500/signup-emails",
        "https://mystry-server-z574yasx7q-uc.a.run.app/signup-emails",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: userName,
            email: userEmail,
            phone: {countryCode, userPhone},
            giveaway: false
          })
        }
      );

      setUserSubscribed(true);
      setUserEmail("");
      setUserPhone("");
      handleChange(e);
    }
  };

  return (
    <>
      {!userSubscribed && (
        <Container
          style={{
            backgroundColor: "black",
          }}
        >
          {(mobile || landscape) && (
            <div ref={ref} className="subscription-div-mobile">
              <p className="sub-text-mobile">
                JOIN MYSTRY BOX WAITLIST
              </p>
              <Row>
                <div className="subs-wrapper">
                  <div className="subs-content">
                    <form
                      className="subs-form mb-4"
                      id="form-subs"
                      onSubmit={subscribe}
                    >
                      <input
                        type="text"
                        placeholder="Name*"
                        className="subs-field-modal"
                        value={userName}
                        required
                        onChange={(e) => setUserName(e.target.value)}
                      />
                      <input
                        type="text"
                        placeholder="Email Address*"
                        className="subs-field"
                        value={userEmail}
                        required
                        onChange={(e) => setUserEmail(e.target.value)}
                      />
                      <FormGroup>
                        <Row>
                          <Col md={2} xs={3} className='pr-0'>
                           <Input
                            value={countryCode}
                            onChange={(e) => setCountryCode(e.target.value)}
                            type="text"
                            placeholder="+1"
                            className="mt-0"
                            required
                           />
                          </Col>
                          <Col md={10} xs={9}>
                            <Input
                              value={userPhone}
                              type="tel"
                              name="phone"
                              id="phone"
                              placeholder="Phone number*"
                              onChange={(e) => setUserPhone(e.target.value)}
                              pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                              maxLength={10}
                              className="mt-0"
                              required
                            />
                          </Col>
                        </Row>
                      </FormGroup>
                      <input
                        type="submit"
                        value="Subscribe"
                        className="subs-button"
                        id="form_submit"
                      ></input>
                    </form>
                    <Link
                      to={{ pathname: "/terms" }}
                      style={{ color: "white" }}
                    >
                      <h5>
                        <u>Terms & Conditions</u>
                      </h5>
                    </Link>
                  </div>
                </div>
              </Row>
            </div>
          )}
          {!mobile && !landscape && (
            <div ref={ref} className="subscription-div">
              <p className="sub-text-mobile">
                JOIN MYSTRY BOX WAITLIST
              </p>
              <Row>
                <div className="subs-wrapper">
                  <div className="subs-content">
                    <form
                      className="subs-form mb-4"
                      id="form-subs"
                      onSubmit={subscribe}
                    >
                      <input
                        type="text"
                        placeholder="Name*"
                        className="subs-field-modal"
                        value={userName}
                        required
                        onChange={(e) => setUserName(e.target.value)}
                      />
                      <input
                        type="text"
                        placeholder="Email Address*"
                        className="subs-field-modal"
                        value={userEmail}
                        required
                        onChange={(e) => setUserEmail(e.target.value)}
                      />
                      <FormGroup>
                        <Row>
                          <Col md={2} className='pr-0'>
                           <Input
                            value={countryCode}
                            onChange={(e) => setCountryCode(e.target.value)}
                            type="text"
                            placeholder="+1"
                            className="mt-0"
                            required
                           />
                          </Col>
                          <Col md={10}>
                            <Input
                              value={userPhone}
                              type="tel"
                              name="phone"
                              id="phone"
                              placeholder="Phone number*"
                              onChange={(e) => setUserPhone(e.target.value)}
                              pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                              maxLength={10}
                              className="mt-0"
                              required
                            />
                          </Col>
                        </Row>
                      </FormGroup>
                      <input
                        type="submit"
                        value="Subscribe"
                        className="subs-button"
                        id="form_submit"
                      ></input>
                    </form>
                    <Link
                      to={{ pathname: "/terms" }}
                      style={{ color: "white" }}
                    >
                      <h5>
                        <u>Terms & Conditions</u>
                      </h5>
                    </Link>
                  </div>
                </div>
              </Row>
            </div>
          )}
        </Container>
      )}
    </>
  );
};

export default SubscriptionModal;
