import React from "react";
import "./MystryBoxCard.css";

export default function MystryBoxCard({ logo, header, text }) {
  return (
    <div className="cardContainerMystry">
      <img className="cardImage" src={logo} alt={header} />
      <h1 className="cardHeader">{header}</h1>
      <p className="cardText">{text}</p>
    </div>
  );
}
