import React from "react";
import useWindowDimensions from "hooks/useWindowDimensions";
import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";
import { useHistory } from "react-router-dom";
import { Container, Button } from "reactstrap";
import { logViewPage } from "../../../firebase";
import { useEffect } from "react";

// import { Button } from 'reactstrap';
import "./Kitchen.css";

const Cookbook = () => {
  const rowStyle = {
    marginLeft: "auto",
    marginRight: "auto",
    height: "19vw",
    width: "95vw",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  };
  const mobileRowStyle = {
    marginLeft: "auto",
    marginRight: "auto",
    height: "40vw",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  };
  const cardStyle1 = {
    width: "19vw",
    cursor: "pointer",
  };
  const cardStyle2 = {
    width: "19vw",
    cursor: "pointer",
  };
  const cardStyle3 = { width: "47.5vw" };
  const cardStyle4 = { width: "47.5vw" };

  const overlayStyle = {
    objectFit: "contain",
    display: "block",
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    minHeight: "100%",
    borderRadius: "0px",
  };

  const width = useWindowDimensions().width;

  var mobile;
  if (width <= 420) {
    mobile = true;
  } else {
    mobile = false;
  }
  const history = useHistory();

  const brands = [
    "adidas",
    "off-white",
    "yeezy",
    "nike",
    "airjordan",
    "balenciaga",
    "supreme",
    "newbalance",
    "vans",
    "converse",
    "veja",
    "yeezy-slides",
    "airforce1",
    "nike-dunks"
  ];
  //will redirect to the marketplace page but filtered by shoes of clicked page
  const handleBrand = (brand) => history.push(`/cookbook/${brands[brand]}`);

  useEffect(() => {
    logViewPage("cookbook");
  }, []);

  return (
    <>
      <div
        style={{
          position: "relative",
          overflow: "hidden",
          background: "black",
        }}
      >
        <Navbar page={"cookbook"} />

        <div
          style={{
            marginTop: "75px",
            marginBottom: "5vh",
            alignItems: "center",
            textAlign: "center",
            background: "black",
          }}
        >
          <h1>Shop by Cuisine</h1>
        </div>

        {!mobile && (
          <div
            style={{
              marginTop: "5vh",
              overflowX: "hidden",
            }}
          >
            <div
              className="row"
              style={{ ...rowStyle, justifyContent: "center" }}
            >
              {/* <div style={cardStyle1} onClick={() => handleBrand(0)}>
                <img
                  src={
                    require("../../../assets/custom/cook/adidas.jpg").default
                  }
                  alt=""
                  style={overlayStyle}
                />
              </div> */}
              <div style={cardStyle2} onClick={() => handleBrand(1)}>
                <img
                  src={
                    require("../../../assets/custom/cook/offwhite.jpg").default
                  }
                  alt=""
                  style={overlayStyle}
                />
              </div>
              <div style={cardStyle1} onClick={() => handleBrand(2)}>
                <img
                  src={require("../../../assets/custom/cook/yeezy.jpg").default}
                  alt=""
                  style={overlayStyle}
                />
              </div>
              <div style={cardStyle2} onClick={() => handleBrand(3)}>
                <img
                  src={require("../../../assets/custom/cook/nike.jpg").default}
                  alt=""
                  style={overlayStyle}
                />
              </div>
              <div style={cardStyle2} onClick={() => handleBrand(4)}>
                <img
                  src={
                    require("../../../assets/custom/cook/airjordan.jpg").default
                  }
                  alt=""
                  style={overlayStyle}
                />
              </div>
              <div style={cardStyle2} onClick={() => handleBrand(6)}>
                <img
                  src={
                    require("../../../assets/custom/cook/supreme.jpg").default
                  }
                  alt=""
                  style={overlayStyle}
                />
              </div>
              {/* <div style={cardStyle1} onClick={() => handleBrand(8)}>
                <img
                  src={require("../../../assets/custom/cook/vans.jpg").default}
                  alt=""
                  style={overlayStyle}
                />
              </div> */}
              {/* <div style={cardStyle1} onClick={() => handleBrand(8)}>
                <img
                  src={require("../../../assets/custom/cook/vans.jpg").default}
                  alt=""
                  style={overlayStyle}
                />
              </div> */}
            </div>
            <div className="row" style={rowStyle}>
              {/* <div style={cardStyle1} onClick={() => handleBrand(5)}>
                <img
                  src={
                    require("../../../assets/custom/cook/balenciaga.jpg")
                      .default
                  }
                  alt=""
                  style={overlayStyle}
                />
              </div> */}
              <div style={cardStyle1} onClick={() => handleBrand(7)}>
                <img
                  src={
                    require("../../../assets/custom/cook/newbalance.jpg")
                      .default
                  }
                  alt=""
                  style={overlayStyle}
                />
              </div>
              <div style={cardStyle2} onClick={() => handleBrand(9)}>
                <img
                  src={
                    require("../../../assets/custom/cook/converse.jpg").default
                  }
                  alt=""
                  style={overlayStyle}
                />
              </div>
              {/* <div style={cardStyle2} onClick={() => handleBrand(10)}>
                <img
                  src={require("../../../assets/custom/cook/veja.jpg").default}
                  alt=""
                  style={overlayStyle}
                />
              </div> */}
              <div style={cardStyle2} onClick={() => handleBrand(11)}>
                <img
                  src={
                    require("../../../assets/custom/cook/yeezyslide.jpg")
                      .default
                  }
                  alt=""
                  style={overlayStyle}
                />
              </div>
              <div style={cardStyle1} onClick={() => handleBrand(12)}>
                <img
                  src={require("../../../assets/custom/cook/air-force-1.png").default}
                  alt=""
                  style={overlayStyle}
                />
              </div>
              <div style={cardStyle1} onClick={() => handleBrand(13)}>
                <img
                  src={
                    require("../../../assets/custom/cook/dunks.png")
                      .default
                  }
                  alt=""
                  style={overlayStyle}
                />
              </div>
            </div>
          </div>
        )}
        {mobile && (
          <Container style={{ padding: "0px", paddingBottom: "200px" }}>
            <div className="row" style={mobileRowStyle}>
              {/* <div style={cardStyle3} onClick={() => handleBrand(0)}>
                <img
                  src={
                    require("../../../assets/custom/cook/adidas.jpg").default
                  }
                  alt=""
                  style={overlayStyle}
                />
              </div> */}
              <div style={cardStyle4} onClick={() => handleBrand(1)}>
                <img
                  src={
                    require("../../../assets/custom/cook/offwhite.jpg").default
                  }
                  alt=""
                  style={overlayStyle}
                />
              </div>
              <div style={cardStyle4} onClick={() => handleBrand(2)}>
                <img
                  src={require("../../../assets/custom/cook/yeezy.jpg").default}
                  alt=""
                  style={overlayStyle}
                />
              </div>
            </div>
            <div className="row" style={mobileRowStyle}>
              <div style={cardStyle3} onClick={() => handleBrand(3)}>
                <img
                  src={require("../../../assets/custom/cook/nike.jpg").default}
                  alt=""
                  style={overlayStyle}
                />
              </div>
              <div style={cardStyle3} onClick={() => handleBrand(4)}>
                <img
                  src={
                    require("../../../assets/custom/cook/airjordan.jpg").default
                  }
                  alt=""
                  style={overlayStyle}
                />
              </div>
            </div>
            <div className="row" style={mobileRowStyle}>
              <div style={cardStyle3} onClick={() => handleBrand(6)}>
                <img
                  src={
                    require("../../../assets/custom/cook/supreme.jpg").default
                  }
                  alt=""
                  style={overlayStyle}
                />
              </div>
              <div style={cardStyle3} onClick={() => handleBrand(7)}>
                <img
                  src={
                    require("../../../assets/custom/cook/newbalance.jpg")
                      .default
                  }
                  alt=""
                  style={overlayStyle}
                />
              </div>
            </div>
            <div className="row" style={mobileRowStyle}>
              <div style={cardStyle4} onClick={() => handleBrand(9)}>
                <img
                  src={
                    require("../../../assets/custom/cook/converse.jpg").default
                  }
                  alt=""
                  style={overlayStyle}
                />
              </div>
              <div style={cardStyle4} onClick={() => handleBrand(11)}>
                <img
                  src={
                    require("../../../assets/custom/cook/yeezyslide.jpg")
                      .default
                  }
                  alt=""
                  style={overlayStyle}
                />
              </div>
            </div>
            <div className="row" style={mobileRowStyle}>
              <div style={cardStyle4} onClick={() => handleBrand(12)}>
                <img
                  src={
                    require("../../../assets/custom/cook/air-force-1.png").default
                  }
                  alt=""
                  style={overlayStyle}
                />
              </div>
              <div style={cardStyle4} onClick={() => handleBrand(13)}>
                <img
                  src={
                    require("../../../assets/custom/cook/dunks.png")
                      .default
                  }
                  alt=""
                  style={overlayStyle}
                />
              </div>
            </div>
          </Container>
        )}
        <div style={{ marginTop: "10vh" }}></div>
        <Footer />
      </div>
    </>
  );
};

export default Cookbook;
