import React from "react";
import { userDb, auth, provider } from "../../../../firebase.js";
import useWindowDimensions from "hooks/useWindowDimensions";
import { toast } from "react-toastify";
import GoogleButton from "react-google-button";

const ModalLoginWith = ({checkout}) => {
  const { width, height } = useWindowDimensions();

  var mobile;
  if (width <= 576 || height <= 576) {
    mobile = true;
  } else {
    mobile = false;
  }

  const handleSignInWithGoogle = () => {
    if (!mobile) {
      auth
        .signInWithPopup(provider)
        .then((authUser) => {
          var userInfo;
          const ref = userDb(authUser.user.uid);
          ref
            .once("value", (snapshot) => {
              userInfo = snapshot.val();
            })
            .then(() => {
              toast.success("Signed in successfully");
              if (!userInfo) {
                const user = authUser.user;
                const obj = {
                  userID: user.uid,
                  email: user.email,
                };
                ref.set(obj);
              }
            })
            .catch((error) => {
            });
        })
        .catch((error) => {
        });
    } else {
      if (checkout) {
        window.history.replaceState(
          {},
          "",
          `${window.location.pathname}?checkout`
        );
      }
      auth.signInWithRedirect(provider);
    }
  };

  return (
    <div>
      <GoogleButton
        onClick={handleSignInWithGoogle}
        className="signInWithGoogle"
      />
      <div className="showPasswordLogin">
        *By having an account on this website, you are agreeing to our{" "}
        <a href="/terms" target="_blank" rel="noreferrer">
          terms and conditions
        </a>
      </div>
    </div>
  );
};

export default ModalLoginWith;
