import { useHistory } from "react-router-dom";
import ReactCardFlip from "react-card-flip";

import { Container, Row, Col, Button } from "reactstrap";
import React, { useEffect, useState } from "react";

import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";

// import shoes from "assets/custom/shoe_data.js";

import "./Marketplace.css";
import useWindowDimensions from "hooks/useWindowDimensions";
import { database } from "../../../firebase.js";
// import shoes from "assets/custom/shoe_data.js";
import "./Marketplace.css";
// import useWindowDimensions from "hooks/useWindowDimensions";
import FloatingActionCart from "../FloatingActionCart/FloatingActionCart";

const DriveThru = () => {
  // const initIsFlipped = [
  //   false,
  //   false,
  //   false,
  //   false,
  //   false,
  //   false,
  //   false,
  //   false,
  //   false,
  //   false,
  //   false,
  //   false,
  //   false,
  //   false,
  //   false,
  //   false,
  //   false,
  //   false,
  //   false,
  //   false,
  // ];
  // const [isFlipped, setFlipped] = useState(initIsFlipped);
  // const [shoes, setShoes] = useState([]);
  const [fbShoes, setFbShoes] = useState([]);
  // const [isFlipped, setFlipped] = useState(new Array(fbShoes).fill(false));
  const [isFlipped, setFlipped] = useState([]);

  useEffect(() => {
    const ref = database.shoes;
    // var shoe_data;
    var shoes_fb = [];
    ref.once("value", (snapshot) => {
      var shoe_data = snapshot.val();
      // ////console.log(shoe_data);
      // ////console.log(shoe_data.values());
      Object.values(shoe_data).forEach((val) => {
        if (val.availableAt.cookbook) {
          shoes_fb.push(val);
        }
      });
      setFbShoes(shoes_fb);
      setFlipped(new Array(fbShoes).fill(false));
    });

    //return () => ref.off();
    // eslint-disable-next-line
  }, []);

  // ////console.log('fb shoes: ', fbShoes);

  // var db_init = shoes.slice(0, total_shoes); // take first 20 shoes

  const width = useWindowDimensions().width;

  var mobile = false;
  if (width <= 420) {
    mobile = true;
  } else {
    mobile = false;
  }

  const shoeContainerClassName = (row) => {
    if (row === 0) {
      return "MarketplaceShoeContainer MarketplaceShoeContainerTop";
    } else {
      return "MarketplaceShoeContainer";
    }
  };

  const handleGridClick = (idx) => {
    isFlipped[idx] = !isFlipped[idx];
    setFlipped([...isFlipped]);
  };

  // reference: https://stackoverflow.com/a/35354844/10401847
  const history = useHistory();
  const handleMoreClick = (idx, shoe) => {
    history.push({
      pathname: `/sneakers/${fbShoes[idx].shoeID}`,
      state: { shoe: shoe },
    });
  };

  const reactCardFlipStyle = {
    paddingLeft: "10px",
    paddingRight: "10px",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  };
  const discountCheckTrue = (discount) => {
    if (discount !== 0 && !isNaN(discount)) return true;
    else return false;
  };

  const discountCheckFalse = (discount) => {
    if (discount === 0 || isNaN(discount)) return true;
    else return false;
  };
  return (
    <div>
      <FloatingActionCart />
      <Navbar page={"drivethru"} />
      <div className="wrapper">
        <div style={{ marginTop: "100px", marginBottom: "75px" }}>
          <Container
            fluid={true}
            style={{ paddingLeft: "5vw", paddingBottom: "2vh" }}
          >
            <h1 style={{ fontFamily: "helveticaneuebold" }}>
              Mystry Drive-Thru Menu
            </h1>
            <h3 style={{ fontFamily: "helveticaneuebold" }}>
              Great shoes at even greater prices
            </h3>
            <span>Click on a shoe to view more info</span>
          </Container>
          {!mobile && (
            <Container
              fluid={true}
              style={{ paddingLeft: "5vw", paddingRight: "5vw" }}
            >
              {[...Array(Math.ceil(fbShoes.length / 4)).keys()].map(
                (row, idx) => (
                  <Row key={idx}>
                    {fbShoes.slice(4 * row, 4 * (row + 1)).map((shoe, idx) => (
                      <Col
                        key={idx}
                        xs={6}
                        md={3}
                        className={shoeContainerClassName(row)}
                        onClick={() => handleGridClick(4 * row + idx)}
                      >
                        {/* 
                        reference for centering: https://www.w3.org/Style/Examples/007/center.en.html#block
                        useful SO thread: https://stackoverflow.com/questions/7273338/how-to-vertically-align-an-image-inside-a-div/7310398#7310398
                      */}
                        <ReactCardFlip
                          isFlipped={isFlipped[4 * row + idx]}
                          containerStyle={reactCardFlipStyle}
                        >
                          <img
                            src={shoe.shoeImg.lowRes.right}
                            alt={`Shoe number ${4 * row + idx + 1}`}
                          />

                          <Col
                            style={{
                              alignItems: "center",
                              justifyContent: "center",
                              textAlign: "center",
                              verticalAlign: "middle",
                            }}
                          >
                            <div className="backSideText">
                              {/* {!landscape && <h4>{shoe.title}</h4>} */}
                              {/* {landscape && <h5>{shoe.title}</h5>} */}
                              {shoe.shoeName}
                            </div>
                            <img
                              src={shoe.shoeImg.lowRes.front}
                              alt={`Shoe number ${4 * row + idx + 1}`}
                              className="backSideShoImg"
                            />
                            {/*////console.log(shoe.discountPercentage)*/}
                            {discountCheckTrue(shoe.discountPercentage) && (
                              <div
                                style={
                                  {
                                    //color: '#39FF14'
                                  }
                                }
                                className="backSideText"
                              >
                                Starting at{" "}
                                <span
                                  style={{ textDecoration: "line-through" }}
                                >
                                  ${shoe.retailPrice}
                                </span>{" "}
                                |{shoe.discountPercentage}% Off{" "}
                                <span style={{ color: "#39FF14" }}>
                                  {" "}
                                  $
                                  {shoe.retailPrice -
                                    (shoe.retailPrice *
                                      shoe.discountPercentage) /
                                      100}
                                </span>
                              </div>
                            )}
                            {discountCheckFalse(shoe.discountPercentage) && (
                              <div
                                style={
                                  {
                                    //color: '#39FF14'
                                  }
                                }
                                className="backSideText"
                              >
                                Starting at ${shoe.marketValue}
                              </div>
                            )}

                            <Button
                              color="simple"
                              size="sm"
                              onClick={() =>
                                handleMoreClick(4 * row + idx, shoe)
                              }
                              className="backSideText"
                            >
                              See More
                            </Button>
                          </Col>
                        </ReactCardFlip>
                      </Col>
                    ))}
                  </Row>
                )
              )}
            </Container>
          )}
          {mobile && (
            <Container>
              {[...Array(Math.ceil(fbShoes.length / 2)).keys()].map(
                (row, idx) => (
                  <Row
                    key={idx}
                    style={{ paddingRight: "5px", paddingLeft: "5px" }}
                  >
                    {fbShoes.slice(2 * row, 2 * (row + 1)).map((shoe, idx) => (
                      <Col
                        key={idx}
                        xs="6"
                        className={shoeContainerClassName(row)}
                        onClick={() => handleGridClick(2 * row + idx)}
                      >
                        <ReactCardFlip
                          isFlipped={isFlipped[2 * row + idx]}
                          containerStyle={reactCardFlipStyle}
                        >
                          <img
                            src={shoe.shoeImg.lowRes.right}
                            alt={`Shoe number ${2 * row + idx + 1}`}
                          />

                          <Col
                            style={{
                              alignItems: "center",
                              justifyContent: "center",
                              textAlign: "center",
                              verticalAlign: "middle",
                            }}
                          >
                            <div className="backSideText">{shoe.shoeName}</div>
                            <img
                              src={shoe.shoeImg.lowRes.front}
                              alt={`Shoe number ${2 * row + idx + 1}`}
                              className="backSideShoImg"
                            />
                            <div>
                              {discountCheckTrue(shoe.discountPercentage) && (
                                <div
                                  style={
                                    {
                                      //color: '#39FF14'
                                    }
                                  }
                                  className="backSideText"
                                >
                                  Starting at{" "}
                                  <span
                                    style={{ textDecoration: "line-through" }}
                                  >
                                    ${shoe.retailPrice}
                                  </span>{" "}
                                  | {shoe.discountPercentage}% Off{" "}
                                  <span style={{ color: "#39FF14" }}>
                                    {" "}
                                    $
                                    {shoe.retailPrice -
                                      (shoe.retailPrice *
                                        shoe.discountPercentage) /
                                        100}{" "}
                                  </span>
                                </div>
                              )}
                              {discountCheckFalse(shoe.discountPercentage) && (
                                <div
                                  style={
                                    {
                                      //color: '#39FF14'
                                    }
                                  }
                                  className="backSideText"
                                >
                                  Starting at ${shoe.marketValue}
                                </div>
                              )}
                            </div>
                            <Button
                              color="simple"
                              size="sm"
                              onClick={() =>
                                handleMoreClick(2 * row + idx, shoe)
                              }
                              className="backSideText"
                            >
                              See More
                            </Button>
                          </Col>
                        </ReactCardFlip>
                      </Col>
                    ))}
                  </Row>
                )
              )}
            </Container>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DriveThru;
