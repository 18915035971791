import {React, useState} from 'react';
import Navbar from "components/Navbars/Navbar.js";
import Footer from 'components/Footer/Footer';
import * as Scroll from "react-scroll";
import useWindowDimensions from "hooks/useWindowDimensions";
import { Container, Row, Col } from "reactstrap";

const Metaverse = () => {
    const { width, height } = useWindowDimensions();

    var mobile, landscape;
    if (width < 768) {
      mobile = true;
      landscape = false;
    } else if (height < 576 || width < 992) {
      mobile = false;
      landscape = true;
    } else {
      mobile = false;
      landscape = false;
    }

    const [showScrollDown, setShowScrollDown] = useState(false);

    const scrollDown = () => {
      Scroll.animateScroll.scrollTo(800, {
        smooth: true,
      });
    };

    const toggleScrollDown = (e) => {
      if (e.target.currentTime >= 3) {
        setShowScrollDown(true);
      }
    };

    const responsiveHeadStyle = mobile
    ? { marginTop: "60px" }
    : { marginTop: "100px" };


    return (
        <>
            <Navbar/>
            <div>
              <div
                style={{
                  position: "relative",
                  overflow: "hidden",
                  background: "black",
                }}
              >
                <video
                  width="100%"
                  type="video/mp4"
                  autoPlay
                  loop
                  playsInline
                  style={{
                    zIndex: 1,
                  }}
                  position="relative"
                  onTimeUpdate={(e) => toggleScrollDown(e)}
                >
                  <source
                    src={require("assets/custom/mystryverse_new.mp4").default}
                    type="video/mp4"
                  ></source>
                </video>
                {showScrollDown && !mobile && (
                  <div className="scrollDownContainer" onClick={scrollDown}>
                    <div className="scrollDownChevron"></div>
                    <div className="scrollDownChevron"></div>
                    <div className="scrollDownChevron"></div>
                    <span className="scrollDownText">Scroll down</span>
                  </div>
                )}
              </div>
            </div>
            <Container
                fluid={true}
                style={{textAlign: "center", paddingBottom: "6vh", ...responsiveHeadStyle }}
            >
              <h1 style={{ textAlign: "center", paddingBottom: "2vh" }}>
                  Mystryverse
              </h1>
              <ul>
                <h4 style={{ paddingBottom: "1vh" }}>
                  We're reinventing the sneaker game, and we're launching the very first sneaker store on the Metaverse. 
                </h4>
                <h4 style={{ paddingBottom: "1vh" }}>
                  Get a chance to experience the latest fashion and shop from a range of 150+ sneakers on the Mystryverse, <br/>delivered to your doorstep in just 3-5 days at no additional costs.
                </h4>
                <h4 style={{ paddingBottom: "1vh", color: "#32CD32" }}>
                  Get ready to reimagine sneakers - coming soon
                </h4>
              </ul>
            </Container>
            <Footer/>
        </>
    );
};

export default Metaverse;
