import React from "react";
import {
  UncontrolledTooltip,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";

const Instructions = ({ modal, setModal }) => {
  const toggle = () => setModal(!modal);

  return (
    <div>
      <div>
        <Button
          color="info"
          id="helptarget"
          className="btn-round btn-icon"
          size="sm"
          style={{ textAlign: "center", fontSize: "2vh" }}
          onClick={toggle}
        >
          ?
        </Button>
        <UncontrolledTooltip placement="top" target="helptarget" delay={0}>
          Instructions
        </UncontrolledTooltip>
        <Modal
          isOpen={modal}
          toggle={toggle}
          size="lg"
          className="instructionModal"
        >
          <div className="modal-header">
            <h5
              className="modal-title"
              style={{ fontWeight: "bolder", fontFamily: "Exo 2" }}
              id="exampleModalLongTitle"
            >
              Instructions
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
              onClick={toggle}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
          </div>
          <ModalBody style={{ color: "black", fontFamily: "Exo 2" }}>
            <p
              style={{
                textAlign: "center",
                background: "black",
                fontWeight: "500",
                color: "white",
                fontSize: "1.1rem",
              }}
            >
              {" "}
              Welcome to{" "}
              <span
                style={{
                  color: "#FF6015",
                  fontWeight: "bold",
                  fontFamily: "edosz",
                }}
              >
                {" "}
                MYSTRY{" "}
              </span>
              SneakerSwipe
            </p>
            {/* <br /> */}
            <br />
            <p style={{ fontWeight: "500" }}>
              Swipe on the available 30 shoes to complete the SneakerSwipe.
              <br />
              Left ={" "}
              <span style={{ color: "red", fontWeight: "bold" }}>DROP</span>
              <br />
              Right ={" "}
              <span style={{ color: "green", fontWeight: "bold" }}>COP</span>
              <br />
              Swipe up/down for{" "}
              <span style={{ color: "#fcba03", fontWeight: "bold" }}>
                SUPERLIKE{" "}
              </span>
              (5 Superlikes Available) <br />
              <br />
              You only have <span style={{ fontWeight: "bold" }}>1</span> entry
              to SNEAKERSWIPE
              <br />
              Winner will be announced on Instagram
              <br />
              <br />
              <span
                style={{
                  color: "black",
                  fontWeight: "bold",
                  fontFamily: "Exo 2",
                }}
              >
                Swipe away!
              </span>
            </p>{" "}
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={toggle}
              style={{ fontFamily: "Exo 2" }}
            >
              Start
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
};

export default Instructions;
