import React, { useEffect, useState, useRef } from "react";
import { Row, Col } from "reactstrap";
import _, { debounce } from "lodash";
import useWindowDimensions from "hooks/useWindowDimensions";
import { useAuth } from "contexts/AuthContext";
import * as Scroll from "react-scroll";
import NextDrop from "../NextDrop/NextDrop";
import Working from "../MystryInfo/Working";
import Subscription from "../Subscribe/Subscription";
import Metaverse from "../Metaverse/Metaverse";
import BehindTheShoes from "../BTS/BehindTheShoes";
import BehindTheShoesMobile from '../../BehindTheShoes/BehindTheShoes'
import AboutMystry from "../MystryInfo/AboutMystry";
import MystryTinder from "../MystryTinder/MystryTinder";
import Footer from "../Footer/Footer";
import NavbarV2 from "../Navbar/Navbar";
import MystryPlus from "../MystryPlus/MystryPlus";
import Contact from "../Contact/Contact";
import MystryBox from "../MystryBoxes/MystryBox";
import MystryBoxes from "../MystryBoxes/MystryBoxes";
import FaqPage from "../FAQ/Faq";
import MystryPopUp from "../MystryPopUp/MystryPopUp";
import Shop from "../Shop/Shop";
import Explore from "../Explore/Explore";
import ExploreMobile from "../Explore/ExploreMobile";
import Brands from "../Brands/Brands";
import Giveaway from "../Giveaway/Giveaway";
import LandingKitchen from "./LandingKitchen";

export default function LandingV2() {
  React.useEffect(() => {
    document.body.classList.toggle("landing-page");
    return function cleanup() {
      document.body.classList.toggle("landing-page");
    };
  }, []);

  const overlayText = {
    position: "absolute",
    top: "25vw",
    left: "60%",
    zIndex: 2,
  };

  const [showScrollDown, setShowScrollDown] = useState(false);
  const { width, height } = useWindowDimensions();

  // window.addEventListener("load", videoScroll);
  // window.addEventListener("scroll", videoScroll);

  // function videoScroll() {
  //   if (document.querySelectorAll("video[autoplay]").length > 0) {
  //     var windowHeight = window.innerHeight,
  //       videoEl = document.querySelectorAll("video[autoplay]");

  //     for (var i = 0; i < videoEl.length; i++) {
  //       var thisVideoEl = videoEl[i],
  //         videoHeight = thisVideoEl.clientHeight,
  //         videoClientRect = thisVideoEl.getBoundingClientRect().top;

  //       if (
  //         videoClientRect <= windowHeight - videoHeight * 0.5 &&
  //         videoClientRect >= 0 - videoHeight * 0.5
  //       ) {
  //         thisVideoEl.play();
  //       } else {
  //         thisVideoEl.pause();
  //       }
  //     }
  //   }
  // }

  // const [playing, setPlaying] = useState(false);
  // const videoRef = useRef(null);

  // useEffect(() => {
  //   window.addEventListener("scroll", debounce(handleScroll, 200));
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  // const pauseVideo = () => {
  //   videoRef.current.play();
  //   videoRef.current.muted = false;
  //   setPlaying(true);
  // };

  // const handleScroll = (e) => {
  //   if (playing) {
  //     pauseVideo();
  //   }
  // };

  var mobile, landscape;
  if (width < 768) {
    mobile = true;
    landscape = false;
  } else if (height < 576 || width < 992) {
    mobile = false;
    landscape = true;
  } else {
    mobile = false;
    landscape = false;
  }

  const titleStyle = mobile
    ? { fontSize: "4vw", marginBottom: ".5vmax", fontFamily: "edosz" }
    : { fontSize: "4vw", marginBottom: ".5vmax", fontFamily: "edosz" };

  const scrollDown = () => {
    Scroll.animateScroll.scrollTo(mobile ? 500 : landscape ? 500 : 1000, {
      smooth: true,
    });
  };


  const toggleScrollDown = (e) => {
    if (e.target.currentTime >= 8) {
      setShowScrollDown(true);
    }
  };

  // const [displayContent, setDisplayContent] = useState(false);
  // Doing this to have the text appear after the video space is occupied.
  // This fixes the issue where text was appearing before video for a split second.
  // Limitation: if the internet speed is slow (for example 3G), then this might not work as expected since
  // 1 sec is not enough to load everything in slow speeds. Will result in text appearing before video
  // useEffect(() => {
    // setTimeout(() => {
      // setDisplayContent(true);
    // }, 1000);
  // }, []);

  return (
    <>
      <NavbarV2/>
      <div
        className="wrapper"
        style={{
          position: "relative",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            position: "relative",
            overflow: "hidden",
            marginBottom: "2vw",
            backgroundColor: "black",
            height: mobile? "95vh": ""
          }}
        >
          {!mobile && (
            <>
              <div className="landing-hero">
                <video
                  width="100%"
                  id="landingVid"
                  autoPlay
                  loop
                  muted
                  playsInline
                  style={{
                    zIndex: 1,
                    paddingRight: "100px",
                    paddingLeft: "100px",
                  }}
                  position="relative"
                  poster={require('assets/custom/MystryBox/LandingDesktopThumbnail.png').default}
                  onTimeUpdate={(e) => toggleScrollDown(e)}
                >
                  {/* First tries to play hevc video, then falls back to webm format, 
                  then falls back to mp4 format with h264 codec which is widely supported
                  Awesome resource for compression and type conversion: 
                  https://convertio.co/
                  
                  Note: HEVC OR H265 files from this website does not work 
                  on Apple devices: https://www.freeconvert.com/video-compressor */}

                  {/* hevc codec supported by Apple devices. Highly compressed, small size */}
                  <source
                    src={
                      require("assets/custom/hero_section_render.mp4")
                        .default
                    }
                    type="video/mp4; codecs=hvc1"
                  ></source>
                  {/* webm supported by everything besides Apple. Highly compressed, small size */}
                  {/* <source
                    src={
                      require("assets/custom/hero-video-compressed-new.webm")
                        .default
                    }
                    type="video/webm; codecs=vp9"
                  ></source> */}
                  {/* mp4 video encoded with h264. Widely supported */}
                  <source
                    src={
                      require("assets/custom/hero_section_render.mp4")
                        .default
                    }
                    type="video/mp4"
                  ></source>
                </video>
                {/* <Row
                  className="row-grid justify-content-between align-items-center text-left"
                  style={overlayText}
                >
                  <Col xs="12" s="12" lg="12" md="12">
                    can change color of this specific title by adding a new custom title class
                    <div className="title text-white" style={titleStyle}>
                      KEEP 'EM GUESSING
                    </div>
                  </Col>
                </Row> */}
                {showScrollDown && (
                  <div className="scrollDownContainer" onClick={scrollDown}>
                    <div className="scrollDownChevron"></div>
                    <div className="scrollDownChevron"></div>
                    <div className="scrollDownChevron"></div>
                    <span className="scrollDownText">Scroll down</span>
                  </div>
                )}
              </div>
            </>
          )}
          {mobile && !landscape && (
            <>
              <video
                width="100%"
                id="landingVid"
                autoPlay
                loop
                muted
                playsInline
                style={{
                  zIndex: 1,
                }}
                position="relative"
                poster={require('assets/custom/MystryBox/LandingMobileThumbnail.png').default}
                onTimeUpdate={(e) => toggleScrollDown(e)}
              >
                <source
                  src={
                    require("assets/custom/boxvideo-compressed-x265.mp4")
                      .default
                  }
                  type="video/mp4; codecs=hvc1"
                ></source>
                <source
                  src={require("assets/custom/boxvideo-compressed.mp4").default}
                  type="video/mp4"
                ></source>
              </video>
              {showScrollDown && (
                <div className="scrollDownContainer" onClick={scrollDown}>
                  <div className="scrollDownChevron"></div>
                  <div className="scrollDownChevron"></div>
                  <div className="scrollDownChevron"></div>
                  <span className="scrollDownText">Scroll down</span>
                </div>
              )}
            </>
          )}
          </div>
          {/* {displayContent && (
            <> */}
              {!mobile && (<Explore/>)}
              {mobile && (<ExploreMobile/>)}
              <MystryBox/>
              <AboutMystry/>
              <Brands/>
              <Shop/>
              <MystryPopUp/>
              <NextDrop/>
              <Subscription/>
              <Working/>
              <MystryTinder/>
              <LandingKitchen/>
              {!mobile && (<BehindTheShoes/>)}
              {mobile && (<BehindTheShoesMobile/>)}
              <Metaverse/>
              <FaqPage/>
              {!mobile && (<MystryPlus/>)}
              {!mobile && (<Contact/>)}
              {/* <Giveaway/> */}
              <Footer/>
            {/* </>
          )} */}
      </div>
    </>
  );
}
